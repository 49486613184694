import React, { Fragment, useState, useEffect, useContext } from "react";
import { H4, Btn } from "../../AbstractElements";
import {
  Container,
  Row,
  Table,
  CardFooter,
  Col,
  Card,
  CardBody,
  Form,
  Label,
} from "reactstrap";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import Select from "react-select";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import * as mergedQueries from "../../graphql/mergedQueries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import AWS from "aws-sdk";
import {
  ACCESSKEYID,
  SECRETACCESSKEY,
  limit,
  EMAIL_API,
} from "../../Config/Config";
import { aocontext } from "../../App";

import SPCompany from "../../Data/SPCompanyData/index";
import SiteData from "../../Data/Sites/index";
import CategoryData from "../../Data/Category/index";
import AssetTemplate from "../../Data/AssetTemplate/index";
import SiteDBData from "../../Data/DistributionBoard/index";
import axios from "axios";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const AddTasksContain = () => {
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [sPUser, setSPUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingtest, setIsLoadingtest] = useState(false);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  const [RepairData, setRepairData] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [repairDemo, setRepairDemo] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [technician, setTechnician] = useState([]);
  const [testing, setTesting] = useState([]);
  const [testingDemo, setTestingDemo] = useState([]);
  const [assets, setAssets] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [siteFilter, setSiteFilter] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [siteId, setSiteId] = useState([]);
  const [catId, setCatId] = useState([]);
  const [assetSiteCat, setAssetSiteCat] = useState([]);
  const [assetSiteCatFilter, setAssetSiteCatFilter] = useState([]);
  const [selectedItemsAsset, setSelectedItemsAsset] = useState([]);
  const [assetsDemo, setAssetsDemo] = useState([]);
  const [distributionBoxes, setDistributionBoxes] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [sPUserName, setSPUserName] = useState([]);
  const [assetArray, setAssetArray] = useState([]);
  const [testView, setTestView] = useState([]);
  const [viewAssets, setViewAssets] = useState([]);
  const [taskCompletionArrayView, setTaskCompletionArrayView] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [logbookData, setLogbookData] = useState([]);
  const [logbookDataSiteFilter, setLogbookDataSiteFilter] = useState([]);
  const [dbId, setDBId] = useState([]);
  const [logbookId, setLogbookId] = useState([]);
  const [spCompany, setSpCompany] = useState([]);
  const [compID, setCompId] = useState([]);
  const [ao, setAO] = useState([]);
  const [testingsitecat, settestingSitecat] = useState([]);
  const [selectedItemsAssetfordistri, setSelectedItemsAssetfordistri] =
    useState([]);
  const [selectedItemsAssetforLogbook, setSelectedItemsAssetforLogbook] =
    useState([]);
  const [selectedItemsAssetforViceVersa, setSelectedItemsAssetforViceversa] =
    useState([]);
  const [db, setDB] = useState(false);
  const [logbook, setLogbook] = useState(false);
  const [assetTemplateData, setAssetTemplateData] = useState([]);
  const [siteIdvalue, setSiteIdvalue] = useState("");
  const [dbIdvalue, setDBIdvalue] = useState("");
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);
  //const [limit, setLimit] = useState(100000);
  //console.log("selectedAssetOwnerGlobal", selectedAssetOwnerGlobal);
  const state = useLocation();
  console.log(state);

  const { id } = useParams();
  const { view } = useParams();
  const { purpose } = useParams();

  async function fetchSiteDetailsAO() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let sites = [];
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: 200000,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.id,
                  limit: 200000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  sites.push(results.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergeSites = sites.flat(1);
            setSiteDetails(
              mergeSites.filter((item) => item.status === "Active")
            );
            SiteData.setSiteData(mergeSites);
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(mergeSites))
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setSiteDetails([]);
          SiteData.setSiteData([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  //--sites for sp/su
  async function fetchSiteDetailsSP(assetOwnerId) {
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: assetOwnerId,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
                nextToken: nextToken,
              },
            })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          const siteDetails = mergeAssets;
          let nextToken1 = "";
          let assets1 = [];
          await client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              let assetDatas = result.data.listSiteDetails.items;
              nextToken1 = result.data.listSiteDetails.nextToken;
              if (result.data.listSiteDetails.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await client
                  .graphql({
                    query: queries.listSiteDetails,
                    variables: {
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken1 = results.data.listSiteDetails.nextToken;
                    if (results.data.listSiteDetails.items.length > 0) {
                      assets1.push(results.data.listSiteDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                const siteDetail = mergeAssets1.filter((item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });
                setSiteDetails(
                  siteDetail.filter((item) => item.status === "Active")
                );
                //fetchlistDistributionBox(siteDetail);
                localStorage.setItem(
                  "site",
                  base64.encode(JSON.stringify(siteDetail))
                );
                SiteData.setSiteData(siteDetail);
              }
            })
            .catch((error) => {
              console.log(error);
              setSiteDetails([]);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  //---assettemplare for ao/sp/su
  async function fetchAssetTemplate() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assetTemplates = [];
      await client
        .graphql({
          query: queries.listAssetTemplates,
          authMode: "API_KEY",
          variables: {
            limit: 200000,
          },
        })
        .then(async (dataDetails) => {
          let assetDatas = dataDetails.data.listAssetTemplates.items;
          nextToken = dataDetails.data.listAssetTemplates.nextToken;
          if (dataDetails.data.listAssetTemplates.items.length > 0) {
            assetTemplates.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listAssetTemplates,
                variables: {
                  limit: 200000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((dataDetailss) => {
                nextToken = dataDetailss.data.listAssetTemplates.nextToken;
                if (dataDetailss.data.listAssetTemplates.items.length > 0) {
                  assetTemplates.push(
                    dataDetailss.data.listAssetTemplates.items
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assetTemplates);
          if (assetTemplates.length > 0) {
            const mergeAssetTemplates = assetTemplates.flat(1);
            setAssetTemplateData(mergeAssetTemplates);
            AssetTemplate.setAssetTemplate(mergeAssetTemplates);
            localStorage.setItem(
              "assetTemp",
              base64.encode(JSON.stringify(mergeAssetTemplates))
            );
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setAssetTemplateData([]);
          AssetTemplate.setAssetTemplate([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setAssetTemplateData([]);
      AssetTemplate.setAssetTemplate([]);
    }
  }

  //--category for ao/sp/su
  async function fetchCategory() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let categories = [];
      await client
        .graphql({
          query: queries.listCategories,
          authMode: "API_KEY",
          variables: {
            limit: 200000,
          },
        })
        .then(async (dataDetails) => {
          let assetDatas = dataDetails.data.listCategories.items;
          nextToken = dataDetails.data.listCategories.nextToken;
          if (dataDetails.data.listCategories.items.length > 0) {
            categories.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listCategories,
                variables: {
                  limit: 200000,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((dataDetailss) => {
                nextToken = dataDetailss.data.listCategories.nextToken;
                if (dataDetailss.data.listCategories.items.length > 0) {
                  categories.push(dataDetailss.data.listCategories.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(categories);
          if (categories.length > 0) {
            const mergeCategories = categories.flat(1);
            setCategoryDetails(mergeCategories);
            CategoryData.setCategoryData(mergeCategories);
            localStorage.setItem(
              "category",
              base64.encode(JSON.stringify(mergeCategories))
            );
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          CategoryData.setCategoryData([]);
          setCategoryDetails([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setCategoryDetails([]);
      CategoryData.setCategoryData([]);
      setIsLoading(false);
    }
  }

  //--sp company for ao
  async function fetchSPCompanySPAO(user) {
    try {
      const getSPComp = await client.graphql({
        query: queries.getSPCompanyDetails,
        authMode: "API_KEY",
        variables: {
          id: user.companyDetailsId,
        },
      });
      console.log("company " + user.companyDetailsId, getSPComp);
      if (getSPComp.data.getSPCompanyDetails !== null) {
        let spcomp = [];
        spcomp.push(getSPComp.data.getSPCompanyDetails);
        SPCompany.setSPCompany(spcomp);
        setSpCompany(spcomp);
        localStorage.setItem(
          "spCompany",
          base64.encode(JSON.stringify(spcomp))
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function fetchSpcompany(asetOwnerId) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: asetOwnerId,
            },
          },
        },
      });
      console.log("company " + asetOwnerId, getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        setSpCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  //---sp company for su/sp
  async function fetchSPCompanySU(userId) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: userId,
            },
          },
        },
      });
      console.log("company " + userId, getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        setSpCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  //---Task Assignee Name---
  async function fetchSPUser() {
    try {
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listSPUserDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listSPUserDetails.items;
          nextToken = result.data.listSPUserDetails.nextToken;
          if (result.data.listSPUserDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listSPUserDetails,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listSPUserDetails.nextToken;
                if (results.data.listSPUserDetails.items.length > 0) {
                  assets.push(results.data.listSPUserDetails.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setSPUserName([]);
              });
          }
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            setSPUserName(mergeAssets);
          }
        })
        .catch((error) => {
          console.log(error);
          setSPUserName([]);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDb() {
    //-
    console.log(siteDetails);
    if (siteDetails.length > 0) {
      let nextToken = "";
      let assets = [];
      siteDetails.map(async (item) => {
        await client
          .graphql({
            query: queries.distributionBoxesBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              limit: limit,
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            let assetDatas = result.data.distributionBoxesBySitedetailsID.items;
            nextToken = result.data.distributionBoxesBySitedetailsID.nextToken;
            console.log(
              "first token count",
              result.data.distributionBoxesBySitedetailsID.items.length
            );
            console.log("nextToken 1", nextToken);
            if (result.data.distributionBoxesBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              await client
                .graphql({
                  query: queries.distributionBoxesBySitedetailsID,
                  variables: {
                    limit: limit,
                    sitedetailsID: item.id,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                .then((results) => {
                  nextToken =
                    results.data.distributionBoxesBySitedetailsID.nextToken;
                  console.log("nextToken", nextToken);
                  console.log(
                    results.data.distributionBoxesBySitedetailsID.items.length
                  );
                  if (
                    results.data.distributionBoxesBySitedetailsID.items.length >
                    0
                  ) {
                    assets.push(
                      results.data.distributionBoxesBySitedetailsID.items
                    );
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              setDistributionBoxes(mergeAssets);
              SiteDBData.setSiteDBData(mergeAssets);
              localStorage.setItem(
                "siteDB",
                base64.encode(JSON.stringify(mergeAssets))
              );
              //--
            } else {
              setDistributionBoxes([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listDistributionBoxes,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listDistributionBoxes.items;
          nextToken = result.data.listDistributionBoxes.nextToken;
          console.log(
            "first token count",
            result.data.listDistributionBoxes.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listDistributionBoxes.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listDistributionBoxes,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listDistributionBoxes.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listDistributionBoxes.items.length);
                if (results.data.listDistributionBoxes.items.length > 0) {
                  assets.push(results.data.listDistributionBoxes.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setDistributionBoxes(mergeAssets);
            SiteDBData.setSiteDBData(mergeAssets);
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(mergeAssets))
            );
          } else {
            setDistributionBoxes([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function getTaskAssigneeName(sPId) {
    const TaskAssigneeName = sPUserName.find((item) => item.id === sPId);
    return TaskAssigneeName ? TaskAssigneeName.email : "";
  }

  //----site unitnumber for view -----
  function getSiteUnitNumber(siteid) {
    const unitNumber = siteDetails.find((item) => item.id === siteid);
    return unitNumber ? unitNumber.siteName + " " + unitNumber.unitNumber : "";
  }

  //----category for view -----
  function getCategory(catid) {
    const category = categoryDetails.find((item) => item.id === catid);
    return category ? category.categoryName : "";
  }

  // -----list
  useEffect(() => {
    fetchSPUser();
    if (
      localStorage.getItem("siteDB") === null &&
      distributionBoxes.length === 0
    ) {
      fetchDb();
    } else {
      setDistributionBoxes(SiteDBData.getSiteDBData());
    }
    if (
      localStorage.getItem("assetTemp") === null &&
      assetTemplateData.length === 0
    ) {
      fetchAssetTemplate();
    } else {
      setAssetTemplateData(AssetTemplate.getAssetTemplate());
    }
    if (
      localStorage.getItem("category") === null &&
      categoryDetails.length === 0
    ) {
      fetchCategory();
    } else {
      setCategoryDetails(CategoryData.getCategoryData());
    }
    // fetchTestingDemotest();
    if (id) {
      async function listTasks() {
        try {
          let nextToken = "";
          let assets = [];
          await client
            .graphql({
              query: queries.listTasks,
              variables: {
                limit: limit,
                filter: {
                  taskStatus: {
                    ne: "Completed",
                  },
                },
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              let assetDatas = result.data.listTasks.items;
              nextToken = result.data.listTasks.nextToken;
              if (result.data.listTasks.items.length > 0) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await client
                  .graphql({
                    query: queries.listTasks,
                    variables: {
                      limit: limit,
                      filter: {
                        taskStatus: {
                          ne: "Completed",
                        },
                      },
                      nextToken: nextToken,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken = results.data.listTasks.nextToken;
                    if (results.data.listTasks.items.length > 0) {
                      assets.push(results.data.listTasks.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              if (assets.length > 0) {
                const mergeAssets = assets.flat(1);
                const tasks = mergeAssets.filter(
                  (item) => !item._deleted && item.id === id
                );

                const taskcompletion = JSON.parse(tasks[0].taskCompletionLink);

                if (tasks[0].taskType === "Test") {
                  console.log(JSON.parse(tasks[0].taskCompletionLink));
                  const taskscompletionlink = JSON.parse(
                    tasks[0].taskCompletionLink
                  );
                  const assets = taskscompletionlink[0].assets;
                  console.log(assets);
                  getMultipleAssetsView(assets).then((assetData) => {
                    console.log(assetData);
                    if (assetData.length > 0) {
                      setViewAssets(assetData);
                    } else {
                      setViewAssets([]);
                    }
                  });

                  setTaskCompletionArrayView(
                    JSON.parse(tasks[0].taskCompletionLink)
                  );
                  const taskCompletionLinkArray = JSON.parse(
                    tasks[0].taskCompletionLink
                  );
                  if (
                    taskCompletionLinkArray &&
                    taskCompletionLinkArray.length > 0
                  ) {
                    if (taskCompletionLinkArray[0].assets.length > 0) {
                      setAssetArray(taskCompletionLinkArray[0].assets[0]);
                    }
                  }
                }
                if (tasks[0].taskType === "Repair" || "Replace") {
                  console.log(JSON.parse(tasks[0].taskCompletionLink));
                  setTaskCompletionArrayView(
                    JSON.parse(tasks[0].taskCompletionLink)
                  );
                  const taskCompletionLinkArray = JSON.parse(
                    tasks[0].taskCompletionLink
                  );
                  //--
                  const assets = taskCompletionLinkArray[0].test;
                  console.log(assets);
                  getMultipleTestsView(assets).then((assetData) => {
                    console.log(assetData);
                    if (assetData.length > 0) {
                      setTestView(assetData);
                    } else {
                      setTestView([]);
                    }
                  });
                  // if (
                  //   taskCompletionLinkArray &&
                  //   taskCompletionLinkArray.length > 0
                  // ) {
                  //   console.log(taskCompletionLinkArray[0].test);
                  // }
                }
                //fetchViewAssets(taskcompletion[0].site);
                setTaskList({ ...tasks[0] });
                reset({ ...tasks[0] });
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      }
      listTasks();
    }
  }, [id, reset]);

  async function getAssetsView(aid) {
    try {
      const data = await client.graphql({
        query: queries.getAssets,
        authMode: "API_KEY",
        variables: {
          id: aid,
        },
      });
      if (data.data.getAssets !== null) {
        const fittingNumber = data.data.getAssets;
        return fittingNumber;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching asset with id", aid, ":", error);
      return null;
    }
  }

  async function getTestsView(aid) {
    try {
      const data = await client.graphql({
        query: queries.getTesting,
        authMode: "API_KEY",
        variables: {
          id: aid,
        },
      });
      if (data.data.getTesting !== null) {
        const fittingNumber = data.data.getTesting;
        return fittingNumber;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching getTesting with id", aid, ":", error);
      return null;
    }
  }

  const [assetData, setAssetData] = useState({});
  const getAssetsViewrepair = async (aid) => {
    try {
      const data = await client.graphql({
        query: queries.getAssets,
        authMode: "API_KEY",
        variables: { id: aid },
      });
      if (data.data.getAssets !== null) {
        const fittingNumberr = data.data.getAssets.fittingNumber;
        setAssetData((prevState) => ({
          ...prevState,
          [aid]: fittingNumberr,
        }));
      } else {
        setAssetData((prevState) => ({
          ...prevState,
          [aid]: null,
        }));
      }
    } catch (error) {
      console.error("Error fetching asset with id", aid, ":", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (testView.length > 0) {
      testView.forEach((item) => {
        if (item.assetsID) {
          getAssetsViewrepair(item.assetsID);
        }
      });
    }
  }, [testView]);

  async function getMultipleAssetsView(assetIds) {
    try {
      const assetPromises = assetIds.map((id) => getAssetsView(id));
      console.log(assetPromises);
      const assetData = await Promise.all(assetPromises);
      return assetData.filter((asset) => asset !== null);
    } catch (error) {
      console.error("Error fetching multiple assets:", error);
      return [];
    }
  }

  async function getMultipleTestsView(assetIds) {
    try {
      const assetPromises = assetIds.map((id) => getTestsView(id));
      console.log(assetPromises);
      const assetData = await Promise.all(assetPromises);
      console.log(assetData)
      return assetData.filter((asset) => asset !== null);
    } catch (error) {
      console.error("Error fetching multiple assets:", error);
      return [];
    }
  }

  //---assets for edit display
  async function fetchViewAssets(siteID) {
    //setIsLoading(true);
    let nextToken1 = "";
    let assets1 = [];
    await client
      .graphql({
        query: queries.assetsBySitedetailsID,
        variables: {
          sitedetailsID: siteID,
          limit: limit,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.assetsBySitedetailsID.items;
        nextToken1 = result.data.assetsBySitedetailsID.nextToken;
        console.log(
          "first token count",
          result.data.assetsBySitedetailsID.items.length
        );
        console.log("nextToken 1", nextToken1);
        if (result.data.assetsBySitedetailsID.items.length > 0) {
          assets1.push(assetDatas);
        }
        while (nextToken1 !== null) {
          await client
            .graphql({
              query: queries.assetsBySitedetailsID,
              variables: {
                sitedetailsID: siteID,
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken1 = results.data.assetsBySitedetailsID.nextToken;
              console.log("nextToken", nextToken1);
              console.log(results.data.assetsBySitedetailsID.items.length);
              if (results.data.assetsBySitedetailsID.items.length > 0) {
                assets1.push(results.data.assetsBySitedetailsID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets1);
        if (assets1.length > 0) {
          const mergeAssets1 = assets1.flat(1);
          console.log(mergeAssets1);
          //--
          //setViewAssets(mergeAssets1);
        } else {
          //setViewAssets([]);
        }
      })
      .catch((error) => {
        console.log(error);
        //setViewAssets([]);
      });
  }

  // testing data for view and edit
  async function fetchTestingDemotest() {
    try {
      setIsLoadingtest(true);
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listTestings,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listTestings.items;
          nextToken = result.data.listTestings.nextToken;
          if (result.data.listTestings.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listTestings,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listTestings.nextToken;
                if (results.data.listTestings.items.length > 0) {
                  assets.push(results.data.listTestings.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setTestView([]);
              });
          }
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setTestView(mergeAssets);
            setIsLoadingtest(false);
          } else {
            setTestView([]);
            setIsLoadingtest(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setTestView([]);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTestingData() {
    try {
      //setIsLoading(true);
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then((sites) => {
          let siteIds = [];
          if (sites.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.data.siteDetailsByAssetownerID.items.map((item) => {
              siteIds.push({
                siteId: {
                  eq: item.id,
                },
              });
            });
            client
              .graphql({
                query: queries.listAssets,
                variables: {
                  filter: {
                    or: siteIds,
                  },
                  limit: limit,
                },
                authMode: "API_KEY",
              })
              .then((assets) => {
                let assetIds = [];
                if (assets.data.listAssets.items.length > 0) {
                  assets.data.listAssets.items.map((item) => {
                    assetIds.push({
                      assetsID: {
                        eq: item.id,
                      },
                    });
                  });
                  client
                    .graphql({
                      query: queries.listTestings,
                      variables: {
                        filter: {
                          or: assetIds,
                        },
                        limit: limit,
                      },
                      authMode: "API_KEY",
                    })
                    .then((response) => {
                      const testingList = response.data.listTestings.items;
                      setTestingDemo(testingList);
                      setTesting(cloneDeep(testingList.slice(0, countPerPage)));
                    })
                    .catch(() => {
                      setErrorMessage("Unable to fetch the list");
                    });
                } else {
                  setTesting([]);
                  //setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
                //setIsLoading(false);
              });
          } else {
            setTesting([]);
            //setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          //setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  }

  // ----radioBox ------
  const updatePage1 = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setRepairData(cloneDeep(repairDemo.slice(from, to)));
    setTesting(cloneDeep(testingDemo.slice(from, to)));
  };

  //----Technician----
  async function fetchUserdetails() {
    try {
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listSPUserDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listSPUserDetails.items;
          nextToken = result.data.listSPUserDetails.nextToken;
          if (result.data.listSPUserDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listSPUserDetails,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listSPUserDetails.nextToken;
                if (results.data.listSPUserDetails.items.length > 0) {
                  assets.push(results.data.listSPUserDetails.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setTechnician([]);
              });
          }
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            setTechnician(mergeAssets);
          } else {
            setTechnician([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setTechnician([]);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function getTechnicianName(technicianId) {
    const technicianName = technician.find((item) => item.id === technicianId);
    return technicianName ? technicianName.email : "";
  }

  function getAssets(assetId) {
    console.log(assetId);
    console.log(assets);
    const assetNew = assets.find((item) => item.id === assetId);
    console.log(assetNew);
    return assetNew ? assetNew.fittingNumber : "";
  }

  function getDBName(dbId) {
    const assetNew = distributionBoxes.find((item) => item.id === dbId);
    return assetNew ? assetNew.distributionBoxName : "";
  }

  function logbookname(logbook) {
    const assetNew = logbookData.find((item) => item.id === logbook);
    return assetNew ? assetNew.logbookName : "";
  }

  const cancelForm = () => {
    if (LoginUserGroup === "spao") {
      if (state.state && state.state.menu === "sp") {
        navigate(`${process.env.PUBLIC_URL}/taskssp`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/tasksaosp`);
      }
    } else {
      navigate(`${process.env.PUBLIC_URL}/tasksao`);
    }
  };

  // ---logbook
  async function fetchLogbooksSites() {
    try {
      let nextToken = "";
      let logbook = [];
      await client
        .graphql({
          query: queries.listLogBooks,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listLogBooks.items;
          nextToken = result.data.listLogBooks.nextToken;
          if (result.data.listLogBooks.items.length > 0) {
            logbook.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listLogBooks,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listLogBooks.nextToken;
                if (results.data.listLogBooks.items.length > 0) {
                  logbook.push(results.data.listLogBooks.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setLogbookData([]);
              });
          }
          if (logbook.length > 0) {
            const mergeLogbook = logbook.flat(1);
            setLogbookData(mergeLogbook);
          } else {
            setLogbookData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLogbookData([]);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function getAssets1(aid) {
    try {
      const data = await client.graphql({
        query: queries.getAssets,
        authMode: "API_KEY",
        variables: {
          id: aid,
        },
      });
      const fittingNumber = data.data.getAssets.fittingNumber;
      return fittingNumber;
    } catch (error) {
      console.error("Error fetching assets:", error);
      return null;
    }
  }

  async function getAssets1temp(aid) {
    try {
      const data = await client.graphql({
        query: queries.getAssets,
        authMode: "API_KEY",
        variables: {
          id: aid,
        },
      });
      const assettemplateID = data.data.getAssets.assettemplateID;
      return assettemplateID;
    } catch (error) {
      console.error("Error fetching assets:", error);
      return null;
    }
  }

  // ----Assets for selected siteId and categoryId---(Test Task Type)
  useEffect(() => {
    async function fetchSitesDetails() {
      try {
        setIsLoading(true);
        let nextToken = "";
        let assets = [];
        await client
          .graphql({
            query: queries.assetsBySitedetailsID,
            variables: {
              sitedetailsID: siteId,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: limit,
            },
          })
          .then(async (result) => {
            let assetDatas = result.data.assetsBySitedetailsID.items;
            nextToken = result.data.assetsBySitedetailsID.nextToken;
            if (result.data.assetsBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              await client
                .graphql({
                  query: queries.assetsBySitedetailsID,
                  variables: {
                    sitedetailsID: siteId,
                    filter: {
                      status: {
                        eq: "Active",
                      },
                    },
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                .then((results) => {
                  nextToken = results.data.assetsBySitedetailsID.nextToken;
                  if (results.data.assetsBySitedetailsID.items.length > 0) {
                    assets.push(results.data.assetsBySitedetailsID.items);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              if (mergeAssets.length > 0) {
                const sort = mergeAssets.sort(
                  (a, b) => a.fittingNumber - b.fittingNumber
                );
                setAssetsDemo(sort);
                setAssetSiteCatFilter(cloneDeep(sort.slice(0, countPerPage)));
                setAssetSiteCat(sort);
                let nextToken1 = "";
                let assets1 = [];
                await client
                  .graphql({
                    query: queries.logBooksBySitedetailsID,
                    variables: {
                      limit: limit,
                      sitedetailsID: siteId,
                    },
                    authMode: "API_KEY",
                  })
                  .then(async (result) => {
                    let assetDatas = result.data.logBooksBySitedetailsID.items;
                    nextToken1 = result.data.logBooksBySitedetailsID.nextToken;
                    if (result.data.logBooksBySitedetailsID.items.length > 0) {
                      assets1.push(assetDatas);
                    }
                    while (nextToken1 !== null) {
                      await client
                        .graphql({
                          query: queries.logBooksBySitedetailsID,
                          variables: {
                            limit: limit,
                            sitedetailsID: siteId,
                            nextToken: nextToken1,
                          },
                          authMode: "API_KEY",
                        })
                        .then((results) => {
                          nextToken1 =
                            results.data.logBooksBySitedetailsID.nextToken;
                          if (
                            results.data.logBooksBySitedetailsID.items.length >
                            0
                          ) {
                            assets1.push(
                              results.data.logBooksBySitedetailsID.items
                            );
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                    if (assets1.length > 0) {
                      const mergeAssets1 = assets1.flat(1);
                      let nextToken2 = "";
                      let assets2 = [];
                      mergeAssets1.map(async (item) => {
                        await client
                          .graphql({
                            query: queries.testingsByLogbookID,
                            variables: {
                              logbookID: item.id,
                              filter: {
                                testResult: {
                                  eq: "Fail",
                                },
                              },
                              limit: limit,
                            },
                            authMode: "API_KEY",
                          })
                          .then(async (result) => {
                            let assetDatas =
                              result.data.testingsByLogbookID.items;
                            nextToken2 =
                              result.data.testingsByLogbookID.nextToken;
                            if (
                              result.data.testingsByLogbookID.items.length > 0
                            ) {
                              assets2.push(assetDatas);
                            }
                            while (nextToken2 !== null) {
                              await client
                                .graphql({
                                  query: queries.testingsByLogbookID,
                                  variables: {
                                    logbookID: item.id,
                                    filter: {
                                      testResult: {
                                        eq: "Fail",
                                      },
                                    },
                                    limit: limit,
                                    nextToken: nextToken2,
                                  },
                                  authMode: "API_KEY",
                                })
                                .then((results) => {
                                  nextToken2 =
                                    results.data.testingsByLogbookID.nextToken;
                                  if (
                                    results.data.testingsByLogbookID.items
                                      .length > 0
                                  ) {
                                    assets2.push(
                                      results.data.testingsByLogbookID.items
                                    );
                                  }
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }
                            if (assets2.length > 0) {
                              const mergeAssets2 = assets2.flat(1);
                              const testArray = [];
                              await Promise.all(
                                mergeAssets2.map(async (test) => {
                                  const asset = await getAssets1(test.assetsID);
                                  const assettempId = await getAssets1temp(
                                    test.assetsID
                                  );
                                  testArray.push({
                                    id: test.id,
                                    createdAt: test.createdAt,
                                    testOn: test.testOn,
                                    testResult: test.testResult,
                                    nextTestSchedule: test.nextTestSchedule,
                                    additionalInformation:
                                      test.additionalInformation,
                                    assetsID: asset,
                                    assetsId: test.assetsID,
                                    assettemplateID: assettempId,
                                    technician: test.technician,
                                  });
                                })
                              );
                              console.log(testArray);
                              setRepairDemo(testArray);
                              settestingSitecat(testArray);
                              setRepairData(
                                cloneDeep(testArray.slice(0, countPerPage))
                              );

                              //setIsLoading(false);
                            } else {
                              setRepairDemo([]);
                              settestingSitecat([]);
                              setRepairData(
                                cloneDeep([].slice(0, countPerPage))
                              );
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      });
                    } else {
                      setRepairDemo([]);
                      settestingSitecat([]);
                      setRepairData(cloneDeep([].slice(0, countPerPage)));
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                setAssetsDemo([]);
                setAssetSiteCatFilter(cloneDeep([].slice(0, countPerPage)));
                setAssetSiteCat([]);
              }
            } else {
              setAssetsDemo([]);
              setAssetSiteCatFilter(cloneDeep([].slice(0, countPerPage)));
              setAssetSiteCat([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } finally {
        setIsLoading(false);
      }
    }

    if (siteId !== "" && distributionBoxes.length > 0) {
      console.log(distributionBoxes);
      fetchSitesDetails(siteId);
      setLogbookDataSiteFilter(
        logbookData.filter((item) => {
          return item.siteId === siteId && item.logbookName !== null;
        })
      );
      setDistributionBoxfilter(
        distributionBoxes.filter((item) => {
          return item.sitedetailsID === siteId;
        })
      );
      console.log(
        distributionBoxes.filter((item) => {
          return item.sitedetailsID === siteId;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxes);
      setLogbookDataSiteFilter(logbookData);
    }
  }, [siteId]);

  // --- Distribution filter for fail tests
  useEffect(() => {
    async function fetchSitesDetailsDistri() {
      try {
        setIsLoading(true);
        if (dbId !== "") {
          console.log(selectedItemsAssetfordistri);
          const assetNewdistri = logbook
            ? selectedItemsAssetforViceVersa
            : selectedItemsAssetfordistri;

          console.log(assetNewdistri);
          if (assetNewdistri.length > 0) {
            const AssetDataFilter = assetNewdistri.filter(
              (item) => item.distributionboxID === dbId
            );
            const sort = AssetDataFilter.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            setAssetsDemo(sort);
            setAssetSiteCatFilter(cloneDeep(sort.slice(0, countPerPage)));
            setSelectedItemsAssetforLogbook(sort);
            setDB(true);
            let assetIds = [];
            AssetDataFilter.map((item) => {
              assetIds.push(item.id);
            });
            const promises = AssetDataFilter.map(async (item) => {
              return client.graphql({
                query: queries.testingsByAssetsID,
                variables: {
                  assetsID: item.id,
                  filter: {
                    testResult: {
                      eq: "Fail",
                    },
                  },
                  limit: limit,
                },
              });
            });
            Promise.all(promises)
              .then(async (results) => {
                const testReport = [];
                const testArray = [];
                results.forEach((result1) => {
                  const assetList = result1.data.testingsByAssetsID.items;
                  testReport.push(...assetList);
                });
                await Promise.all(
                  testReport.map(async (test) => {
                    const asset = await getAssets1(test.assetsID);
                    const assettempId = await getAssets1temp(test.assetsID);
                    testArray.push({
                      id: test.id,
                      createdAt: test.createdAt,
                      testOn: test.testOn,
                      testResult: test.testResult,
                      nextTestSchedule: test.nextTestSchedule,
                      additionalInformation: test.additionalInformation,
                      assetsID: asset,
                      assetsId: test.assetsID,
                      assettemplateID: assettempId,
                      technician: test.technician,
                    });
                  })
                );
                setRepairDemo(testArray);
                setRepairData(cloneDeep(testArray.slice(0, countPerPage)));

                //setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
                setErrorMessage("Unable to fetch the list");
              });
          }
        } else {
          setDB(false);
          const assetNewdistri = logbook
            ? selectedItemsAssetforViceVersa
            : selectedItemsAssetfordistri;
          console.log(assetNewdistri);
          const sort = assetNewdistri.sort(
            (a, b) => a.fittingNumber - b.fittingNumber
          );
          setAssetsDemo(sort);
          setAssetSiteCatFilter(cloneDeep(sort.slice(0, countPerPage)));
          setSelectedItemsAssetforLogbook(sort);
          let assetIds = [];
          assetNewdistri.map((item) => {
            assetIds.push(item.id);
          });
          const promises = assetNewdistri.map(async (item) => {
            return client.graphql({
              query: queries.testingsByAssetsID,
              variables: {
                assetsID: item.id,
                filter: {
                  testResult: {
                    eq: "Fail",
                  },
                },
                limit: limit,
              },
            });
          });
          Promise.all(promises)
            .then(async (results) => {
              const testReport = [];
              const testArray = [];
              results.forEach((result1) => {
                const assetList = result1.data.testingsByAssetsID.items;
                testReport.push(...assetList);
              });
              await Promise.all(
                testReport.map(async (test) => {
                  const asset = await getAssets1(test.assetsID);
                  const assettempId = await getAssets1temp(test.assetsID);
                  testArray.push({
                    id: test.id,
                    createdAt: test.createdAt,
                    testOn: test.testOn,
                    testResult: test.testResult,
                    nextTestSchedule: test.nextTestSchedule,
                    additionalInformation: test.additionalInformation,
                    assetsID: asset,
                    assetsId: test.assetsID,
                    assettemplateID: assettempId,
                    technician: test.technician,
                  });
                })
              );
              setRepairDemo(testArray);
              setRepairData(cloneDeep(testArray.slice(0, countPerPage)));

              //setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage("Unable to fetch the list");
            });
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchSitesDetailsDistri(dbId);
  }, [dbId]);

  // --- logbook filter for fail tests
  useEffect(() => {
    async function fetchSitesDetailsLogbook() {
      try {
        setIsLoading(true);
        if (logbookId !== "") {
          const assetNewdistri = db
            ? selectedItemsAssetforLogbook
            : selectedItemsAssetfordistri;
          console.log(assetNewdistri);
          if (assetNewdistri.length > 0) {
            const AssetDataFilter = assetNewdistri.filter(
              (item) => item.logbookId === logbookId
            );
            const sort = AssetDataFilter.sort(
              (a, b) => a.fittingNumber - b.fittingNumber
            );
            setAssetsDemo(sort);
            setAssetSiteCatFilter(cloneDeep(sort.slice(0, countPerPage)));
            setSelectedItemsAssetforViceversa(sort);
            setLogbook(true);
            let assetIds = [];
            AssetDataFilter.map((item) => {
              assetIds.push(item.id);
            });
            const promises = AssetDataFilter.map(async (item) => {
              return client.graphql({
                query: queries.testingsByAssetsID,
                variables: {
                  assetsID: item.id,
                  filter: {
                    testResult: {
                      eq: "Fail",
                    },
                  },
                  limit: limit,
                },
              });
            });
            Promise.all(promises)
              .then(async (results) => {
                const testReport = [];
                const testArray = [];
                results.forEach((result1) => {
                  const assetList = result1.data.testingsByAssetsID.items;
                  testReport.push(...assetList);
                });
                await Promise.all(
                  testReport.map(async (test) => {
                    const asset = await getAssets1(test.assetsID);
                    const assettempId = await getAssets1temp(test.assetsID);
                    testArray.push({
                      id: test.id,
                      createdAt: test.createdAt,
                      testOn: test.testOn,
                      testResult: test.testResult,
                      nextTestSchedule: test.nextTestSchedule,
                      additionalInformation: test.additionalInformation,
                      assetsID: asset,
                      assetsId: test.assetsID,
                      assettemplateID: assettempId,
                      technician: test.technician,
                    });
                  })
                );
                setRepairDemo(testArray);
                setRepairData(cloneDeep(testArray.slice(0, countPerPage)));
              })
              .catch((error) => {
                console.log(error);
                setErrorMessage("Unable to fetch the list");
              });
          }
        } else {
          setLogbook(false);
          const assetNewdistri = db
            ? selectedItemsAssetforLogbook
            : selectedItemsAssetfordistri;
          const sort = assetNewdistri.sort(
            (a, b) => a.fittingNumber - b.fittingNumber
          );
          setAssetsDemo(sort);
          setAssetSiteCatFilter(cloneDeep(sort.slice(0, countPerPage)));
          setSelectedItemsAssetforViceversa(sort);
          let assetIds = [];
          assetNewdistri.map((item) => {
            assetIds.push(item.id);
          });
          const promises = assetNewdistri.map(async (item) => {
            return client.graphql({
              query: queries.testingsByAssetsID,
              variables: {
                assetsID: item.id,
                filter: {
                  testResult: {
                    eq: "Fail",
                  },
                },
                limit: limit,
              },
            });
          });
          Promise.all(promises)
            .then(async (results) => {
              const testReport = [];
              const testArray = [];
              results.forEach((result1) => {
                const assetList = result1.data.testingsByAssetsID.items;
                testReport.push(...assetList);
              });
              await Promise.all(
                testReport.map(async (test) => {
                  const asset = await getAssets1(test.assetsID);
                  const assettempId = await getAssets1temp(test.assetsID);
                  testArray.push({
                    id: test.id,
                    createdAt: test.createdAt,
                    testOn: test.testOn,
                    testResult: test.testResult,
                    nextTestSchedule: test.nextTestSchedule,
                    additionalInformation: test.additionalInformation,
                    assetsID: asset,
                    assetsId: test.assetsID,
                    assettemplateID: assettempId,
                    technician: test.technician,
                  });
                })
              );
              setRepairDemo(testArray);
              setRepairData(cloneDeep(testArray.slice(0, countPerPage)));

              //setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage("Unable to fetch the list");
            });
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchSitesDetailsLogbook(logbookId);
  }, [logbookId]);

  // ----Failed Tests for selected siteId and categoryId (Repair and Replace task type)---
  async function getAssetsByTemplates(assetTemplates, siteID) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      let count = 0;
      console.log(assetTemplates);
      console.log(siteID);
      assetTemplates.map(async (assetTemplate) => {
        count = count + 1;
        let tempSiteId = assetTemplate.id + "_" + siteID;
        await client
          .graphql({
            query: queries.assetsByAssettemplateSiteIDAndAssetNum,
            variables: {
              assettemplateSiteID: tempSiteId,
              limit: limit,
              filter: {
                status: {
                  eq: "Active",
                },
              },
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            let assetDatas =
              result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
            nextToken =
              result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
            if (
              result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length >
              0
            ) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              await client
                .graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    limit: limit,
                    nextToken: nextToken,
                    filter: {
                      status: {
                        eq: "Active",
                      },
                    },
                  },
                  authMode: "API_KEY",
                })
                .then((results) => {
                  nextToken =
                    results.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    );
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setIsLoading(false);
                });
            }
            if (count === assetTemplates.length) {
              const mergeAssets = assets.flat(1);
              const sort = mergeAssets.sort(
                (a, b) => a.fittingNumber - b.fittingNumber
              );
              console.log(sort);
              setAssetsDemo(sort);
              setAssetSiteCatFilter(cloneDeep(sort.slice(0, countPerPage)));
              setSelectedItemsAssetfordistri(mergeAssets);
              //setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            //setIsLoading(false);
          });
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function fetchSitesDetailsData() {
      setIsLoading(true);
      try {
        //setRepairDemo(testingsitecat);
        if (selectedTaskType === "Test" && catId !== "") {
          let assetTempaltesForCategory = assetTemplateData.filter((item) => {
            return item.categoriesID === catId && item.status === "Active";
          });
          if (assetTempaltesForCategory.length > 0) {
            getAssetsByTemplates(assetTempaltesForCategory, siteId);
          }
        } else if (selectedTaskType !== "Test" && catId !== "") {
          let assetTempaltesForCategory = assetTemplateData.filter((item) => {
            return item.categoriesID === catId && item.status === "Active";
          });
          if (assetTempaltesForCategory.length > 0) {
            let assetTemp = assetTempaltesForCategory;
            console.log(testingsitecat);
            const Assets = testingsitecat.filter((item) => {
              const _matchAssets = assetTemp?.find(
                (idd) => idd.id === item?.assettemplateID
              );
              if (_matchAssets) {
                return item;
              }
            });
            console.log(Assets);
            getAssetsByTemplates(assetTempaltesForCategory, siteId);
            //setAssetsDemo(Assets);
            //setAssetSiteCatFilter(cloneDeep(Assets.slice(0, countPerPage)));
            //setSelectedItemsAssetfordistri(Assets);
            if (Assets.length > 0) {
              setRepairDemo(Assets);
              setRepairData(cloneDeep(Assets.slice(0, countPerPage)));

              //setIsLoading(false);
            } else {
              setRepairDemo([]);
              setRepairData(cloneDeep([].slice(0, countPerPage)));

              //setIsLoading(false);
            }
          }
        } else {
          setAssetsDemo(assetSiteCat);
          setAssetSiteCatFilter(cloneDeep(assetSiteCat.slice(0, countPerPage)));
          setSelectedItemsAssetfordistri(assetSiteCat);

          setRepairDemo(testingsitecat);
          setRepairData(cloneDeep(testingsitecat.slice(0, countPerPage)));

          //setIsLoading(false);
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchSitesDetailsData(catId);
  }, [catId]);

  // ---Asset Multiple selection ----
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAllSelectedRepair, setIsAllSelectedRepair] = useState(false);
  const handleCheckboxAssetChange = (event, item) => {
    const isChecked = event.target.checked;
    console.log(isChecked);

    if (item === "selectAll") {
      console.log(isChecked);
      setIsAllSelected(isChecked);

      if (isChecked) {
        console.log(assetsDemo);
        setSelectedItemsAsset(assetsDemo.slice());
      } else {
        setSelectedItemsAsset([]);
      }
    } else {
      setSelectedItemsAsset((prevSelectedItems) => {
        const updatedItems = isChecked
          ? [...prevSelectedItems, item]
          : prevSelectedItems.filter((selectedItem) => selectedItem !== item);
        // Update isAllSelected based on the length of updatedItems
        setIsAllSelected(updatedItems.length === assetsDemo.length);
        return updatedItems;
      });
      console.log(selectedItemsAsset);
      // Update isAllSelected after updating selectedItemsAsset
      //setIsAllSelected(selectedItemsAsset.length === assetsDemo.length);
    }
  };

  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;

    if (item === "selectAll") {
      setIsAllSelectedRepair(isChecked);

      if (isChecked) {
        setSelectedItems(repairDemo.slice());
      } else {
        setSelectedItems([]);
      }
    } else {
      setSelectedItems((prevSelectedItems) => {
        if (isChecked && !prevSelectedItems.includes(item)) {
          return [...prevSelectedItems, item];
        } else if (!isChecked && prevSelectedItems.includes(item)) {
          return prevSelectedItems.filter(
            (selectedItem) => selectedItem !== item
          );
        }
        return prevSelectedItems;
      });

      setIsAllSelectedRepair(selectedItems.length === repairDemo.length);
    }
  };

  const updatePage2 = (p) => {
    setCurrentPage1(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setAssetSiteCatFilter(cloneDeep(assetsDemo.slice(from, to)));
  };

  //--- for sp comapny filtered sp user
  async function fetchSpuserforSpComp(compId) {
    if (compId.length > 0) {
      client
        .graphql({
          query: queries.listUserDetails,
          authMode: "API_KEY",
          variables: {
            filter: {
              companyDetailsId: {
                eq: compId,
              },
            },
            limit: limit,
          },
        })
        .then((response) => {
          const spuser = response.data.listUserDetails.items;
          if (spuser.length > 0) {
            client
              .graphql({
                query: queries.listSPUserDetails,
                authMode: "API_KEY",
                variables: {
                  filter: {
                    status: {
                      eq: "Active",
                    },
                    userGroup: {
                      eq: "electrician",
                    },
                  },
                  limit: limit,
                },
              })
              .then((response3) => {
                const spDetails2 =
                  response3.data.listSPUserDetails.items.filter((item) => {
                    const _matchSites = spuser?.find(
                      (user) => user.id === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  });
                setSPUser(spDetails2);
              })
              .catch((error4) => {
                console.log(error4);
              });
          } else {
            setSPUser([]);
          }
        })
        .catch((error4) => {
          console.log(error4);
        });
    } else {
      console.log("No company selected");
      setSPUser([]);
    }
  }

  // ---Ao
  async function fetchAO() {
    try {
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listAssetOwners,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listAssetOwners.items;
          nextToken = result.data.listAssetOwners.nextToken;
          if (result.data.listAssetOwners.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listAssetOwners,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listAssetOwners.nextToken;
                if (results.data.listAssetOwners.items.length > 0) {
                  assets.push(results.data.listAssetOwners.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setAO(mergeAssets);
          } else {
            setAO([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAOSP() {
    try {
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listSPUserDetails,
          variables: {
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.listSPUserDetails.items;
          nextToken = result.data.listSPUserDetails.nextToken;
          if (result.data.listSPUserDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listSPUserDetails,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listSPUserDetails.nextToken;
                if (results.data.listSPUserDetails.items.length > 0) {
                  assets.push(results.data.listSPUserDetails.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setAO(mergeAssets);
          } else {
            setAO([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function getAo(aoId) {
    const assetNew = ao.find((item) => item.id === aoId);
    if (LoginUserGroup === "site_owner") {
      return assetNew ? assetNew.firstName + " " + assetNew.lastName : "";
    } else {
      return assetNew ? assetNew.fname + " " + assetNew.lname : "";
    }
  }

  function getCompany(cid) {
    const technicianName =
      LoginUserGroup === "spao"
        ? spCompany.find((item) => item.id === cid)
        : spCompany.find((item) => item.spcomapnyId === cid);
    return technicianName ? technicianName.companyName : "";
  }

  // mail function----
  const sendEmail = async (recipientEmail, taskData) => {
    const {
      assignedBy,
      taskAssignee,
      TaskType,
      TaskStatus,
      DueDate,
      site,
      category,
      logbook,
      distributionBoard,
      assets,
      AssetModel,
      AssetLocation,
      AssetDescription,
      AssetWarrantyStart,
      AssetWarrantyEnd,
      InstalledOn,
      testOn,
      nextTestSchedule,
      testResult,
      assetTest,
      company,
    } = taskData;
    const senderEmail = "noreply@knowyourasset.com.au";
    const subject = "Task Assigned";

    let message = `
    <html>
    <body>
      <p>Hello,</p>
      <p>You have been assigned a new task:</p>
      <table style="border-collapse: collapse; width: 80%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Assigned By:</strong></td>
          <td>${assignedBy}</td>
        </tr>       
        
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Type:</strong></td>
          <td>${TaskType}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Task Status:</strong></td>
          <td>${TaskStatus}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Company:</strong></td>
          <td>${company}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Site:</strong></td>
          <td>${site}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Distribution Board:</strong></td>
          <td>${distributionBoard}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Logbook:</strong></td>
          <td>${logbook}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Category:</strong></td>
          <td>${category}</td>
        </tr>
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Due Date:</strong></td>
          <td>${DueDate}</td>
        </tr>
      </table>`;

    if (selectedItemsAsset && selectedItemsAsset.length > 0) {
      message += `
      <p><strong>Assets:</strong></p>
      <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
          <td style="border-right: 1px solid black;"><strong>Fitting Number</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Model</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Location</strong></td>
          <td style="border-right: 1px solid black;"><strong>Asset Description</strong></td>
          <td style="border-right: 1px solid black;"><strong>Installed On</strong></td>
          <td style="border-right: 1px solid black;"><strong>Warranty Start</strong></td>
          <td><strong>Warranty End</strong></td>
        </tr>`;
      selectedItemsAsset.forEach((asset) => {
        message += `
          <tr style="border-bottom: 1px solid black;">
            <td style="border-right: 1px solid black;">${asset.fittingNumber
          }</td>
            <td style="border-right: 1px solid black;">${asset.assetModel}</td>
            <td style="border-right: 1px solid black;">${asset.assetLocation
          }</td>
            <td style="border-right: 1px solid black;">${asset.assetDescription
          }</td>
            <td style="border-right: 1px solid black;">${moment(
            asset.installedOn
          ).format("DD/MM/YYYY")}</td>
            <td style="border-right: 1px solid black;">${moment(
            asset.assetWarrantyStart
          ).format("DD/MM/YYYY")}</td>
            <td>${moment(asset.assetWarrantyEnd).format("DD/MM/YYYY")}</td>
          </tr>`;
      });
      message += `
      </table>`;
    }

    if (selectedItems && selectedItems.length > 0) {
      message += `
      <p><strong>Test:</strong></p>
      <table style="border-collapse: collapse; width: 100%; border: 1px solid black;">
        <tr style="border-bottom: 1px solid black;">
        <td style="border-right: 1px solid black;"><strong>Fitting NO.</strong></td>
          <td style="border-right: 1px solid black;"><strong>Test On</strong></td>
          <td style="border-right: 1px solid black;"><strong>Test Result</strong></td>
          <td><strong>Next Test Schedule Date</strong></td>
        </tr>`;
      selectedItems.forEach((assetTest) => {
        message += `
        <tr style="border-bottom: 1px solid black;">
        <td style="border-right: 1px solid black;">${assetTest.assetsID}</td>
          <td style="border-right: 1px solid black;">${moment(
          assetTest.testOn
        ).format("DD/MM/YYYY")}</td>
          <td style="border-right: 1px solid black;">${assetTest.testResult
          }</td>
          <td>${moment(assetTest.nextTestSchedule).format("DD/MM/YYYY")}</td>
          </tr>`;
      });
      message += `
        </table>`;
    }

    message += `
      <p>Please complete the task by the due date.</p>
      <p>Best regards,</p>
      <p>KYA</p>
    </body>
    </html>`;
    try {
      await axios.post(EMAIL_API, {
        body: JSON.stringify({
          email: recipientEmail,
          subject: "Task Assigned",
          messageBody: message,
        }),
      });
    } catch (error) {
      console.log("error ", error);
    }
    // const params = {
    //   Destination: {
    //     ToAddresses: [recipientEmail],
    //   },
    //   Message: {
    //     Body: {
    //       Html: { Data: message },
    //     },
    //     Subject: { Data: subject },
    //   },
    //   Source: senderEmail,
    // };

    // const sendPromise = new AWS.SES({ apiVersion: "2010-12-01" })
    //   .sendEmail(params)
    //   .promise();

    // sendPromise
    //   .then((data) => {
    //     console.log("Email sent:", data.MessageId);
    //   })
    //   .catch((error) => {
    //     console.log("Error sending email:", error);
    //   });
  };

  useEffect(() => {
    fetchLogbooksSites();
    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      LoginUserGroup === "site_owner"
        ? fetchSiteDetailsAO()
        : LoginUserGroup === "site_user"
          ? fetchSiteDetailsSU()
          : fetchSiteDetailsSP(LoginUserDetails.id);
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
      setSiteFilter(activeSites.filter((item) => item.status === "Active"));
    }
    if (localStorage.getItem("spCompany") === null && spCompany.length === 0) {
      LoginUserGroup === "site_owner"
        ? fetchSpcompany(LoginUserDetails.id)
        : LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
          ? fetchSPCompanySU(LoginUserDetails.id)
          : fetchSPCompanySPAO(LoginUserDetails);
    } else {
      console.log(JSON.parse(base64.decode(localStorage.getItem("spCompany"))));
      setSpCompany(
        JSON.parse(base64.decode(localStorage.getItem("spCompany")))
      );
    }
    if (LoginUserGroup === "site_owner") {
      fetchAO();
    } else if (LoginUserGroup === "electrician") {
      fetchAOSP();
    } else if (LoginUserGroup === "spao") {
      fetchAOSP();
    }
    fetchUserdetails();
    //fetchTestingData();
  }, [selectedAssetOwnerGlobal]);

  async function fetchSiteDetailsSU() {
    try {
      //setIsLoading(true);
      const response = await client.graphql({
        query: mergedQueries.getSiteUsersFromLink,
        authMode: "API_KEY",
        variables: {
          //nextToken: '',
          assetownerID: LoginUserDetails.assetOwnerId,
          userdetailsID: LoginUserDetails.id,
        },
      });
      console.log(response);
      let nextToken = "";
      let sitess = [];
      if (response.data.siteDetailsByAssetownerID.items.length > 0) {
        let sites = response.data.siteDetailsByAssetownerID.items;
        nextToken = response.data.siteDetailsByAssetownerID.nextToken;
        for (let site of sites) {
          console.log(site);
          console.log(site.LinkUsersAndSites.items.length);
          if (site.LinkUsersAndSites.items.length > 0) {
            sitess.push(site);
          }
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: mergedQueries.getSiteUsersFromLink,
            variables: {
              nextToken: nextToken,
              assetownerID: LoginUserDetails.assetOwnerId,
              userdetailsID: LoginUserDetails.id,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          sites = results.data.siteDetailsByAssetownerID.items;
          for (let site of sites) {
            console.log(site);
            console.log(site.LinkUsersAndSites.items.length);
            if (site.LinkUsersAndSites.items.length > 0) {
              sitess.push(site);
            }
          }
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      } else {
        setSiteDetails([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
      setIsLoading(false);
    }
  }

  // ----- Submit Function ----------
  const onSubmit = async (data, e) => {
    if (id) {
      if (state.state !== null) {
        let taskCompletionLinkData;
        if (data.taskType === "Test") {
          taskCompletionLinkData = JSON.stringify([
            {
              site: taskCompletionArrayView[0].site,
              category: taskCompletionArrayView[0].category,
              distributionBoard: taskCompletionArrayView[0].distributionBoard,
              logbook: taskCompletionArrayView[0].logbook,
              company:
                data.company !== null
                  ? data.company
                  : taskCompletionArrayView[0].company,
              assets: taskCompletionArrayView[0].assets,
            },
          ]);
        } else {
          taskCompletionLinkData = JSON.stringify([
            {
              site: taskCompletionArrayView[0].site,
              category: taskCompletionArrayView[0].category,
              distributionBoard: taskCompletionArrayView[0].distributionBoard,
              logbook: taskCompletionArrayView[0].logbook,
              company:
                data.company !== null
                  ? data.company
                  : taskCompletionArrayView[0].company,
              test: taskCompletionArrayView[0].test,
              assetsTest: taskCompletionArrayView[0].assetsTest,
            },
          ]);
        }
        await client
          .graphql({
            query: mutations.updateTask,
            variables: {
              limit: limit,
              input: {
                id: id,
                dueDate: data.dueDate,
                taskCompletionLink: taskCompletionLinkData,
                taskStatus: data.taskStatus,
              },
            },
            authMode: "API_KEY",
          })
          .then((response) => {
            e.target.reset();
            toast.success(" Updated Succesfully!..", {
              position: toast.POSITION.TOP_CENTER,
            });
            if (LoginUserGroup === "spao") {
              navigate(`${process.env.PUBLIC_URL}/tasksaosp`);
            } else {
              navigate(`${process.env.PUBLIC_URL}/tasksao`);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error while Updating", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        await client
          .graphql({
            query: mutations.updateTask,
            variables: {
              limit: limit,
              input: {
                id: id,
                dueDate: data.dueDate,
                taskStatus: data.taskStatus,
              },
            },
            authMode: "API_KEY",
          })
          .then((response) => {
            e.target.reset();
            toast.success(" Updated Succesfully!..", {
              position: toast.POSITION.TOP_CENTER,
            });
            if (LoginUserGroup === "spao") {
              navigate(`${process.env.PUBLIC_URL}/tasksaosp`);
            } else {
              navigate(`${process.env.PUBLIC_URL}/tasksao`);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error while Updating", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      }
    } else {
      console.log(selectedItemsAsset);
      console.log(selectedItems);
      console.log(data);
      console.log(siteId);
      console.log(compID);
      //const selectedAssets = assetsDropdown.filter((item) =>
      //  selectedItemsAsset.includes(item.id)
      //);
      //const test = await fetchTest(selectedItems);
      //const selectedRepairData = RepairData.filter((item) =>
      //  selectedItems.includes(item.id)
      //);
      //const TestData = repairDemo.filter((item) =>
      //  selectedItems.includes(item.id)
      //);
      //console.log(TestData);
      const assetsTestRepair = selectedItems.map((item) => {
        return item.assetsId ? item.assetsId : null;
      });
      const selectedItemsAssetIds = selectedItemsAsset.map((asset) => asset.id);
      const selectedItemsIds = selectedItems.map((asset) => asset.id);
      let taskCompletionLinkData;
      if (data.taskType === "Test") {
        taskCompletionLinkData = JSON.stringify([
          {
            site: siteId,
            category: data.category,
            distributionBoard: dbId,
            logbook: data.logbook,
            company: compID,
            assets: selectedItemsAssetIds,
          },
        ]);
      } else {
        taskCompletionLinkData = JSON.stringify([
          {
            site: siteId,
            category: data.category,
            distributionBoard: dbId,
            logbook: data.logbook,
            company: compID,
            test: selectedItemsIds,
            assetsTest: assetsTestRepair,
          },
        ]);
      }
      setSiteId(siteId);
      setCatId(data.category);
      setDBId(dbId);
      setLogbookId(data.logbook);
      await client
        .graphql({
          query: mutations.createTask,
          variables: {
            input: {
              taskType: data.taskType,
              //taskAssignee: data.taskAssignee,
              taskStatus: data.taskStatus,
              status: "Active",
              assignedDate: new Date().toISOString().split("T")[0],
              dueDate: data.dueDate,
              spcompanydetailsID: compID,
              sitedetailsID: siteId,
              taskCompletionLink: taskCompletionLinkData,
              assignedBy: LoginUserDetails.id,
            },
          },
          authMode: "API_KEY",
        })
        .then((result) => {
          //-- asstTask column update
          console.log(selectedItemsAssetIds);
          if (data.taskType === "Test") {
            const deletePromises = selectedItemsAssetIds.map(async (item, i) => {
              await sleep(Math.random() * i * 17);
              console.log(item)
              const deleteResult = await client.graphql({
                query: mutations.updateAssets,
                variables: {
                  input: { id: item, addedToTask: "intask" },
                },
                authMode: "API_KEY",
              });
              console.log(deleteResult);
            });
          }
          else {
            const deletePromises = assetsTestRepair.map(async (item, i) => {
              await sleep(Math.random() * i * 17);
              console.log(item)
              const deleteResult = await client.graphql({
                query: mutations.updateAssets,
                variables: {
                  input: { id: item, addedToTask: "intask" },
                },
                authMode: "API_KEY",
              });
              console.log(deleteResult);
            });
          }
          toast.success("Successfully Added Task.", {
            position: toast.POSITION.TOP_CENTER,
          });
          if (LoginUserGroup === "spao") {
            if (state.state && state.state.menu === "sp") {
              navigate(`${process.env.PUBLIC_URL}/taskssp`);
            } else {
              navigate(`${process.env.PUBLIC_URL}/tasksaosp`);
            }
          } else {
            navigate(`${process.env.PUBLIC_URL}/tasksao`);
          }
          //---mail ----
          if (data.company) {
            client
              .graphql({
                query: queries.listSPCompanyDetails,
                variables: {
                  filter: {
                    id: {
                      eq: data.company,
                    },
                  },
                  limit: limit,
                },
                authMode: "API_KEY",
              })
              .then((response) => {
                console.log(response);
                const company = response.data.listSPCompanyDetails.items;
                const email = company[0].email;
                console.log(email);
                const emailData = {
                  assignedBy: getAo(LoginUserDetails.id),
                  taskAssignee: getTaskAssigneeName(data.taskAssignee),
                  TaskType: data.taskType,
                  TaskStatus: data.taskStatus,
                  site: getSiteUnitNumber(siteId),
                  category: getCategory(data.category),
                  distributionBoard: getDBName(dbId),
                  logbook: logbookname(data.logbook),
                  company: company[0].companyName,
                  // company: getCompany(data.company),
                  DueDate: moment(data.dueDate).format("DD/MM/YYYY"),
                };
                console.log(emailData);
                {
                  /*
                const sorted = selectedItemsAsset.sort(
                  (a, b) => a.fittingNumber - b.fittingNumber
                );
                if (selectedItemsAsset.length > 0) {
                  emailData.assets = sorted.map((asset) => asset.fittingNumber);
                  emailData.AssetModel = sorted.map(
                    (asset) => asset.assetModel
                  );
                  emailData.AssetLocation = sorted.map(
                    (asset) => asset.assetLocation
                  );
                  emailData.AssetDescription = sorted.map(
                    (asset) => asset.assetDescription
                  );
                  emailData.AssetWarrantyStart = sorted.map((asset) =>
                    moment(asset.assetWarrantyStart).format("DD/MM/YYYY")
                  );
                  emailData.AssetWarrantyEnd = sorted.map((asset) =>
                    moment(asset.assetWarrantyEnd).format("DD/MM/YYYY")
                  );
                  emailData.InstalledOn = sorted.map((asset) =>
                    moment(asset.installedOn).format("DD/MM/YYYY")
                  );
                } else {
                  emailData.testResult = selectedItems.map(
                    (item) => item.testResult
                  );
                  emailData.testOn = selectedItems.map((item) =>
                    moment(item.testOn).format("DD/MM/YYYY")
                  );
                  emailData.nextTestSchedule = selectedItems.map((item) =>
                    item.nextTestSchedule
                      ? moment(item.nextTestSchedule).format("DD/MM/YYYY")
                      : "-"
                  );
                  emailData.assetTest = selectedItems.map((item) => {
                    const assetDetails = assetsView.find(
                      (asset) => asset.id === item.assetsID
                    );
                    return assetDetails ? assetDetails.fittingNumber : null;
                  });
                }
              */
                }
                sendEmail(email, emailData);
              })
              .catch(() => {
                setErrorMessage("Unable to fetch the list");
              });
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error While Adding Task: " + error, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    console.log("to filter selected sp company sites");
    const getspsites = async () => {
      await client
        .graphql({
          query: queries.listUserDetails,
          variables: {
            filter: {
              companyDetailsId: {
                eq: compID,
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          console.log(result);
          const spp = result.data.listUserDetails.items;
          let nextToken = "";
          let assets = [];
          spp.map(async (item) => {
            await client
              .graphql({
                query: queries.linkUsersAndSitesByUserdetailsID,
                variables: {
                  userdetailsID: item.id,
                  filter: {
                    status: {
                      eq: "Active",
                    },
                  },
                  limit: limit,
                },
                authMode: "API_KEY",
              })
              .then(async (result) => {
                console.log(result);
                let assetDatas =
                  result.data.linkUsersAndSitesByUserdetailsID.items;
                nextToken =
                  result.data.linkUsersAndSitesByUserdetailsID.nextToken;
                console.log(
                  "first token count",
                  result.data.linkUsersAndSitesByUserdetailsID.items.length
                );
                console.log("nextToken 1", nextToken);
                if (
                  result.data.linkUsersAndSitesByUserdetailsID.items.length > 0
                ) {
                  assets.push(assetDatas);
                }
                while (nextToken !== null) {
                  await client
                    .graphql({
                      query: queries.linkUsersAndSitesByUserdetailsID,
                      variables: {
                        userdetailsID: item.id,
                        filter: {
                          status: {
                            eq: "Active",
                          },
                        },
                        limit: limit,
                        nextToken: nextToken,
                      },
                      authMode: "API_KEY",
                    })
                    .then((results) => {
                      nextToken =
                        results.data.linkUsersAndSitesByUserdetailsID.nextToken;
                      console.log("nextToken", nextToken);
                      console.log(
                        results.data.linkUsersAndSitesByUserdetailsID.items
                          .length
                      );
                      if (
                        results.data.linkUsersAndSitesByUserdetailsID.items
                          .length > 0
                      ) {
                        assets.push(
                          results.data.linkUsersAndSitesByUserdetailsID.items
                        );
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                console.log(assets);
                if (assets.length > 0) {
                  const mergeAssets = assets.flat(1);
                  console.log(mergeAssets);
                  const sitesfilter = siteDetails.filter((item) => {
                    const _matchSites = mergeAssets?.find(
                      (user) => user.sitedetailsID === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  });
                  console.log(sitesfilter);
                  setSiteFilter(
                    sitesfilter.filter((item) => item.status === "Active")
                  );
                }
              })
              .catch((error) => {
                console.log(error);
                setSiteFilter([]);
              });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getspsites();
  }, [compID]);

  const handleDropdownChange = (event) => {
    console.log(event);
    console.log(event.target.value);
    setCompId(event.target.value);
  };

  const optionsSites = [
    { value: "", label: "Select Site" },
    ...siteFilter.map((item) => ({
      value: item.id,
      label:
        item.siteName +
        " " +
        "(" +
        (item.unitNumber ? item.unitNumber : "") +
        ")",
    })),
  ];

  const optionsDB = [
    { value: "", label: "Select Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];

  const handleSite = (event) => {
    console.log(event.value);
    setSiteId(event.value);
    setSiteIdvalue(event);
    //setSiteselected(true);
  };

  const handleDB = (event) => {
    console.log(event.value);
    setDBId(event.value);
    setDBIdvalue(event);
    //setSiteselected(true);
  };

  //-----------------------------------------Display------------------------------------------
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardBody>
                <H4>
                  {" "}
                  {view
                    ? "View Tasks"
                    : taskList.taskStatus
                      ? " Edit Tasks"
                      : "Add Tasks"}
                </H4>
                <Form
                  className="needs-validation"
                  noValidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Task Type</Label>
                      <select
                        className="form-select"
                        name="taskType"
                        value={selectedTaskType}
                        {...register("taskType", {
                          required: taskList.taskType ? false : true,
                        })}
                        onChange={(e) => setSelectedTaskType(e.target.value)}
                        disabled={taskList.taskType ? true : false}
                      >
                        {taskList.taskType ? (
                          <option value={taskList.taskType}>
                            {taskList.taskType}
                          </option>
                        ) : (
                          <>
                            <option value="">Select Task Type</option>
                            <option value="Test">Test</option>
                            <option value="Repair">Repair</option>
                            <option value="Replace">Replace</option>
                          </>
                        )}
                      </select>
                      <span>{errors.taskType && "Task Type is required"}</span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Task Status</Label>
                      <select
                        className="form-control"
                        name="taskStatus"
                        {...register("taskStatus", {
                          required: taskList.taskStatus ? false : true,
                        })}
                        defaultValue={
                          taskList.taskStatus ? taskList.taskStatus : "Assigned"
                        }
                      //disabled={taskList.taskStatus ? true : false}
                      >
                        {taskList.taskStatus && (
                          <option value={taskList.taskStatus}>
                            {taskList.taskStatus}
                          </option>
                        )}

                        <option value="Assigned">Assigned</option>
                        <option value="Hold">Hold</option>
                        {!taskList.taskStatus && (
                          <>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Completed</option>
                          </>
                        )}
                        {/* </>
                         )} */}
                      </select>
                      <span>
                        {errors.taskStatus && "Task Status is required"}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-3">
                      <Label>Due Date</Label>
                      <input
                        className="form-control"
                        name="dueDate"
                        type="date"
                        {...register("dueDate", { required: true })}
                      />
                      <span>{errors.dueDate && "Due Date is required"}</span>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>Service Provider Company</Label>
                      <select
                        className="form-select"
                        name="company"
                        {...register("company", {
                          required: taskList.taskCompletionLink ? false : true,
                        })}
                        onChange={handleDropdownChange}
                        disabled={
                          state.state && state.state.purpose === "hold"
                            ? false
                            : taskList.taskCompletionLink
                              ? true
                              : false
                        }
                      >
                        {state.state && state.state.purpose === "hold" ? (
                          <>
                            {taskList.taskCompletionLink &&
                              taskList.taskCompletionLink[0] && (
                                <option
                                  value={taskCompletionArrayView[0].company}
                                >
                                  {getCompany(
                                    taskCompletionArrayView[0].company
                                  )}
                                </option>
                              )}
                            {spCompany.map((item) => (
                              <option
                                value={item.spcomapnyId}
                                key={item.spcomapnyId}
                              >
                                {item.companyName}
                              </option>
                            ))}
                          </>
                        ) : taskList.taskCompletionLink ? (
                          <>
                            <option
                              value={taskList.taskCompletionLink[0]["company"]}
                            >
                              {getCompany(taskCompletionArrayView[0].company)}
                            </option>
                          </>
                        ) : (
                          <>
                            <option value="">Select Company</option>
                            {spCompany.map((item) => (
                              <option
                                value={
                                  LoginUserGroup === "spao"
                                    ? item.spcomapnyId
                                    : item.spcomapnyId
                                }
                                key={
                                  LoginUserGroup === "spao"
                                    ? item.spcomapnyId
                                    : item.spcomapnyId
                                }
                              >
                                {item.companyName}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                      <span>{errors.company && "Company is required"}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3 mb-3">
                      <Label>Choose Sites</Label>
                      {taskList.taskCompletionLink &&
                        taskList.taskCompletionLink.length > 0 ? (
                        <>
                          <select
                            className="form-select"
                            name="site"
                            {...register("site", {
                              required: taskList.taskCompletionLink
                                ? false
                                : true,
                            })}
                            onChange={(event) => setSiteId(event.target.value)}
                            disabled={
                              taskList.taskCompletionLink ? true : false
                            }
                          >
                            {taskList.taskCompletionLink &&
                              taskList.taskCompletionLink.length > 0 && (
                                <option
                                  value={taskList.taskCompletionLink[0]["site"]}
                                >
                                  {getSiteUnitNumber(
                                    taskCompletionArrayView[0].site
                                  )}
                                </option>
                              )}
                            {!taskList.taskCompletionLink && (
                              <>
                                <option value="">Select Site</option>
                                {siteFilter.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {(item?.siteName || "") +
                                      " (" +
                                      (item?.unitNumber || "") +
                                      ")"}
                                  </option>
                                ))}
                              </>
                            )}
                          </select>
                        </>
                      ) : (
                        <>
                          <Select
                            options={optionsSites}
                            isSearchable={true}
                            value={siteIdvalue}
                            placeholder="Select Sites"
                            onChange={handleSite}
                            name="site"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />

                          {/* <span>{!siteselected && "Site is required"}</span> */}
                        </>
                      )}
                      <span>{errors.site && "Site is required"}</span>
                    </Col>
                    <Col md="3 mb-3">
                      <Label>Choose Category</Label>
                      <select
                        className="form-select"
                        name="category"
                        {...register("category", {
                          required: taskList.taskCompletionLink ? false : true,
                        })}
                        onChange={(event) => setCatId(event.target.value)}
                        disabled={taskList.taskCompletionLink ? true : false}
                      >
                        {taskList.taskCompletionLink &&
                          taskList.taskCompletionLink.length > 0 && (
                            <option
                              value={taskList.taskCompletionLink[0]["category"]}
                            >
                              {getCategory(taskCompletionArrayView[0].category)}
                            </option>
                          )}
                        {!taskList.taskCompletionLink && (
                          <>
                            <option value="">Select Category</option>
                            {categoryDetails.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.categoryName}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                      <span>{errors.category && "Category is required"}</span>
                    </Col>
                    <Col md="3 mb-3">
                      <Label>Choose Logbook</Label>
                      <select
                        className="form-select"
                        name="logbook"
                        {...register("logbook", { required: false })}
                        onChange={(event) => setLogbookId(event.target.value)}
                        disabled={taskList.taskCompletionLink ? true : false}
                      >
                        {taskList.taskCompletionLink &&
                          taskList.taskCompletionLink.length > 0 && (
                            <option
                              value={taskList.taskCompletionLink[0]["logbook"]}
                            >
                              {logbookname(taskCompletionArrayView[0].logbook)}
                            </option>
                          )}
                        {!taskList.taskCompletionLink && (
                          <>
                            <option value="">Select Logbook</option>
                            {logbookDataSiteFilter.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.logbookName}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                    </Col>
                    <Col md="3 mb-3">
                      <Label>Choose Distribution Board</Label>
                      {taskList.taskCompletionLink &&
                        taskList.taskCompletionLink.length > 0 ? (
                        <>
                          <select
                            className="form-select"
                            name="distributionBoard"
                            {...register("distributionBoard", {
                              required: false,
                            })}
                            onChange={(event) => setDBId(event.target.value)}
                            disabled={
                              taskList.taskCompletionLink ? true : false
                            }
                          >
                            {taskList.taskCompletionLink &&
                              taskList.taskCompletionLink.length > 0 && (
                                <option
                                  value={
                                    taskList.taskCompletionLink[0][
                                    "distributionBoard"
                                    ]
                                  }
                                >
                                  {getDBName(
                                    taskCompletionArrayView[0].distributionBoard
                                  )}
                                </option>
                              )}
                            {!taskList.taskCompletionLink && (
                              <>
                                <option value="">
                                  Select Distribution Board
                                </option>
                                {distributionBoxFilter.map((item) => (
                                  <option value={item.id} key={item.id}>
                                    {item.distributionBoxName}
                                  </option>
                                ))}
                              </>
                            )}
                          </select>
                        </>
                      ) : (
                        <>
                          <Select
                            options={optionsDB}
                            isSearchable={true}
                            value={dbIdvalue}
                            placeholder="Select Distribution Boards"
                            onChange={handleDB}
                            name="DB"
                            menuPlacement="auto"
                            menuPosition="fixed"
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {taskList.taskCompletionLink &&
                      taskList.taskCompletionLink.length > 0 && (
                        <>
                          {!!taskList.dueDate &&
                            taskCompletionArrayView[0].assets &&
                            taskCompletionArrayView[0].assets.length > 0 &&
                            taskCompletionArrayView[0].assets[0].length > 0 && (
                              <Row>
                                <div className="table-responsive">
                                  <Table className="table-striped  table-de">
                                    <thead className="custom-table-head">
                                      <tr>
                                        <th scope="col">Added On</th>
                                        <th scope="col">Fitting Number</th>
                                        <th scope="col">Asset Model</th>
                                        <th scope="col">Asset Type</th>
                                        <th scope="col">Asset Location</th>
                                        <th scope="col">Installed On</th>
                                        <th scope="col">Description</th>
                                      </tr>
                                    </thead>
                                    <tbody className="trhover">
                                      {viewAssets.length > 0 ? (
                                        taskCompletionArrayView[0].assets.map(
                                          (assetId) => {
                                            const matchingAsset =
                                              viewAssets.find(
                                                (item) => item.id === assetId
                                              );
                                            if (matchingAsset) {
                                              return (
                                                <tr key={matchingAsset.id}>
                                                  <td>
                                                    {moment(
                                                      matchingAsset.createdAt
                                                    ).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                    {
                                                      matchingAsset.fittingNumber
                                                    }
                                                  </td>
                                                  <td>
                                                    {matchingAsset.assetModel}
                                                  </td>
                                                  <td>
                                                    {matchingAsset.assetType}
                                                  </td>
                                                  <td>
                                                    {
                                                      matchingAsset.assetLocation
                                                    }
                                                  </td>
                                                  <td>
                                                    {moment(
                                                      matchingAsset.installedOn
                                                    ).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                    {
                                                      matchingAsset.assetDescription
                                                    }
                                                  </td>
                                                </tr>
                                              );
                                            } else {
                                              // Handle the case where the asset with the given ID is not found in assetsView
                                              return null;
                                            }
                                          }
                                        )
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="5"
                                              className="text-center"
                                            >
                                              No Assets Available.
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </Row>
                            )}
                          {taskCompletionArrayView[0].test && (
                            <div className="table-responsive">
                              <Table className="table-striped  table-de">
                                <thead className="custom-table-head">
                                  <tr>
                                    <th scope="col">Added On</th>
                                    <th scope="col">Fitting Number</th>
                                    <th scope="col">Test On</th>
                                    <th scope="col">Test Result</th>
                                    <th scope="col">Next Test Schedule</th>
                                    <th scope="col">Technician</th>
                                  </tr>
                                </thead>
                                <tbody className="trhover">
                                  {isLoadingtest ? (
                                    <LoadingSpinner />
                                  ) : (
                                    <>
                                      {testView.length > 0 ? (
                                        taskCompletionArrayView[0].test.map(
                                          (testId) => {
                                            const matchingTest = testView.find(
                                              (item) => item.id === testId
                                            );
                                            if (matchingTest) {
                                              return (
                                                <tr key={matchingTest.id}>
                                                  <td>
                                                    {moment(
                                                      matchingTest.createdAt
                                                    ).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                    {isLoading ? (
                                                      "Loading..."
                                                    ) : (
                                                      assetData[matchingTest.assetsID] || "Not Found"
                                                    )}
                                                  </td>
                                                  <td>
                                                    {moment(
                                                      matchingTest.testOn
                                                    ).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                    {matchingTest.testResult}
                                                  </td>
                                                  <td>
                                                    {moment(
                                                      matchingTest.nextTestSchedule
                                                    ).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                    {getTechnicianName(
                                                      matchingTest
                                                        .technician[0]
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            } else {
                                              // Handle the case where the asset with the given ID is not found in assetsView
                                              return null;
                                            }
                                          }
                                        )
                                      ) : (
                                        //  testView
                                        //  .filter(
                                        //    (item) =>
                                        //      item.id ===
                                        //      taskCompletionArrayView[0].test
                                        //  )
                                        // .map((item) => (
                                        //  <tr key={item.id}>
                                        //    <td>
                                        //      {moment(
                                        //        item.createdAt
                                        //      ).format("DD/MM/YYYY")}
                                        //    </td>
                                        //    <td>
                                        //     {getAssets(item.assetsID)}
                                        //    </td>
                                        //    <td>
                                        //      {moment(item.testOn).format(
                                        //        "YYYY-MM-DD"
                                        //     )}
                                        //    </td>
                                        //    <td>{item.testResult}</td>
                                        //   <td>
                                        //      {moment(
                                        //       item.nextTestSchedule
                                        //      ).format("DD/MM/YYYY")}
                                        //    </td>
                                        //    <td>
                                        //      {getTechnicianName(
                                        //        item.technician[0]
                                        //       )}
                                        //     </td>
                                        //    </tr>
                                        //  ))

                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="5"
                                              className="text-center"
                                            >
                                              No Test Data Available.
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}{" "}
                                    </>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          )}
                          {!taskList.dueDate && (
                            <div className="table-responsive">
                              <Table className="table-striped  table-de">
                                <thead className="custom-table-head">
                                  <tr>
                                    <th scope="col">Added On</th>
                                    <th scope="col">Test Result</th>
                                    <th scope="col">Test On</th>
                                    <th scope="col">Next Test Schedule</th>
                                    <th scope="col">Additional Information</th>
                                  </tr>
                                </thead>
                                <tbody className="trhover">
                                  {testView.length > 0 ? (
                                    taskCompletionArrayView[0].assets.map(
                                      (assetId) => {
                                        const matchingAsset = testView.find(
                                          (item) => item.id === assetId
                                        );
                                        if (matchingAsset) {
                                          return (
                                            <tr key={matchingAsset.id}>
                                              <td>
                                                {moment(
                                                  matchingAsset.createdAt
                                                ).format("DD/MM/YYYY")}
                                              </td>
                                              <td>
                                                {matchingAsset.testResult}
                                              </td>
                                              <td>
                                                {moment(
                                                  matchingAsset.testOn
                                                ).format("DD/MM/YYYY")}
                                              </td>
                                              <td>
                                                {matchingAsset.nextTestSchedule
                                                  ? moment(
                                                    matchingAsset.nextTestSchedule
                                                  ).format("DD/MM/YYYY")
                                                  : "-"}
                                              </td>
                                              <td>
                                                {
                                                  matchingAsset.additionalInformation
                                                }
                                              </td>
                                            </tr>
                                          );
                                        } else {
                                          return null;
                                        }
                                      }
                                    )
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td colSpan="5" className="text-center">
                                          No Pass Test Available.
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </tbody>
                                ;
                              </Table>
                            </div>
                          )}
                        </>
                      )}
                    {!taskList.taskCompletionLink && (
                      <>
                        {(selectedTaskType === "Test" ||
                          selectedTaskType === "") && (
                            <>
                              <div className="table-responsive">
                                <Table className="table-striped table-de">
                                  <thead className="custom-table-head">
                                    <tr>
                                      <th scope="col">
                                        <input
                                          type="checkbox"
                                          checked={isAllSelected}
                                          onChange={(event) =>
                                            handleCheckboxAssetChange(
                                              event,
                                              "selectAll"
                                            )
                                          }
                                        />
                                        Select All
                                      </th>
                                      <th scope="col">Added On</th>
                                      <th scope="col">Fitting Number</th>
                                      <th scope="col">Asset Model</th>
                                      <th scope="col">Asset Type</th>
                                      <th scope="col">Asset Location</th>
                                      <th scope="col">Installed On</th>
                                      <th scope="col">Description</th>
                                    </tr>
                                  </thead>
                                  <tbody className="trhover">
                                    {isLoading ? (
                                      <LoadingSpinner />
                                    ) : (
                                      <>
                                        {assetSiteCatFilter.length > 0 ? (
                                          assetSiteCatFilter.map((item) => (
                                            <tr key={item.id}>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  name="completionLink"
                                                  checked={
                                                    isAllSelected ||
                                                    selectedItemsAsset.includes(
                                                      item
                                                    )
                                                  }
                                                  onChange={(event) =>
                                                    handleCheckboxAssetChange(
                                                      event,
                                                      item
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                {moment(item.createdAt).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td>{item.fittingNumber}</td>
                                              <td>{item.assetModel}</td>
                                              <td>{item.assetType}</td>
                                              <td>{item.assetLocation}</td>
                                              <td>
                                                {moment(item.installedOn).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td>{item.assetDescription}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td
                                                colSpan="6"
                                                className="text-center"
                                              >
                                                No Assets Available.
                                              </td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                              <CardFooter className="row">
                                <Pagination
                                  className="p-t-10"
                                  pageSize={countPerPage}
                                  onChange={updatePage2}
                                  current={currentPage1}
                                  total={assetsDemo.length}
                                  showTotal={(total, range) =>
                                    `${range[0]} - ${range[1]} of ${total} items`
                                  }
                                />
                              </CardFooter>
                            </>
                          )}
                        {selectedTaskType !== "Test" &&
                          selectedTaskType !== "" && (
                            <>
                              <div className="table-responsive">
                                <Table className="table-striped  table-de">
                                  <thead className="custom-table-head">
                                    <tr>
                                      <th scope="col">
                                        <input
                                          type="checkbox"
                                          checked={isAllSelectedRepair}
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              event,
                                              "selectAll"
                                            )
                                          }
                                        />
                                        Select All
                                      </th>
                                      <th scope="col">Added On</th>
                                      <th scope="col">Fitting Number</th>
                                      <th scope="col">Test On</th>
                                      <th scope="col">Test Result</th>
                                      <th scope="col">Next Test Schedule</th>
                                      <th scope="col">Technician</th>
                                    </tr>
                                  </thead>
                                  <tbody className="trhover">
                                    {isLoading ? (
                                      <LoadingSpinner />
                                    ) : (
                                      <>
                                        {RepairData.length > 0 ? (
                                          RepairData.map((item) => (
                                            <tr key={item.id}>
                                              <td>
                                                {/*
                                                <input
                                                  type="radio"
                                                  name="completionLink"
                                                  onChange={(event) =>
                                                    handleCheckboxChange(
                                                      event,
                                                      item.id
                                                    )
                                                  }
                                                />
                                                */}
                                                <input
                                                  type="checkbox"
                                                  name="completionLink"
                                                  checked={
                                                    isAllSelectedRepair ||
                                                    selectedItems.includes(item)
                                                  }
                                                  onChange={(event) =>
                                                    handleCheckboxChange(
                                                      event,
                                                      item
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                {moment(item.createdAt).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td>{item.assetsID}</td>
                                              <td>
                                                {moment(item.testOn).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td>{item.testResult}</td>
                                              <td>
                                                {item.nextTestSchedule
                                                  ? moment(
                                                    item.nextTestSchedule
                                                  ).format("DD/MM/YYYY")
                                                  : "-"}
                                              </td>
                                              <td>
                                                {item.technician &&
                                                  getTechnicianName(
                                                    item.technician[0]
                                                  )}
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td
                                                colSpan="5"
                                                className="text-center"
                                              >
                                                No Test Data Available.
                                              </td>
                                            </tr>
                                          </tbody>
                                        )}{" "}
                                      </>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                              <CardFooter className="row">
                                <Pagination
                                  className="p-t-10"
                                  pageSize={countPerPage}
                                  onChange={updatePage1}
                                  current={currentPage}
                                  total={repairDemo.length}
                                  showTotal={(total, range) =>
                                    `${range[0]} - ${range[1]} of ${total} items`
                                  }
                                />
                              </CardFooter>
                            </>
                          )}
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col md="6"></Col>
                    <Col md="6" className="text-end">
                      <Btn attrBtn={{ color: "danger", onClick: cancelForm }}>
                        Cancel
                      </Btn>
                      <Btn attrBtn={{ color: "primary" }}>
                        {!id ? "Submit" : "Update"}
                      </Btn>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default AddTasksContain;
