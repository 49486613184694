import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { H5, H4, H6, P, UL, LI } from "../../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { PlusCircle } from "react-feather";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Btn } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import { FormGroup, Label, InputGroupAddon, InputGroupText } from "reactstrap";

import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import ScrollBar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import awsExports from "../../../aws-exports";
import uuid from "react-native-uuid";
import { useNavigate, Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { aocontext } from "../../../App";
import { Typeahead } from "react-bootstrap-typeahead";
import { limit } from "../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const AddEditSPDetails = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const { id } = useParams();
  const { view } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [listofSP, setlistofSP] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [permissions, setPermissions] = useState([]);
  const [linkedSites, setSites] = useState([]);
  const [company, setCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const LoginUserGroup = localStorage.getItem("groups");
  const [sites, setsitesss] = React.useState([]);
  const [sitesSelected, setsitesSelected] = React.useState([]);
  //const [limit, setLimit] = useState(100000);

  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);

  //----------------------------List--------------------------------------

  useEffect(() => {
    setIsLoading(true);
    console.log(id);

    if (id) {
      async function listSPDetails() {
        const dataDetails = await client.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
          },
        });
        if (
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
        ) {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          if (siteDetails.length > 0) {
            const siteFilterArray = [];
            // siteDetails.map((item) => {
            //   siteFilterArray.push(item.sitedetailsID);
            // });
            for (let item of siteDetails) {
              siteFilterArray.push(item.sitedetailsID);
            }
            if (LoginUserGroup === "site_owner") {
              const result = await client.graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.id,
                  limit: limit,
                },
                authMode: "API_KEY",
              });
              if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                console.log(result);
                const sites = result.data.siteDetailsByAssetownerID.items;
                console.log(sites);
                const asset = sites.filter((item) => {
                  const _matchSites = siteFilterArray?.find(
                    (id) => id === item?.id
                  );
                  if (_matchSites) {
                    return item;
                  }
                });
                setSites(asset);
                const data2 = await client.graphql({
                  query: queries.listSPUserDetails,
                  authMode: "API_KEY",
                  variables: {
                    filter: {
                      id: {
                        eq: id,
                      },
                    },
                    limit: limit,
                  },
                });
                if (data2.data.listSPUserDetails.items.length > 0) {
                  const spDetails = data2.data.listSPUserDetails.items;
                  console.log(spDetails);
                  setlistofSP({ ...spDetails[0] });
                  reset({ ...spDetails[0] });
                  setPermissions(JSON.parse(spDetails[0].permission));
                  //---company
                  const data1 = await client.graphql({
                    query: queries.listUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      filter: {
                        id: {
                          eq: id,
                        },
                      },
                      limit: limit,
                    },
                  });
                  if (data1.data.listUserDetails.items.length > 0) {
                    const comp = data1.data.listUserDetails.items;
                    console.log(comp);

                    const dataDetails = await client.graphql({
                      query: queries.listSPCompanyDetails,
                      authMode: "API_KEY",
                      variables: {
                        filter: {
                          id: {
                            eq: comp[0].companyDetailsId,
                          },
                        },
                        limit: limit,
                      },
                    });
                    if (
                      dataDetails.data.listSPCompanyDetails.items.length > 0
                    ) {
                      const compdata =
                        dataDetails.data.listSPCompanyDetails.items;
                      console.log(compdata);
                      setCompany({ ...compdata[0] });
                      setIsLoading(false);
                    }
                    setIsLoading(false);
                  }
                  setIsLoading(false);
                }
              }
            } else {
              const result = await client.graphql({
                query: queries.listSiteDetails,
                variables: {
                  limit: limit,
                },
                authMode: "API_KEY",
              });
              if (result.data.listSiteDetails.items.length > 0) {
                console.log(result);
                const sites = result.data.listSiteDetails.items;
                console.log(sites);
                const asset = sites.filter((item) => {
                  const _matchSites = siteFilterArray?.find(
                    (id) => id === item?.id
                  );
                  if (_matchSites) {
                    return item;
                  }
                });
                setSites(asset);
                const data2 = await client.graphql({
                  query: queries.listSPUserDetails,
                  authMode: "API_KEY",
                  variables: {
                    filter: {
                      id: {
                        eq: id,
                      },
                    },
                    limit: limit,
                  },
                });
                if (data2.data.listSPUserDetails.items.length > 0) {
                  const spDetails = data2.data.listSPUserDetails.items;
                  console.log(spDetails);
                  setlistofSP({ ...spDetails[0] });
                  reset({ ...spDetails[0] });
                  setPermissions(JSON.parse(spDetails[0].permission));
                  //---company
                  const data1 = await client.graphql({
                    query: queries.listUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      filter: {
                        id: {
                          eq: id,
                        },
                      },
                      limit: limit,
                    },
                  });
                  if (data1.data.listUserDetails.items.length > 0) {
                    const comp = data1.data.listUserDetails.items;
                    console.log(comp);

                    const dataa = await client.graphql({
                      query: queries.listSPCompanyDetails,
                      authMode: "API_KEY",
                      variables: {
                        filter: {
                          id: {
                            eq: comp[0].companyDetailsId,
                          },
                        },
                        limit: limit,
                      },
                    });
                    if (dataa.data.listSPCompanyDetails.items.length > 0) {
                      const compdata = dataa.data.listSPCompanyDetails.items;
                      console.log(compdata);
                      setCompany({ ...compdata[0] });
                      setIsLoading(false);
                    }
                    setIsLoading(false);
                  }
                  setIsLoading(false);
                }
              }
            }
          } else {
            setIsLoading(false);
            console.log("No sites linked to the service provider");
            const dataDetails = await client.graphql({
              query: queries.listSPUserDetails,
              authMode: "API_KEY",
              variables: {
                filter: {
                  id: {
                    eq: id,
                  },
                },
                limit: limit,
              },
            });
            if (dataDetails.data.listSPUserDetails.items.length > 0) {
              const spDetails = dataDetails.data.listSPUserDetails.items;
              console.log(spDetails);
              setlistofSP({ ...spDetails[0] });
              reset({ ...spDetails[0] });
              setPermissions(JSON.parse(spDetails[0].permission));
              setIsLoading(false);
            }
          }
        }
      }

      listSPDetails();
    }
  }, [id, reset]);

  //----------------------------add--edit------------------------------------

  const onSubmit = useCallback((data, e) => {
    if (id) {
      const datalogbook = client
        .graphql({
          query: mutations.updateSPUserDetails,
          variables: {
            input: {
              id: id,
              fname: data.fname,
              lname: data.lname,
              email: data.email,
              mobile: data.mobile,
              password: data.password,
              license: data.license,
              profilepic: data.profilepic,
            },
          },
          authMode: "API_KEY",
        })
        .then(async (response) => {
          console.log(response);
          const addedsite = await client.graphql({
            query: queries.getSPUserDetails,
            variables: {
              id: id,
              limit: 10000,
            },
            authMode: "API_KEY",
          });
          console.log(addedsite);
          if (addedsite.data.getSPUserDetails !== null) {
            let activeAssets = [];
            if (localStorage.getItem("spuser") !== null) {
              activeAssets = JSON.parse(
                base64.decode(localStorage.getItem("spuser"))
              );
              console.log(activeAssets);
              const existingIndex = activeAssets.findIndex(
                (item) => item.id === addedsite.data.getSPUserDetails.id
              );
              console.log(existingIndex);
              if (existingIndex > -1) {
                activeAssets[existingIndex] = addedsite.data.getSPUserDetails;
              } else {
                activeAssets.push(addedsite.data.getSPUserDetails);
              }
              console.log(activeAssets);
              localStorage.setItem(
                "spuser",
                base64.encode(JSON.stringify(activeAssets))
              );
            }
          }
          console.log(sitesSelected);

          sitesSelected?.map(async (item) => {
            const dataDetails = await client.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: id,
                filter: {
                  sitedetailsID: {
                    eq: item.id,
                  },
                },
                limit: limit,
              },
            });
            if (
              dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
            ) {
              await client
                .graphql({
                  query: mutations.updateLinkUsersAndSite,
                  variables: {
                    limit: 10000,
                    input: {
                      id: dataDetails.data.linkUsersAndSitesByUserdetailsID
                        .items[0].id,
                      status: "Active",
                    },
                  },
                  authMode: "API_KEY",
                })
                .then(async (response) => {
                  console.log(response);
                });
            } else {
              client
                .graphql({
                  query: mutations.createLinkUsersAndSite,
                  variables: {
                    input: {
                      sitedetailsID: item.id,
                      userdetailsID: id,
                      status: "Active",
                    },
                  },
                  authMode: "API_KEY",
                })
                .then((result) => {
                  console.log(result);
                })
                .catch((error) => {
                  console.log(error);
                  toast.error("Error while Creating ", {
                    position: toast.POSITION.TOP_CENTER,
                  });
                });
            }
          });

          e.target.reset();
          toast.success(" Updated Succesfully!..", {
            position: toast.POSITION.TOP_CENTER,
          });
          //window.location = `/siteownerpanel/serviceprovider`;
          navigate(`${process.env.PUBLIC_URL}/siteownerpanel/serviceprovider`);
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error while Updating ", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
    }
  });

  async function fetchSites() {
    const dataDetails = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: id,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
    });
    if (dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      const siteDetails =
        dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
      if (siteDetails.length > 0) {
        const siteFilterArray = [];
        siteDetails.map((item) => {
          siteFilterArray.push(item.sitedetailsID);
        });
        //---

        const dataDetails1 = await client.graphql({
          query: queries.listSiteDetails,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        });
        if (dataDetails1.data.listSiteDetails.items.length > 0) {
          console.log(dataDetails1);
          const sites = dataDetails1.data.listSiteDetails.items;
          const filteredSites = sites.filter((item) => {
            const isMatch = siteFilterArray?.includes(item?.id);
            return !isMatch;
          });

          console.log(sites);
          console.log(filteredSites);
          if (LoginUserGroup === "admin") {
            setsitesss(filteredSites);
          } else if (LoginUserGroup === "site_user") {
            const dataDetails2 = await client.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
              },
            });
            if (
              dataDetails2.data.linkUsersAndSitesByUserdetailsID.items.length >
              0
            ) {
              console.log(dataDetails2);
              const siteDetails =
                dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
              if (siteDetails.length > 0) {
                const siteDetailsu = filteredSites.filter((item) => {
                  const _matchingSites = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingSites) {
                    return item;
                  }
                });
                console.log(siteDetailsu);
                setsitesss(siteDetailsu);
                setIsLoading(false);
              } else {
                setsitesss([]);
                setIsLoading(false);
              }
            }
          } else if (LoginUserGroup === "electrician") {
            const dataDetails = await client.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
              },
            });
            if (
              dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
            ) {
              console.log(dataDetails);
              const siteDetails =
                dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
              if (siteDetails.length > 0) {
                const siteDetailsu = filteredSites.filter((item) => {
                  const _matchingSites = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingSites) {
                    return item;
                  }
                });
                console.log(siteDetailsu);
                const data = siteDetailsu.filter(
                  (item) => item.assetownerID === selectedAssetOwnerGlobal
                );
                const filterdata = selectedAssetOwnerGlobal
                  ? data
                  : siteDetailsu;
                console.log(filterdata);
                setsitesss(filterdata);
                setIsLoading(false);
              } else {
                setsitesss([]);
                setIsLoading(false);
              }
            }
          } else {
            setsitesss(
              filteredSites.filter((item) => {
                return item.assetownerID === LoginUserDetails.id;
              })
            );
          }
        }
      }
    }
  }

  const selectSites = (d) => {
    console.log(d);
    setsitesSelected(d);
    //if (d.length > 0) {
    //  setSiteError(false);
    //} else {
    //  setSiteError(true);
    //}
  };

  useEffect(() => {
    fetchSites();
  }, []);

  const handleSelectAllSitesSP = (event) => {
    try {
      if (event.target.checked) {
        setsitesSelected(sites);
      } else {
        setsitesSelected([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelink = async (siteId) => {
    console.log("Delinking site :", siteId);
    const dataDetails = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: id,
        filter: {
          sitedetailsID: {
            eq: siteId,
          },
        },
        limit: limit,
      },
    });
    if (dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      console.log(dataDetails);
      const siteDetails =
        dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
      if (siteDetails.length > 0) {
        await client
          .graphql({
            query: mutations.updateLinkUsersAndSite,
            variables: {
              limit: 10000,
              input: {
                id: siteDetails[0].id,
                status: "Inactive",
              },
            },
            authMode: "API_KEY",
          })
          .then(async (response) => {
            console.log(response);
            setSites((prevSites) => {
              return prevSites.filter((site) => site.id !== siteId);
            });
          });
      } else {
      }
    }
  };

  //-----------------------------------------Display------------------------------------------
  const renderList = (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <H4>{category}</H4>
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <H4>
                    {" "}
                    {view
                      ? "View SP-Details"
                      : id
                      ? " Edit SP-Details"
                      : "Add SP-Details"}
                  </H4>

                  {view && (
                    <Link float="right" to={`/addedit/${id}`}>
                      <i className="fa fa-edit "></i> Edit{" "}
                    </Link>
                  )}
                  <Col md="12">
                    <Form
                      className="needs-validation"
                      noValidate=""
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustom01">First Name</Label>
                          {view ? (
                            <p>{listofSP.fname}</p>
                          ) : (
                            <input
                              className="form-control"
                              name="fname"
                              type="text"
                              placeholder="First Name"
                              {...register("fname", { required: false })}
                            />
                          )}
                          <span>
                            {errors.fname && "First Name is required"}
                          </span>
                          <div className="valid-feedback">{"Looks good!"}</div>
                        </Col>
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustom01">Last Name</Label>
                          {view ? (
                            <p>{listofSP.lname}</p>
                          ) : (
                            <input
                              className="form-control"
                              name="lname"
                              type="text"
                              placeholder="Last Name"
                              {...register("lname", { required: false })}
                            />
                          )}
                          <span>{errors.lname && "Last Name is required"}</span>
                          <div className="valid-feedback">{"Looks good!"}</div>
                        </Col>
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustom02">E-mail</Label>
                          {view ? (
                            <p>{listofSP.email}</p>
                          ) : (
                            <input
                              className="form-control"
                              name="email"
                              type="email"
                              placeholder="E-mail"
                              {...register("email", { required: true })}
                            />
                          )}
                          <span>{errors.email && "email is required"}</span>
                          <div className="valid-feedback">{"Looks good!"}</div>
                        </Col>
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustomUsername">
                            Mobile Number
                          </Label>
                          {view ? (
                            <p>{listofSP.mobile}</p>
                          ) : (
                            <InputGroup>
                              <InputGroupText>{"#"}</InputGroupText>
                              <input
                                className="form-control"
                                name="mobile"
                                type="number"
                                maxLength="10"
                                placeholder="Mobile Number"
                                {...register("mobile", { required: true })}
                              />
                              <span>
                                {errors.mobile && "Mobile Number is required"}
                              </span>
                            </InputGroup>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustomUsername">
                            SP Company
                          </Label>

                          <p>{company.companyName}</p>
                        </Col>
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustomUsername">
                            license #
                          </Label>
                          {view ? (
                            <p>{listofSP.license}</p>
                          ) : (
                            <InputGroup>
                              <InputGroupText>{"#"}</InputGroupText>
                              <input
                                className="form-control"
                                name="license"
                                type="text"
                                placeholder="license #"
                                {...register("license", { required: true })}
                              />
                              <span>
                                {errors.license && "License Number is required"}
                              </span>
                            </InputGroup>
                          )}
                        </Col>
                        <Col md="3" mb="3">
                          <div>
                            <Label htmlFor="validationCustomUsername">
                              {view ? "" : "Link Sites"}
                            </Label>
                          </div>
                          <div>
                            {view ? (
                              ""
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  onChange={(event) =>
                                    handleSelectAllSitesSP(event)
                                  }
                                />{" "}
                                Select All Sites
                              </>
                            )}
                          </div>
                        </Col>
                        <Col md="3" mb="3">
                          <Label htmlFor="validationCustomUsername">
                            {view ? "" : "Link Sites"}
                          </Label>
                          {view ? (
                            ""
                          ) : (
                            <>
                              <Typeahead
                                id="rendering-example"
                                multiple={true}
                                options={sites}
                                labelKey={(sites) =>
                                  `${sites.siteName} ,(${
                                    sites.unitNumber ? sites.unitNumber : ""
                                  })`
                                }
                                placeholder="Choose Sites"
                                onChange={(d) => selectSites(d)}
                              />
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9 mb-3">
                          <Label>Linked Sites</Label>
                          <div
                            style={{
                              height:
                                linkedSites.length > 35
                                  ? `${linkedSites.length * 2}px`
                                  : "auto",
                              overflowY: "auto",
                            }}
                          >
                            <ul class="list-group">
                              {linkedSites?.map((item) => (
                                <li key={item.id} class="list-group-item">
                                  <span>
                                    {item?.siteName || ""}{" "}
                                    <span>({item?.unitNumber || ""})</span>
                                  </span>
                                  {/* Add type="button" to prevent form submission */}
                                  <button
                                    type="button" // Prevents form submission
                                    className="btn btn-link" // Assuming Btn is a wrapper, you can use standard Bootstrap classes here
                                    onClick={() => handleDelink(item.id)} // Calls handleDelink on click
                                  >
                                    Delink
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {sitesSelected.length > 0 && (
                            <>
                              <Label>Selected Sites</Label>
                              <ScrollBar
                                className="vertical-scroll ps-container ps-theme-default ps-active-y"
                                style={{
                                  height: "200px",
                                  border: "1px solid #efefef",
                                  padding: "1px",
                                  borderRadius: "1px",
                                }}
                              >
                                {sitesSelected.map((item, index) => (
                                  <Label className="d-block" key={index}>
                                    <span>
                                      {item.siteName},{item.unitNumber}
                                    </span>
                                  </Label>
                                ))}
                              </ScrollBar>
                            </>
                          )}
                        </Col>
                        <Col md="3 mb-3">
                          <Label>Permissions</Label>
                          <div style={{ height: "200px", overflowY: "auto" }}>
                            <UL>
                              {permissions?.map((item) => (
                                <LI>{item}</LI>
                              ))}
                            </UL>
                          </div>
                        </Col>
                      </Row>
                      <Link to={`/siteownerpanel/serviceprovider`}>
                        <Button variant="danger">Close</Button>
                      </Link>
                      {!view && (
                        <Btn attrBtn={{ color: "primary" }}>
                          {" "}
                          {!id ? "Create SP" : "Update"}
                        </Btn>
                      )}
                    </Form>
                  </Col>
                </Row>
              </CardHeader>
              <CardFooter className="row"></CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};
export default AddEditSPDetails;
