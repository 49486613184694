/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAssetCounts = /* GraphQL */ `
  query GetAssetCounts($id: ID!) {
    getAssetCounts(id: $id) {
      id
      assetCount
      categoriesID
      sitedetailsID
      assetownerID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetCounts = /* GraphQL */ `
  query ListAssetCounts(
    $filter: ModelAssetCountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetCounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assetCount
        categoriesID
        sitedetailsID
        assetownerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetCountsByCategoriesID = /* GraphQL */ `
  query AssetCountsByCategoriesID(
    $categoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetCountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetCountsByCategoriesID(
      categoriesID: $categoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetCount
        categoriesID
        sitedetailsID
        assetownerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetCountsBySitedetailsID = /* GraphQL */ `
  query AssetCountsBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetCountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetCountsBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetCount
        categoriesID
        sitedetailsID
        assetownerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetCountsByAssetownerID = /* GraphQL */ `
  query AssetCountsByAssetownerID(
    $assetownerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetCountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetCountsByAssetownerID(
      assetownerID: $assetownerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetCount
        categoriesID
        sitedetailsID
        assetownerID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLinkAOAPCompany = /* GraphQL */ `
  query GetLinkAOAPCompany($id: ID!) {
    getLinkAOAPCompany(id: $id) {
      id
      spaoId
      spcomapnyId
      companyAddress
      companyBN
      companyLicense
      companyName
      email
      logo
      phone
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLinkAOAPCompanies = /* GraphQL */ `
  query ListLinkAOAPCompanies(
    $filter: ModelLinkAOAPCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinkAOAPCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        spaoId
        spcomapnyId
        companyAddress
        companyBN
        companyLicense
        companyName
        email
        logo
        phone
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linkAOAPCompaniesBySpaoId = /* GraphQL */ `
  query LinkAOAPCompaniesBySpaoId(
    $spaoId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLinkAOAPCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkAOAPCompaniesBySpaoId(
      spaoId: $spaoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        spaoId
        spcomapnyId
        companyAddress
        companyBN
        companyLicense
        companyName
        email
        logo
        phone
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActionLogs = /* GraphQL */ `
  query GetActionLogs($id: ID!) {
    getActionLogs(id: $id) {
      id
      dataType
      action
      actionedBy
      recordDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActionLogs = /* GraphQL */ `
  query ListActionLogs(
    $filter: ModelActionLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dataType
        action
        actionedBy
        recordDetails
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImportLogs = /* GraphQL */ `
  query GetImportLogs($id: ID!) {
    getImportLogs(id: $id) {
      id
      dataType
      sourceFileLocation
      successCount
      failedCount
      errorFileLocation
      impotedBy
      siteId
      categoryId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImportLogs = /* GraphQL */ `
  query ListImportLogs(
    $filter: ModelImportLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImportLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dataType
        sourceFileLocation
        successCount
        failedCount
        errorFileLocation
        impotedBy
        siteId
        categoryId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionAdd = /* GraphQL */ `
  query GetSubscriptionAdd($id: ID!) {
    getSubscriptionAdd(id: $id) {
      id
      subscriptionId
      quantity
      amount
      currentCount
      subscriptionlimitID
      dueDate
      invoiceId
      startDate
      userdetailsID
      status
      paymentObject
      stripeSubscriptionId
      stripeSubscriptionEndedOn
      paymentObjectBeforePayment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscriptionAdds = /* GraphQL */ `
  query ListSubscriptionAdds(
    $filter: ModelSubscriptionAddFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionAdds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriptionId
        quantity
        amount
        currentCount
        subscriptionlimitID
        dueDate
        invoiceId
        startDate
        userdetailsID
        status
        paymentObject
        stripeSubscriptionId
        stripeSubscriptionEndedOn
        paymentObjectBeforePayment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionAddsBySubscriptionlimitID = /* GraphQL */ `
  query SubscriptionAddsBySubscriptionlimitID(
    $subscriptionlimitID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionAddFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionAddsBySubscriptionlimitID(
      subscriptionlimitID: $subscriptionlimitID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriptionId
        quantity
        amount
        currentCount
        subscriptionlimitID
        dueDate
        invoiceId
        startDate
        userdetailsID
        status
        paymentObject
        stripeSubscriptionId
        stripeSubscriptionEndedOn
        paymentObjectBeforePayment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionAddsByUserdetailsID = /* GraphQL */ `
  query SubscriptionAddsByUserdetailsID(
    $userdetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionAddFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionAddsByUserdetailsID(
      userdetailsID: $userdetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriptionId
        quantity
        amount
        currentCount
        subscriptionlimitID
        dueDate
        invoiceId
        startDate
        userdetailsID
        status
        paymentObject
        stripeSubscriptionId
        stripeSubscriptionEndedOn
        paymentObjectBeforePayment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailSent = /* GraphQL */ `
  query GetEmailSent($id: ID!) {
    getEmailSent(id: $id) {
      id
      emailType
      lastSent
      testId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmailSents = /* GraphQL */ `
  query ListEmailSents(
    $filter: ModelEmailSentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailSents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailType
        lastSent
        testId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      taskType
      taskAssignee
      assignedDate
      dueDate
      assignedBy
      taskStatus
      status
      taskCompletionDate
      taskCompletionLink
      sitedetailsID
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskType
        taskAssignee
        assignedDate
        dueDate
        assignedBy
        taskStatus
        status
        taskCompletionDate
        taskCompletionLink
        sitedetailsID
        spcompanydetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksByTaskAssigneeAndDueDate = /* GraphQL */ `
  query TasksByTaskAssigneeAndDueDate(
    $taskAssignee: String!
    $dueDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByTaskAssigneeAndDueDate(
      taskAssignee: $taskAssignee
      dueDate: $dueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        taskAssignee
        assignedDate
        dueDate
        assignedBy
        taskStatus
        status
        taskCompletionDate
        taskCompletionLink
        sitedetailsID
        spcompanydetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksBySitedetailsID = /* GraphQL */ `
  query TasksBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        taskAssignee
        assignedDate
        dueDate
        assignedBy
        taskStatus
        status
        taskCompletionDate
        taskCompletionLink
        sitedetailsID
        spcompanydetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tasksBySpcompanydetailsID = /* GraphQL */ `
  query TasksBySpcompanydetailsID(
    $spcompanydetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksBySpcompanydetailsID(
      spcompanydetailsID: $spcompanydetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskType
        taskAssignee
        assignedDate
        dueDate
        assignedBy
        taskStatus
        status
        taskCompletionDate
        taskCompletionLink
        sitedetailsID
        spcompanydetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLogbookEmail = /* GraphQL */ `
  query GetLogbookEmail($id: ID!) {
    getLogbookEmail(id: $id) {
      id
      emailID
      status
      pdfUrl
      logbookID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLogbookEmails = /* GraphQL */ `
  query ListLogbookEmails(
    $filter: ModelLogbookEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogbookEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailID
        status
        pdfUrl
        logbookID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logbookEmailsByLogbookID = /* GraphQL */ `
  query LogbookEmailsByLogbookID(
    $logbookID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLogbookEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logbookEmailsByLogbookID(
      logbookID: $logbookID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailID
        status
        pdfUrl
        logbookID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReports = /* GraphQL */ `
  query GetReports($id: ID!) {
    getReports(id: $id) {
      id
      reportURL
      userID
      assetID
      categoriesID
      sitedetailsID
      reportName
      distributionBoxId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reportURL
        userID
        assetID
        categoriesID
        sitedetailsID
        reportName
        distributionBoxId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reportsByCategoriesID = /* GraphQL */ `
  query ReportsByCategoriesID(
    $categoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByCategoriesID(
      categoriesID: $categoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportURL
        userID
        assetID
        categoriesID
        sitedetailsID
        reportName
        distributionBoxId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const reportsBySitedetailsID = /* GraphQL */ `
  query ReportsBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportURL
        userID
        assetID
        categoriesID
        sitedetailsID
        reportName
        distributionBoxId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCertificates = /* GraphQL */ `
  query GetCertificates($id: ID!) {
    getCertificates(id: $id) {
      id
      certificateURL
      categoriesID
      sitedetailsID
      userID
      certificateName
      certificateType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCertificates = /* GraphQL */ `
  query ListCertificates(
    $filter: ModelCertificatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCertificates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        certificateURL
        categoriesID
        sitedetailsID
        userID
        certificateName
        certificateType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const certificatesByCategoriesID = /* GraphQL */ `
  query CertificatesByCategoriesID(
    $categoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCertificatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    certificatesByCategoriesID(
      categoriesID: $categoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        certificateURL
        categoriesID
        sitedetailsID
        userID
        certificateName
        certificateType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const certificatesBySitedetailsID = /* GraphQL */ `
  query CertificatesBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCertificatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    certificatesBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        certificateURL
        categoriesID
        sitedetailsID
        userID
        certificateName
        certificateType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLogbookAssetLink = /* GraphQL */ `
  query GetLogbookAssetLink($id: ID!) {
    getLogbookAssetLink(id: $id) {
      id
      Logbook
      AssetID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLogbookAssetLinks = /* GraphQL */ `
  query ListLogbookAssetLinks(
    $filter: ModelLogbookAssetLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogbookAssetLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Logbook
        AssetID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionLimit = /* GraphQL */ `
  query GetSubscriptionLimit($id: ID!) {
    getSubscriptionLimit(id: $id) {
      id
      subscriptionId
      AssetLimit
      SiteLimit
      SiteUserLimit
      SPUserLimit
      Price
      SubscriptionName
      FutureCost
      UserTopup
      MinAsset
      DiscountPrice
      Type
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscriptionLimits = /* GraphQL */ `
  query ListSubscriptionLimits(
    $filter: ModelSubscriptionLimitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionLimits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subscriptionId
        AssetLimit
        SiteLimit
        SiteUserLimit
        SPUserLimit
        Price
        SubscriptionName
        FutureCost
        UserTopup
        MinAsset
        DiscountPrice
        Type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceGroupUserMap = /* GraphQL */ `
  query GetServiceGroupUserMap($id: ID!) {
    getServiceGroupUserMap(id: $id) {
      id
      groupName
      cognitogroupID
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServiceGroupUserMaps = /* GraphQL */ `
  query ListServiceGroupUserMaps(
    $filter: ModelServiceGroupUserMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceGroupUserMaps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupName
        cognitogroupID
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const serviceGroupUserMapsByCognitogroupID = /* GraphQL */ `
  query ServiceGroupUserMapsByCognitogroupID(
    $cognitogroupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelServiceGroupUserMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceGroupUserMapsByCognitogroupID(
      cognitogroupID: $cognitogroupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupName
        cognitogroupID
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const serviceGroupUserMapsByServicegroupID = /* GraphQL */ `
  query ServiceGroupUserMapsByServicegroupID(
    $servicegroupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelServiceGroupUserMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceGroupUserMapsByServicegroupID(
      servicegroupID: $servicegroupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupName
        cognitogroupID
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCognitoGroup = /* GraphQL */ `
  query GetCognitoGroup($id: ID!) {
    getCognitoGroup(id: $id) {
      id
      groupName
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCognitoGroups = /* GraphQL */ `
  query ListCognitoGroups(
    $filter: ModelCognitoGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCognitoGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssets = /* GraphQL */ `
  query GetAssets($id: ID!) {
    getAssets(id: $id) {
      id
      siteId
      formFieldValues
      status
      assettemplateID
      Repairs {
        nextToken
        __typename
      }
      Testings {
        nextToken
        __typename
      }
      assetLocation
      assetType
      assetSerialNumber
      assetDescription
      assetModel
      assetMake
      assetWarrantyStart
      assetWarrantyEnd
      installedOn
      assetNumber
      batteryTypeId
      lampTypeId
      fittingTypeId
      assetImage
      distributionboxID
      logbookId
      sitedetailsID
      fittingNumber
      type
      assettemplateSiteID
      source
      createdBy
      assetNum
      duplicate
      assetOwnerID
      addedToTask
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteId
        formFieldValues
        status
        assettemplateID
        assetLocation
        assetType
        assetSerialNumber
        assetDescription
        assetModel
        assetMake
        assetWarrantyStart
        assetWarrantyEnd
        installedOn
        assetNumber
        batteryTypeId
        lampTypeId
        fittingTypeId
        assetImage
        distributionboxID
        logbookId
        sitedetailsID
        fittingNumber
        type
        assettemplateSiteID
        source
        createdBy
        assetNum
        duplicate
        assetOwnerID
        addedToTask
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetsByStatus = /* GraphQL */ `
  query AssetsByStatus(
    $status: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteId
        formFieldValues
        status
        assettemplateID
        assetLocation
        assetType
        assetSerialNumber
        assetDescription
        assetModel
        assetMake
        assetWarrantyStart
        assetWarrantyEnd
        installedOn
        assetNumber
        batteryTypeId
        lampTypeId
        fittingTypeId
        assetImage
        distributionboxID
        logbookId
        sitedetailsID
        fittingNumber
        type
        assettemplateSiteID
        source
        createdBy
        assetNum
        duplicate
        assetOwnerID
        addedToTask
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetsByAssettemplateID = /* GraphQL */ `
  query AssetsByAssettemplateID(
    $assettemplateID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByAssettemplateID(
      assettemplateID: $assettemplateID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteId
        formFieldValues
        status
        assettemplateID
        assetLocation
        assetType
        assetSerialNumber
        assetDescription
        assetModel
        assetMake
        assetWarrantyStart
        assetWarrantyEnd
        installedOn
        assetNumber
        batteryTypeId
        lampTypeId
        fittingTypeId
        assetImage
        distributionboxID
        logbookId
        sitedetailsID
        fittingNumber
        type
        assettemplateSiteID
        source
        createdBy
        assetNum
        duplicate
        assetOwnerID
        addedToTask
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetsBySitedetailsID = /* GraphQL */ `
  query AssetsBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteId
        formFieldValues
        status
        assettemplateID
        assetLocation
        assetType
        assetSerialNumber
        assetDescription
        assetModel
        assetMake
        assetWarrantyStart
        assetWarrantyEnd
        installedOn
        assetNumber
        batteryTypeId
        lampTypeId
        fittingTypeId
        assetImage
        distributionboxID
        logbookId
        sitedetailsID
        fittingNumber
        type
        assettemplateSiteID
        source
        createdBy
        assetNum
        duplicate
        assetOwnerID
        addedToTask
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetsByTypeAndAssetNum = /* GraphQL */ `
  query AssetsByTypeAndAssetNum(
    $type: String!
    $assetNum: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByTypeAndAssetNum(
      type: $type
      assetNum: $assetNum
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteId
        formFieldValues
        status
        assettemplateID
        assetLocation
        assetType
        assetSerialNumber
        assetDescription
        assetModel
        assetMake
        assetWarrantyStart
        assetWarrantyEnd
        installedOn
        assetNumber
        batteryTypeId
        lampTypeId
        fittingTypeId
        assetImage
        distributionboxID
        logbookId
        sitedetailsID
        fittingNumber
        type
        assettemplateSiteID
        source
        createdBy
        assetNum
        duplicate
        assetOwnerID
        addedToTask
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetsByAssettemplateSiteIDAndAssetNum = /* GraphQL */ `
  query AssetsByAssettemplateSiteIDAndAssetNum(
    $assettemplateSiteID: ID!
    $assetNum: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByAssettemplateSiteIDAndAssetNum(
      assettemplateSiteID: $assettemplateSiteID
      assetNum: $assetNum
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteId
        formFieldValues
        status
        assettemplateID
        assetLocation
        assetType
        assetSerialNumber
        assetDescription
        assetModel
        assetMake
        assetWarrantyStart
        assetWarrantyEnd
        installedOn
        assetNumber
        batteryTypeId
        lampTypeId
        fittingTypeId
        assetImage
        distributionboxID
        logbookId
        sitedetailsID
        fittingNumber
        type
        assettemplateSiteID
        source
        createdBy
        assetNum
        duplicate
        assetOwnerID
        addedToTask
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetsByAssetOwnerID = /* GraphQL */ `
  query AssetsByAssetOwnerID(
    $assetOwnerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByAssetOwnerID(
      assetOwnerID: $assetOwnerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        siteId
        formFieldValues
        status
        assettemplateID
        assetLocation
        assetType
        assetSerialNumber
        assetDescription
        assetModel
        assetMake
        assetWarrantyStart
        assetWarrantyEnd
        installedOn
        assetNumber
        batteryTypeId
        lampTypeId
        fittingTypeId
        assetImage
        distributionboxID
        logbookId
        sitedetailsID
        fittingNumber
        type
        assettemplateSiteID
        source
        createdBy
        assetNum
        duplicate
        assetOwnerID
        addedToTask
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetTemplate = /* GraphQL */ `
  query GetAssetTemplate($id: ID!) {
    getAssetTemplate(id: $id) {
      id
      templatename
      description
      formId
      status
      categoriesID
      Assets {
        nextToken
        __typename
      }
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetTemplates = /* GraphQL */ `
  query ListAssetTemplates(
    $filter: ModelAssetTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        templatename
        description
        formId
        status
        categoriesID
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetTemplatesByCategoriesID = /* GraphQL */ `
  query AssetTemplatesByCategoriesID(
    $categoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetTemplatesByCategoriesID(
      categoriesID: $categoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templatename
        description
        formId
        status
        categoriesID
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetTemplatesByServicegroupID = /* GraphQL */ `
  query AssetTemplatesByServicegroupID(
    $servicegroupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetTemplatesByServicegroupID(
      servicegroupID: $servicegroupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        templatename
        description
        formId
        status
        categoriesID
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLinkUsersAndSite = /* GraphQL */ `
  query GetLinkUsersAndSite($id: ID!) {
    getLinkUsersAndSite(id: $id) {
      id
      status
      sitedetailsID
      userdetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLinkUsersAndSites = /* GraphQL */ `
  query ListLinkUsersAndSites(
    $filter: ModelLinkUsersAndSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLinkUsersAndSites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        sitedetailsID
        userdetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linkUsersAndSitesBySitedetailsID = /* GraphQL */ `
  query LinkUsersAndSitesBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLinkUsersAndSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkUsersAndSitesBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        sitedetailsID
        userdetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linkUsersAndSitesByUserdetailsID = /* GraphQL */ `
  query LinkUsersAndSitesByUserdetailsID(
    $userdetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLinkUsersAndSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkUsersAndSitesByUserdetailsID(
      userdetailsID: $userdetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        sitedetailsID
        userdetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStaticForm = /* GraphQL */ `
  query GetStaticForm($id: ID!) {
    getStaticForm(id: $id) {
      id
      formName
      formFields
      dataModelName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStaticForms = /* GraphQL */ `
  query ListStaticForms(
    $filter: ModelStaticFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaticForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formName
        formFields
        dataModelName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategories = /* GraphQL */ `
  query GetCategories($id: ID!) {
    getCategories(id: $id) {
      id
      categoryName
      formDescription
      formIds
      categoryNumber
      status
      AssetTemplates {
        nextToken
        __typename
      }
      servicegroupID
      imageURL
      assetNumberPrefix
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryName
        formDescription
        formIds
        categoryNumber
        status
        servicegroupID
        imageURL
        assetNumberPrefix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const categoriesByServicegroupID = /* GraphQL */ `
  query CategoriesByServicegroupID(
    $servicegroupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesByServicegroupID(
      servicegroupID: $servicegroupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryName
        formDescription
        formIds
        categoryNumber
        status
        servicegroupID
        imageURL
        assetNumberPrefix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getServiceGroup = /* GraphQL */ `
  query GetServiceGroup($id: ID!) {
    getServiceGroup(id: $id) {
      id
      serviceName
      serviceProvider
      status
      serviceNumber
      Categories {
        nextToken
        __typename
      }
      AssetTemplates {
        nextToken
        __typename
      }
      FormDetails {
        nextToken
        __typename
      }
      ServiceGroupUserMaps {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServiceGroups = /* GraphQL */ `
  query ListServiceGroups(
    $filter: ModelServiceGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceName
        serviceProvider
        status
        serviceNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDistributionBox = /* GraphQL */ `
  query GetDistributionBox($id: ID!) {
    getDistributionBox(id: $id) {
      id
      distributionBoxNumber
      location
      status
      sitedetailsID
      distributionBoxName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDistributionBoxes = /* GraphQL */ `
  query ListDistributionBoxes(
    $filter: ModelDistributionBoxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistributionBoxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributionBoxNumber
        location
        status
        sitedetailsID
        distributionBoxName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const distributionBoxesBySitedetailsID = /* GraphQL */ `
  query DistributionBoxesBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDistributionBoxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distributionBoxesBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        distributionBoxNumber
        location
        status
        sitedetailsID
        distributionBoxName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLampType = /* GraphQL */ `
  query GetLampType($id: ID!) {
    getLampType(id: $id) {
      id
      lampType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLampTypes = /* GraphQL */ `
  query ListLampTypes(
    $filter: ModelLampTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLampTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lampType
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBatteryType = /* GraphQL */ `
  query GetBatteryType($id: ID!) {
    getBatteryType(id: $id) {
      id
      batteryType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBatteryTypes = /* GraphQL */ `
  query ListBatteryTypes(
    $filter: ModelBatteryTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatteryTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        batteryType
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFittingType = /* GraphQL */ `
  query GetFittingType($id: ID!) {
    getFittingType(id: $id) {
      id
      fittingType
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFittingTypes = /* GraphQL */ `
  query ListFittingTypes(
    $filter: ModelFittingTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFittingTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fittingType
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFormDetails = /* GraphQL */ `
  query GetFormDetails($id: ID!) {
    getFormDetails(id: $id) {
      id
      formName
      serviceGroup
      formDescription
      enableSchedule
      formFields
      status
      formNumber
      formType
      servicegroupID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFormDetails = /* GraphQL */ `
  query ListFormDetails(
    $filter: ModelFormDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        formName
        serviceGroup
        formDescription
        enableSchedule
        formFields
        status
        formNumber
        formType
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formDetailsByServicegroupID = /* GraphQL */ `
  query FormDetailsByServicegroupID(
    $servicegroupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFormDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formDetailsByServicegroupID(
      servicegroupID: $servicegroupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        formName
        serviceGroup
        formDescription
        enableSchedule
        formFields
        status
        formNumber
        formType
        servicegroupID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSPUserDetails = /* GraphQL */ `
  query GetSPUserDetails($id: ID!) {
    getSPUserDetails(id: $id) {
      id
      fname
      lname
      email
      mobile
      password
      license
      profilepic
      status
      sitedetailsID
      UserDetails {
        id
        username
        email
        companyDetailsId
        assetOwnerId
        paymentId
        siteDetailsId
        userDetailsId
        status
        pushNotificationToken
        permissions
        paymentObject
        createdAt
        updatedAt
        __typename
      }
      userGroup
      permission
      invitedBy
      SPGroup
      spcompanydetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSPUserDetails = /* GraphQL */ `
  query ListSPUserDetails(
    $filter: ModelSPUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSPUserDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fname
        lname
        email
        mobile
        password
        license
        profilepic
        status
        sitedetailsID
        userGroup
        permission
        invitedBy
        SPGroup
        spcompanydetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sPUserDetailsBySitedetailsID = /* GraphQL */ `
  query SPUserDetailsBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSPUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sPUserDetailsBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fname
        lname
        email
        mobile
        password
        license
        profilepic
        status
        sitedetailsID
        userGroup
        permission
        invitedBy
        SPGroup
        spcompanydetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sPUserDetailsBySpcompanydetailsID = /* GraphQL */ `
  query SPUserDetailsBySpcompanydetailsID(
    $spcompanydetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSPUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sPUserDetailsBySpcompanydetailsID(
      spcompanydetailsID: $spcompanydetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fname
        lname
        email
        mobile
        password
        license
        profilepic
        status
        sitedetailsID
        userGroup
        permission
        invitedBy
        SPGroup
        spcompanydetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBillingPlan = /* GraphQL */ `
  query GetBillingPlan($id: ID!) {
    getBillingPlan(id: $id) {
      id
      plan
      assets
      price
      users
      additionalUsers
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBillingPlans = /* GraphQL */ `
  query ListBillingPlans(
    $filter: ModelBillingPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillingPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        plan
        assets
        price
        users
        additionalUsers
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails($id: ID!) {
    getUserDetails(id: $id) {
      id
      username
      email
      companyDetailsId
      assetOwnerId
      paymentId
      siteDetailsId
      userDetailsId
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      pushNotificationToken
      permissions
      paymentObject
      SubscriptionAdds {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserDetails = /* GraphQL */ `
  query ListUserDetails(
    $filter: ModelUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        companyDetailsId
        assetOwnerId
        paymentId
        siteDetailsId
        userDetailsId
        status
        pushNotificationToken
        permissions
        paymentObject
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userDetailsByEmail = /* GraphQL */ `
  query UserDetailsByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDetailsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        email
        companyDetailsId
        assetOwnerId
        paymentId
        siteDetailsId
        userDetailsId
        status
        pushNotificationToken
        permissions
        paymentObject
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRepair = /* GraphQL */ `
  query GetRepair($id: ID!) {
    getRepair(id: $id) {
      id
      technician
      repairDate
      warrantyUpto
      details
      reportorPics
      status
      customFields
      assetsID
      additionalInformation
      logbookID
      replacedAssetID
      repairTime
      siteId
      assettemplateSiteID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRepairs = /* GraphQL */ `
  query ListRepairs(
    $filter: ModelRepairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRepairs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        technician
        repairDate
        warrantyUpto
        details
        reportorPics
        status
        customFields
        assetsID
        additionalInformation
        logbookID
        replacedAssetID
        repairTime
        siteId
        assettemplateSiteID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const repairsByAssetsID = /* GraphQL */ `
  query RepairsByAssetsID(
    $assetsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRepairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repairsByAssetsID(
      assetsID: $assetsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        technician
        repairDate
        warrantyUpto
        details
        reportorPics
        status
        customFields
        assetsID
        additionalInformation
        logbookID
        replacedAssetID
        repairTime
        siteId
        assettemplateSiteID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const repairsByLogbookID = /* GraphQL */ `
  query RepairsByLogbookID(
    $logbookID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRepairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repairsByLogbookID(
      logbookID: $logbookID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        technician
        repairDate
        warrantyUpto
        details
        reportorPics
        status
        customFields
        assetsID
        additionalInformation
        logbookID
        replacedAssetID
        repairTime
        siteId
        assettemplateSiteID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const repairsByAssettemplateSiteID = /* GraphQL */ `
  query RepairsByAssettemplateSiteID(
    $assettemplateSiteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRepairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    repairsByAssettemplateSiteID(
      assettemplateSiteID: $assettemplateSiteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        technician
        repairDate
        warrantyUpto
        details
        reportorPics
        status
        customFields
        assetsID
        additionalInformation
        logbookID
        replacedAssetID
        repairTime
        siteId
        assettemplateSiteID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTesting = /* GraphQL */ `
  query GetTesting($id: ID!) {
    getTesting(id: $id) {
      id
      testResult
      warranty
      testOn
      nextTestSchedule
      testReport
      emailNofication
      calendar
      reminder
      status
      customFields
      technician
      assetsID
      additionalInformation
      spUserId
      logbookID
      testTime
      groupId
      siteId
      bulkUpdate
      testType
      tripTime
      assettemplateSiteID
      sitedetailsID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTestings = /* GraphQL */ `
  query ListTestings(
    $filter: ModelTestingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testResult
        warranty
        testOn
        nextTestSchedule
        testReport
        emailNofication
        calendar
        reminder
        status
        customFields
        technician
        assetsID
        additionalInformation
        spUserId
        logbookID
        testTime
        groupId
        siteId
        bulkUpdate
        testType
        tripTime
        assettemplateSiteID
        sitedetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testingsByAssetsID = /* GraphQL */ `
  query TestingsByAssetsID(
    $assetsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTestingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testingsByAssetsID(
      assetsID: $assetsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResult
        warranty
        testOn
        nextTestSchedule
        testReport
        emailNofication
        calendar
        reminder
        status
        customFields
        technician
        assetsID
        additionalInformation
        spUserId
        logbookID
        testTime
        groupId
        siteId
        bulkUpdate
        testType
        tripTime
        assettemplateSiteID
        sitedetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testingsByLogbookID = /* GraphQL */ `
  query TestingsByLogbookID(
    $logbookID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTestingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testingsByLogbookID(
      logbookID: $logbookID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResult
        warranty
        testOn
        nextTestSchedule
        testReport
        emailNofication
        calendar
        reminder
        status
        customFields
        technician
        assetsID
        additionalInformation
        spUserId
        logbookID
        testTime
        groupId
        siteId
        bulkUpdate
        testType
        tripTime
        assettemplateSiteID
        sitedetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testingsByAssettemplateSiteID = /* GraphQL */ `
  query TestingsByAssettemplateSiteID(
    $assettemplateSiteID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTestingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testingsByAssettemplateSiteID(
      assettemplateSiteID: $assettemplateSiteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResult
        warranty
        testOn
        nextTestSchedule
        testReport
        emailNofication
        calendar
        reminder
        status
        customFields
        technician
        assetsID
        additionalInformation
        spUserId
        logbookID
        testTime
        groupId
        siteId
        bulkUpdate
        testType
        tripTime
        assettemplateSiteID
        sitedetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const testingsBySitedetailsID = /* GraphQL */ `
  query TestingsBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTestingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    testingsBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        testResult
        warranty
        testOn
        nextTestSchedule
        testReport
        emailNofication
        calendar
        reminder
        status
        customFields
        technician
        assetsID
        additionalInformation
        spUserId
        logbookID
        testTime
        groupId
        siteId
        bulkUpdate
        testType
        tripTime
        assettemplateSiteID
        sitedetailsID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLogBook = /* GraphQL */ `
  query GetLogBook($id: ID!) {
    getLogBook(id: $id) {
      id
      reports
      status
      siteId
      customFields
      additionalInformation
      technician
      Testings {
        nextToken
        __typename
      }
      Repairs {
        nextToken
        __typename
      }
      distributionBox
      repairInfo
      LogbookAssetLink {
        id
        Logbook
        AssetID
        createdAt
        updatedAt
        __typename
      }
      logbookDateTime
      LogbookEmails {
        nextToken
        __typename
      }
      sitedetailsID
      assetsID
      logbookName
      categoryId
      createdAt
      updatedAt
      logBookLogbookAssetLinkId
      __typename
    }
  }
`;
export const listLogBooks = /* GraphQL */ `
  query ListLogBooks(
    $filter: ModelLogBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reports
        status
        siteId
        customFields
        additionalInformation
        technician
        distributionBox
        repairInfo
        logbookDateTime
        sitedetailsID
        assetsID
        logbookName
        categoryId
        createdAt
        updatedAt
        logBookLogbookAssetLinkId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const logBooksBySitedetailsID = /* GraphQL */ `
  query LogBooksBySitedetailsID(
    $sitedetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLogBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    logBooksBySitedetailsID(
      sitedetailsID: $sitedetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reports
        status
        siteId
        customFields
        additionalInformation
        technician
        distributionBox
        repairInfo
        logbookDateTime
        sitedetailsID
        assetsID
        logbookName
        categoryId
        createdAt
        updatedAt
        logBookLogbookAssetLinkId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompanyDetails = /* GraphQL */ `
  query GetCompanyDetails($id: ID!) {
    getCompanyDetails(id: $id) {
      id
      companyName
      companyBN
      companyAddress
      logo
      managerName
      email
      mobile
      status
      street
      suburb
      state
      postcode
      country
      SiteDetails {
        nextToken
        __typename
      }
      AssetOwners {
        nextToken
        __typename
      }
      website
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanyDetails = /* GraphQL */ `
  query ListCompanyDetails(
    $filter: ModelCompanyDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyName
        companyBN
        companyAddress
        logo
        managerName
        email
        mobile
        status
        street
        suburb
        state
        postcode
        country
        website
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSPCompanyDetails = /* GraphQL */ `
  query GetSPCompanyDetails($id: ID!) {
    getSPCompanyDetails(id: $id) {
      id
      companyName
      companyBN
      companyAddress
      companyLicense
      status
      street
      suburb
      state
      postcode
      country
      logo
      phone
      website
      EC
      FAX
      email
      Tasks {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSPCompanyDetails = /* GraphQL */ `
  query ListSPCompanyDetails(
    $filter: ModelSPCompanyDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSPCompanyDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        companyBN
        companyAddress
        companyLicense
        status
        street
        suburb
        state
        postcode
        country
        logo
        phone
        website
        EC
        FAX
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetOwner = /* GraphQL */ `
  query GetAssetOwner($id: ID!) {
    getAssetOwner(id: $id) {
      id
      firstName
      lastName
      email
      mobile
      profilePicture
      companyName
      sites
      status
      companydetailsID
      SiteDetails {
        nextToken
        __typename
      }
      permission
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssetOwners = /* GraphQL */ `
  query ListAssetOwners(
    $filter: ModelAssetOwnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetOwners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        mobile
        profilePicture
        companyName
        sites
        status
        companydetailsID
        permission
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetOwnersByCompanydetailsID = /* GraphQL */ `
  query AssetOwnersByCompanydetailsID(
    $companydetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetOwnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetOwnersByCompanydetailsID(
      companydetailsID: $companydetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        mobile
        profilePicture
        companyName
        sites
        status
        companydetailsID
        permission
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSiteDetails = /* GraphQL */ `
  query GetSiteDetails($id: ID!) {
    getSiteDetails(id: $id) {
      id
      unitNumber
      street
      suburb
      state
      postCode
      country
      status
      LinkUsersAndSites {
        nextToken
        __typename
      }
      DistributionBoxes {
        nextToken
        __typename
      }
      SPUserDetails {
        nextToken
        __typename
      }
      companydetailsID
      assetownerID
      Certificates {
        nextToken
        __typename
      }
      Reports {
        nextToken
        __typename
      }
      LogBooks {
        nextToken
        __typename
      }
      siteName
      Assets {
        nextToken
        __typename
      }
      Tasks {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSiteDetails = /* GraphQL */ `
  query ListSiteDetails(
    $filter: ModelSiteDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSiteDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        unitNumber
        street
        suburb
        state
        postCode
        country
        status
        companydetailsID
        assetownerID
        siteName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const siteDetailsByCompanydetailsID = /* GraphQL */ `
  query SiteDetailsByCompanydetailsID(
    $companydetailsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSiteDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteDetailsByCompanydetailsID(
      companydetailsID: $companydetailsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitNumber
        street
        suburb
        state
        postCode
        country
        status
        companydetailsID
        assetownerID
        siteName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const siteDetailsByAssetownerID = /* GraphQL */ `
  query SiteDetailsByAssetownerID(
    $assetownerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSiteDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    siteDetailsByAssetownerID(
      assetownerID: $assetownerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        unitNumber
        street
        suburb
        state
        postCode
        country
        status
        companydetailsID
        assetownerID
        siteName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
