import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  Container,
  Row,
  Input,
  Table,
  Col,
  Card,
  InputGroup,
  ModalBody,
  Modal,
  Form,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { Btn, H4, H1 } from "../../../AbstractElements";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { SiteContext } from "../../../App";
import Select from "react-select";
import SiteDBData from "../../../Data/DistributionBoard/index";
import { generateClient } from "aws-amplify/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { S3_BUCKET, REGION, limit } from "../../../Config/Config";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const DeletedAssetsList = () => {
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const navigate = useNavigate();
  const state = useLocation();
  const [assets, setAssets] = useState([]);
  const [fullAssets, setFullAssets] = useState([]);
  const [value, setValue] = useState("");
  const [collection, setCollection] = React.useState([]);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [isSecoundSearch, setIsSecoundSearch] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedSiteData, setSelectedSiteData] = useState("");
  const [selectedSearchSiteData, setSelectedSearchSiteData] = useState("");
  const [selectedSearchDBData, setSelectedSearchDBData] = useState("");
  const [sites, setSites] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
      LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [isLoading, setIsLoading] = useState(false);
  const [firstSearch, setFirstSearch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [distributionBoxList, setDistributionBoxList] = useState(
    SiteDBData.getSiteDBData()
  );
  const [distributionBoxFilter, setDistributionBoxfilter] = useState(
    SiteDBData.getSiteDBData()
  );
  const catid =
    state.state && state.state.category ? state.state.category.id : "";
  const [sitess, setSitess] = useState();
  const [selectedBox, setSelectedBox] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isActive, setIsActive] = useState(true);
  const LoginUserGroup = localStorage.getItem("groups");
  const countPerPageCard = 8;
  const [currentPageCard, setCurrentPageCard] = useState(1);
  const [assetDemo, setAssetsDemo] = React.useState([]);
  const [assetDemoCard, setAssetsDemoCard] = React.useState([]);
  const [collectionCard, setCollectionCard] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 10;
  const [filteredData, setFilteredData] = React.useState([]);
  const [assetTemplatData, setAssetTemplatData] = React.useState([]);
  const [activedata, setactiveData] = React.useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [deleteAsset, setDelete] = useState(false);
  const [selectedItemsAsset, setSelectedItemsAsset] = useState([]);
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const [Loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [items, setItems] = useState([]);
  const [siteIndex, setSiteIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [nextToken, setNextToken] = useState(null);
  const [fullItems, setFullItems] = useState([]);
  var isReset = false;
  const [searchAssets, setSearchAssets] = useState(null);
  var isDBChange = false;
  const assetRecordsRef = useRef([]);
  const assetRecordsSearchRef = useRef([]);
  const assetRecordsSearchDBRef = useRef([]);
  const [siteName, setSiteName] = useState("");

  const deleteAllToggle = () => {
    setDeleteAllModal(!deleteAllModal);
  };

  const deleteToggle = () => {
    selectedItemsAsset.length > 0
      ? setDeleteModal(!deleteModal)
      : setDeleteModal(false);
  };

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    const data =
      value ||
        (state.state && state.state.site ? false : selectedSite) ||
        selectedLocation ||
        selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
          ? assetDemo
          : assetDemo;
    setCollection(cloneDeep(data.slice(from, to)));
  };

  const updatePageCard = (p) => {
    setCurrentPageCard(p);
    const to = countPerPageCard * p;
    const from = to - countPerPageCard;
    const data =
      value ||
        (state.state && state.state.site ? false : selectedSite) ||
        selectedLocation ||
        selectedBox
        ? filteredData
        : state.state && state.state.site && state.state.category
          ? assetDemoCard
          : assetDemoCard;
    setCollectionCard(cloneDeep(data.slice(from, to)));
  };

  // --- site user assets ---
  async function fetchSitesDetailsSU() {
    try {
      //setIsLoading(true);
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          variables: {
            userdetailsID: LoginUserDetails.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          const sites = result.data.linkUsersAndSitesByUserdetailsID.items;
          if (sites.length > 0) {
            const promises = sites.map(async (item) => {
              return client.graphql({
                query: queries.getSiteDetails,
                variables: {
                  id: item.sitedetailsID,
                },
              });
            });
            Promise.all(promises)
              .then((results) => {
                //console.log(results);
                let siteData = [];
                if (results.length > 0) {
                  results.map((site) => {
                    if (site.data.getSiteDetails !== null)
                      siteData.push(site.data.getSiteDetails);
                  });
                  if (siteData.length > 0) {
                    const siteOptions = [];
                    if (state.state) {
                      //getAssetTemplate(siteData);
                    }
                    const activesites = siteData.filter(
                      (item) => item.status === "Active"
                    );
                    setSiteDetails(activesites);
                    activesites.map(async (item) => {
                      siteOptions.push({
                        value: item.id,
                        label:
                          item.siteName +
                          " " +
                          (item.unitNumber ? item.unitNumber : " "),
                      });
                    });
                    setSites(siteOptions);
                  }
                }
              })
              .catch((error) => {
                //console.log(error);
              });
          } else {
            setSites([]);
            setAssets([]);
            //setIsLoading(false);

            setErrorMessage("No Assets to fetch");
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchSitesDetails() {
    try {
      //setIsLoading(true);
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          const sites = result.data.siteDetailsByAssetownerID.items;
          let nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          let siteData = [];
          if (sites.length > 0) {
            siteData.push(sites);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: LoginUserDetails.id,
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((result) => {
                nextToken = result.data.siteDetailsByAssetownerID.nextToken;
                if (result.data.siteDetailsByAssetownerID.items.length > 0) {
                  siteData.push(result.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                //console.log(error);
              });
          }
          if (siteData.length > 0) {
            let mergeSites = siteData.flat(1);
            const siteFilterArray = [];
            const siteOptions = [];
            let siteFilter = [];
            if (state.state && state.state.site) {
              siteFilter = mergeSites.filter(
                (site) => site.id === state.state.site.id
              );
            }
            // if (state.state) {
            //   siteFilter.length > 0
            //     ? getAssetTemplate(siteFilter)
            //     : getAssetTemplate(mergeSites);
            // }

            const activesites = mergeSites.filter(
              (item) => item.status === "Active"
            );
            siteFilter.length > 0
              ? setSiteDetails(siteFilter)
              : setSiteDetails(activesites);
            activesites.map(async (item) => {
              siteFilterArray.push(item.id);
              siteOptions.push({
                value: item.id,
                label:
                  item.siteName +
                  " " +
                  (item.unitNumber ? item.unitNumber : " "),
              });
            });
            setSites(siteOptions);
          } else {
            setAssets([]);
            //setIsLoading(false);

            setErrorMessage("No Assets to fetch");
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchlistDistributionBox() {
    try {
      //console.log(sitess);
      if (sitess.length > 0) {
        let nextToken = "";
        let assets = [];
        const activesites = sitess.filter((item) => item.status === "Active");
        activesites.map(async (item) => {
          const dbList = await client.graphql({
            query: queries.distributionBoxesBySitedetailsID,
            variables: {
              sitedetailsID: item.id,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (dbList.data.distributionBoxesBySitedetailsID.items.length > 0) {
            let assetDatas = dbList.data.distributionBoxesBySitedetailsID.items;
            nextToken = dbList.data.distributionBoxesBySitedetailsID.nextToken;
            if (dbList.data.distributionBoxesBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              const dbList1 = await client.graphql({
                query: queries.distributionBoxesBySitedetailsID,
                variables: {
                  limit: limit,
                  sitedetailsID: item.id,
                  filter: {
                    status: {
                      eq: "Active",
                    },
                  },
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              if (
                dbList1.data.distributionBoxesBySitedetailsID.items.length > 0
              ) {
                nextToken =
                  dbList1.data.distributionBoxesBySitedetailsID.nextToken;
                if (
                  dbList1.data.distributionBoxesBySitedetailsID.items.length > 0
                ) {
                  assets.push(
                    dbList1.data.distributionBoxesBySitedetailsID.items
                  );
                }
              }
              else {
                nextToken = null;
              }
            }
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              setDistributionBoxList(mergeAssets);
              setDistributionBoxfilter(mergeAssets);
              SiteDBData.setSiteDBData(mergeAssets);
              localStorage.setItem(
                "siteDB",
                base64.encode(JSON.stringify(mergeAssets))
              );
            } else {
              setDistributionBoxList([]);
              setDistributionBoxfilter([]);
            }
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    //console.log("select global Site");
    if (localStorage.getItem("site") === null && sites.length === 0) {
      LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
        ? fetchSitesDetailsSU()
        : fetchSitesDetails();
    } else {
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      let activeSites = sites.filter((item) => item.status === "Active");
      setSiteDetails(activeSites);
      let siteOptions = [];
      for (let site of activeSites) {
        siteOptions.push({
          value: site.id,
          label:
            site.siteName + " " + (site.unitNumber ? site.unitNumber : " "),
        });
      }
      setSites(siteOptions);
    }
    if (
      localStorage.getItem("siteDB") === null &&
      (distributionBoxList.length === 0 || distributionBoxFilter.length === 0)
    ) {
      fetchlistDistributionBox();
    } else {
      let DB = JSON.parse(base64.decode(localStorage.getItem("siteDB")));
      setDistributionBoxList(DB);
      setDistributionBoxfilter(DB);
      optionsDB.push({ value: "", label: "All Distribution Board" });
      DB.map(async (item) => {
        optionsDB.push({
          value: item.id,
          label: item.distributionBoxName,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (LoginUserGroup === "site_user") {
      fetchSitesDetailsSU();
    } else if (LoginUserGroup === "electrician") {
      fetchSitesDetailsSU();
    } else {
      fetchSitesDetails();
    }

    if (selectedSiteGlobal) {
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSiteGlobal;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSiteGlobal]);

  useEffect(() => {
    //console.log("selected Site");
    if (selectedSite !== "") {
      setDistributionBoxfilter(
        distributionBoxList.filter((item) => {
          return item.sitedetailsID === selectedSite;
        })
      );
    } else {
      setDistributionBoxfilter(distributionBoxList);
    }
  }, [selectedSite]);

  useEffect(() => {
    //console.log("select Site view");
    if (
      state.state &&
      state.state.site &&
      state.state.category &&
      !firstSearch
    ) {
      setSelectedSite(state.state.site.id);
      setSelectedSiteData(state.state.site);
      handleSelectedSiteSearch();
    }
  }, [assets && collection]);

  const handleSelectedSiteSearch = () => {
    setDistributionBoxfilter(
      distributionBoxList.filter(
        (item) => item.sitedetailsID === state.state.site.id
      )
    );
  };

  const handleSearch = () => {
    try {
      isDBChange = false;
      assetRecordsSearchDBRef.current = [];
      setIsSearch(true);
      setSearchAssets(true);
      setSiteIndex(0);
    } catch (error) {
      console.error("asset search", error);
    }
  };

  const cancelForm = () => {
    navigate(`${process.env.PUBLIC_URL}/assets`, {
      state: {
        category: state.state.category,
        site: state.state.site,
      },
    });
  };

  const handleSiteDDChange = (selectedOption) => {
    setSelectedSite(selectedOption.value);
    setSelectedSearchSiteData(selectedOption);
  };

  const optionsDB = [
    { value: "", label: "All Distribution Board" },
    ...distributionBoxFilter.map((item) => ({
      value: item.id,
      label: item.distributionBoxName,
    })),
  ];

  const handleSiteDB = (selectedOption) => {
    setSelectedBox(selectedOption.value);
    setSelectedSearchDBData(selectedOption);
  };

  const getSiteName = (siteId) => {
    try {
      const sites = siteDetails.filter((site) => {
        return site.id === siteId;
      });
      ////console.log("display site name", sites);
      return sites.length > 0 ? sites[0].siteName : "";
    } catch (error) {
      //console.log("Error while displaying site name", error);
      return "";
    }
  };

  const getDBName = (dbId) => {
    try {
      const db = distributionBoxList.filter((db) => {
        return db.id === dbId;
      });
      return db.length > 0 ? db[0].distributionBoxName : "";
    } catch (error) {
      //console.log("display DB name", error);
      return "";
    }
  };

  //---infinte scrolling of deletedassets
  async function getAssets() {
    if (isReset) {
      setHasMore(true);
      isReset = false;
      if (
        selectedSiteGlobal ||
        (state.state && state.state.site && state.state.site.id)
      ) {
        if (assetTemplatData.length > 0) {
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplatData) {
              try {
                count = count + 1;
                let assetSiteTemp =
                  item.id + "_" + selectedSiteGlobal
                    ? selectedSiteGlobal
                    : state.state.site.id;
                const assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        eq: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });

                const data =
                  assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextTokens = data.nextToken;
                if (nextTokens !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
                console.log(assetRecordsRef.current);
                if (data.items.length > 0) {
                  assets.push(data.items);
                }

                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  // if (assets.flat(1).length > 0) {
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  // }
                  if (nextTokens === null) {
                    console.log(siteIndex);
                    setSiteIndex(siteIndex + 1);
                  } else {
                    setHasMore(!nextTokens);
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      } else {
        let siteId = siteDetails[siteIndex].id;
        setSiteName(siteDetails[siteIndex].siteName);
        if (assetTemplatData.length > 0) {
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplatData) {
              try {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + siteId;
                const assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        eq: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });

                console.log(
                  "template->" + item.id + "<->" + item.templatename,
                  assetsData
                );
                const data =
                  assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextTokens = data.nextToken;
                if (nextTokens !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
                console.log(assetRecordsRef.current);
                if (data.items.length > 0) {
                  assets.push(data.items);
                }

                if (count === assetTemplatData.length) {
                  const mergeAssets = assets.flat(1);
                  // if (assets.flat(1).length > 0) {
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  // }

                  if (nextTokens === null) {
                    console.log(siteIndex);
                    setSiteIndex(siteIndex + 1);
                    setSiteName(siteDetails[siteIndex + 1].siteName);
                  } else {
                    setHasMore(!nextTokens);
                  }
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      }
      setIsLoading(false);
    } else {
      if (selectedSiteGlobal) {
        console.log("selectedSiteGlobal", selectedSiteGlobal);
        if (assetTemplatData.length > 0) {
          if (!hasMore) return;

          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplatData) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + selectedSiteGlobal;
              const assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    status: {
                      eq: "delete",
                    },
                  },
                },
                authMode: "API_KEY",
              });
              console.log(assetsData);
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextTokens = data.nextToken;
              if (nextTokens !== null) {
                assetRecordsRef.current.push({
                  assettemplsiteid: assetSiteTemp,
                  nextToken: nextTokens,
                });
              }
              console.log(siteIndex, assetRecordsRef.current);
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              if (count === assetTemplatData.length) {
                const mergeAssets = assets.flat(1);
                // if (assets.flat(1).length > 0) {
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                // }

                setHasMore(!nextTokens);
              }
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          const assetTemplateResults = await client.graphql({
            query: queries.assetTemplatesByCategoriesID,
            variables: {
              categoriesID: catid,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 800,
            },
            authMode: "API_KEY",
          });

          if (
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
              .length > 0
          ) {
            setAssetTemplatData(
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
            );
            if (!hasMore) return;

            try {
              let assets = [];
              let count = 0;
              let nextTokens = "";
              for (let item of assetTemplateResults.data
                .assetTemplatesByCategoriesID.items) {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + selectedSiteGlobal;
                var assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        eq: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });

                console.log(assetResults);
                const assetData =
                  assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextTokens = assetData.nextToken;
                if (assetData.items.length > 0) {
                  assets.push(assetData.items);
                }

                if (nextTokens !== null) {
                  assetRecordsRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
                console.log(siteIndex, assetRecordsRef.current);
                if (
                  count ===
                  assetTemplateResults.data.assetTemplatesByCategoriesID.items
                    .length
                ) {
                  console.log(assets);
                  const mergeAssets = assets.flat(1);
                  // if (assets.flat(1).length > 0) {
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);
                  // }

                  setHasMore(!nextTokens);
                }
              }
            } catch (error) {
              console.error("Error fetching asset data:", error);
            }
          }
        }
      } else {
        if (siteIndex < siteDetails.length) {
          let siteId = siteDetails[siteIndex].id;
          setSiteName(siteDetails[siteIndex].siteName);
          if (assetTemplatData.length > 0) {
            if (!hasMore) return;
            try {
              let assets = [];
              let count = 0;
              let nextTokens = "";
              for (let item of assetTemplatData) {
                try {
                  count = count + 1;
                  let assetSiteTemp = item.id + "_" + siteId;
                  const assetsData = await client.graphql({
                    query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                    variables: {
                      assettemplateSiteID: assetSiteTemp,
                      limit: 800,
                      filter: {
                        status: {
                          eq: "delete",
                        },
                      },
                    },
                    authMode: "API_KEY",
                  });

                  const data =
                    assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
                  console.log(
                    "template->" + item.id + "<->" + item.templatename,
                    data.nextToken
                  );
                  nextTokens = data.nextToken;
                  if (nextTokens !== null) {
                    assetRecordsRef.current.push({
                      assettemplsiteid: assetSiteTemp,
                      nextToken: data.nextToken,
                    });
                  }
                  console.log(siteIndex, assetRecordsRef.current);
                  if (data.items.length > 0) {
                    assets.push(data.items);
                  }

                  if (count === assetTemplatData.length) {
                    const mergeAssets = assets.flat(1);
                    // if (assets.flat(1).length > 0) {
                    setNextToken(nextTokens);
                    setItems((prevItems) => [...prevItems, ...mergeAssets]);
                    // }
                    console.log("hasMore", hasMore);
                    if (nextTokens === null) {
                      console.log(siteIndex);
                      setSiteIndex(siteIndex + 1);
                      setSiteName(siteDetails[siteIndex + 1].siteName);
                    } else {
                      if (assets.flat(1).length === 0) {
                        setSiteIndex(siteIndex + 1);
                        setSiteName(siteDetails[siteIndex + 1].siteName);
                      } else {
                        setHasMore(!!nextTokens);
                      }
                    }
                  }
                } catch (error) {
                  console.error("Error fetching data:", error);
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          } else {
            const assetTemplateResults = await client.graphql({
              query: queries.assetTemplatesByCategoriesID,
              variables: {
                categoriesID: catid,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: 800,
              },
              authMode: "API_KEY",
            });

            if (
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
                .length > 0
            ) {
              setAssetTemplatData(
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
              );
              if (!hasMore) return;

              try {
                let assets = [];
                let count = 0;
                let nextTokens = "";
                for (let item of assetTemplateResults.data
                  .assetTemplatesByCategoriesID.items) {
                  try {
                    count = count + 1;
                    let siteId = siteDetails[siteIndex].id;
                    let assetSiteTemp = item.id + "_" + siteId;
                    console.log(assetSiteTemp);

                    var assetResults = await client.graphql({
                      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                      variables: {
                        assettemplateSiteID: assetSiteTemp,
                        limit: 800,
                        filter: {
                          status: {
                            eq: "delete",
                          },
                        },
                      },
                      authMode: "API_KEY",
                    });
                    const assetData =
                      assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                    console.log(
                      "template->" + item.id + "<->" + item.templatename,
                      assetData.nextToken
                    );
                    nextTokens = assetData.nextToken;
                    if (nextTokens !== null) {
                      assetRecordsRef.current.push({
                        assettemplsiteid: assetSiteTemp,
                        nextToken: nextTokens,
                      });
                    }
                    console.log(siteIndex, assetRecordsRef.current);
                    if (assetData.items.length > 0) {
                      assets.push(assetData.items);
                    }
                    if (
                      count ===
                      assetTemplateResults.data.assetTemplatesByCategoriesID
                        .items.length
                    ) {
                      const mergeAssets = assets.flat(1);
                      // if (assets.flat(1).length > 0) {
                      setNextToken(nextTokens);
                      setItems((prevItems) => [...prevItems, ...mergeAssets]);
                      // }
                      if (nextTokens === null) {
                        console.log(siteIndex);
                        setSiteIndex(siteIndex + 1);
                        setSiteName(siteDetails[siteIndex + 1].siteName);
                      } else {
                        console.log("hasmore");
                        if (assets.flat(1).length === 0) {
                          setSiteIndex(siteIndex + 1);
                          setSiteName(siteDetails[siteIndex + 1].siteName);
                        } else {
                          setHasMore(!!nextTokens);
                        }
                      }
                    }
                  } catch (error) {
                    console.error("Error fetching data:", error);
                  }
                }
              } catch (error) {
                console.error("Error fetching asset data:", error);
              }
            }
          }
        } else {
          console.log(
            "site Index",
            siteIndex + ",length " + siteDetails.length
          );
          if (assetRecordsRef.current.length === 0) {
            setHasMore(!hasMore);
          }
        }
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (siteDetails.length > 0) {
      if (state.state && state.state.site && state.state.site.id) {
        setSelectedSite(state.state.site.id);
        searchAssetDataSiteView();
      } else {
        console.log(siteIndex, "assets from site");
        getAssets();
      }
    }
  }, [siteDetails]);

  useEffect(() => {
    if (siteIndex > 0 && items.length < 800) {
      console.log(siteIndex, "assets from siteindex innc");
      getAssets();
    }
  }, [siteIndex]);

  useEffect(() => {
    if (isReset) {
      console.log(siteIndex, "assets from reset");
      state.state && state.state.site && state.state.site.id
        ? searchAssetDataSiteView()
        : getAssets();
    }
  }, [isReset]);

  async function searchAssetDataSiteView() {
    setHasMore(true);

    let siteId = state.state.site.id;
    //console.log(siteId);
    //console.log(assetTemplatData);
    if (isReset) {
      isReset = false;
      setItems([]);
      setFullAssets([]);
      if (state.state && state.state.site && state.state.site.id) {
        if (assetTemplatData.length > 0) {
          if (isLoading || !hasMore) return;

          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            //siteDetails.map(async (site) => {
            for (let item of assetTemplatData) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + state.state.site.id;
              const assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              //console.log(assetsData);
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextTokens = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              if (count === assetTemplatData.length) {
                const mergeAssets = assets.flat(1);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);

                setHasMore(!nextTokens);
              }
            }
            //});
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          const assetTemplateResults = await client.graphql({
            query: queries.assetTemplatesByCategoriesID,
            variables: {
              categoriesID: catid,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 800,
            },
            authMode: "API_KEY",
          });

          if (
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
              .length > 0
          ) {
            setAssetTemplatData(
              assetTemplateResults.data.assetTemplatesByCategoriesID.items
            );
            if (isLoading || !hasMore) return;

            try {
              let assets = [];
              let count = 0;
              let nextTokens = "";
              for (let item of assetTemplateResults.data
                .assetTemplatesByCategoriesID.items) {
                count = count + 1;
                let assetSiteTemp = item.id + "_" + state.state.site.id;
                var assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });

                //console.log(assetResults);
                const assetData =
                  assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
                nextToken = assetData.nextToken;
                if (assetData.items.length > 0) {
                  assets.push(assetData.items);
                }
                if (
                  count ===
                  assetTemplateResults.data.assetTemplatesByCategoriesID.items
                    .length
                ) {
                  //console.log(assets);
                  const mergeAssets = assets.flat(1);
                  setNextToken(nextTokens);
                  setItems((prevItems) => [...prevItems, ...mergeAssets]);

                  setHasMore(!nextTokens);
                }
              }
            } catch (error) {
              console.error("Error fetching asset data:", error);
            }
          }
        }
      } else {
        setNextToken(null);
        setItems((prevItems) => [...prevItems, ...[]]);

        setHasMore(!hasMore);
      }
    } else {
      if (assetTemplatData.length > 0) {
        try {
          let assets = [];
          let count = 0;
          let nextTokens = "";
          for (let item of assetTemplatData) {
            count = count + 1;
            let assetSiteTemp = item.id + "_" + siteId;
            //console.log(assetSiteTemp);
            let assetsData = [];
            if (selectedBox) {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                  },
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
            } else {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
            }
            //console.log(assetsData);
            const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
            nextTokens = data.nextToken;
            if (data.items.length > 0) {
              assets.push(data.items);
            }
            if (count === assetTemplatData.length) {
              const mergeAssets = assets.flat(1);
              setNextToken(nextTokens);
              setItems((prevItems) => [...prevItems, ...mergeAssets]);

              setHasMore(!nextTokens);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const assetTemplateResults = await client.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: state.state.category.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 800,
          },
          authMode: "API_KEY",
        });

        if (
          assetTemplateResults.data.assetTemplatesByCategoriesID.items.length >
          0
        ) {
          setAssetTemplatData(
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
          );
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";
            for (let item of assetTemplateResults.data
              .assetTemplatesByCategoriesID.items) {
              count = count + 1;
              let assetSiteTemp = item.id + "_" + siteId;
              let assetResults = [];
              if (selectedBox) {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      distributionboxID: { eq: selectedBox },
                    },
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
              } else {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
              }

              //console.log(assetResults);
              const assetData =
                assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextToken = assetData.nextToken;
              if (assetData.items.length > 0) {
                assets.push(assetData.items);
              }
              if (
                count ===
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
                  .length
              ) {
                //console.log(assets);
                const mergeAssets = assets.flat(1);
                //console.log(mergeAssets);
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);

                setHasMore(!nextTokens);
              }
            }
          } catch (error) {
            console.error("Error fetching asset data:", error);
          }
        }
      }
    }
  }

  async function searchAssetData() {
    // setHasMore(true);
    //console.log(assetRecordsSearchRef.current);
    //console.log(assetRecordsSearchDBRef.current);
    if (!isDBChange) {
      if (isSearch && !isSecoundSearch) {
        setItems([]);
      }
      let siteId = selectedSite;
      if (assetTemplatData.length > 0) {
        try {
          let assets = [];
          let count = 0;
          let nextTokens = "";
          for (let item of assetTemplatData) {
            count = count + 1;
            let assetSiteTemp = item.id + "_" + siteId;
            //console.log(assetSiteTemp);
            //console.log(selectedBox);
            let assetsData = [];
            if (selectedBox) {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                    status: {
                      eq: "delete",
                    },
                  },
                },
                authMode: "API_KEY",
              });
            } else {
              assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    status: {
                      eq: "delete",
                    },
                  },
                },
                authMode: "API_KEY",
              });
            }
            //console.log(assetsData);
            const data = assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
            nextTokens = data.nextToken;
            if (nextTokens !== null) {
              if (selectedBox) {
                assetRecordsSearchDBRef.current.push({
                  assettemplsiteid: assetSiteTemp,
                  nextToken: nextTokens,
                  distributionboxID: selectedBox,
                });
              } else {
                assetRecordsSearchRef.current.push({
                  assettemplsiteid: assetSiteTemp,
                  nextToken: nextTokens,
                });
              }
            }
            if (data.items.length > 0) {
              assets.push(data.items);
            }

            //console.log(assetRecordsSearchRef.current);
            if (count === assetTemplatData.length) {
              const mergeAssets = assets.flat(1);
              setNextToken(nextTokens);
              setItems((prevItems) => [...prevItems, ...mergeAssets]);
              if (selectedBox) {
                assetRecordsSearchDBRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              } else {
                assetRecordsSearchRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        const assetTemplateResults = await client.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: catid,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 800,
          },
          authMode: "API_KEY",
        });

        if (
          assetTemplateResults.data.assetTemplatesByCategoriesID.items.length >
          0
        ) {
          setAssetTemplatData(
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
          );
          // if (isLoading || !hasMore) return;
          //
          try {
            let assets = [];
            let count = 0;
            let nextTokens = "";

            for (let item of assetTemplateResults.data
              .assetTemplatesByCategoriesID.items) {
              var assetResults = [];
              count = count + 1;
              let assetSiteTemp = item.id + "_" + siteId;
              //console.log(selectedBox);
              if (selectedBox) {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      distributionboxID: { eq: selectedBox },
                      status: {
                        eq: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });
              } else {
                assetResults = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        eq: "delete",
                      },
                    },
                  },
                  authMode: "API_KEY",
                });
              }

              //console.log(assetResults);
              const assetData =
                assetResults.data.assetsByAssettemplateSiteIDAndAssetNum;
              nextTokens = assetData.nextToken;
              if (nextTokens !== null) {
                if (selectedBox) {
                  assetRecordsSearchDBRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                    distributionboxID: selectedBox,
                  });
                } else {
                  assetRecordsSearchRef.current.push({
                    assettemplsiteid: assetSiteTemp,
                    nextToken: nextTokens,
                  });
                }
              }
              if (assetData.items.length > 0) {
                assets.push(assetData.items);
              }

              //console.log(assetRecordsSearchRef.current);
              if (
                count ===
                assetTemplateResults.data.assetTemplatesByCategoriesID.items
                  .length
              ) {
                const mergeAssets = assets.flat(1);
                //setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                if (nextTokens === null) {
                  setHasMore(!!nextTokens);
                }
              }
            }
          } catch (error) {
            console.error("Error fetching asset data:", error);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (isSearch) {
      searchAssetData();
    }
  }, [isSearch]);

  async function getAssetsLoop() {
    // setHasMore(true);
    console.log(assetRecordsRef.current);
    try {
      // if (siteIndex === siteDetails.length) {
      if (assetRecordsRef.current.length > 0) {
        let assets = [];
        let count = 0;
        let allNextTokensNull = true;
        for (let item of assetRecordsRef.current) {
          count += 1;
          if (item.nextToken !== null) {
            try {
              let assetSiteTemp = item.assettemplsiteid;

              const assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    status: {
                      eq: "delete",
                    },
                  },
                  nextToken: item.nextToken,
                },
                authMode: "API_KEY",
              });

              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              const nextTokens = data.nextToken;

              if (data.items.length > 0) {
                assets.push(data.items);
              }

              const existingIndex = assetRecordsRef.current.findIndex(
                (record) => record.assettemplsiteid === item.assettemplsiteid
              );
              if (existingIndex !== -1) {
                assetRecordsRef.current[existingIndex].nextToken = nextTokens;
              } else {
                assetRecordsRef.current.push({
                  assettemplsiteid: item.assettemplsiteid,
                  nextToken: nextTokens,
                });
              }
              if (nextTokens !== null) {
                allNextTokensNull = false;
              }

              if (count === assetRecordsRef.current.length) {
                const mergeAssets = assets.flat(1);
                // if (assets.flat(1).length > 0) {
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                // }
                let removeData = assetRecordsRef.current;
                removeData.map((item, i) => {
                  if (item.nextToken === null) {
                    assetRecordsRef.current.splice(i, 1);
                  }
                });
                if (nextTokens === null) {
                  setSiteIndex(siteIndex + 1);
                }
                if (siteIndex === siteDetails.length) {
                  setHasMore(assetRecordsRef.current.length > 0);
                } else {
                  setHasMore(true);
                }
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          } else {
            if (count === assetRecordsRef.current.length) {
              let removeData = assetRecordsRef.current;
              removeData.map((item, i) => {
                if (item.nextToken === null) {
                  assetRecordsRef.current.splice(i, 1);
                }
              });
              getAssets();
            }
          }
        }
      } else {
        getAssets();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function getAssetsLoopSearch() {
    // setHasMore(true);
    console.log(assetRecordsSearchRef.current);
    console.log(assetRecordsSearchDBRef.current);
    if (assetRecordsSearchRef.current.length > 0) {
      try {
        let assets = [];
        let count = 0;
        for (let item of assetRecordsSearchRef.current) {
          count += 1;
          if (item.nextToken !== null) {
            try {
              let assetSiteTemp = item.assettemplsiteid;
              let assetsData = [];
              if (selectedBox) {
                assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      distributionboxID: { eq: selectedBox },
                      status: {
                        eq: "delete",
                      },
                    },
                    nextToken: item.nextToken,
                  },
                  authMode: "API_KEY",
                });
              } else {
                assetsData = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: assetSiteTemp,
                    limit: 800,
                    filter: {
                      status: {
                        eq: "delete",
                      },
                    },
                    nextToken: item.nextToken,
                  },
                  authMode: "API_KEY",
                });
              }
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              const nextTokens = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              const existingIndex = assetRecordsSearchRef.current.findIndex(
                (record) => record.assettemplsiteid === item.assettemplsiteid
              );
              if (nextTokens !== null) {
                if (existingIndex !== -1) {
                  assetRecordsSearchRef.current[existingIndex].nextToken =
                    nextTokens;
                } else {
                  assetRecordsSearchRef.current.push({
                    assettemplsiteid: item.assettemplsiteid,
                    nextToken: nextTokens,
                  });
                }
              } else {
                if (existingIndex !== -1) {
                  assetRecordsSearchRef.current.splice(existingIndex, 1);
                }
              }
              if (count === assetRecordsSearchRef.current.length) {
                let removeData = assetRecordsSearchRef.current;
                removeData.map((item, i) => {
                  if (item.nextToken === null) {
                    assetRecordsSearchRef.current.splice(i, 1);
                  }
                });
                const mergeAssets = assets.flat(1);
                // if (assets.flat(1).length > 0) {
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                // }
                assetRecordsSearchRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          } else {
            if (count === assetRecordsRef.current.length) {
              let removeData = assetRecordsRef.current;
              removeData.map((item, i) => {
                if (item.nextToken === null) {
                  assetRecordsRef.current.splice(i, 1);
                }
              });
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (assetRecordsSearchDBRef.current.length > 0) {
      try {
        let assets = [];
        let count = 0;
        for (let item of assetRecordsSearchDBRef.current) {
          if (
            item.nextToken !== null &&
            selectedBox === item.distributionboxID
          ) {
            try {
              count += 1;
              let assetSiteTemp = item.assettemplsiteid;
              let assetsData = await client.graphql({
                query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                variables: {
                  assettemplateSiteID: assetSiteTemp,
                  limit: 800,
                  filter: {
                    distributionboxID: { eq: selectedBox },
                    status: {
                      eq: "delete",
                    },
                  },
                  nextToken: item.nextToken,
                },
                authMode: "API_KEY",
              });
              const data =
                assetsData.data.assetsByAssettemplateSiteIDAndAssetNum;
              const nextTokens = data.nextToken;
              if (data.items.length > 0) {
                assets.push(data.items);
              }
              const existingIndex = assetRecordsSearchDBRef.current.findIndex(
                (record) =>
                  record.assettemplsiteid === item.assettemplsiteid &&
                  selectedBox === record.distributionboxID
              );
              if (nextTokens !== null) {
                if (existingIndex !== -1) {
                  assetRecordsSearchDBRef.current[existingIndex].nextToken =
                    nextTokens;
                } else {
                  assetRecordsSearchDBRef.current.push({
                    assettemplsiteid: item.assettemplsiteid,
                    nextToken: nextTokens,
                    distributionboxID: selectedBox,
                  });
                }
              } else {
                if (existingIndex !== -1) {
                  assetRecordsSearchDBRef.current[existingIndex].nextToken =
                    nextTokens;
                }
              }
              if (count === assetRecordsSearchDBRef.current.length) {
                let removeData = assetRecordsSearchDBRef.current;
                removeData.map((item, i) => {
                  if (item.nextToken === null) {
                    assetRecordsSearchDBRef.current.splice(i, 1);
                  }
                });
                const mergeAssets = assets.flat(1);

                // if (assets.flat(1).length > 0) {
                setNextToken(nextTokens);
                setItems((prevItems) => [...prevItems, ...mergeAssets]);
                // }
                assetRecordsSearchDBRef.current.length === 0
                  ? setHasMore(false)
                  : setHasMore(true);
              }
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }

  useEffect(() => {
    console.log(items);
    if (items.length > 0) {
      const seen = new Set();
      const uniqueArr = items.filter((item) => {
        if (seen.has(item["id"])) {
          return false;
        } else {
          seen.add(item["id"]);
          return true;
        }
      });
      console.log("uniqueArr", uniqueArr);
      if (fullItems.length !== uniqueArr.length) {
        if (items.length > 0 && hasMore) {
          if (state.state && state.state.site && state.state.site.id) {
            console.log(state.state.site.id);
            const assets = items.filter(
              (item) => item.sitedetailsID === state.state.site.id
            );
            const assetsFilter =
              state.state && state.state.site && state.state.site.id
                ? assets
                : items;
            console.log([...new Set(assetsFilter)]);
            setFullItems(assetsFilter);
          }
          setFullItems(uniqueArr);
          console.log("fullItems " + siteIndex, fullItems);
        } else if (items.length > 0 && !hasMore) {
          if (state.state && state.state.site && state.state.site.id) {
            console.log(state.state.site.id);
            const assets = items.filter(
              (item) => item.sitedetailsID === state.state.site.id
            );
            const assetsFilter =
              state.state && state.state.site && state.state.site.id
                ? assets
                : items;
            console.log([...new Set(assetsFilter)]);
            setFullItems(assetsFilter);
          }
          setFullItems(uniqueArr);
          console.log("fullItems " + siteIndex, fullItems);
        } else if (siteIndex < siteDetails.length && items.length === 0) {
          getAssets();
        }
      } else {
        if (isSearch) {
          if (items.length > 0 && hasMore) {
            if (state.state && state.state.site && state.state.site.id) {
              console.log(state.state.site.id);
              const assets = items.filter(
                (item) => item.sitedetailsID === state.state.site.id
              );
              const assetsFilter =
                state.state && state.state.site && state.state.site.id
                  ? assets
                  : items;
              setFullItems(assetsFilter);
            }
            setFullItems(uniqueArr);
            console.log("fullItems " + siteIndex, fullItems);
          } else if (siteIndex < siteDetails.length && items.length === 0) {
            getAssets();
          }
        } else if (
          siteIndex < siteDetails.length &&
          uniqueArr.length > 800 &&
          fullItems.length === uniqueArr.length
        ) {
          getAssets();
        } else if (
          siteIndex === siteDetails.length &&
          uniqueArr.length > 800 &&
          fullItems.length === uniqueArr.length
        ) {
          getAssetsLoop();
        }
      }
    } else if (isSearch && items.length === 0) {
      setFullItems([]);
      setHasMore(false);
    }
  }, [items]);

  const handleCheckboxAssetChange = (event, itemId) => {
    //console.log(isSearch);
    let assets = isSearch ? filteredData : fullItems;
    const isChecked = event.target.checked;
    if (itemId === "selectAll") {
      setIsAllSelected(isChecked);

      if (isChecked) {
        const allItemIds = fullItems.map((item) => item.id);
        setSelectedItemsAsset(allItemIds);
      } else {
        setSelectedItemsAsset([]);
      }
    } else {
      setSelectedItemsAsset((prevSelectedItems) => {
        if (isChecked && !prevSelectedItems.includes(itemId)) {
          return [...prevSelectedItems, itemId];
        } else if (!isChecked && prevSelectedItems.includes(itemId)) {
          return prevSelectedItems.filter((item) => item !== itemId);
        }
        return prevSelectedItems;
      });

      const allItemIds = fullItems.map((item) => item.id);
      setIsAllSelected(selectedItemsAsset.length === allItemIds.length);
    }
  };

  const handleDeleteAssets = () => {
    try {
      let counter = 0;
      let siteAssetDeletCount = [];
      const selectedAssets = fullItems.filter((item) =>
        selectedItemsAsset.includes(item.id)
      );
      //console.log(selectedAssets);
      setLoading(true);

      // Track async deletion and file upload
      const deletePromises = selectedItemsAsset.map(async (item, i) => {
        await sleep(Math.random() * i * 17);

        const deleteResult = await client.graphql({
          query: mutations.deleteAssets,
          variables: {
            input: { id: item },
          },
          authMode: "API_KEY",
        });
        //console.log(deleteResult);

        if (deleteResult) {
          let assets = selectedAssets.filter((items) => items.id === item);
          // if (counter === 0) {
          //   siteAssetDeletCount.push({
          //     siteId: assets[0].siteId,
          //     assetCount: 1,
          //   });
          // } else {
          //   for (let val of siteAssetDeletCount) {
          //     if (val.siteId === assets[0].siteId) {
          //       val.assetCount = parseInt(val.assetCount) + 1;
          //     } else {
          //       siteAssetDeletCount.push({
          //         siteId: assets[0].siteId,
          //         assetCount: 1,
          //       });
          //     }
          //   }
          // }
          if (counter === 0) {
            siteAssetDeletCount.push({
              siteId: assets[0].siteId,
              assetCount: 1,
            });
          } else {
            const existingAsset = siteAssetDeletCount.find(
              (val) => val.siteId === assets[0].siteId
            );

            if (existingAsset) {
              existingAsset.assetCount = parseInt(existingAsset.assetCount) + 1;
            } else {
              siteAssetDeletCount.push({
                siteId: assets[0].siteId,
                assetCount: 1,
              });
            }
          }

          counter = counter + 1;

          if (counter === selectedItemsAsset.length) {
            setLoading(false);
            toast.success("Selected Assets Successfully Deleted Permanently.", {
              position: toast.POSITION.TOP_CENTER,
            });

            const jsonData = JSON.stringify(selectedAssets, null, 2);
            const blob = new Blob([jsonData], { type: "application/json" });

            const fileName = `deletedAssets_${Date.now()}.json`;

            const fileurl = await uploadToS3(blob, fileName);
            await client
              .graphql({
                query: mutations.createActionLogs,
                variables: {
                  input: {
                    dataType: "Assets",
                    action: "delete",
                    recordDetails: fileName,
                    actionedBy: LoginUserDetails.id,
                  },
                },
                authMode: "API_KEY",
              })
              .then(async (result) => {
                //console.log(result);
                if (siteAssetDeletCount.length > 0) {
                  siteAssetDeletCount.map(async (item) => {
                    const getAssetCount = await client.graphql({
                      query: queries.assetCountsBySitedetailsID,
                      variables: {
                        sitedetailsID: item.siteId,
                        limit: 100000,
                        filter: {
                          categoriesID: {
                            eq: state.state.category.id,
                          },
                        },
                      },
                      authMode: "API_KEY",
                    });
                    //console.log(getAssetCount);
                    if (
                      getAssetCount.data.assetCountsBySitedetailsID.items
                        .length > 0
                    ) {
                      const resultdata = await client.graphql({
                        query: mutations.updateAssetCounts,
                        variables: {
                          input: {
                            id: getAssetCount.data.assetCountsBySitedetailsID
                              .items[0].id,
                            assetCount:
                              parseInt(
                                getAssetCount.data.assetCountsBySitedetailsID
                                  .items[0].assetCount
                              ) - parseInt(item.assetCount),
                          },
                        },
                        authMode: "API_KEY",
                      });
                      //console.log(resultdata);
                      //--
                      if (resultdata.data.updateAssetCounts !== null) {
                        let activeAssets = [];
                        if (
                          localStorage.getItem("categoryAssetCount") !== null
                        ) {
                          activeAssets = JSON.parse(
                            base64.decode(
                              localStorage.getItem("categoryAssetCount")
                            )
                          );
                          //console.log(activeAssets);
                          const existingIndex = activeAssets.findIndex(
                            (item) =>
                              item.id ===
                              getAssetCount.data.assetCountsBySitedetailsID
                                .items[0].id
                          );
                          //console.log(existingIndex);
                          if (existingIndex > -1) {
                            activeAssets[existingIndex] =
                              resultdata.data.updateAssetCounts;
                          } else {
                            activeAssets.push(
                              resultdata.data.updateAssetCounts
                            );
                          }
                          //console.log(activeAssets);
                          localStorage.setItem(
                            "categoryAssetCount",
                            base64.encode(JSON.stringify(activeAssets))
                          );
                        }
                      }
                    }
                  });
                }
              })
              .catch((error) => {
                //console.log(error);
              });
            navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
              state: {
                category: state.state.category,
                site: state.state.site,
              },
            });
          }
        }
      });

      Promise.all(deletePromises).then(() => {
        //console.log("All deletions completed");
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteAllAssets = async () => {
    try {
      //console.log("all assets deleting");
      setLoading(true);
      let siteAssetDeletCount = [];
      let nextToken = "";
      let assets = [];
      let count = 0;
      //console.log(assetTemplatData);
      if (assetTemplatData.length > 0) {
        for (let assetTemplate of assetTemplatData) {
          count = count + 1;
          for (let sites of siteDetails) {
            let tempSiteId = assetTemplate.id + "_" + sites.id;
            const result = await client.graphql({
              query: queries.assetsByAssettemplateSiteIDAndAssetNum,
              variables: {
                assettemplateSiteID: tempSiteId,
                filter: {
                  status: {
                    eq: "delete",
                  },
                },
                limit: limit,
              },
              authMode: "API_KEY",
            });
            if (
              result.data.assetsByAssettemplateSiteIDAndAssetNum.items.length >
              0
            ) {
              let assetDatas =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
              nextToken =
                result.data.assetsByAssettemplateSiteIDAndAssetNum.nextToken;
              if (
                result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                  .length > 0
              ) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                const results = await client.graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    nextToken: nextToken,
                    filter: {
                      status: {
                        eq: "delete",
                      },
                    },
                    limit: limit,
                  },
                  authMode: "API_KEY",
                });
                if (
                  results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    .length > 0
                ) {
                  nextToken =
                    results.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                    );
                  }
                } else {
                  nextToken = null;
                }
              }
            } else {
              //console.log("else no data for filter");
            }
          }
          //console.log(assets);
          //console.log(count);
          //console.log(assetTemplatData);
          if (count === assetTemplatData.length) {
            const mergeAssets = assets.flat(1);
            //console.log(mergeAssets);
            //--deleting process
            if (mergeAssets.length > 0) {
              let counter = 0;
              const taskassets = mergeAssets.filter(
                (item) => item.addedToTask !== "intask"
              );
              // Track async deletion and file upload
              const deletePromises = taskassets.map(async (item, i) => {
                await sleep(Math.random() * i * 17);

                const deleteResult = await client.graphql({
                  query: mutations.deleteAssets,
                  variables: {
                    input: { id: item.id },
                  },
                  authMode: "API_KEY",
                });
                //console.log(deleteResult);

                if (deleteResult) {
                  if (counter === 0) {
                    siteAssetDeletCount.push({
                      siteId: item.siteId,
                      assetCount: 1,
                    });
                  } else {
                    const existingAsset = siteAssetDeletCount.find(
                      (val) => val.siteId === item.siteId
                    );

                    if (existingAsset) {
                      existingAsset.assetCount =
                        parseInt(existingAsset.assetCount) + 1;
                    } else {
                      siteAssetDeletCount.push({
                        siteId: item.siteId,
                        assetCount: 1,
                      });
                    }
                  }

                  counter = counter + 1;

                  if (counter === taskassets.length) {
                    setLoading(false);
                    toast.success(
                      "Selected Assets Successfully Deleted Permanently.",
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );

                    const jsonData = JSON.stringify(taskassets, null, 2);
                    const blob = new Blob([jsonData], {
                      type: "application/json",
                    });

                    const fileName = `deletedAssets_${Date.now()}.json`;

                    const fileurl = await uploadToS3(blob, fileName);
                    await client
                      .graphql({
                        query: mutations.createActionLogs,
                        variables: {
                          input: {
                            dataType: "Assets",
                            action: "delete",
                            recordDetails: fileName,
                            actionedBy: LoginUserDetails.id,
                          },
                        },
                        authMode: "API_KEY",
                      })
                      .then(async (result) => {
                        //console.log(result);
                        if (siteAssetDeletCount.length > 0) {
                          siteAssetDeletCount.map(async (itemm) => {
                            //console.log(itemm);
                            const getAssetCount = await client.graphql({
                              query: queries.assetCountsBySitedetailsID,
                              variables: {
                                sitedetailsID: itemm.siteId,
                                limit: 100000,
                                filter: {
                                  categoriesID: {
                                    eq: state.state.category.id,
                                  },
                                },
                              },
                              authMode: "API_KEY",
                            });
                            //console.log(getAssetCount);
                            if (
                              getAssetCount.data.assetCountsBySitedetailsID
                                .items.length > 0
                            ) {
                              const resultdata = await client.graphql({
                                query: mutations.updateAssetCounts,
                                variables: {
                                  input: {
                                    id: getAssetCount.data
                                      .assetCountsBySitedetailsID.items[0].id,
                                    assetCount:
                                      parseInt(
                                        getAssetCount.data
                                          .assetCountsBySitedetailsID.items[0]
                                          .assetCount
                                      ) - parseInt(itemm.assetCount),
                                  },
                                },
                                authMode: "API_KEY",
                              });
                              //console.log(resultdata);
                              //--
                              if (resultdata.data.updateAssetCounts !== null) {
                                let activeAssets = [];
                                if (
                                  localStorage.getItem("categoryAssetCount") !==
                                  null
                                ) {
                                  activeAssets = JSON.parse(
                                    base64.decode(
                                      localStorage.getItem("categoryAssetCount")
                                    )
                                  );
                                  //console.log(activeAssets);
                                  const existingIndex = activeAssets.findIndex(
                                    (item) =>
                                      item.id ===
                                      getAssetCount.data
                                        .assetCountsBySitedetailsID.items[0].id
                                  );
                                  //console.log(existingIndex);
                                  if (existingIndex > -1) {
                                    activeAssets[existingIndex] =
                                      resultdata.data.updateAssetCounts;
                                  } else {
                                    activeAssets.push(
                                      resultdata.data.updateAssetCounts
                                    );
                                  }
                                  //console.log(activeAssets);
                                  localStorage.setItem(
                                    "categoryAssetCount",
                                    base64.encode(JSON.stringify(activeAssets))
                                  );
                                }
                              }
                            }
                          });
                        }
                      })
                      .catch((error) => {
                        //console.log(error);
                      });
                    navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                      state: {
                        category: state.state.category,
                        site: state.state.site,
                      },
                    });
                  }
                }
              });

              Promise.all(deletePromises).then(() => {
                //console.log("All deletions completed");
              });
              // taskassets.map(async (item, i) => {
              //   await sleep(Math.random() * i * 17); // wait
              //   //if (item.addedToTask !== "intask"){
              //   await client
              //     .graphql({
              //       query: mutations.updateAssets,
              //       variables: {
              //         input: { id: item.id, status: "delete" },
              //       },
              //       authMode: "API_KEY",
              //     })
              //     .then((result) => {
              //       //console.log(result);
              //       counter = counter + 1;
              //       if (counter === taskassets.length) {
              //         setLoading(false);
              //         toast.success("Selected Assets Deleted Successfully.", {
              //           position: toast.POSITION.TOP_CENTER,
              //         });
              //         navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
              //           state: {
              //             category: state.state.category,
              //             site: state.state.site,
              //           },
              //         });
              //       }
              //     })
              //     .catch((error) => {
              //       console.error(error);
              //       setLoading(false);
              //     });
              //   //}
              // });
            }
          }
          //   }
          // }
        }
      } else {
        const assetTemplateResults = await client.graphql({
          query: queries.assetTemplatesByCategoriesID,
          variables: {
            categoriesID: catid,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 800,
          },
          authMode: "API_KEY",
        });

        if (
          assetTemplateResults.data.assetTemplatesByCategoriesID.items.length >
          0
        ) {
          setAssetTemplatData(
            assetTemplateResults.data.assetTemplatesByCategoriesID.items
          );
          const assettempdata =
            assetTemplateResults.data.assetTemplatesByCategoriesID.items;
          for (let assetTemplate of assettempdata) {
            count = count + 1;
            //siteDetails.map(async (sites) => {
            for (let sites of siteDetails) {
              let tempSiteId = assetTemplate.id + "_" + sites.id;
              const result = await client
                .graphql({
                  query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                  variables: {
                    assettemplateSiteID: tempSiteId,
                    limit: limit,
                  },
                  authMode: "API_KEY",
                })
                .then(async (result) => {
                  let assetDatas =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items;
                  nextToken =
                    result.data.assetsByAssettemplateSiteIDAndAssetNum
                      .nextToken;
                  if (
                    result.data.assetsByAssettemplateSiteIDAndAssetNum.items
                      .length > 0
                  ) {
                    assets.push(assetDatas);
                  }
                  while (nextToken !== null) {
                    const results = await client.graphql({
                      query: queries.assetsByAssettemplateSiteIDAndAssetNum,
                      variables: {
                        assettemplateSiteID: tempSiteId,
                        nextToken: nextToken,
                        limit: limit,
                      },
                      authMode: "API_KEY",
                    });
                    if (
                      results.data.assetsByAssettemplateSiteIDAndAssetNum.items
                        .length > 0
                    ) {
                      nextToken =
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .nextToken;
                      if (
                        results.data.assetsByAssettemplateSiteIDAndAssetNum
                          .items.length > 0
                      ) {
                        assets.push(
                          results.data.assetsByAssettemplateSiteIDAndAssetNum
                            .items
                        );
                      }
                    }
                  }
                });
            }
            //console.log(assets);
            //console.log(count);
            //console.log(assettempdata);
            if (count === assettempdata.length) {
              const mergeAssets = assets.flat(1);
              //console.log(mergeAssets);
              //--deleting process
              if (mergeAssets.length > 0) {
                let counter = 0;
                mergeAssets.map(async (item, i) => {
                  await sleep(Math.random() * i * 17); // wait
                  await client
                    .graphql({
                      query: mutations.updateAssets,
                      variables: {
                        input: { id: item.id, status: "delete" },
                      },
                      authMode: "API_KEY",
                    })
                    .then((result) => {
                      //console.log(result);
                      counter = counter + 1;
                      if (counter === mergeAssets.length) {
                        setLoading(false);
                        toast.success("Selected Assets Deleted Successfully.", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                        navigate(`${process.env.PUBLIC_URL}/asset/deleteList`, {
                          state: {
                            category: state.state.category,
                            site: state.state.site,
                          },
                        });
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                      setLoading(false);
                    });
                });
              }
            }
            //});
            //}
          }
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Function to handle the S3 file upload
  const uploadToS3 = async (fileBlob, fileName) => {
    // try {
    //   const uploadResult = await S3.upload(uploadParams).promise();
    //   //console.log("File uploaded successfully:", uploadResult);
    //   return uploadResult.Location; // URL of the uploaded file
    // } catch (error) {
    //   console.error("Error uploading file to S3:", error);
    //   throw error;
    // }
    try {
      const params = {
        ACL: "public-read",
        Body: fileBlob,
        ContentType: "application/json",
        Bucket: S3_BUCKET,
        Key: `public/deletedAssets/${fileName}`,
      };
      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: fileName,
        Body: fileBlob,
        ContentType: "application/json",
        ACL: "public-read",
      };
      await myBucket
        .putObject(params)
        .on("httpUploadProgress", async (evt) => {
          console.log(evt);
        })
        .send((err) => {
          if (err) console.log(err);
        });
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      //throw error;
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const renderList = (
    <Fragment>
      <Container fluid={true} className="p-0 project-list">
        {state.state && state.state.category && (
          <H1>{state.state.category.categoryName}</H1>
        )}
        <Card className="custom-card">
          {/* <CardHeader> */}
          <Row>
            <Col md="4">
              <InputGroup>
                <span className="input-group-text">
                  <i className="icon-search"></i>
                </span>
                <Input
                  type="search"
                  placeholder="Search Assets.."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md="4">
              {selectedSiteData !== "" || selectedSiteGlobal ? (
                <select
                  className="form-select"
                  onChange={(e) => setSelectedSite(e.target.value)}
                  disabled={
                    state.state.site ? true : selectedSiteGlobal ? true : false
                  }
                  value={selectedSiteGlobal ? selectedSiteGlobal : selectedSite}
                  menuPlacement="auto"
                  menuPosition="fixed"
                >
                  <option value="">All Sites</option>
                  {sites.map((site, index) => (
                    <option value={site.value} key={index}>
                      {site?.label}
                    </option>
                  ))}
                </select>
              ) : (
                <Select
                  value={selectedSearchSiteData}
                  onChange={handleSiteDDChange}
                  options={sites}
                  isSearchable={true}
                  hideSelectedOptions={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                />
              )}
            </Col>
            <Col md="4">
              <Select
                value={selectedSearchDBData}
                onChange={handleSiteDB}
                options={optionsDB}
                isSearchable={true}
                hideSelectedOptions={false}
                menuPlacement="auto"
                menuPosition="fixed"
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md="9" className="d-flex">
              <Btn attrBtn={{ color: "primary", onClick: handleSearch }}>
                Search
              </Btn>

              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => {
                    setIsLoading(false);
                    setDelete(false);
                    setIsSearch(false);
                    setSelectedItemsAsset([]);
                    state.state.site
                      ? setSelectedSite(state.state.site.id)
                      : setSelectedSite("");
                    updatePage(1);
                    updatePageCard(1);
                    isReset = true;
                    assetRecordsRef.current = [];
                    assetRecordsSearchRef.current = [];
                    assetRecordsSearchDBRef.current = [];
                    getAssets();
                    setItems([]);
                    setFullAssets([]);
                    setIsSearch(false);
                    setFullItems([]);
                    setSearchAssets(false);
                    setIsSecoundSearch(false);
                    setDelete(false);
                    setIsLoading(true);
                    state.state.site
                      ? setSelectedSite(state.state.site.id)
                      : setSelectedSite("");
                    setSelectedBox("");
                    setValue("");
                    setSelectedLocation("");
                    setSelectedSearchSiteData("");
                    setSelectedSearchDBData("");
                    setSelectedItemsAsset([]);
                    if (state.state.site && state.state.category) {
                      setCollection(
                        cloneDeep(fullAssets.slice(0, countPerPage))
                      );
                    } else {
                      setCollection(
                        cloneDeep(assetDemo.slice(0, countPerPage))
                      );
                      setCollectionCard(assetDemoCard);
                    }
                  },
                }}
              >
                Reset
              </Btn>
              <Btn attrBtn={{ color: "primary", onClick: cancelForm }}>
                Back
              </Btn>
              <Btn attrBtn={{ color: "primary", onClick: deleteAllToggle }}>
                Delete All Assets Permanently
              </Btn>
              <Btn attrBtn={{ color: "primary", onClick: deleteToggle }}>
                Delete Assets Permanently
              </Btn>
            </Col>
          </Row>
        </Card>
        <Row></Row>
        <div className="card-block row">
          <Col sm="12" lg="12" xl="12">
            Loaded {fullItems.length} of {state.state.category.assetsCount}{" "}
            Assets
            <InfiniteScroll
              dataLength={fullItems.length}
              next={isSearch ? getAssetsLoopSearch : getAssetsLoop}
              hasMore={hasMore}
              height={500}
              loader={
                hasMore ? (
                  <p style={{ textAlign: "center" }}>
                    <b>Loading Assets for {siteName}...</b>
                  </p>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    <b>No Assets To Load.</b>
                  </p>
                )
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  {fullItems.length > 0 ? (
                    <b>All Assets Loaded</b>
                  ) : (
                    <b>No Assets To Load.</b>
                  )}
                </p>
              }
            >
              <Table className="table-striped  table-de ">
                <thead
                  className="custom-table-head"
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                  }}
                >
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={isAllSelected}
                        onChange={(event) =>
                          handleCheckboxAssetChange(event, "selectAll")
                        }
                      />
                    </th>
                    <th>Fitting No.</th>
                    <th>Asset Model</th>
                    <th>Site</th>
                    <th>Distribution board</th>
                    <th>Installed On</th>
                    <th>Asset Warranty</th>
                    <th>Asset Location</th>
                    {/* <th>Tasks</th> */}
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="trhover">
                  {fullItems.length > 0 &&
                    fullItems &&
                    fullItems
                      .sort((a, b) => a.fittingNumber - b.fittingNumber)
                      .map((item, i) => (
                        <tr
                          key={item.id}
                          className={item.duplicate ? "duplicateRow" : ""}
                          title={item.duplicate ? item.duplicate : ""}
                        >
                          <td>
                            <input
                              type="checkbox"
                              name="completionLink"
                              checked={selectedItemsAsset.includes(item.id)}
                              onChange={(event) =>
                                handleCheckboxAssetChange(event, item.id)
                              }
                            />
                          </td>
                          <td>
                            {userPermission.includes("Edit Assets") ? (
                              state.state && (
                                <Link
                                  to={`${process.env.PUBLIC_URL}/asset/view`}
                                  state={{
                                    category: state.state.category,
                                    item: item,
                                    site: getSiteName(item.sitedetailsID),
                                    db: getDBName(item.distributionboxID),
                                  }}
                                >
                                  {item.fittingNumber}
                                </Link>
                              )
                            ) : (
                              <>{item.fittingNumber}</>
                            )}
                          </td>
                          <td>{item.assetModel}</td>
                          <td>{getSiteName(item.sitedetailsID)}</td>
                          <td>{getDBName(item.distributionboxID)}</td>
                          <td>
                            {item.installedOn !== null
                              ? moment(item.installedOn).format("DD/MM/YYYY")
                              : "-"}
                          </td>
                          {item.assetWarrantyStart != null && (
                            <td>
                              {moment(item.assetWarrantyStart).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              -
                              {moment(item.assetWarrantyEnd).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                          )}
                          {item.assetWarrantyStart === null && <td>-</td>}
                          <td>{item.assetLocation}</td>
                          {/* <td>{item.addedToTask}</td> */}
                          <td>{item.status}</td>
                        </tr>
                      ))}{" "}
                </tbody>
              </Table>
            </InfiniteScroll>
          </Col>
        </div>
        {/* <div className="card-block row">
          <Col sm="12" lg="12" xl="12">
            <div className="table-responsive">
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>

                  <Pagination
                    className="p-t-10"
                    pageSize={countPerPage}
                    onChange={updatePage}
                    current={currentPage}
                    total={
                      value ||
                        (state.state && state.state.site ? false : selectedSite) ||
                        selectedLocation ||
                        selectedBox
                        ? filteredData.length
                        : state.state && state.state.site && state.state.category
                          ? assetDemo.length
                          : assetDemo.length
                    }
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                  <br></br>
                  <Table className="table-striped  table-de ">
                    <thead className="custom-table-head">
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={(event) =>
                              handleCheckboxAssetChange(event, "selectAll")
                            }
                          />
                        </th>
                        <th>Fitting No.</th>
                        <th>Asset Model</th>
                        <th>Site</th>
                        <th>Distribution board</th>
                        <th>Installed On</th>
                        <th>Asset Warranty</th>
                        <th>Asset Location</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="trhover">
                      {collection.length > 0 ? (
                        collection &&
                        collection.map((item, i) => (
                          <tr key={item.id}>
                            
                            <td>
                              <input
                                type="checkbox"
                                name="completionLink"
                                checked={selectedItemsAsset.includes(item.id)}
                                onChange={(event) =>
                                  handleCheckboxAssetChange(event, item.id)
                                }
                              />
                            </td>
                            <td>
                              {userPermission.includes("Edit Assets") ? (
                                state.state && (
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/asset/view`}
                                    state={{
                                      category: state.state.category,
                                      item: item,
                                      site: getSiteName(item.sitedetailsID),
                                      db: getDBName(item.distributionboxID),
                                    }}
                                  >
                                    {item.fittingNumber}
                                  </Link>
                                )
                              ) : (
                                <>{item.fittingNumber}</>
                              )}
                            </td>
                            <td>{item.assetModel}</td>
                            <td>{getSiteName(item.sitedetailsID)}</td>
                            <td>{getDBName(item.distributionboxID)}</td>
                            <td>
                              {item.installedOn !== null
                                ? moment(item.installedOn).format("DD/MM/YYYY")
                                : "-"}
                            </td>
                            {item.assetWarrantyStart != null && (
                              <td>
                                {moment(item.assetWarrantyStart).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -
                                {moment(item.assetWarrantyEnd).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                            )}
                            {item.assetWarrantyStart === null && <td>-</td>}
                            <td>{item.assetLocation}</td>
                            <td>{item.status}</td>
                          </tr>
                        ))
                      ) : (
                        <p>No Assets Found</p>
                      )}{" "}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
          </Col>
        </div> */}
      </Container>
      <Modal isOpen={deleteModal} toggle={deleteToggle}>
        <ModalBody>
          <Form className="form-bookmark">
            <H4>Are you sure, to delete selected Assets??</H4>
            <Btn
              attrBtn={{
                color: "primary",
                className: "ms-1",
                onClick: deleteToggle,
              }}
            >
              Cancel
            </Btn>
            &nbsp;&nbsp;
            <Btn attrBtn={{ color: "primary", onClick: handleDeleteAssets }}>
              {Loading ? "Deleting..." : "Ok"}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={deleteAllModal} toggle={deleteAllToggle}>
        <ModalBody>
          <Form className="form-bookmark">
            <H4>
              Are you sure, to delete{" "}
              {/* {selectedSite
                ? assetscountbysite[0] && assetscountbysite[0].assetCount
                : state.state.category.assetsCount}{" "}
              Assets?? */}
            </H4>
            <Btn
              attrBtn={{
                color: "primary",
                className: "ms-1",
                onClick: deleteAllToggle,
              }}
            >
              Cancel
            </Btn>
            &nbsp;&nbsp;
            <Btn attrBtn={{ color: "primary", onClick: handleDeleteAllAssets }}>
              {Loading ? "Deleting..." : "Ok"}
            </Btn>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default DeletedAssetsList;
