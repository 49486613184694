import React, {
  Fragment,
  useState,
  useEffect,
  createRef,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { Btn, H5, H3, H4, H6, LI, P, UL } from "../../AbstractElements";
import {
  Col,
  Container,
  CardFooter,
  Form,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  CardBody,
  Card,
  CardHeader,
  Media,
  Table,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { SiteContext } from "../../App";
import { aocontext } from "../../App";
import { PlusCircle } from "react-feather";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import SiteData from "../../Data/Sites/index";
import { AlertWithLightDismissData } from "../../Data/Ui-kits";
import { limit } from "../../Config/Config";
import useInfiniteLoading from "./logbookInfiniteloop";
import SiteUser from "../../Pages/SiteOwnerPanel/siteUser";
import SiteDBData from "../../Data/DistributionBoard/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const GeneratePDFAsset = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [logBookDetailsList, setLogBookDetailsList] = useState([]);
  const [collection, setCollection] = React.useState([]);
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const LoginUserGroup = localStorage.getItem("groups");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [siteDetails, setSiteDetails] = useState([]);
  const [dbDetails, setDBDetails] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [distributionBox, setDistributionBox] = useState([]);
  const [distributionBoxFilter, setDistributionBoxfilter] = useState([]);
  const [value, setValue] = useState("");
  const [siteError, setSiteError] = useState(false);
  const [filteredData, setFilteredData] = React.useState([]);
  const [dbId, setDBId] = useState([]);
  const [catId, setCatId] = useState([]);
  //const [limit, setLimit] = useState(100000);

  // const { items, loadMoreItems, hasMore } = useInfiniteLoading({ sites });
  // const containerRef = useRef(null);
  // const handleScroll = () => {
  //   console.log(items);
  //   if (!containerRef.current) return;
  //   const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
  //   if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore) {
  //     loadMoreItems();
  //   }
  // };

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  //console.log(selectedSiteGlobal);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);

  const [Viewdbid, setViewdbid] = useState(false);
  const ViewLogbookModal = () => {
    setViewdbid(!Viewdbid);
  };
  const ViewdbidModaltoggle = () => setViewdbid(!Viewdbid);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    e,
    ...form
  } = useForm();

  const onSubmit = async (data, e) => {
    if (siteId !== "") {
      console.log(data);
      console.log(siteId);
      console.log(dbId);
      setSiteError(false);
      try {
        client
          .graphql({
            query: mutations.createLogBook,
            variables: {
              limit: limit,
              input: {
                siteId: siteId,
                sitedetailsID: siteId,
                // distributionBox: dbId,
                additionalInformation: data.additionalInfo,
                logbookName: data.logbookName,
                categoryId: catId,
                status: "Active",
              },
            },
            authMode: "API_KEY",
          })
          .then((result1) => {
            console.log(result1);
            e.target.reset();
            const formsdata = {
              site: "",
              // distributionBox: "",
              categoryId: "",
              additionalInfo: "",
              logbookName: "",
            };
            reset({ ...formsdata });
            toast.success("Logbook Saved Succesfully!..", {
              position: toast.POSITION.TOP_CENTER,
            });
            setViewdbid(!Viewdbid);
            //loadMoreItems();
            fetchListLogBookDetails();
          });
      } catch (err) {
        console.log(err);
        toast.error("Error While saving Logbook - " + err, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      setSiteError(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("site") === null && sites.length === 0) {
      LoginUserGroup === "site_owner"
        ? fetchSiteDetailsAO()
        : fetchSiteDetailsSPSU(LoginUserDetails.id);
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSites(activeSites.filter((item) => item.status === "Active"));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
    }

    if (
      localStorage.getItem("siteDB") === null &&
      distributionBox.length === 0
    ) {
      fetchDb();
    } else {
      setDistributionBox(
        JSON.parse(base64.decode(localStorage.getItem("siteDB")))
      );
    }
    if (
      localStorage.getItem("category") === null &&
      setCategoryDetails.length === 0
    ) {
      fetchDbCategory();
    } else {
      setCategoryDetails(
        JSON.parse(base64.decode(localStorage.getItem("category")))
      );
    }
    fetchListLogBookDetails();
  }, [selectedSiteGlobal, selectedAssetOwnerGlobal]);

  useEffect(() => {
    if (siteId) {
      const filteredDistributionBox = distributionBox.filter(
        (item) => item.sitedetailsID === siteId
      );
      setDistributionBoxfilter(filteredDistributionBox);
    } else {
      setDistributionBoxfilter([distributionBox]);
    }
  }, [siteId, distributionBox, siteDetails]);

  async function fetchDb() {
    console.log(sites);
    if (sites.length > 0) {
      let nextToken = "";
      let assets = [];
      for (let item of sites) {
        const result = await client.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.distributionBoxesBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.distributionBoxesBySitedetailsID.items;
          nextToken = result.data.distributionBoxesBySitedetailsID.nextToken;
          console.log(
            "first token count",
            result.data.distributionBoxesBySitedetailsID.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.distributionBoxesBySitedetailsID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await client.graphql({
              query: queries.distributionBoxesBySitedetailsID,
              variables: {
                limit: limit,
                sitedetailsID: item.id,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (
              results.data.distributionBoxesBySitedetailsID.items.length > 0
            ) {
              nextToken =
                results.data.distributionBoxesBySitedetailsID.nextToken;
              console.log("nextToken", nextToken);
              console.log(
                results.data.distributionBoxesBySitedetailsID.items.length
              );
              if (
                results.data.distributionBoxesBySitedetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.distributionBoxesBySitedetailsID.items
                );
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            setDistributionBox(mergeAssets);
            SiteDBData.setSiteDBData(mergeAssets);
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(mergeAssets))
            );
            //--
            mergeAssets.length > 0
              ? setDBDetails(mergeAssets)
              : setDBDetails([]);
          } else {
            setDistributionBox([]);
            setDBDetails([]);
          }
        }
      }
    } else {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listDistributionBoxes,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.listDistributionBoxes.items.length > 0) {
        let assetDatas = result.data.listDistributionBoxes.items;
        nextToken = result.data.listDistributionBoxes.nextToken;
        console.log(
          "first token count",
          result.data.listDistributionBoxes.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listDistributionBoxes.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listDistributionBoxes,
            variables: {
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (result.data.listDistributionBoxes.items.length > 0) {
            nextToken = results.data.listDistributionBoxes.nextToken;
            console.log("nextToken", nextToken);
            console.log(results.data.listDistributionBoxes.items.length);
            if (results.data.listDistributionBoxes.items.length > 0) {
              assets.push(results.data.listDistributionBoxes.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          setDistributionBox(mergeAssets);
          SiteDBData.setSiteDBData(mergeAssets);
          localStorage.setItem(
            "siteDB",
            base64.encode(JSON.stringify(mergeAssets))
          );
          //--
          mergeAssets.length > 0 ? setDBDetails(mergeAssets) : setDBDetails([]);
        } else {
          setDistributionBox([]);
          setDBDetails([]);
        }
      } else {
        setDistributionBox([]);
        setDBDetails([]);
      }
    }
  }

  async function fetchSiteDetailsAO() {
    try {
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSites(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        } else {
          setSites([]);
          setIsLoading(false);
        }
      } else {
        setSites([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSites([]);
      setIsLoading(false);
    }
  }
  //--sites for sp/su
  async function fetchSiteDetailsSPSU(assetOwnerId) {
    try {
      let nextToken = "";
      let linkedSites = [];
      const result = await client.graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      });
      if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          linkedSites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.linkUsersAndSitesByUserdetailsID,
            authMode: "API_KEY",
            variables: {
              userdetailsID: assetOwnerId,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: limit,
              nextToken: nextToken,
            },
          });
          if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
            nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
            if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
              linkedSites.push(
                results.data.linkUsersAndSitesByUserdetailsID.items
              );
            }
          }
        }
        console.log(linkedSites);
        if (linkedSites.length > 0) {
          const mergeSites = linkedSites.flat(1);
          let sitesforUsers = [];
          const promises = mergeSites.map(async (item) => {
            return await client.graphql({
              query: queries.getSiteDetails,
              variables: {
                id: item.sitedetailsID,
              },
              authMode: "API_KEY",
            });
          });
          Promise.all(promises).then((sitesData) => {
            sitesData.forEach((sites) => {
              if (sites.data.getSiteDetails !== null) {
                sitesforUsers.push(sites.data.getSiteDetails);
              }
            });
            setSites(sitesforUsers.filter((item) => item.status === "Active"));
            setSiteDetails(
              sitesforUsers.filter((item) => item.status === "Active")
            );
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(sitesforUsers))
            );
            //SiteData.setSiteData(sitesforUsers);
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDbCategory() {
    //-
    try {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listCategories,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.listCategories.items.length > 0) {
        let assetDatas = result.data.listCategories.items;
        nextToken = result.data.listCategories.nextToken;
        console.log("first token count", result.data.listCategories.items.length);
        console.log("nextToken 1", nextToken);
        if (result.data.listCategories.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listCategories,
            variables: {
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listCategories.items.length > 0) {
            nextToken = results.data.listCategories.nextToken;
            console.log("nextToken", nextToken);
            console.log(results.data.listCategories.items.length);
            if (results.data.listCategories.items.length > 0) {
              assets.push(results.data.listCategories.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          mergeAssets.length > 0
            ? setCategoryDetails(mergeAssets)
            : setCategoryDetails([]);
        } else {
          setCategoryDetails([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchListLogBookDetails() {
    //setIsLoading(true);
    //setSiteDetails(sites);
    try {
      let nextToken1 = "";
      let logbookBySites = [];
      let siteDatas = JSON.parse(base64.decode(localStorage.getItem("site")));
      console.log("siteDatas", siteDatas);
      let activeSites = siteDatas.filter((item) => item.status === "Active");
      console.log("activeSites", activeSites);
      for (let item of activeSites) {
        const result = await client.graphql({
          query: queries.logBooksBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.logBooksBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.logBooksBySitedetailsID.items;
          nextToken1 = result.data.logBooksBySitedetailsID.nextToken;
          if (result.data.logBooksBySitedetailsID.items.length > 0) {
            logbookBySites.push(assetDatas);
          }
          while (nextToken1 !== null) {
            const results = await client.graphql({
              query: queries.logBooksBySitedetailsID,
              variables: {
                sitedetailsID: item.id,
                limit: limit,
                nextToken: nextToken1,
              },
              authMode: "API_KEY",
            });
            if (results.data.logBooksBySitedetailsID.items.length > 0) {
              nextToken1 = results.data.logBooksBySitedetailsID.nextToken;
              if (results.data.logBooksBySitedetailsID.items.length > 0) {
                logbookBySites.push(results.data.logBooksBySitedetailsID.items);
              }
            }
          }
          if (logbookBySites.length > 0) {
            const mergeLogbooks = logbookBySites.flat(1);
            const sortedItems = mergeLogbooks.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            const filterlogbook = sortedItems.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );
            const NewLogbook = selectedSiteGlobal ? filterlogbook : sortedItems;
            setLogBookDetailsList(NewLogbook);
            setCollection(cloneDeep(NewLogbook.slice(0, countPerPage)));
            setIsLoading(false);
          } else {
            setLogBookDetailsList([]);
            setCollection(cloneDeep([].slice(0, countPerPage)));
            setIsLoading(false);
          }
        }
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  function getSiteName(id) {
    const siteName = siteDetails.find((item) => item.id === id);
    return siteName
      ? siteName.siteName + " " + "(" + siteName.unitNumber + ")"
      : "";
  }

  function getSiteUnitNumber(id) {
    const siteUnitNumber = siteDetails.find((item) => item.id === id);
    return siteUnitNumber ? siteUnitNumber.unitNumber : "";
  }

  function getDB(id) {
    const db = dbDetails.find((item) => item.id === id);
    return db ? db.distributionBoxName : "Multiple";
  }

  const globalSearch = (searchValue) => {
    const filteredData = logBookDetailsList.filter((item) => {
      const siteName = getSiteName(item.sitedetailsID) || "";
      const unitnumber = getSiteUnitNumber(item.sitedetailsID) || "";
      const db = getDB(item.distributionBox) || "";
      return (
        item.logbookName
          ?.toLowerCase()
          .includes(searchValue.trim().toLowerCase()) ||
        moment(item.createdAt)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(searchValue.trim().toLowerCase()) ||
        item.status?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        siteName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        unitnumber?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
        db?.toLowerCase().includes(searchValue.trim().toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(logBookDetailsList.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const setSite = (site) => {
    if (site !== "") {
      setSiteError(false);
      console.log(site[0].id);
      setSiteId(site[0].id);
    } else {
      setSiteError(true);
    }
  };

  const setCat = (cat) => {
    console.log(cat);
    if (cat !== "") {
      console.log(cat[0].id);
      setCatId(cat[0].id);
    }
  };

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(logBookDetailsList.slice(from, to)));
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Col md="12" className="project-list">
          <Card className="custom-card">
            <Row>
              <H4>Logbook List</H4>
              <Col md="6">
                <InputGroup>
                  <span className="input-group-text">
                    <i className="icon-search"></i>
                  </span>
                  <Input
                    type="search"
                    placeholder="Search Logbooks.."
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </InputGroup>
              </Col>
              {LoginUserGroup !== "electrician" && (
                <Col md="6">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: ViewLogbookModal,
                    }}
                  >
                    <PlusCircle />
                    Add Logbook
                  </Btn>
                </Col>
              )}
            </Row>
            <CardBody>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <br></br>
                  <Pagination
                    className="p-t-10"
                    pageSize={countPerPage}
                    onChange={updatePage}
                    current={currentPage}
                    total={
                      value ? filteredData.length : logBookDetailsList.length
                    }
                    showTotal={(total, range) =>
                      `${range[0]} - ${range[1]} of ${total} items`
                    }
                  />
                  <br></br>
                  <div style={{ position: "relative" }}>
                    <Table
                      className="table-striped table-de"
                      style={{ marginBottom: "0" }}
                    >
                      <thead
                        className="custom-table-head"
                        style={{
                          position: "sticky",
                          top: "0",
                          backgroundColor: "#fff",
                          zIndex: "1",
                        }}
                      >
                        <tr>
                          <th>Logbook</th>
                          <th>Site</th>
                          <th>Added On</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {collection.length > 0 ? (
                        <tbody className="trhover">
                          {collection
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            .map((item, i) => (
                              <tr key={i}>
                                <td>{item.logbookName}</td>
                                <td>{getSiteName(item.sitedetailsID)}</td>
                                <td>
                                  {moment(item.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>{item.status}</td>
                                <td>
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/generatereportfasset/assetslogbook`}
                                    state={{ item }}
                                  >
                                    <Btn
                                      attrBtn={{
                                        className: "btn-outline-* btn-xs m-r-5",
                                        color: "primary",
                                        title: "View",
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>{" "}
                                    </Btn>
                                  </Link>
                                  {LoginUserGroup !== "electrician" && (
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/generatereportfasset/assetslogbook`}
                                      state={{ item, purpose: "edit" }}
                                    >
                                      <Btn
                                        attrBtn={{
                                          className:
                                            "btn-outline-* btn-xs m-r-5",
                                          color: "info",
                                          title: "Edit",
                                        }}
                                      >
                                        <i className="fa fa-edit "></i>
                                      </Btn>
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="12" className="text-center">
                              No data available.
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Col>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
      <Modal isOpen={Viewdbid} toggle={ViewdbidModaltoggle} size="lg" centered>
        <ModalHeader toggle={ViewdbidModaltoggle}>Add New Logbook</ModalHeader>
        <ModalBody className="modal-body">
          <Container fluid={true}>
            <br></br>
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col md="6 mb-3">
                  <Label>Category</Label>
                  {/*
                  <select
                    className="form-control"
                    placeholder="Choose Category"
                    name="category"
                    {...register("category", { required: false })}
                  >
                    <option value="">Choose Category...</option>
                    {categoryDetails &&
                      categoryDetails.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.categoryName}
                        </option>
                      ))}
                  </select>
                      */}
                  <Typeahead
                    id="rendering-example"
                    multiple={false}
                    options={categoryDetails}
                    labelKey={(categoryDetails) =>
                      `${categoryDetails.categoryName}`
                    }
                    placeholder="Choose Category"
                    onChange={(d) => setCat(d)}
                  />
                </Col>
                <Col md="6 mb-3">
                  <Label>Site</Label>
                  {/* <select
                    className="form-control"
                    name="site"
                    {...register("site", { required: true })}
                    onChange={(event) => setSiteId(event.target.value)}
                  >
                    <option value="">Select Site</option>
                    {siteDetails.map((item) => (
                      <option value={item.id} key={item.id}>
                        {(item?.siteName || "") +
                          " (" +
                          (item?.unitNumber || "") +
                          ")"}
                      </option>
                    ))}
                  </select> */}
                  <Typeahead
                    id="rendering-example"
                    multiple={false}
                    options={siteDetails}
                    labelKey={(siteDetails) =>
                      `${siteDetails.siteName} ,(${siteDetails.unitNumber ? siteDetails.unitNumber : ""
                      })`
                    }
                    placeholder="Choose Site"
                    onChange={(d) => setSite(d)}
                  />
                  {siteError ? <span>{"Selecting Site is required"}</span> : ""}
                  {/* <span>{errors.site && "Site is required"}</span> */}
                </Col>
                {/*<Col md="4 mb-3">
                  <Label>Distribution Board</Label> 
                                   
                  <select
                    className="form-control"
                    placeholder="Choose Distribution Board"
                    name="distributionBox"
                    {...register("distributionBox", { required: false })}
                  >
                    <option value="">Choose Distribution Board...</option>
                    {distributionBoxFilter &&
                      distributionBoxFilter.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.distributionBoxName}
                        </option>
                      ))}
                  </select>
                      
                  <Typeahead
                    id="rendering-example"
                    multiple={false}
                    options={distributionBoxFilter}
                    labelKey={(distributionBoxFilter) =>
                      `${distributionBoxFilter.distributionBoxName}`
                    }
                    placeholder="Choose Distribution Board"
                    onChange={(d) => setDB(d)}
                  />
                </Col>*/}
              </Row>
              <Row>
                <Col md="6 mb-3">
                  <Label>Logbook Name</Label>
                  <input
                    type="text"
                    className="form-control"
                    name="logbookName"
                    {...register("logbookName", { required: true })}
                  />
                  <span>
                    {errors.logbookName && "Logbook Name is required"}
                  </span>
                </Col>
                <Col md="6 mb-3">
                  <Label>Additional Information</Label>
                  <input
                    type="textarea"
                    className="form-control"
                    name="additionalInfo"
                    {...register("additionalInfo", { required: false })}
                  />
                </Col>
              </Row>
              <Btn attrBtn={{ color: "primary" }}>Save</Btn>
            </Form>
          </Container>
        </ModalBody>
      </Modal>
    </Fragment>
  );

  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default GeneratePDFAsset;
