import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Input,
  Card,
  CardBody,
  CardHeader,
  Media,
} from "reactstrap";
import * as queries from "../../../graphql/queries";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import moment from "moment";
import DatePicker from "react-datepicker";
import { H5, Btn } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { SiteContext } from "../../../App";
import { SiteDetailsContext } from "../../../App";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import * as mergedQueries from "../../../graphql/mergedQueries";
import SPCompany from "../../../Data/SPCompanyData/index";
import SiteData from "../../../Data/Sites/index";
import CategoryData from "../../../Data/Category/index";
import AssetTemplate from "../../../Data/AssetTemplate/index";
import SiteDBData from "../../../Data/DistributionBoard/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const AssetOwnerDashboard = () => {
  const navigate = useNavigate();
  const LoginUserGroup = localStorage.getItem("groups");
  if (LoginUserGroup === "site_user") {
    navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
  }
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (LoginUserGroup === "spao") {
      fetchSPCompanySPAO(LoginUserDetails);
    } else if (LoginUserGroup === "site_owner") {
      fetchSpcompany(LoginUserDetails.id);
    }
    setIsLoading(true);
    fetchCategory();
    fetchAssetTemplate();
    fetchSiteDetailsAO(LoginUserDetails.id, "Today");
    return () => client.cancel();
  }, []);

  async function fetchSPCompanySPAO(user) {
    // try {
    //   const getSPComp = await client.graphql({
    //     query: queries.getSPCompanyDetails,
    //     authMode: "API_KEY",
    //     variables: {
    //       id: user.companyDetailsId,
    //     },
    //   });
    //   console.log("company " + user.companyDetailsId, getSPComp);
    //   if (getSPComp.data.getSPCompanyDetails !== null) {
    //     let spcomp = [];
    //     spcomp.push(getSPComp.data.getSPCompanyDetails);
    //     SPCompany.setSPCompany(spcomp);
    //     localStorage.setItem(
    //       "spCompany",
    //       base64.encode(JSON.stringify(spcomp))
    //     );
    //   }
    //   return () => client.cancel();
    // } catch (error) {
    //   console.log(error);
    //   setIsLoading(false);
    // }
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: user.id,
            },
          },
        },
      });
      console.log("company " + user.id, getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
        //--
        //     let spcomp = [];
        //     spcomp.push(getSPComp.data.getSPCompanyDetails);
        //     SPCompany.setSPCompany(spcomp);
        //     localStorage.setItem(
        //       "spCompany",
        //       base64.encode(JSON.stringify(spcomp))
        //     );
      }
    } catch (error) {
      console.log(error);
      //setIsLoading(false);
    }
  }

  async function fetchSpcompany(asetOwnerId) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: asetOwnerId,
            },
          },
        },
      });
      console.log("company " + asetOwnerId, getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
      }
      return () => client.cancel();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function fetchSiteDetailsAO(assetOwnerId, onDateRangeSelected) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: assetOwnerId,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      console.log(result);
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: assetOwnerId,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          console.log(results);
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          //fetchSpuser(mergeSites);
          SiteData.setSiteData(mergeSites);
          const promises1 = mergeSites.map(async (item) => {
            return client.graphql({
              query: queries.assetCountsBySitedetailsID,
              variables: {
                sitedetailsID: item.id,
                limit: 10000,
              },
            });
          });
          Promise.all(promises1).then(async (results) => {
            console.log(results);
            let assetCount = [];
            if (results.length > 0) {
              results.forEach((result1) => {
                let counts = result1.data.assetCountsBySitedetailsID;
                if (counts !== null) {
                  if (counts.items.length > 0) {
                    counts.items.map((item) => {
                      assetCount.push(item);
                    });
                  }
                }
              });
              console.log(assetCount);
              const totalAssetCount = assetCount.reduce(
                (accumulator, current) => {
                  return accumulator + current.assetCount;
                },
                0
              );
              console.log(onDateRangeSelected);
              console.log(totalAssetCount);
              if (onDateRangeSelected === "All Time") {
                setAssetsCount(totalAssetCount);
              }
              if (assetCount.length > 0) {
                localStorage.setItem(
                  "categoryAssetCount",
                  base64.encode(JSON.stringify(assetCount))
                );
              } else {
                localStorage.setItem(
                  "categoryAssetCount",
                  base64.encode(JSON.stringify(assetCount))
                );
              }
            }
          });
          //setSiteDetails(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
          fetchSpcompany(mergeSites);
          fetchlistDistributionBox(mergeSites);
          fetchAssets(mergeSites, "Today");
          setIsLoading(false);
        }
      }
      return () => client.cancel();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function fetchCategory() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let categories = [];
      const dataDetails = await client.graphql({
        query: queries.listCategories,
        authMode: "API_KEY",
        variables: {
          limit: 200000,
        },
      });
      if (dataDetails.data.listCategories.items.length > 0) {
        let assetDatas = dataDetails.data.listCategories.items;
        nextToken = dataDetails.data.listCategories.nextToken;
        if (dataDetails.data.listCategories.items.length > 0) {
          categories.push(assetDatas);
        }
        while (nextToken !== null) {
          const dataDetailss = await client.graphql({
            query: queries.listCategories,
            variables: {
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (dataDetailss.data.listCategories.items.length > 0) {
            nextToken = dataDetailss.data.listCategories.nextToken;
            if (dataDetailss.data.listCategories.items.length > 0) {
              categories.push(dataDetailss.data.listCategories.items);
            }
          }
        }
        console.log(categories);
        if (categories.length > 0) {
          const mergeCategories = categories.flat(1);
          CategoryData.setCategoryData(mergeCategories);
          localStorage.setItem(
            "category",
            base64.encode(JSON.stringify(mergeCategories))
          );
          setIsLoading(false);
        }
      }
      return () => client.cancel();
    } catch (error) {
      console.log(error);
      CategoryData.setCategoryData([]);
      setIsLoading(false);
    }
  }
  //---spuser
  async function fetchSpuser(sites) {
    //--
    try {
      let userIds = [];
      let nextToken1 = "";
      console.log(sites);
      const filterSites = sites.filter(
        (item) => item.id === selectedSiteGlobal
      );
      const filtersitesss = selectedSiteGlobal ? filterSites : sites;
      if (filtersitesss.length > 0) {
        for (let site of filtersitesss) {
          const getSiteIds = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: site.id,
              filter: {
                status: {
                  eq: "Active",
                },
              },
            },
          });
          console.log(getSiteIds);
          if (
            getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length > 0
          ) {
            nextToken1 =
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
            userIds.push(
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
            );
            while (nextToken1 !== null) {
              const getSiteIds1 = await client.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.id,
                  filter: {
                    status: {
                      eq: "Active",
                    },
                  },
                  nextToken: nextToken1,
                },
              });
              console.log(getSiteIds1);
              if (
                getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items.length >
                0
              ) {
                nextToken1 =
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                );
              }
            }
            console.log(userIds);
            if (userIds.length > 0) {
              const mergeUserIds = userIds.flat(1);
              const response1 = await client.graphql({
                query: queries.listSPUserDetails,
                authMode: "API_KEY",
                variables: {
                  // filter: {
                  //   userGroup: {
                  //     eq: "electrician",
                  //   },
                  // },
                  limit: 200000,
                },
              });
              console.log(response1);
              if (response1.data.listSPUserDetails.items.length > 0) {
                const spDetails2 =
                  response1.data.listSPUserDetails.items.filter((item) => {
                    const _matchSites = mergeUserIds?.find(
                      (user) => user.userdetailsID === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  });
                console.log(spDetails2);
                const sortedItems = spDetails2.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortedItems);
                localStorage.setItem(
                  "spuser",
                  base64.encode(JSON.stringify(sortedItems))
                );
              }
            } else {
            }
          }
        }
      } else {
      }
      return () => client.cancel();
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAssetTemplate() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assetTemplates = [];
      const dataDetails = await client.graphql({
        query: queries.listAssetTemplates,
        authMode: "API_KEY",
        variables: {
          limit: 200000,
        },
      });
      if (dataDetails.data.listAssetTemplates.items.length > 0) {
        let assetDatas = dataDetails.data.listAssetTemplates.items;
        nextToken = dataDetails.data.listAssetTemplates.nextToken;
        if (dataDetails.data.listAssetTemplates.items.length > 0) {
          assetTemplates.push(assetDatas);
        }
        while (nextToken !== null) {
          const dataDetailss = await client.graphql({
            query: queries.listAssetTemplates,
            variables: {
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (dataDetailss.data.listAssetTemplates.items.length > 0) {
            nextToken = dataDetailss.data.listAssetTemplates.nextToken;
            if (dataDetailss.data.listAssetTemplates.items.length > 0) {
              assetTemplates.push(dataDetailss.data.listAssetTemplates.items);
            }
          }
        }
        console.log(assetTemplates);
        if (assetTemplates.length > 0) {
          const mergeAssetTemplates = assetTemplates.flat(1);
          AssetTemplate.setAssetTemplate(mergeAssetTemplates);
          localStorage.setItem(
            "assetTemp",
            base64.encode(JSON.stringify(mergeAssetTemplates))
          );
          setIsLoading(false);
        }
      }
      return () => client.cancel();
    } catch (error) {
      console.log(error);
      AssetTemplate.setAssetTemplate([]);
      setIsLoading(false);
    }
  }

  async function fetchlistDistributionBox(siteData) {
    try {
      setIsLoading(true);
      const activesites = siteData.filter((item) => item.status === "Active");
      const promises = activesites.map((item) => {
        return client.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 200000,
          },
        });
      });
      Promise.all(promises)
        .then(async (results) => {
          console.log("results -> ", results);
          let SiteDB = [];
          results.map((items) => {
            if (items.data.distributionBoxesBySitedetailsID.items.length > 0) {
              SiteDB.push(items.data.distributionBoxesBySitedetailsID.items);
            }
          });
          if (SiteDB.length > 0) {
            SiteDBData.setSiteDBData(SiteDB.flat(1));
            console.log("SiteDB", SiteDB.flat(1));
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(SiteDB.flat(1)))
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return () => client.cancel();
    } catch (error) {
      console.log(error);
    }
  }

  const [graphLabelsLogBooks, setgraphLabelsLogBooks] = useState([]);
  const [graphDataLogBooks, setgraphDataLogBooks] = useState([]);
  const [graphLabelsRepairs, setgraphLabelsRepairs] = useState([]);
  const [graphDataRepairs, setgraphDataRepairs] = useState([]);
  const [graphLabelsTests, setgraphLabelsTests] = useState([]);
  const [graphDataTests, setgraphDataTests] = useState([]);
  const [graphLabelsSites, setgraphLabelsSites] = useState([]);
  const [graphDataSites, setgraphDataSites] = useState([]);
  const [graphLabelsAssets, setgraphLabelsAssets] = useState([]);
  const [graphDataAssets, setgraphDataAssets] = useState([]);
  const [graphLabelsSP, setgraphLabelsSP] = useState([]);
  const [graphDataSP, setgraphDataSP] = useState([]);
  const [graphLabelsSiteUsers, setgraphLabelsSiteUsers] = useState([]);
  const [graphDataSiteUsers, setgraphDataSiteUsers] = useState([]);
  const [graphLabelsPI, setgraphLabelsPI] = useState([]);
  const [graphDataPI, setgraphDataPI] = useState([]);
  const [graphLabelsWarranty, setgraphLabelsWarranty] = useState([]);
  const [graphDataWarranty, setgraphDataWarranty] = useState([]);
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  //console.log(selectedSiteGlobal);
  const { siteDetailsGlobal, handleSiteDetailsChange } =
    useContext(SiteDetailsContext);
  const chartWarranty = {
    labels: graphLabelsWarranty.reverse(),
    datasets: [
      {
        label: "Warranty",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataWarranty,
      },
    ],
  };
  const chartRepairs = {
    labels: graphLabelsRepairs.reverse(),
    datasets: [
      {
        label: "Repairs",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataRepairs,
      },
    ],
  };
  const chartTests = {
    labels: graphLabelsTests.reverse(),
    datasets: [
      {
        label: "Tests",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataTests,
      },
    ],
  };
  const chartSP = {
    labels: graphLabelsSP.reverse(),
    datasets: [
      {
        label: "Service Providers",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataSP,
      },
    ],
  };
  const chartSiteUsers = {
    labels: graphLabelsSiteUsers.reverse(),
    datasets: [
      {
        label: "SiteUsers",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataSiteUsers,
      },
    ],
  };
  const chartSites = {
    labels: graphLabelsSites.reverse(),
    datasets: [
      {
        label: "Sites",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataSites,
      },
    ],
  };
  const chartAssets = {
    labels: graphLabelsAssets.reverse(),
    datasets: [
      {
        label: "Assets",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataAssets,
      },
    ],
  };
  const chartLogBooks = {
    labels: graphLabelsLogBooks.reverse(),
    datasets: [
      {
        label: "LogBooks",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataLogBooks,
      },
    ],
  };
  const chartPendingInvitations = {
    labels: graphLabelsPI.reverse(),
    datasets: [
      {
        label: "Pending Invitations",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataPI,
      },
    ],
  };
  //-------single Calendar-----
  const [dateRange, setDateRange] = useState([null, null]);
  function SingleCalendar() {
    const [startDate, endDate] = dateRange;
    const handleDateRangeChange = (dates) => {
      if (dates && dates.length === 2) {
        setDateRange(dates);
        // fetchAssetOwnerDetailsData("Custom");
        fetchAssets(siteDetails, "Custom");
        fetchSitesDetails(siteDetails, "Custom");
        fetchServiceProviderDetails("Custom");
        // fetchTestingDetails("Custom");
        // fetchRepairDetails("Custom");
        // fetchLogbookDetails("Custom");
        fetchSiteUserDetails("Custom");
        // fetchWarantyDetails("Custom");
      }
    };
    return (
      <Fragment>
        <Row className="d-flex justify-content-end">
          <Col xl="6">
            <div className="daterange-card">
              <div className="mb-3">
                <FormGroup>
                  <DatePicker
                    className="form-control digits"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateRangeChange}
                    isClearable={true}
                    placeholderText="Enter Date Range"
                    dateFormat="dd-MM-yyyy"
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
  //-------------Daterange Start-----------------------------------------------------
  const [selectedOption, setSelectedOption] = useState("Today");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);
  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };
  const onDateRangeSelected = (range) => {
    //setSelectedOption("");
    handleDateRangeSelected(range);
  };
  function DateRangePicker({ onDateRangeSelected }) {
    //const [selectedOption, setSelectedOption] =  useState('');

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());

          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());

          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());

          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());

          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());

          break;

        default:
          break;
      }
    };
    //---------------------------------------------------------------------------
    const getTodayRange = () => {
      // fetchAssetOwnerDetailsData("Today");
      fetchSitesDetails(siteDetails, "Today");
      fetchAssets(siteDetails, "Today");
      fetchServiceProviderDetails("Today");
      // fetchTestingDetails("Today");
      // fetchRepairDetails("Today");
      // fetchLogbookDetails("Today");
      fetchSiteUserDetails("Today");
      // fetchWarantyDetails("Today");
    };
    //---------------------------------------------------------------------------
    const getAllTimeRange = () => {
      const start = new Date(0);
      const end = new Date();
      const controller = new AbortController();
      // fetchAssetOwnerDetailsData("All Time");
      //fetchAssets(siteDetails, "All Time");
      fetchSiteDetailsAO(LoginUserDetails.id, "All Time", controller);
      fetchSitesDetails(siteDetails, "All Time");
      fetchServiceProviderDetails("All Time");
      // fetchTestingDetails("All Time");
      // fetchRepairDetails("All Time");
      // fetchLogbookDetails("All Time");
      fetchSiteUserDetails("All Time");
      // fetchWarantyDetails("All Time");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [start, end];
    };
    //---------------------------------------------------------------------------
    const getWeekRange = () => {
      const currentDate = new Date();
      const currentDayOfWeek = currentDate.getDay();
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDayOfWeek
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDayOfWeek)
      );

      const today = new Date();
      console.log(today);
      console.log(moment(startOfWeek).format("DD/MM/YYYY"));

      console.log(endOfWeek);
      // fetchAssetOwnerDetailsData("This Week");
      fetchAssets(siteDetails, "This Week");
      fetchSitesDetails(siteDetails, "This Week");
      fetchServiceProviderDetails("This Week");
      // fetchTestingDetails("This Week");
      // fetchRepairDetails("This Week");
      // fetchLogbookDetails("This Week");
      // fetchSiteUserDetails("This Week");
      // fetchWarantyDetails("This Week");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfWeek, endOfWeek];
    };
    //-----------------------------------------------------------------------
    const getMonthRange = () => {
      console.log("month range");
      const currentDate = new Date();
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      // fetchAssetOwnerDetailsData("This Month");
      fetchAssets(siteDetails, "This Month");
      fetchSitesDetails(siteDetails, "This Month");
      fetchServiceProviderDetails("This Month");
      // fetchTestingDetails("This Month");
      // fetchRepairDetails("This Month");
      // fetchLogbookDetails("This Month");
      fetchSiteUserDetails("This Month");
      // fetchWarantyDetails("This Month");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfMonth, endOfMonth];
    };
    //---------------------------------------------------------------------------
    const getThisYearRange = () => {
      const currentDate = new Date();
      const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
      const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
      // fetchAssetOwnerDetailsData("This Year");
      fetchAssets(siteDetails, "This Year");
      fetchSitesDetails(siteDetails, "This Year");
      fetchServiceProviderDetails("This Year");
      // fetchTestingDetails("This Year");
      // fetchRepairDetails("This Year");
      // fetchLogbookDetails("This Year");
      fetchSiteUserDetails("This Year");
      // fetchWarantyDetails("This Year");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfThisYear, endOfThisYear];
    };
    //---------------------------------------------------------------------------------
    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                {/* <option
                  value="All Time"
                  selected={selectedOption === "All Time"}
                >
                  All Time
                </option> */}
                <option value="Today" selected={selectedOption === "Today"}>
                  Today
                </option>
                <option
                  value="This Week"
                  selected={selectedOption === "This Week"}
                >
                  This Week
                </option>
                <option
                  value="This Month"
                  selected={selectedOption === "This Month"}
                >
                  This Month
                </option>
                <option
                  value="This Year"
                  selected={selectedOption === "This Year"}
                >
                  This Year
                </option>
                <option value="Custom" selected={selectedOption === "Custom"}>
                  Custom
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <SingleCalendar onDateRangeSelected={onDateRangeSelected} />
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
  //--------------Daterange End----------------------------------------------------
  //-------------- Asset Owner Start -------------------------------------------
  const [assetOwnerDetailsList, setAssetOwnerDetailsList] = useState([]);
  //--------------Assets ---------------
  const [assetsList, setAssetsList] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [assetsCount, setAssetsCount] = useState("");
  const [listofServiceProviders, setlistofServiceProviders] = useState([]);
  const [testingDetailsList, setTestingDetailsList] = useState([]);
  const [repairDetailsList, setRepairDetailsList] = useState([]);
  const [warantyDetailsList, setWarrantyDetailsList] = useState([]);
  const [logBookDetailsList, setLogBookDetailsList] = useState([]);
  const [listofSiteUsers, setListofSiteUsers] = useState([]);

  useEffect(() => {
    // fetchAssetOwnerDetailsData("All Time");
    fetchSitesDetails(siteDetails, "Today");
    fetchAssets(siteDetails, "Today");
    fetchServiceProviderDetails("Today");
    // fetchTestingDetails("All Time");
    // fetchRepairDetails("All Time");
    // fetchWarantyDetails("All Time");
    // fetchLogbookDetails("All Time");
    fetchSiteUserDetails("Today");
    return () => client.cancel();
  }, [selectedSiteGlobal]);

  async function fetchAssetOwnerDetailsData(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listLinkUsersAndSites,
        authMode: "API_KEY",
        variables: {
          limit: 20000,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
      })
      .then(async (result) => {
        let assetDatas = result.data.listLinkUsersAndSites.items;
        nextToken = result.data.listLinkUsersAndSites.nextToken;
        console.log(
          "first token count",
          result.data.listLinkUsersAndSites.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listLinkUsersAndSites.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listLinkUsersAndSites,
              authMode: "API_KEY",
              variables: {
                limit: 20000,
                nextToken: nextToken,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listLinkUsersAndSites.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listLinkUsersAndSites.items.length);
              if (results.data.listLinkUsersAndSites.items.length > 0) {
                assets.push(results.data.listLinkUsersAndSites.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const linkedUsers = mergeAssets;
          const spDetailss = linkedUsers.filter((item) => {
            const _matchSites = siteDetailsGlobal?.find(
              (site) => site.id === item?.sitedetailsID
            );
            if (_matchSites) {
              return item;
            }
          });
          console.log(spDetailss);
          const filterSites = spDetailss.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const filtersitesss = selectedSiteGlobal ? filterSites : spDetailss;
          console.log(siteDetailsGlobal);
          console.log(filtersitesss);
          //--
          let nextToken1 = "";
          let assets1 = [];
          await client
            .graphql({
              query: queries.listSPUserDetails,
              authMode: "API_KEY",
              variables: {
                limit: 200000,
              },
            })
            .then(async (result) => {
              let assetDatas = result.data.listSPUserDetails.items;
              nextToken1 = result.data.listSPUserDetails.nextToken;
              console.log(
                "first token count",
                result.data.listSPUserDetails.items.length
              );
              console.log("nextToken 1", nextToken1);
              if (result.data.listSPUserDetails.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await client
                  .graphql({
                    query: queries.listSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      limit: 200000,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken1 = results.data.listSPUserDetails.nextToken;
                    console.log("nextToken", nextToken1);
                    console.log(results.data.listSPUserDetails.items.length);
                    if (results.data.listSPUserDetails.items.length > 0) {
                      assets1.push(results.data.listSPUserDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                const spDetails2 = mergeAssets1;
                const aoSP = spDetails2.filter((item) => {
                  const _matchSites = filtersitesss?.find(
                    (linkUser) => linkUser.userdetailsID === item?.id
                  );
                  if (_matchSites) {
                    return item;
                  }
                });
                console.log(aoSP);

                const filterSites = aoSP.filter(
                  (item) => item.id === selectedSiteGlobal
                );
                const sortedItems = aoSP.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                const sortedItems2 = aoSP.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortedItems);
                //const spDetails = sortedItems2;
                //console.log(spDetails);
                const SpUsers = sortedItems2;
                let PInvites = SpUsers.filter(
                  (item) => item.status === "Inactive"
                );
                let serviceProviders = [];
                console.log(PInvites);
                if (PInvites.length > 0) {
                  if (onDateRangeSelected === "This Week") {
                    serviceProviders = PInvites.filter(
                      (item) =>
                        moment(item.createdAt).format("DD/MM/YYYY") >=
                        moment(startOfWeek).format("DD/MM/YYYY") &&
                        moment(item.createdAt).format("DD/MM/YYYY") <=
                        moment(endOfWeek).format("DD/MM/YYYY")
                    );
                    //----Graph----------------------------------------
                    let unique = serviceProviders
                      .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    unique.map((uitem) => {
                      countsArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ).length
                      );
                    });
                    unique.map((uitem) => {
                      monthArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ) && uitem
                      );
                    });
                    setgraphLabelsPI(monthArray);
                    setgraphDataPI(countsArray);
                    //------------------------------end-------------------------------
                  } else if (onDateRangeSelected === "This Month") {
                    serviceProviders = PInvites.filter(
                      (item) =>
                        moment(item.createdAt).format("DD/MM/YYYY") >=
                        moment(startOfMonth).format("DD/MM/YYYY") &&
                        moment(item.createdAt).format("DD/MM/YYYY") <=
                        moment(endOfMonth).format("DD/MM/YYYY")
                    );
                    //----Graph----------------------------------------
                    let unique = serviceProviders
                      .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    unique.map((uitem) => {
                      countsArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ).length
                      );
                    });
                    unique.map((uitem) => {
                      monthArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ) && uitem
                      );
                    });
                    setgraphLabelsPI(monthArray);
                    setgraphDataPI(countsArray);
                    //------------------------------end-------------------------------
                  } else if (onDateRangeSelected === "Today") {
                    serviceProviders = PInvites.filter(
                      (item) =>
                        moment(item.createdAt).format("DD/MM/YYYY") ===
                        moment(new Date()).format("DD/MM/YYYY")
                    );
                    //----Graph----------------------------------------
                    let unique = serviceProviders
                      .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    unique.map((uitem) => {
                      countsArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ).length
                      );
                    });
                    unique.map((uitem) => {
                      monthArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ) && uitem
                      );
                    });
                    setgraphLabelsPI(monthArray);
                    setgraphDataPI(countsArray);
                    //------------------------------end-------------------------------
                  } else if (onDateRangeSelected === "This Year") {
                    serviceProviders = PInvites.filter(
                      (item) =>
                        moment(item.createdAt).format("DD/MM/YYYY") >=
                        moment(startOfThisYear).format("YYYY-MM-DD") &&
                        moment(item.createdAt).format("DD/MM/YYYY") <=
                        moment(endOfThisYear).format("DD/MM/YYYY")
                    );
                    //----Graph----------------------------------------
                    let unique = serviceProviders
                      .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    unique.map((uitem) => {
                      countsArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ).length
                      );
                    });
                    unique.map((uitem) => {
                      monthArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ) && uitem
                      );
                    });
                    setgraphLabelsPI(monthArray);
                    setgraphDataPI(countsArray);
                    //------------------------------end-------------------------------
                  } else if (onDateRangeSelected === "Custom") {
                    serviceProviders = PInvites.filter(
                      (item) =>
                        moment(item.createdAt).format("DD/MM/YYYY") >=
                        moment(startDate).format("DD/MM/YYYY") &&
                        moment(item.createdAt).format("DD/MM/YYYY") <=
                        moment(endDate).format("DD/MM/YYYY")
                    );
                    //----Graph----------------------------------------
                    let unique = serviceProviders
                      .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    unique.map((uitem) => {
                      countsArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ).length
                      );
                    });
                    unique.map((uitem) => {
                      monthArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ) && uitem
                      );
                    });
                    setgraphLabelsPI(monthArray);
                    setgraphDataPI(countsArray);
                    //------------------------------end-------------------------------
                  } else if (onDateRangeSelected === "All Time") {
                    serviceProviders = PInvites;
                    //----Graph----------------------------------------
                    let unique = serviceProviders
                      .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                      .filter(
                        (value, index, self) => self.indexOf(value) === index
                      );
                    unique.map((uitem) => {
                      countsArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ).length
                      );
                    });
                    unique.map((uitem) => {
                      monthArray.push(
                        serviceProviders.filter(
                          (d) =>
                            moment(d.createdAt).format("YYYY-MMM") === uitem
                        ) && uitem
                      );
                    });
                    setgraphLabelsPI(monthArray);
                    setgraphDataPI(countsArray);
                    //------------------------------end-------------------------------
                  }
                  setAssetOwnerDetailsList(serviceProviders);
                  const oneYearAgo = new Date();
                  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                  const val = serviceProviders.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") ===
                      moment(new Date()).format("DD/MM/YYYY")
                  );
                  setIsLoading(false);
                } else {
                  setAssetOwnerDetailsList([]);
                  setIsLoading(false);
                }
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    return () => client.cancel();
    // p invite ends
  }

  //--------------Pending Invitations End----------------------------------------------------
  async function fetchAssets(sites, onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let assetsCount = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);
    let assets = [];
    let testing = [];
    let repair = [];
    let filter = {};
    if (onDateRangeSelected === "Today") {
      filter = {
        createdAt: {
          ge: moment().startOf("day").toISOString(),
          le: moment().endOf("day").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Week") {
      filter = {
        createdAt: {
          ge: moment().startOf("week").toISOString(),
          le: moment().endOf("week").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Month") {
      filter = {
        createdAt: {
          ge: moment().startOf("month").toISOString(),
          le: moment().endOf("month").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Year") {
      filter = {
        createdAt: {
          ge: moment().startOf("year").toISOString(),
          le: moment().endOf("year").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "Custom") {
      filter = {
        createdAt: {
          ge: moment(startDate).startOf("day").toISOString(),
          le: moment(endDate).endOf("day").toISOString(),
        },
      };
    }
    console.log("filter", filter);
    try {
      //console.log(sites);
      console.log(assetsCount);
      let nextToken = "";
      let sites = [];
      await client
        .graphql({
          query: queries.assetsByAssetOwnerID,
          variables: {
            assetOwnerID: LoginUserDetails.id,
            filter: filter,
            limit: 200000,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.assetsByAssetOwnerID.items;
          nextToken = result.data.assetsByAssetOwnerID.nextToken;
          if (result.data.assetsByAssetOwnerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.assetsByAssetOwnerID,
                variables: {
                  assetOwnerID: LoginUserDetails.id,
                  limit: 200000,
                  filter: filter,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.assetsByAssetOwnerID.nextToken;
                if (results.data.assetsByAssetOwnerID.items.length > 0) {
                  sites.push(results.data.assetsByAssetOwnerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergeSites = sites.flat(1);
            console.log(mergeSites);

            // fetchRepairDetails(mergeSites, onDateRangeSelected);
            // fetchTestingDetails(mergeSites, onDateRangeSelected);
            // fetchWarantyDetails(mergeSites, onDateRangeSelected);
            //---
            if (onDateRangeSelected === "All Time") {
              //----Graph----------------------------------------
              let unique = mergeSites
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsAssets(monthArray);
              setgraphDataAssets(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Week") {
              //----Graph----------------------------------------
              let unique = mergeSites
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsAssets(monthArray);
              setgraphDataAssets(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Month") {
              //----Graph----------------------------------------
              let unique = mergeSites
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsAssets(monthArray);
              setgraphDataAssets(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Today") {
              //----Graph----------------------------------------
              let unique = mergeSites
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsAssets(monthArray);
              setgraphDataAssets(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Year") {
              //----Graph----------------------------------------
              let unique = mergeSites
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsAssets(monthArray);
              setgraphDataAssets(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Custom") {
              //----Graph----------------------------------------
              let unique = mergeSites
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  mergeSites.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsAssets(monthArray);
              setgraphDataAssets(countsArray);
              //------------------graph-------------------end---------------
            }
            setAssetsCount(mergeSites.length);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      {
        /*
      const promises = sites.map((item) => {
        return client.graphql({
          query: mergedQueries.getAssetsFullCount,
          variables: {
            sitedetailsID: item.id,
            limit: 100000,
          },
        });
      });
      Promise.all(promises)
        .then((results) => {
          console.log(results);
          results.map((asset) => {
            let assetData = asset.data.assetsBySitedetailsID.items;
            if (assetData.length > 0) {
              assetData.map((items) => {
                repair.push(items.Repairs.items);
                testing.push(items.Testings.items);
                assets.push(items);
              });
            }
          });
          console.log("repair", repair.flat(1));
          fetchRepairDetails(repair.flat(1), onDateRangeSelected);
          console.log("testing", testing.flat(1));
          fetchTestingDetails(testing.flat(1), onDateRangeSelected);
          console.log("assets", assets.flat(1));
          let fullAssets = assets.flat(1);
          const filterAssetsSites = fullAssets.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          console.log(filterAssetsSites);
          const data = selectedSiteGlobal ? filterAssetsSites : assets;
          if (onDateRangeSelected === "All Time") {
            assetsCount = data.filter((item) => !item._delete);
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Week") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startOfWeek).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endOfWeek).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Month") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startOfMonth).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endOfMonth).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Today") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") ===
                moment(new Date()).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Year") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startOfThisYear).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endOfThisYear).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Custom") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startDate).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endDate).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          }
          setIsLoading(false);
          setAssetsList(assetsCount);
        })
        .catch((error) => {
          console.log(error);
        });
        */
      }
      {
        /*
      const promises = sites.map((item) => {
        return client.graphql({
          query: mergedQueries.getAssetsFullCount,
          variables: {
            sitedetailsID: item.id,
          },
        });
      });
      Promise.all(promises)
        .then((results) => {
          console.log(results);
          results.map((asset) => {
            let assetData = asset.data.assetsBySitedetailsID.items;
            if (assetData.length > 0) {
              assetData.map((items) => {
                repair.push(items.Repairs.items);
                testing.push(items.Testings.items);
                assets.push(items);
              });
            }
          });
          console.log("repair", repair.flat(1));
          fetchRepairDetails(repair.flat(1), onDateRangeSelected);
          console.log("testing", testing.flat(1));
          fetchTestingDetails(testing.flat(1), onDateRangeSelected);
          console.log("assets", assets.flat(1));
          let fullAssets = assets.flat(1);
          const filterAssetsSites = fullAssets.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          console.log(filterAssetsSites);
          const data = selectedSiteGlobal ? filterAssetsSites : assets;
          if (onDateRangeSelected === "All Time") {
            assetsCount = data.filter((item) => !item._delete);
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Week") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfWeek).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfWeek).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Month") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfMonth).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfMonth).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Today") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") ===
                moment(new Date()).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Year") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfThisYear).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfThisYear).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Custom") {
            assetsCount = data.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startDate).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endDate).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = assetsCount
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                assetsCount.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsAssets(monthArray);
            setgraphDataAssets(countsArray);
            //------------------graph-------------------end---------------
          }
          setIsLoading(false);
          setAssetsList(assetsCount);
        })
        .catch((error) => {
          console.log(error);
        });
        */
      }
    } catch (error) {
      console.log(error);
    }
    return () => client.cancel();
  }

  //--------------Sites start-------------------------------------------------------------------
  async function fetchSitesDetails(sites, onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let sitesCount = [];
    let monthArray = [];
    let countsArray = [];
    setIsLoading(true);
    let nextToken = "";
    let sitess = [];
    await client
      .graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sitess.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.siteDetailsByAssetownerID,
              variables: {
                assetownerID: LoginUserDetails.id,
                limit: 200000,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.siteDetailsByAssetownerID.nextToken;
              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                sitess.push(results.data.siteDetailsByAssetownerID.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);
          const sitesss = mergeSites;
          //---fetching testingbysiteid
          // fetchTestingDetails(sitesss, onDateRangeSelected);
          //fetchWarantyDetails(sitesss, onDateRangeSelected);
          if (sitesss.length > 0) {
            const siteFilter = sitesss.filter(
              (item) => item.id === selectedSiteGlobal
            );
            console.log(siteFilter);
            const NewSite = siteFilter.length > 0 ? siteFilter : mergeSites;
            console.log("onDateRangeSelected", onDateRangeSelected);
            if (onDateRangeSelected === "All Time") {
              sitesCount = NewSite.filter((item) => !item._delete);
              //----Graph----------------------------------------
              let unique = sitesCount
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSites(monthArray);
              setgraphDataSites(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Week") {
              sitesCount = NewSite.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfWeek).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfWeek).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = sitesCount
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSites(monthArray);
              setgraphDataSites(countsArray);
            } else if (onDateRangeSelected === "This Month") {
              sitesCount = NewSite.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfMonth).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfMonth).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = sitesCount
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSites(monthArray);
              setgraphDataSites(countsArray);
              //--------------------------------------------------------
            } else if (onDateRangeSelected === "Today") {
              sitesCount = NewSite.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") ===
                  moment(new Date()).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = sitesCount
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSites(monthArray);
              setgraphDataSites(countsArray);
              //------------------------------end-------------------------------
            } else if (onDateRangeSelected === "This Year") {
              sitesCount = NewSite.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfThisYear).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfThisYear).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = sitesCount
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSites(monthArray);
              setgraphDataSites(countsArray);
              //------------------------------end-------------------------------
            } else if (onDateRangeSelected === "Custom") {
              sitesCount = NewSite.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startDate).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endDate).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = sitesCount
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  sitesCount.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSites(monthArray);
              setgraphDataSites(countsArray);
              //------------------------------end-------------------------------
            }
            console.log(sitesCount);
            fetchLogbookDetails(sitesCount, onDateRangeSelected);
            setIsLoading(false);
            setSiteDetails(sitesCount);
          } else {
            setSiteDetails([]);
          }
        } else {
          setSiteDetails([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => client.cancel();
  }
  //--- Sites End ----

  //--- Service provider Start ----
  async function fetchServiceProviderDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let monthArray = [];
    let countsArray = [];
    setIsLoading(true);
    let serviceProviders = [];
    let siteUserDetails = [];
    let monthArray1 = [];
    let countsArray1 = [];

    await client
      .graphql({
        query: mergedQueries.getAssetOwnerDetails,
        variables: {
          assetownerID: LoginUserDetails.id,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        console.log("result full asset", result);
        let users = [];
        result.data.siteDetailsByAssetownerID.items.map((items) => {
          if (items.SPUserDetails.items.length > 0) {
            users.push(items.SPUserDetails.items);
          }
        });
        if (users.length > 0) {
          let mergeUsers = users.flat(1);
          if (mergeUsers.length > 0) {
            console.log(mergeUsers);
            const filterSites = mergeUsers.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );
            const spDetails = selectedSiteGlobal ? filterSites : mergeUsers;
            const electrician = spDetails.filter(
              (item) => item.userGroup === "electrician"
            );
            if (onDateRangeSelected === "This Week") {
              serviceProviders = electrician.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfWeek).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfWeek).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = serviceProviders
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSP(monthArray);
              setgraphDataSP(countsArray);
              //------------------------------end-------------------------------
            } else if (onDateRangeSelected === "This Month") {
              serviceProviders = electrician.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfMonth).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfMonth).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = serviceProviders
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSP(monthArray);
              setgraphDataSP(countsArray);
              //------------------------------end-------------------------------
            } else if (onDateRangeSelected === "Today") {
              serviceProviders = electrician.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") ===
                  moment(new Date()).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = serviceProviders
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSP(monthArray);
              setgraphDataSP(countsArray);
              //------------------------------end-------------------------------
            } else if (onDateRangeSelected === "This Year") {
              serviceProviders = electrician.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfThisYear).format("YYYY-MM-DD") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfThisYear).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = serviceProviders
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSP(monthArray);
              setgraphDataSP(countsArray);
              //------------------------------end-------------------------------
            } else if (onDateRangeSelected === "Custom") {
              serviceProviders = electrician.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startDate).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endDate).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = serviceProviders
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSP(monthArray);
              setgraphDataSP(countsArray);
              //------------------------------end-------------------------------
            } else if (onDateRangeSelected === "All Time") {
              serviceProviders = electrician;
              //----Graph----------------------------------------
              let unique = serviceProviders
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  serviceProviders.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSP(monthArray);
              setgraphDataSP(countsArray);
              //------------------------------end-------------------------------
            }
            setlistofServiceProviders(serviceProviders);

            // site User
            const filterSites1 = mergeUsers.filter(
              (item) => item.sitedetailsID === selectedSiteGlobal
            );
            const SpUsers = selectedSiteGlobal ? filterSites1 : mergeUsers;
            if (onDateRangeSelected === "This Week") {
              siteUserDetails = SpUsers.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfWeek).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfWeek).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = siteUserDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSiteUsers(monthArray1);
              setgraphDataSiteUsers(countsArray1);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Month") {
              siteUserDetails = SpUsers.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfMonth).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfMonth).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = siteUserDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSiteUsers(monthArray1);
              setgraphDataSiteUsers(countsArray1);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Today") {
              siteUserDetails = SpUsers.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") ===
                  moment(new Date()).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = siteUserDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSiteUsers(monthArray1);
              setgraphDataSiteUsers(countsArray1);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Year") {
              siteUserDetails = SpUsers.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startOfThisYear).format("YYYY-MM-DD") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endOfThisYear).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = siteUserDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSiteUsers(monthArray1);
              setgraphDataSiteUsers(countsArray1);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Custom") {
              siteUserDetails = SpUsers.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") >=
                  moment(startDate).format("DD/MM/YYYY") &&
                  moment(item.createdAt).format("DD/MM/YYYY") <=
                  moment(endDate).format("DD/MM/YYYY")
              );
              //----Graph----------------------------------------
              let unique = siteUserDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSiteUsers(monthArray1);
              setgraphDataSiteUsers(countsArray1);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "All Time") {
              siteUserDetails = SpUsers;
              //----Graph----------------------------------------
              let unique = siteUserDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray1.push(
                  siteUserDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsSiteUsers(monthArray1);
              setgraphDataSiteUsers(countsArray1);
              //------------------graph-------------------end---------------
            }
            setListofSiteUsers(
              siteUserDetails.filter((item) => item.userGroup === "site_user")
            );

            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    return () => client.cancel();
  }
  //--- Service provider End ----
  //--- Testing Start ----
  async function fetchTestingDetails(testingData, onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let testingDetails = [];
    let monthArray = [];
    let countsArray = [];
    setIsLoading(true);
    let filter = {};
    if (onDateRangeSelected === "Today") {
      filter = {
        createdAt: {
          ge: moment().startOf("day").toISOString(),
          lt: moment().endOf("day").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Week") {
      filter = {
        createdAt: {
          ge: moment().startOf("week").toISOString(),
          lt: moment().endOf("week").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Month") {
      filter = {
        createdAt: {
          ge: moment().startOf("month").toISOString(),
          lt: moment().endOf("month").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Year") {
      filter = {
        createdAt: {
          ge: moment().startOf("year").toISOString(),
          lt: moment().endOf("year").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "Custom") {
      filter = {
        createdAt: {
          ge: moment(startDate).startOf("day").toISOString(),
          lt: moment(endDate).endOf("day").toISOString(),
        },
      };
    }
    console.log("filter", filter);
    console.log(testingData);
    let nextToken2 = "";
    let assets2 = [];
    testingData.map(async (item) => {
      await client
        .graphql({
          query: queries.testingsBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            filter: filter,
            limit: 1000,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.testingsBySitedetailsID.items;
          nextToken2 = result.data.testingsBySitedetailsID.nextToken;
          if (result.data.testingsBySitedetailsID.items.length > 0) {
            assets2.push(assetDatas);
          }
          while (nextToken2 !== null) {
            await client
              .graphql({
                query: queries.testingsBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  filter: filter,
                  limit: 1000,
                  nextToken: nextToken2,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken2 = results.data.testingsBySitedetailsID.nextToken;
                if (results.data.testingsBySitedetailsID.items.length > 0) {
                  assets2.push(results.data.testingsBySitedetailsID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (assets2.length > 0) {
            const mergeAssets2 = assets2.flat(1);
            testingDetails = mergeAssets2;
            if (onDateRangeSelected === "All Time") {
              // testingDetails = testingData.filter((item) => !item._delete);
              //----Graph----------------------------------------
              let unique = testingDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsTests(monthArray);
              setgraphDataTests(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Week") {
              // testingDetails = testingData.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startOfWeek).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endOfWeek).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = testingDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsTests(monthArray);
              setgraphDataTests(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Month") {
              // testingDetails = testingData.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startOfMonth).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endOfMonth).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = testingDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsTests(monthArray);
              setgraphDataTests(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Today") {
              // testingDetails = testingData.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") ===
              //     moment(new Date()).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = testingDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsTests(monthArray);
              setgraphDataTests(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Year") {
              // testingDetails = testingData.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startOfThisYear).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endOfThisYear).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = testingDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  testingDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsTests(monthArray);
              setgraphDataTests(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Custom") {
              // testingDetails = testingData.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startDate).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endDate).format("DD/MM/YYYY")
              // );
            }
            setIsLoading(false);
            setTestingDetailsList(testingDetails);
          } else {
            setIsLoading(false);
            setTestingDetailsList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
    {
      /*
    if (onDateRangeSelected === "All Time") {
      testingDetails = testingData.filter((item) => !item._delete);
      //----Graph----------------------------------------
      let unique = testingDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsTests(monthArray);
      setgraphDataTests(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "This Week") {
      testingDetails = testingData.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startOfWeek).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endOfWeek).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = testingDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsTests(monthArray);
      setgraphDataTests(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "This Month") {
      testingDetails = testingData.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startOfMonth).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endOfMonth).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = testingDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsTests(monthArray);
      setgraphDataTests(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "Today") {
      testingDetails = testingData.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") ===
          moment(new Date()).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = testingDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsTests(monthArray);
      setgraphDataTests(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "This Year") {
      testingDetails = testingData.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startOfThisYear).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endOfThisYear).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = testingDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          testingDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsTests(monthArray);
      setgraphDataTests(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "Custom") {
      testingDetails = testingData.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startDate).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endDate).format("DD/MM/YYYY")
      );
    }
    setIsLoading(false);
    setTestingDetailsList(testingDetails);
    */
    }
    return () => client.cancel();
  }

  //--- Testing End ----
  //--- Repair Start ----
  async function fetchRepairDetails(repairData, onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let repairDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);
    let filter = {};
    if (onDateRangeSelected === "Today") {
      filter = {
        createdAt: {
          ge: moment().startOf("day").toISOString(),
          lt: moment().endOf("day").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Week") {
      filter = {
        createdAt: {
          ge: moment().startOf("week").toISOString(),
          lt: moment().endOf("week").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Month") {
      filter = {
        createdAt: {
          ge: moment().startOf("month").toISOString(),
          lt: moment().endOf("month").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "This Year") {
      filter = {
        createdAt: {
          ge: moment().startOf("year").toISOString(),
          lt: moment().endOf("year").toISOString(),
        },
      };
    } else if (onDateRangeSelected === "Custom") {
      filter = {
        createdAt: {
          ge: moment(startDate).startOf("day").toISOString(),
          lt: moment(endDate).endOf("day").toISOString(),
        },
      };
    }
    console.log("filter", filter);
    console.log(repairData);
    let nextToken2 = "";
    let assets2 = [];
    repairData.map(async (item) => {
      await client
        .graphql({
          query: queries.repairsByLogbookID,
          variables: {
            logbookID: item.id,
            filter: filter,
            limit: 1000,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.repairsByLogbookID.items;
          nextToken2 = result.data.repairsByLogbookID.nextToken;
          if (result.data.repairsByLogbookID.items.length > 0) {
            assets2.push(assetDatas);
          }
          while (nextToken2 !== null) {
            await client
              .graphql({
                query: queries.repairsByLogbookID,
                variables: {
                  logbookID: item.id,
                  filter: filter,
                  limit: 1000,
                  nextToken: nextToken2,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken2 = results.data.repairsByLogbookID.nextToken;
                if (results.data.repairsByLogbookID.items.length > 0) {
                  assets2.push(results.data.repairsByLogbookID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (assets2.length > 0) {
            const mergeAssets2 = assets2.flat(1);
            repairDetails = mergeAssets2;
            if (onDateRangeSelected === "All Time") {
              // repairDetails = repair.filter((item) => !item._delete);
              //----Graph----------------------------------------
              let unique = repairDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsRepairs(monthArray);
              setgraphDataRepairs(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Week") {
              // repairDetails = repair.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startOfWeek).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endOfWeek).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = repairDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsRepairs(monthArray);
              setgraphDataRepairs(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Month") {
              // repairDetails = repair.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startOfMonth).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endOfMonth).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = repairDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsRepairs(monthArray);
              setgraphDataRepairs(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Today") {
              // repairDetails = repair.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") ===
              //     moment(new Date()).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = repairDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsRepairs(monthArray);
              setgraphDataRepairs(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "This Year") {
              // repairDetails = repair.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startOfThisYear).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endOfThisYear).format("DD/MM/YYYY")
              // );
              //----Graph----------------------------------------
              let unique = repairDetails
                .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                .filter((value, index, self) => self.indexOf(value) === index);
              unique.map((uitem) => {
                countsArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ).length
                );
              });
              unique.map((uitem) => {
                monthArray.push(
                  repairDetails.filter(
                    (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                  ) && uitem
                );
              });
              setgraphLabelsRepairs(monthArray);
              setgraphDataRepairs(countsArray);
              //------------------graph-------------------end---------------
            } else if (onDateRangeSelected === "Custom") {
              // repairDetails = repair.filter(
              //   (item) =>
              //     moment(item.createdAt).format("DD/MM/YYYY") >=
              //     moment(startDate).format("DD/MM/YYYY") &&
              //     moment(item.createdAt).format("DD/MM/YYYY") <=
              //     moment(endDate).format("DD/MM/YYYY")
              // );
            }
            setIsLoading(false);
            setRepairDetailsList(repairDetails);
          } else {
            setIsLoading(false);
            setRepairDetailsList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
    {
      /*
    const repair = repairData;
    if (onDateRangeSelected === "All Time") {
      repairDetails = repair.filter((item) => !item._delete);
      //----Graph----------------------------------------
      let unique = repairDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsRepairs(monthArray);
      setgraphDataRepairs(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "This Week") {
      repairDetails = repair.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startOfWeek).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endOfWeek).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = repairDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsRepairs(monthArray);
      setgraphDataRepairs(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "This Month") {
      repairDetails = repair.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startOfMonth).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endOfMonth).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = repairDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsRepairs(monthArray);
      setgraphDataRepairs(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "Today") {
      repairDetails = repair.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") ===
          moment(new Date()).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = repairDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsRepairs(monthArray);
      setgraphDataRepairs(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "This Year") {
      repairDetails = repair.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startOfThisYear).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endOfThisYear).format("DD/MM/YYYY")
      );
      //----Graph----------------------------------------
      let unique = repairDetails
        .map((item) => moment(item.createdAt).format("YYYY-MMM"))
        .filter((value, index, self) => self.indexOf(value) === index);
      unique.map((uitem) => {
        countsArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ).length
        );
      });
      unique.map((uitem) => {
        monthArray.push(
          repairDetails.filter(
            (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
          ) && uitem
        );
      });
      setgraphLabelsRepairs(monthArray);
      setgraphDataRepairs(countsArray);
      //------------------graph-------------------end---------------
    } else if (onDateRangeSelected === "Custom") {
      repairDetails = repair.filter(
        (item) =>
          moment(item.createdAt).format("DD/MM/YYYY") >=
          moment(startDate).format("DD/MM/YYYY") &&
          moment(item.createdAt).format("DD/MM/YYYY") <=
          moment(endDate).format("DD/MM/YYYY")
      );
    }
*/
    }
    return () => client.cancel();
  }
  //--- Repair End ----
  //--- Warranty Start ----
  async function fetchWarantyDetails(sites, onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let warrantyDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);
    // let nextToken = "";
    // let assets = [];
    // await client.graphql({
    //   query: queries.siteDetailsByAssetownerID,
    //   variables: {
    //     assetownerID: LoginUserDetails.id,
    //     limit: 2000000,
    //   },
    //   authMode: "API_KEY",
    // })
    //   .then(async (result) => {
    //     let assetDatas = result.data.siteDetailsByAssetownerID.items;
    //     nextToken = result.data.siteDetailsByAssetownerID.nextToken;
    //     console.log(
    //       "first token count",
    //       result.data.siteDetailsByAssetownerID.items.length
    //     );
    //     console.log("nextToken 1", nextToken);
    //     if (result.data.siteDetailsByAssetownerID.items.length > 0) {
    //       assets.push(assetDatas);
    //     }
    //     while (nextToken !== null) {
    //       await client.graphql({
    //         query: queries.siteDetailsByAssetownerID,
    //         variables: {
    //           assetownerID: LoginUserDetails.id,
    //           limit: 2000000,
    //           nextToken: nextToken,
    //         },
    //         authMode: "API_KEY",
    //       })
    //         .then((results) => {
    //           nextToken = results.data.siteDetailsByAssetownerID.nextToken;
    //           console.log("nextToken", nextToken);
    //           console.log(results.data.siteDetailsByAssetownerID.items.length);
    //           if (results.data.siteDetailsByAssetownerID.items.length > 0) {
    //             assets.push(results.data.siteDetailsByAssetownerID.items);
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //     console.log(assets);
    if (sites.length > 0) {
      const sitedetails = sites;
      // //--
      // const promises = sitedetails.map(async (item) => {
      //   return client.graphql({
      //     query: queries.assetsBySitedetailsID,
      //     variables: {
      //       sitedetailsID: item.id,
      //       filter: {
      //         status: {
      //           ne: "delete",
      //         },
      //       },
      //       limit: 10000,
      //     },
      //   });
      // });
      // Promise.all(promises)
      //   .then(async (results) => {
      //     const testReport = [];
      //     const testArray = [];
      //     results.forEach((result1) => {
      //       const assetList = result1.data.assetsBySitedetailsID.items;
      //       testReport.push(...assetList);
      //     });
      console.log(sites);

      const FilterAssets = sites.filter(
        (item) => item.siteId === selectedSiteGlobal
      );
      console.log(FilterAssets);
      const NewAsset = selectedSiteGlobal ? FilterAssets : sites;

      const warAssets = NewAsset.filter(
        (item) =>
          !item._delete &&
          moment(item.assetWarrantyEnd).format("DD/MM/YYYY") >
          moment(new Date()).format("DD/MM/YYYY")
      );
      console.log("warranty");
      console.log(warAssets);
      setWarrantyDetailsList(warAssets);

      if (onDateRangeSelected === "All Time") {
        warrantyDetails = warAssets.filter((item) => !item._delete);
        //----Graph----------------------------------------
        let unique = warrantyDetails
          .map((item) => moment(item.createdAt).format("YYYY-MMM"))
          .filter((value, index, self) => self.indexOf(value) === index);
        unique.map((uitem) => {
          countsArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ).length
          );
        });
        unique.map((uitem) => {
          monthArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ) && uitem
          );
        });
        setgraphLabelsWarranty(monthArray);
        setgraphDataWarranty(countsArray);
        //------------------graph-------------------end---------------
      } else if (onDateRangeSelected === "This Week") {
        warrantyDetails = warAssets.filter(
          (item) =>
            moment(item.createdAt).format("DD/MM/YYYY") >=
            moment(startOfWeek).format("DD/MM/YYYY") &&
            moment(item.createdAt).format("DD/MM/YYYY") <=
            moment(endOfWeek).format("DD/MM/YYYY")
        );
        //----Graph----------------------------------------
        let unique = warrantyDetails
          .map((item) => moment(item.createdAt).format("YYYY-MMM"))
          .filter((value, index, self) => self.indexOf(value) === index);
        unique.map((uitem) => {
          countsArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ).length
          );
        });
        unique.map((uitem) => {
          monthArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ) && uitem
          );
        });
        setgraphLabelsWarranty(monthArray);
        setgraphDataWarranty(countsArray);
        //------------------graph-------------------end---------------
      } else if (onDateRangeSelected === "This Month") {
        warrantyDetails = warAssets.filter(
          (item) =>
            moment(item.createdAt).format("DD/MM/YYYY") >=
            moment(startOfMonth).format("DD/MM/YYYY") &&
            moment(item.createdAt).format("DD/MM/YYYY") <=
            moment(endOfMonth).format("DD/MM/YYYY")
        );
        //----Graph----------------------------------------
        let unique = warrantyDetails
          .map((item) => moment(item.createdAt).format("YYYY-MMM"))
          .filter((value, index, self) => self.indexOf(value) === index);
        unique.map((uitem) => {
          countsArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ).length
          );
        });
        unique.map((uitem) => {
          monthArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ) && uitem
          );
        });
        setgraphLabelsWarranty(monthArray);
        setgraphDataWarranty(countsArray);
        //------------------graph-------------------end---------------
      } else if (onDateRangeSelected === "Today") {
        warrantyDetails = warAssets.filter(
          (item) =>
            moment(item.createdAt).format("DD/MM/YYYY") ===
            moment(new Date()).format("DD/MM/YYYY")
        );
        //----Graph----------------------------------------
        let unique = warrantyDetails
          .map((item) => moment(item.createdAt).format("YYYY-MMM"))
          .filter((value, index, self) => self.indexOf(value) === index);
        unique.map((uitem) => {
          countsArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ).length
          );
        });
        unique.map((uitem) => {
          monthArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ) && uitem
          );
        });
        setgraphLabelsWarranty(monthArray);
        setgraphDataWarranty(countsArray);
        //------------------graph-------------------end---------------
      } else if (onDateRangeSelected === "This Year") {
        warrantyDetails = warAssets.filter(
          (item) =>
            moment(item.createdAt).format("DD/MM/YYYY") >=
            moment(startOfThisYear).format("DD/MM/YYYY") &&
            moment(item.createdAt).format("DD/MM/YYYY") <=
            moment(endOfThisYear).format("DD/MM/YYYY")
        );
        //----Graph----------------------------------------
        let unique = warrantyDetails
          .map((item) => moment(item.createdAt).format("YYYY-MMM"))
          .filter((value, index, self) => self.indexOf(value) === index);
        unique.map((uitem) => {
          countsArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ).length
          );
        });
        unique.map((uitem) => {
          monthArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ) && uitem
          );
        });
        setgraphLabelsWarranty(monthArray);
        setgraphDataWarranty(countsArray);
        //------------------graph-------------------end---------------
      } else if (onDateRangeSelected === "Custom") {
        warrantyDetails = warAssets.filter(
          (item) =>
            moment(item.createdAt).format("DD/MM/YYYY") >=
            moment(startDate).format("DD/MM/YYYY") &&
            moment(item.createdAt).format("DD/MM/YYYY") <=
            moment(endDate).format("DD/MM/YYYY")
        );
        //----Graph----------------------------------------
        let unique = warrantyDetails
          .map((item) => moment(item.createdAt).format("YYYY-MMM"))
          .filter((value, index, self) => self.indexOf(value) === index);
        unique.map((uitem) => {
          countsArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ).length
          );
        });
        unique.map((uitem) => {
          monthArray.push(
            warrantyDetails.filter(
              (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
            ) && uitem
          );
        });
        setgraphLabelsWarranty(monthArray);
        setgraphDataWarranty(countsArray);
        //------------------graph-------------------end---------------
      }
      setIsLoading(false);
      setWarrantyDetailsList(warrantyDetails);
      // })
      // .catch((error) => {
      //   console.log(error);
      //   //setErrorMessage("Unable to fetch the list");
      // });
    }
    // })
    // .catch((error) => {
    //   console.log(error);
    //   setIsLoading(false);
    // });
    return () => client.cancel();
  }
  //--- Warranty End ----
  //--- Logbook Start ----
  async function fetchLogbookDetails(sitess, onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
    //---custom -----
    const [startDate, endDate] = dateRange;
    let logbookDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);
    //---------------------------------------------------------
    setIsLoading(true);
    //---

    // let nextToken = "";
    // let assets = [];
    // await client.graphql({
    //   query: queries.siteDetailsByAssetownerID,
    //   variables: {
    //     assetownerID: LoginUserDetails.id,
    //     limit: 2000000,
    //   },
    //   authMode: "API_KEY",
    // })
    //   .then(async (result) => {
    //     let assetDatas = result.data.siteDetailsByAssetownerID.items;
    //     nextToken = result.data.siteDetailsByAssetownerID.nextToken;
    //     console.log(
    //       "first token count",
    //       result.data.siteDetailsByAssetownerID.items.length
    //     );
    //     console.log("nextToken 1", nextToken);
    //     if (result.data.siteDetailsByAssetownerID.items.length > 0) {
    //       assets.push(assetDatas);
    //     }
    //     while (nextToken !== null) {
    //       await client.graphql({
    //         query: queries.siteDetailsByAssetownerID,
    //         variables: {
    //           assetownerID: LoginUserDetails.id,
    //           limit: 2000000,
    //           nextToken: nextToken,
    //         },
    //         authMode: "API_KEY",
    //       })
    //         .then((results) => {
    //           nextToken = results.data.siteDetailsByAssetownerID.nextToken;
    //           console.log("nextToken", nextToken);
    //           console.log(results.data.siteDetailsByAssetownerID.items.length);
    //           if (results.data.siteDetailsByAssetownerID.items.length > 0) {
    //             assets.push(results.data.siteDetailsByAssetownerID.items);
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    console.log(sitess);
    console.log(onDateRangeSelected);
    // const filtersitess = sitess.filter(
    //   (item) => item.id === selectedSiteGlobal
    // );
    // const sitessfilter = selectedSiteGlobal ? filtersitess : sitess;
    // console.log(sitessfilter);

    if (sitess.length > 0) {
      const promises = sitess.map(async (item) => {
        return client.graphql({
          query: queries.logBooksBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: 10000,
          },
        });
      });
      Promise.all(promises)
        .then(async (results) => {
          const testReport = [];
          const testArray = [];
          results.forEach((result1) => {
            const assetList = result1.data.logBooksBySitedetailsID.items;
            testReport.push(...assetList);
          });
          console.log(testReport);

          const FilterAssets = testReport.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          console.log(FilterAssets);
          const NewLogbook = selectedSiteGlobal ? FilterAssets : testReport;
          //--fetching repairbyLogbookid
          // fetchRepairDetails(NewLogbook, onDateRangeSelected);

          if (onDateRangeSelected === "All Time") {
            logbookDetails = NewLogbook.filter((item) => !item._delete);
            //----Graph----------------------------------------
            let unique = logbookDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsLogBooks(monthArray);
            setgraphDataLogBooks(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Week") {
            logbookDetails = NewLogbook.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startOfWeek).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endOfWeek).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = logbookDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsLogBooks(monthArray);
            setgraphDataLogBooks(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Month") {
            logbookDetails = NewLogbook.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startOfMonth).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endOfMonth).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = logbookDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsLogBooks(monthArray);
            setgraphDataLogBooks(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Today") {
            logbookDetails = NewLogbook.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") ===
                moment(new Date()).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = logbookDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsLogBooks(monthArray);
            setgraphDataLogBooks(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Year") {
            logbookDetails = NewLogbook.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startOfThisYear).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endOfThisYear).format("DD/MM/YYYY")
            );
            //----Graph----------------------------------------
            let unique = logbookDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                logbookDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsLogBooks(monthArray);
            setgraphDataLogBooks(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Custom") {
            logbookDetails = NewLogbook.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") >=
                moment(startDate).format("DD/MM/YYYY") &&
                moment(item.createdAt).format("DD/MM/YYYY") <=
                moment(endDate).format("DD/MM/YYYY")
            );
          }
          console.log(logbookDetails);
          setLogBookDetailsList(logbookDetails);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          //setErrorMessage("Unable to fetch the list");
        });
    }
    return () => client.cancel();
  }
  //--- Logbook End ----
  //--- SiteUser Start ----
  async function fetchSiteUserDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let siteUserDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);

    //------------------------------------------------------
    console.log("ao related site user");

    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listLinkUsersAndSites,
        authMode: "API_KEY",
        variables: {
          limit: 20000,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
      })
      .then(async (result) => {
        let assetDatas = result.data.listLinkUsersAndSites.items;
        nextToken = result.data.listLinkUsersAndSites.nextToken;
        if (result.data.listLinkUsersAndSites.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listLinkUsersAndSites,
              authMode: "API_KEY",
              variables: {
                limit: 20000,
                nextToken: nextToken,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listLinkUsersAndSites.nextToken;
              if (results.data.listLinkUsersAndSites.items.length > 0) {
                assets.push(results.data.listLinkUsersAndSites.items);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const linkedUsers = mergeAssets;
          const spDetailss = linkedUsers.filter((item) => {
            const _matchSites = siteDetailsGlobal?.find(
              (site) => site.id === item?.sitedetailsID
            );
            if (_matchSites) {
              return item;
            }
          });
          console.log(spDetailss);
          const filterSites = spDetailss.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const filtersitesss = selectedSiteGlobal ? filterSites : spDetailss;
          console.log(siteDetailsGlobal);
          console.log(filtersitesss);
          //--
          let nextToken1 = "";
          let assets1 = [];
          await client
            .graphql({
              query: queries.listSPUserDetails,
              authMode: "API_KEY",
              variables: {
                filter: {
                  userGroup: {
                    eq: "site_user",
                  },
                },
                limit: 200000,
              },
            })
            .then(async (result) => {
              let assetDatas = result.data.listSPUserDetails.items;
              nextToken1 = result.data.listSPUserDetails.nextToken;
              if (result.data.listSPUserDetails.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await client
                  .graphql({
                    query: queries.listSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      filter: {
                        userGroup: {
                          eq: "site_user",
                        },
                      },
                      limit: 200000,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken1 = results.data.listSPUserDetails.nextToken;
                    if (results.data.listSPUserDetails.items.length > 0) {
                      assets1.push(results.data.listSPUserDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                const spDetails2 = mergeAssets1;
                const aoSP = spDetails2.filter((item) => {
                  const _matchSites = filtersitesss?.find(
                    (linkUser) => linkUser.userdetailsID === item?.id
                  );
                  if (_matchSites) {
                    return item;
                  }
                });
                console.log(aoSP);

                const filterSites = aoSP.filter(
                  (item) => item.id === selectedSiteGlobal
                );
                const sortedItems = aoSP.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                const sortedItems2 = aoSP.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortedItems);
                //const spDetails = sortedItems2;
                //console.log(spDetails);
                const SpUsers = sortedItems2;
                if (onDateRangeSelected === "This Week") {
                  siteUserDetails = SpUsers.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") >=
                      moment(startOfWeek).format("DD/MM/YYYY") &&
                      moment(item.createdAt).format("DD/MM/YYYY") <=
                      moment(endOfWeek).format("DD/MM/YYYY")
                  );
                  //----Graph----------------------------------------
                  let unique = siteUserDetails
                    .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  unique.map((uitem) => {
                    countsArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ).length
                    );
                  });
                  unique.map((uitem) => {
                    monthArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ) && uitem
                    );
                  });
                  setgraphLabelsSiteUsers(monthArray);
                  setgraphDataSiteUsers(countsArray);
                  //------------------graph-------------------end---------------
                } else if (onDateRangeSelected === "This Month") {
                  siteUserDetails = SpUsers.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") >=
                      moment(startOfMonth).format("DD/MM/YYYY") &&
                      moment(item.createdAt).format("DD/MM/YYYY") <=
                      moment(endOfMonth).format("DD/MM/YYYY")
                  );
                  //----Graph----------------------------------------
                  let unique = siteUserDetails
                    .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  unique.map((uitem) => {
                    countsArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ).length
                    );
                  });
                  unique.map((uitem) => {
                    monthArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ) && uitem
                    );
                  });
                  setgraphLabelsSiteUsers(monthArray);
                  setgraphDataSiteUsers(countsArray);
                  //------------------graph-------------------end---------------
                } else if (onDateRangeSelected === "Today") {
                  siteUserDetails = SpUsers.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") ===
                      moment(new Date()).format("DD/MM/YYYY")
                  );
                  //----Graph----------------------------------------
                  let unique = siteUserDetails
                    .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  unique.map((uitem) => {
                    countsArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ).length
                    );
                  });
                  unique.map((uitem) => {
                    monthArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ) && uitem
                    );
                  });
                  setgraphLabelsSiteUsers(monthArray);
                  setgraphDataSiteUsers(countsArray);
                  //------------------graph-------------------end---------------
                } else if (onDateRangeSelected === "This Year") {
                  siteUserDetails = SpUsers.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") >=
                      moment(startOfThisYear).format("YYYY-MM-DD") &&
                      moment(item.createdAt).format("DD/MM/YYYY") <=
                      moment(endOfThisYear).format("DD/MM/YYYY")
                  );
                  //----Graph----------------------------------------
                  let unique = siteUserDetails
                    .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  unique.map((uitem) => {
                    countsArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ).length
                    );
                  });
                  unique.map((uitem) => {
                    monthArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ) && uitem
                    );
                  });
                  setgraphLabelsSiteUsers(monthArray);
                  setgraphDataSiteUsers(countsArray);
                  //------------------graph-------------------end---------------
                } else if (onDateRangeSelected === "Custom") {
                  siteUserDetails = SpUsers.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") >=
                      moment(startDate).format("DD/MM/YYYY") &&
                      moment(item.createdAt).format("DD/MM/YYYY") <=
                      moment(endDate).format("DD/MM/YYYY")
                  );
                  //----Graph----------------------------------------
                  let unique = siteUserDetails
                    .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  unique.map((uitem) => {
                    countsArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ).length
                    );
                  });
                  unique.map((uitem) => {
                    monthArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ) && uitem
                    );
                  });
                  setgraphLabelsSiteUsers(monthArray);
                  setgraphDataSiteUsers(countsArray);
                  //------------------graph-------------------end---------------
                } else if (onDateRangeSelected === "All Time") {
                  siteUserDetails = SpUsers;
                  //----Graph----------------------------------------
                  let unique = siteUserDetails
                    .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                    .filter(
                      (value, index, self) => self.indexOf(value) === index
                    );
                  unique.map((uitem) => {
                    countsArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ).length
                    );
                  });
                  unique.map((uitem) => {
                    monthArray.push(
                      siteUserDetails.filter(
                        (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                      ) && uitem
                    );
                  });
                  setgraphLabelsSiteUsers(monthArray);
                  setgraphDataSiteUsers(countsArray);
                  //------------------graph-------------------end---------------
                }
                setListofSiteUsers(siteUserDetails);
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    //----
    return () => client.cancel();
  }
  //--- siteuser End --------
  const site = () => {
    navigate(`${process.env.PUBLIC_URL}/sites`);
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <Row>
          <Card className="custom-card">
            <CardHeader className="pb-0"></CardHeader>
            <CardBody>
              <Col>
                <H5>Date Range Picker</H5>
                <span>
                  {" "}
                  <DateRangePicker
                    onDateRangeSelected={onDateRangeSelected}
                  ></DateRangePicker>{" "}
                </span>
              </Col>
            </CardBody>
          </Card>
        </Row>
        <br></br>
        <Row>
          <Col md="9"></Col>
          <Col md="3" className="d-flex justify-content-end">
            <Btn attrBtn={{ color: "primary", onClick: site }}>
              Get Started With Site Set Up
            </Btn>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Row>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{siteDetails.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/sites`}>Sites</Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {siteDetails.length > 0 && <Line data={chartSites} />}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        <div className="media-end">
                          <H5>{assetsCount > 0 ? assetsCount : 0}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/catassetlist`}>Assets</Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {assetsCount > 0 && <Line data={chartAssets} />}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{assetOwnerDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/siteownerpanel/serviceprovider`}>
                          Pending Invitations
                        </Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {assetOwnerDetailsList.length > 0 && (
                      <Line data={chartPendingInvitations} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{listofSiteUsers.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/siteownerpanel/siteUser`}>Site Users</Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {listofSiteUsers.length > 0 && (
                      <Line data={chartSiteUsers} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{listofServiceProviders.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/siteownerpanel/serviceprovider`}>
                          Service Providers
                        </Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {listofServiceProviders.length > 0 && (
                      <Line data={chartSP} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{testingDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>Tests</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {testingDetailsList.length > 0 && (
                      <Line data={chartTests} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{repairDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>Repairs</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {repairDetailsList.length > 0 && (
                      <Line data={chartRepairs} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{logBookDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/generatereportfasset`}>Logbooks</Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {logBookDetailsList.length > 0 && (
                      <Line data={chartLogBooks} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{warantyDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>Warranty</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    {warantyDetailsList.length > 0 && (
                      <Line data={chartWarranty} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Row>
        <br></br>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};
export default AssetOwnerDashboard;
