import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Card,
  CardBody,
  CardHeader,
  Table,
  Media,
} from "reactstrap";
import Greeting from "./Greeting";
import { generateClient } from "aws-amplify/api";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import * as queries from "../../../graphql/queries";
import * as mergedQueries from "../../../graphql/mergedQueries";
import SPCompany from "../../../Data/SPCompanyData/index";
import SiteData from "../../../Data/Sites/index";
import CategoryData from "../../../Data/Category/index";
import AssetTemplate from "../../../Data/AssetTemplate/index";
import SiteDBData from "../../../Data/DistributionBoard/index";
import { limit } from "../../../Config/Config";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import avtar from "../../../assets/images/avatar.jpg";
import { Bar } from "react-chartjs-2";
import { H6, Image, H4, H5, P } from "../../../AbstractElements";
import { SiteContext } from "../../../App";
import { aocontext } from "../../../App";
import ScrollBar from "react-perfect-scrollbar";
import moment from "moment";
var base64 = require("base-64");

const DefaultContain = () => {
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");
  const [isLoading, setIsLoading] = useState(false);
  const [nextTesting, setNextTesting] = useState([]);
  const [repair, setRepair] = useState([]);
  const [tasks, settasks] = useState([]);
  const [testing, setTesting] = useState([]);
  const [testingAsset, setTestingAsset] = useState([]);
  const currentDate = new Date();
  const options1 = { day: "2-digit", month: "2-digit", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-AU", options1);

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);

  const client = generateClient({
    headers: {
      "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
    },
  });

  //---
  //-------single Calendar-----
  const [dateRange, setDateRange] = useState([null, null]);
  function SingleCalendar() {
    const [startDate, endDate] = dateRange;
    const handleDateRangeChange = (dates) => {
      if (dates && dates.length === 2) {
        setDateRange(dates);
      }
    };
    return (
      <Fragment>
        <Row className="d-flex justify-content-end">
          <Col xl="6">
            <div className="daterange-card">
              <div className="mb-3">
                <FormGroup>
                  <DatePicker
                    className="form-control digits"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateRangeChange}
                    isClearable={true}
                    placeholderText="Enter Date Range"
                    dateFormat="dd-MM-yyyy"
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
  //-------------Daterange Start-----------------------------------------------------
  const [selectedOption, setSelectedOption] = useState("All Time");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);
  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };
  const onDateRangeSelected = (range) => {
    //setSelectedOption("");
    handleDateRangeSelected(range);
  };
  function DateRangePicker({ onDateRangeSelected }) {
    //const [selectedOption, setSelectedOption] =  useState('');

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(event.target.value);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());

          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());

          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());

          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());

          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());

          break;

        default:
          break;
      }
    };
    //---------------------------------------------------------------------------
    const getTodayRange = () => { };
    //---------------------------------------------------------------------------
    const getAllTimeRange = () => {
      const start = new Date(0);
      const end = new Date();
      const controller = new AbortController();
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [start, end];
    };
    //---------------------------------------------------------------------------
    const getWeekRange = () => {
      const currentDate = new Date();
      const currentDayOfWeek = currentDate.getDay();
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDayOfWeek
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDayOfWeek)
      );

      const today = new Date();
      console.log(today);
      console.log(moment(startOfWeek).format("DD/MM/YYYY"));

      console.log(endOfWeek);
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfWeek, endOfWeek];
    };
    //-----------------------------------------------------------------------
    const getMonthRange = () => {
      console.log("month range");
      const currentDate = new Date();
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfMonth, endOfMonth];
    };
    //---------------------------------------------------------------------------
    const getThisYearRange = () => {
      const currentDate = new Date();
      const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
      const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfThisYear, endOfThisYear];
    };
    //---------------------------------------------------------------------------------
    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                {/* <option
                  value="All Time"
                  selected={selectedOption === "All Time"}
                >
                  All Time
                </option> */}
                <option value="Today" selected={selectedOption === "Today"}>
                  Today
                </option>
                <option
                  value="This Week"
                  selected={selectedOption === "This Week"}
                >
                  This Week
                </option>
                <option
                  value="This Month"
                  selected={selectedOption === "This Month"}
                >
                  This Month
                </option>
                <option
                  value="This Year"
                  selected={selectedOption === "This Year"}
                >
                  This Year
                </option>
                <option value="Custom" selected={selectedOption === "Custom"}>
                  Custom
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <SingleCalendar onDateRangeSelected={onDateRangeSelected} />
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  useEffect(() => {
    //
    fetchCategory();
    fetchAssetTemplate();

    if (LoginUserGroup === "site_user") {
      fetchSPCompanySU(LoginUserDetails);
      fetchSiteDetailsSU();
    } else if (LoginUserGroup === "electrician") {
      fetchSPCompanySP(LoginUserDetails.id);
      fetchSiteDetailsSP(LoginUserDetails.id);
    }
    //fetchAssets();
    if (LoginUserGroup === "electrician") {
      // fetchTasksSPLogin();
    } else {
      // fetchTasks();
    }
  }, []);

  //---spuser
  async function fetchSpuser(sites) {
    //--
    try {
      let userIds = [];
      let nextToken1 = "";
      console.log(sites);
      const filterSites = sites.filter(
        (item) => item.id === selectedSiteGlobal
      );
      const filtersitesss = selectedSiteGlobal ? filterSites : sites;
      if (filtersitesss.length > 0) {
        for (let site of filtersitesss) {
          const getSiteIds = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: site.id,
              filter: {
                status: {
                  eq: "Active",
                },
              },
            },
          });
          console.log(getSiteIds);
          if (
            getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length > 0
          ) {
            nextToken1 =
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
            userIds.push(
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
            );
            while (nextToken1 !== null) {
              const getSiteIds1 = await client.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.id,
                  filter: {
                    status: {
                      eq: "Active",
                    },
                  },
                  nextToken: nextToken1,
                },
              });
              console.log(getSiteIds1);
              if (
                getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items.length >
                0
              ) {
                nextToken1 =
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                );
              }
            }
            console.log(userIds);
            if (userIds.length > 0) {
              const mergeUserIds = userIds.flat(1);
              const response1 = await client.graphql({
                query: queries.listSPUserDetails,
                authMode: "API_KEY",
                variables: {
                  // filter: {
                  //   userGroup: {
                  //     eq: "electrician",
                  //   },
                  // },
                  limit: 200000,
                },
              });
              console.log(response1);
              if (response1.data.listSPUserDetails.items.length > 0) {
                const spDetails2 =
                  response1.data.listSPUserDetails.items.filter((item) => {
                    const _matchSites = mergeUserIds?.find(
                      (user) => user.userdetailsID === item?.id
                    );
                    if (_matchSites) {
                      return item;
                    }
                  });
                console.log(spDetails2);
                const sortedItems = spDetails2.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortedItems);
                localStorage.setItem(
                  "spuser",
                  base64.encode(JSON.stringify(sortedItems))
                );
              }
            } else {
            }
          }
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSPCompanySU(user) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.linkAOAPCompaniesBySpaoId,
        authMode: "API_KEY",
        variables: {
          spaoId: user.id,
        },
      });
      console.log("getLinkAOAPCompanyDetails", user.invitedBy);
      console.log("getLinkAOAPCompanyDetails", getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.linkAOAPCompaniesBySpaoId.items.length >
        0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.linkAOAPCompaniesBySpaoId.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.linkAOAPCompaniesBySpaoId.items
            )
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function fetchSPCompanySP(sPId) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: sPId,
            },
          },
        },
      });
      console.log("getLinkAOAPCompanyDetails", sPId);
      console.log("getLinkAOAPCompanyDetails", getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function fetchCategory() {
    try {
      let nextToken = "";
      let categories = [];
      const dataDetails = await client.graphql({
        query: queries.listCategories,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (dataDetails.data.listCategories.items.length > 0) {
        let assetDatas = dataDetails.data.listCategories.items;
        nextToken = dataDetails.data.listCategories.nextToken;
        if (dataDetails.data.listCategories.items.length > 0) {
          categories.push(assetDatas);
        }
        while (nextToken !== null) {
          const dataDetailss = await client.graphql({
            query: queries.listCategories,
            variables: {
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (dataDetailss.data.listCategories.items.length > 0) {
            nextToken = dataDetailss.data.listCategories.nextToken;
            if (dataDetailss.data.listCategories.items.length > 0) {
              categories.push(dataDetailss.data.listCategories.items);
            }
          }
        }
        console.log(categories);
        if (categories.length > 0) {
          const mergeCategories = categories.flat(1);
          localStorage.setItem(
            "category",
            base64.encode(JSON.stringify(mergeCategories))
          );
          CategoryData.setCategoryData(mergeCategories);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      CategoryData.setCategoryData([]);
      setIsLoading(false);
    }
  }

  async function fetchAssetTemplate() {
    try {
      let nextToken = "";
      let assetTemplates = [];
      const dataDetails = await client.graphql({
        query: queries.listAssetTemplates,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      });
      if (dataDetails.data.listAssetTemplates.items.length > 0) {
        let assetDatas = dataDetails.data.listAssetTemplates.items;
        nextToken = dataDetails.data.listAssetTemplates.nextToken;
        if (dataDetails.data.listAssetTemplates.items.length > 0) {
          assetTemplates.push(assetDatas);
        }
        while (nextToken !== null) {
          const dataDetailss = await client.graphql({
            query: queries.listAssetTemplates,
            variables: {
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (dataDetailss.data.listAssetTemplates.items.length > 0) {
            nextToken = dataDetailss.data.listAssetTemplates.nextToken;
            if (dataDetailss.data.listAssetTemplates.items.length > 0) {
              assetTemplates.push(dataDetailss.data.listAssetTemplates.items);
            }
          }
        }
        console.log(assetTemplates);
        if (assetTemplates.length > 0) {
          const mergeAssetTemplates = assetTemplates.flat(1);
          localStorage.setItem(
            "assetTemp",
            base64.encode(JSON.stringify(mergeAssetTemplates))
          );
          AssetTemplate.setAssetTemplate(mergeAssetTemplates);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      AssetTemplate.setAssetTemplate([]);
      setIsLoading(false);
    }
  }
  async function fetchSiteDetailsSU() {
    try {
      //setIsLoading(true);
      const response = await client.graphql({
        query: mergedQueries.getSiteUsersFromLink,
        authMode: "API_KEY",
        variables: {
          //nextToken: '',
          assetownerID: LoginUserDetails.assetOwnerId,
          userdetailsID: LoginUserDetails.id,
        },
      });
      console.log(response);
      let nextToken = "";
      let sitess = [];
      if (response.data.siteDetailsByAssetownerID.items.length > 0) {
        let sites = response.data.siteDetailsByAssetownerID.items;
        nextToken = response.data.siteDetailsByAssetownerID.nextToken;
        for (let site of sites) {
          console.log(site);
          console.log(site.LinkUsersAndSites.items.length);
          if (site.LinkUsersAndSites.items.length > 0) {
            sitess.push(site);
          }
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: mergedQueries.getSiteUsersFromLink,
            variables: {
              nextToken: nextToken,
              assetownerID: LoginUserDetails.assetOwnerId,
              userdetailsID: LoginUserDetails.id,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          sites = results.data.siteDetailsByAssetownerID.items;
          for (let site of sites) {
            //console.log(site);
            //console.log(site.LinkUsersAndSites.items.length);
            if (site.LinkUsersAndSites.items.length > 0) {
              sitess.push(site);
            }
          }
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);

          fetchlistDistributionBox(mergeSites);
          //fetchSpuser(mergeSites);
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  //--sites for sp/su
  async function fetchSiteDetailsSP(assetOwnerId) {
    //
    let nextToken = "";
    let sites = [];
    const result = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: assetOwnerId,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
    });
    if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
      nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
      sites.push(assetDatas);
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: assetOwnerId,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
            nextToken: nextToken,
          },
        });
        nextToken = results.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (results.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          sites.push(results.data.linkUsersAndSitesByUserdetailsID.items);
        }
      }
      console.log(sites);
      if (sites.length > 0) {
        const mergesites = sites.flat(1);
        let sitesforUsers = [];
        const promises = mergesites.map(async (item) => {
          return await client.graphql({
            query: queries.getSiteDetails,
            variables: {
              id: item.sitedetailsID,
            },
            authMode: "API_KEY",
          });
        });
        Promise.all(promises).then((sitesData) => {
          sitesData.forEach((sites) => {
            if (sites.data.getSiteDetails !== null) {
              sitesforUsers.push(sites.data.getSiteDetails);
            }
          });
          fetchlistDistributionBox(sitesforUsers);
          //fetchSpuser(sitesforUsers);
          // setSites(sitesforUsers);
          // setSiteDetails(sitesforUsers);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(sitesforUsers))
          );
          //SiteData.setSiteData(sitesforUsers);
        });
        // for (let site of mergesites) {
        //   const sitesData = await client.graphql({
        //     query: queries.getSiteDetails,
        //     variables: {
        //       id: site.id,
        //     },
        //     authMode: "API_KEY",
        //   });
        //   if (sitesData.data.getSiteDetails !== null) {
        //     sitesforUsers.push(sitesData.data.getSiteDetails);
        //     setSites(sitesforUsers);
        //     setSiteDetails(sitesforUsers);
        //     localStorage.setItem(
        //       "site",
        //       base64.encode(JSON.stringify(sitesforUsers))
        //     );
        //     SiteData.setSiteData(sitesforUsers);
        //   }
        // }
      }
    }
  }

  async function fetchlistDistributionBox(siteData) {
    try {
      const promises = siteData.map((item) => {
        return client.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
        });
      });
      Promise.all(promises)
        .then(async (results) => {
          console.log(results);
          let SiteDB = [];
          results.map((items) => {
            if (items.data.distributionBoxesBySitedetailsID.items.length > 0) {
              SiteDB.push(items.data.distributionBoxesBySitedetailsID.items);
            }
          });
          if (SiteDB.length > 0) {
            console.log(SiteDB.flat(1));
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(SiteDB.flat(1)))
            );
            SiteDBData.setSiteDBData(SiteDB.flat(1));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  // site user testings
  async function fetchAssets() {
    const dataDetails = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
    });
    if (dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      const siteDetails =
        dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
      if (siteDetails.length > 0) {
        const promises = siteDetails.map(async (item) => {
          return client.graphql({
            query: queries.getSiteDetails,
            variables: {
              id: item.sitedetailsID,
            },
          });
        });
        Promise.all(promises).then(async (results) => {
          console.log(results);
          let siteDetail = [];
          if (results.length > 0) {
            results.map((site) => {
              if (site.data.getSiteDetails !== null)
                siteDetail.push(site.data.getSiteDetails);
            });
            const data = siteDetail.filter(
              (item) => item.assetownerID === selectedAssetOwnerGlobal
            );
            const filterdata = selectedAssetOwnerGlobal ? data : siteDetail;
            console.log(filterdata);
            let siteIds = [];
            if (filterdata.length > 0) {
              // filterdata.map((item) => {
              //   siteIds.push(item.id);
              // });
              //fetchTestingss(filterdata);
              console.log(siteIds);
              let filter = {};
              if (selectedOption === "Today") {
                filter = {
                  createdAt: {
                    ge: moment().startOf("day").toISOString(),
                    le: moment().endOf("day").toISOString(),
                  },
                };
              } else if (selectedOption === "This Week") {
                filter = {
                  createdAt: {
                    ge: moment().startOf("week").toISOString(),
                    le: moment().endOf("week").toISOString(),
                  },
                };
              } else if (selectedOption === "This Month") {
                filter = {
                  createdAt: {
                    ge: moment().startOf("month").toISOString(),
                    le: moment().endOf("month").toISOString(),
                  },
                };
              } else if (selectedOption === "This Year") {
                filter = {
                  createdAt: {
                    ge: moment().startOf("year").toISOString(),
                    le: moment().endOf("year").toISOString(),
                  },
                };
              } else if (selectedOption === "Custom") {
                // filter = {
                //   createdAt: {
                //     ge: moment(startDate).startOf("day").toISOString(),
                //     le: moment(endDate).endOf("day").toISOString(),
                //   },
                // };
              }
              console.log("filter", filter);
              const promises = filterdata.map(async (siteee) => {
                return client.graphql({
                  query: queries.assetsBySitedetailsID,
                  variables: {
                    sitedetailsID: siteee.id,
                    filter: filter,
                    limit: limit,
                  },
                });
              });
              Promise.all(promises)
                .then((results) => {
                  const testReport1 = [];
                  results.forEach((result1) => {
                    const assetList = result1.data.assetsBySitedetailsID.items;
                    console.log(assetList);
                    if (assetList.length > 0) {
                      console.log(assetList);
                      const filtersites = assetList.filter(
                        (item) => item.sitedetailsID === selectedSiteGlobal
                      );
                      console.log(filtersites);
                      const data = selectedSiteGlobal ? filtersites : assetList;
                      testReport1.push(...data);
                    }
                  });
                  console.log("final data");
                  setTestingAsset(testReport1);
                  console.log(testReport1);
                  ////fetchTestingss(testReport1)
                  if (testReport1.length > 0) {
                    ////fetchTestingss(testReport1);
                    //fetchRepairss(testReport1);
                  } else {
                    setNextTesting([]);
                    setRepair([]);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              setNextTesting([]);
              setRepair([]);
            }
          }
        });
      }
    }
  }

  //---repair
  async function fetchRepairss(assets) {
    if (assets.length > 0) {
      let nextToken = "";
      let repair = [];
      for (let item of assets) {
        const result = await client.graphql({
          query: queries.repairsByAssetsID,
          variables: {
            assetsID: item.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.repairsByAssetsID.items.length > 0) {
          let assetDatas = result.data.repairsByAssetsID.items;
          nextToken = result.data.repairsByAssetsID.nextToken;
          if (result.data.repairsByAssetsID.items.length > 0) {
            repair.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await client.graphql({
              query: queries.repairsByAssetsID,
              variables: {
                limit: limit,
                assetsID: item.id,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.repairsByAssetsID.items.length > 0) {
              nextToken = results.data.repairsByAssetsID.nextToken;
              if (results.data.repairsByAssetsID.items.length > 0) {
                repair.push(results.data.repairsByAssetsID.items);
              }
            }
          }
          console.log(repair);
          if (repair.length > 0) {
            const mergeAssets = repair.flat(1);
            console.log(mergeAssets);
            setRepair(mergeAssets);
          } else {
            setRepair([]);
          }
        }
      }
    }
  }

  //---testingssss
  async function fetchTestingss(assets) {
    if (assets.length > 0) {
      let nextToken = "";
      let testing = [];
      let filter = {};
      if (selectedOption === "Today") {
        filter = {
          createdAt: {
            ge: moment().startOf("day").toISOString(),
            le: moment().endOf("day").toISOString(),
          },
        };
      } else if (selectedOption === "This Week") {
        filter = {
          createdAt: {
            ge: moment().startOf("week").toISOString(),
            le: moment().endOf("week").toISOString(),
          },
        };
      } else if (selectedOption === "This Month") {
        filter = {
          createdAt: {
            ge: moment().startOf("month").toISOString(),
            le: moment().endOf("month").toISOString(),
          },
        };
      } else if (selectedOption === "This Year") {
        filter = {
          createdAt: {
            ge: moment().startOf("year").toISOString(),
            le: moment().endOf("year").toISOString(),
          },
        };
      } else if (selectedOption === "Custom") {
        // filter = {
        //   createdAt: {
        //     ge: moment(startDate).startOf("day").toISOString(),
        //     le: moment(endDate).endOf("day").toISOString(),
        //   },
        // };
      }
      console.log("filter", filter);
      for (let item of assets) {
        const result = await client.graphql({
          query: queries.testingsBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            filter: filter,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.testingsBySitedetailsID.items.length > 0) {
          let assetDatas = result.data.testingsBySitedetailsID.items;
          nextToken = result.data.testingsBySitedetailsID.nextToken;
          if (result.data.testingsBySitedetailsID.items.length > 0) {
            testing.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await client.graphql({
              query: queries.testingsBySitedetailsID,
              variables: {
                limit: limit,
                sitedetailsID: item.id,
                filter: filter,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.testingsBySitedetailsID.items.length > 0) {
              nextToken = results.data.testingsBySitedetailsID.nextToken;
              if (results.data.testingsBySitedetailsID.items.length > 0) {
                testing.push(results.data.testingsBySitedetailsID.items);
              }
            }
          }
          console.log(testing);
          if (testing.length > 0) {
            const mergeAssets = testing.flat(1);
            console.log(mergeAssets);
            setNextTesting(mergeAssets);
            setTesting(mergeAssets);
          } else {
            setNextTesting([]);
            setTesting([]);
          }
        }
      }
    }
  }

  // pending tasks
  async function fetchTasks() {
    //---
    const response3 = await client.graphql({
      query: queries.linkUsersAndSitesByUserdetailsID,
      authMode: "API_KEY",
      variables: {
        userdetailsID: LoginUserDetails.id,
        filter: {
          status: {
            eq: "Active",
          },
        },
        limit: limit,
      },
    });
    if (response3.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      console.log(response3);
      const siteDetails = response3.data.linkUsersAndSitesByUserdetailsID.items;
      if (siteDetails.length > 0) {
        //---
        let nextToken = "";
        let assets = [];
        let filter = {};
        if (selectedOption === "Today") {
          filter = {
            createdAt: {
              ge: moment().startOf("day").toISOString(),
              le: moment().endOf("day").toISOString(),
            },
            taskStatus: {
              ne: "Pending",
            },
          };
        } else if (selectedOption === "This Week") {
          filter = {
            createdAt: {
              ge: moment().startOf("week").toISOString(),
              le: moment().endOf("week").toISOString(),
            },
            taskStatus: {
              ne: "Pending",
            },
          };
        } else if (selectedOption === "This Month") {
          filter = {
            createdAt: {
              ge: moment().startOf("month").toISOString(),
              le: moment().endOf("month").toISOString(),
            },
            taskStatus: {
              ne: "Pending",
            },
          };
        } else if (selectedOption === "This Year") {
          filter = {
            createdAt: {
              ge: moment().startOf("year").toISOString(),
              le: moment().endOf("year").toISOString(),
            },
            taskStatus: {
              ne: "Pending",
            },
          };
        } else if (selectedOption === "Custom") {
          // filter = {
          //   createdAt: {
          //     ge: moment(startDate).startOf("day").toISOString(),
          //     le: moment(endDate).endOf("day").toISOString(),
          //   },
          // };
        }
        console.log("filter", filter);
        for (let site of siteDetails) {
          const result = await client.graphql({
            query: queries.tasksBySitedetailsID,
            variables: {
              sitedetailsID: site.sitedetailsID,
              filter: filter,
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.tasksBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.tasksBySitedetailsID.items;
            nextToken = result.data.tasksBySitedetailsID.nextToken;
            if (result.data.tasksBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              const results = await client.graphql({
                query: queries.tasksBySitedetailsID,
                variables: {
                  sitedetailsID: site.sitedetailsID,
                  filter: filter,
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              if (results.data.tasksBySitedetailsID.items.length > 0) {
                nextToken = results.data.tasksBySitedetailsID.nextToken;
                if (results.data.tasksBySitedetailsID.items.length > 0) {
                  assets.push(results.data.tasksBySitedetailsID.items);
                }
              }
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              //---
              const sortItemsnew = mergeAssets.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              const filterTasks = mergeAssets.filter((item) => {
                const siteArray = JSON.parse(item.taskCompletionLink).map(
                  (link) => link.site
                );
                return siteArray.includes(selectedSiteGlobal);
              });
              console.log(sortItemsnew);
              const sortItems = selectedSiteGlobal ? filterTasks : sortItemsnew;
              console.log(sortItems);
              settasks(sortItems);

              //--
            } else {
              settasks([]);
            }
          }
        }
      } else {
        settasks([]);
      }
    }
  }

  async function fetchTasksSPLogin() {
    //---
    let nextToken = "";
    let assets = [];
    let filter = {};
    if (selectedOption === "Today") {
      filter = {
        createdAt: {
          ge: moment().startOf("day").toISOString(),
          le: moment().endOf("day").toISOString(),
        },
        taskStatus: {
          ne: "Pending",
        },
      };
    } else if (selectedOption === "This Week") {
      filter = {
        createdAt: {
          ge: moment().startOf("week").toISOString(),
          le: moment().endOf("week").toISOString(),
        },
        taskStatus: {
          ne: "Pending",
        },
      };
    } else if (selectedOption === "This Month") {
      filter = {
        createdAt: {
          ge: moment().startOf("month").toISOString(),
          le: moment().endOf("month").toISOString(),
        },
        taskStatus: {
          ne: "Pending",
        },
      };
    } else if (selectedOption === "This Year") {
      filter = {
        createdAt: {
          ge: moment().startOf("year").toISOString(),
          le: moment().endOf("year").toISOString(),
        },
        taskStatus: {
          ne: "Pending",
        },
      };
    } else if (selectedOption === "Custom") {
      // filter = {
      //   createdAt: {
      //     ge: moment(startDate).startOf("day").toISOString(),
      //     le: moment(endDate).endOf("day").toISOString(),
      //   },
      // };
    }
    console.log("filter", filter);
    const result = await client.graphql({
      query: queries.tasksBySpcompanydetailsID,
      variables: {
        spcompanydetailsID: LoginUserDetails.companyDetailsId,
        filter: filter,
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.tasksBySpcompanydetailsID.items.length > 0) {
      let assetDatas = result.data.tasksBySpcompanydetailsID.items;
      nextToken = result.data.tasksBySpcompanydetailsID.nextToken;
      if (result.data.tasksBySpcompanydetailsID.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.tasksBySpcompanydetailsID,
          variables: {
            spcompanydetailsID: LoginUserDetails.companyDetailsId,
            filter: filter,
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.tasksBySpcompanydetailsID.items.length > 0) {
          nextToken = results.data.tasksBySpcompanydetailsID.nextToken;
          if (results.data.tasksBySpcompanydetailsID.items.length > 0) {
            assets.push(results.data.tasksBySpcompanydetailsID.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //---
        const filterTasks = mergeAssets.filter((item) => {
          const siteArray = JSON.parse(item.taskCompletionLink).map(
            (link) => link.site
          );
          return siteArray.includes(selectedSiteGlobal);
        });

        const sortItemsnew = mergeAssets.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        console.log(sortItemsnew);
        const sortItems = selectedSiteGlobal ? filterTasks : sortItemsnew;
        console.log(sortItems);
        settasks(sortItems);

        //--
      } else {
        settasks([]);
      }
    }
  }

  const groupedData = nextTesting.reduce((acc, item) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    if (acc[date]) {
      acc[date] += 1;
    } else {
      acc[date] = 1;
    }
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(groupedData),
    datasets: [
      {
        label: "Number of Tests",
        data: Object.values(groupedData),
        backgroundColor: "rgba(0,122,255,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  // repair
  const groupedData1 = repair.reduce((acc, item) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    if (acc[date]) {
      acc[date] += 1;
    } else {
      acc[date] = 1;
    }
    return acc;
  }, {});

  const chartData1 = {
    labels: Object.keys(groupedData1),
    datasets: [
      {
        label: "Number of Repairs",
        data: Object.values(groupedData1),
        backgroundColor: "rgba(0,122,255,1)",
        borderWidth: 1,
      },
    ],
  };

  function calculateDaysDifference(createdAt) {
    const today = new Date();
    const creationDate = new Date(createdAt);
    const diffInMs = today - creationDate;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  const getAssetsMOdel = (id) => {
    const asset = testingAsset.filter((item) => {
      return item.id === id;
    });
    if (asset.length > 0) {
      return asset[0].assetModel;
    } else {
      return "";
    }
  };
  const getDays = (enDt) => {
    let date_1 = new Date(enDt);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="default-dash">
        <Row>
          <Card className="custom-card">
            <CardHeader className="pb-0"></CardHeader>
            <CardBody>
              <Col>
                <H5>Date Range Picker</H5>
                <span>
                  {" "}
                  <DateRangePicker
                    onDateRangeSelected={onDateRangeSelected}
                  ></DateRangePicker>{" "}
                </span>
              </Col>
            </CardBody>
          </Card>
        </Row>
        <br></br>
        <Row>
          <Greeting />
          {/* daily earning component*/}
          <Col xl="3" md="6" className="dash-xl-50 box-col-6">
            <Card className="pb-0 o-hidden earning-card">
              <CardHeader className="earning-back"></CardHeader>
              <CardBody className="p-0">
                <div
                  className="earning-content"
                  style={{ position: "relative" }}
                >
                  <Image
                    attrImage={{
                      className: "img-fluid",
                      src: `${avtar}`,
                      alt: "",
                    }}
                  />
                  <H4>Pending Tasks</H4>
                  <H6>{tasks.length}</H6>
                  <span>{formattedDate}</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="3" md="6" className="dash-xl-50 box-col-6">
            <Card className="weekly-column">
              <CardBody className="card-body p-0">
                <Bar
                  data={chartData}
                  options={options}
                  id="weekly-chart"
                  type="bar"
                  height="200"
                />
              </CardBody>
            </Card>
          </Col>
          {/* <Col xl="3" md="6" className="dash-xl-50 box-col-6">
            <Card className="weekly-column">
              <CardBody className="card-body p-0">
                <Bar
                  data={chartData1}
                  options={options}
                  id="weekly-chart"
                  type="bar"
                  height="200"
                />
              </CardBody>
            </Card>
          </Col> */}
          {/* recnt activity component */}
          {/* <Col xl="4" md="6" className="dash-31 dash-xl-50 box-col-12">
            <Card className="recent-activity">
              <CardHeader className="card-no-border">
                <Media className="media-dashboard">
                  <Media body>
                    <H5 attrH5={{ className: "mb-0" }}>Recent Tests</H5>
                  </Media>
                </Media>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="table-responsive">
                  <Table className="table-bordernone">
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "290px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Added On</th>
                          <th>Recent</th>
                        </tr>
                      </thead>
                      <tbody>
                        {testing.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.additionalInformation}</td>
                              <td>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {calculateDaysDifference(
                                  moment(item.createdAt).format("DD/MM/YYYY")
                                )}{" "}
                                Days back
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ScrollBar>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="4" md="6" className="dash-31 dash-xl-50 box-col-12">
            <Card className="recent-activity">
              <CardHeader className="card-no-border">
                <Media className="media-dashboard">
                  <Media body>
                    <H5 attrH5={{ className: "mb-0" }}>Recent Repairs</H5>
                  </Media>
                </Media>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="table-responsive">
                  <Table className="table-bordernone">
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "290px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Added On</th>
                          <th>Recent</th>
                        </tr>
                      </thead>
                      <tbody>
                        {repair.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.additionalInformation}</td>
                              <td>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {calculateDaysDifference(
                                  moment(item.createdAt).format("DD/MM/YYYY")
                                )}{" "}
                                Days back
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ScrollBar>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* outgoing project component */}
          {/* <Col xl="4" className={`dash-35 col-md-6 dash-xl-50 box-col-6`}>
            <Card className="ongoing-project">
              <CardHeader className="card-no-border">
                <Media className="media-dashboard">
                  <Media body>
                    <H5 attrH5={{ className: "mb-0" }}>Tests</H5>
                  </Media>
                </Media>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="table-responsive">
                  <Table className="table-bordernone">
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "290px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Test On</th>
                          <th>Asset</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nextTesting.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.additionalInformation}</td>
                              <td>
                                {moment(item.testOn).format("DD/MM/YYYY")}
                              </td>
                              <td>{getAssetsMOdel(item.assetsID)}</td>
                              <td>{item.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ScrollBar>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="4" className={`dash-35 col-md-6 dash-xl-50 box-col-6`}>
            <Card className="ongoing-project">
              <CardHeader className="card-no-border">
                <Media className="media-dashboard">
                  <Media body>
                    <H5 attrH5={{ className: "mb-0" }}>Repair</H5>
                  </Media>
                </Media>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="table-responsive">
                  <Table className="table-bordernone">
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "290px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Repair Date</th>
                          <th>Asset</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {repair.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.additionalInformation}</td>
                              <td>
                                {moment(item.repairDate).format("DD/MM/YYYY")}
                              </td>
                              <td>{getAssetsMOdel(item.assetsID)}</td>
                              <td>{item.status}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ScrollBar>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
          {/* Yearly Component */}
          {/* <Col xl="6" md="6" className="dash-xl-50 box-col-12">
            <Card>
              <CardBody>
                <Media>
                  <Media body>
                    <div className="greeting-user">
                      <H5>{testing.length} Tests</H5>
                      <P>{formattedDate}</P>
                    </div>
                  </Media>
                </Media>
                <div className="cartoon-img"></div>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl="6" md="6" className="dash-xl-50 box-col-12">
            <Card>
              <CardBody>
                <Media>
                  <Media body>
                    <div className="greeting-user">
                      <H5>{repair.length} Repair</H5>
                      <P>{formattedDate}</P>
                    </div>
                  </Media>
                </Media>
                <div className="cartoon-img"></div>
              </CardBody>
            </Card>
          </Col> */}
          {/* NewsUpdates Cmponent */}
          {/* <Col xl="4" md="6" className="dash-31 dash-xl-50 box-col-6">
            <Card className="news-update">
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <H5 attrH5={{ className: "m-0" }}>Tests &amp; Days Left</H5>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="table-responsive">
                  <Table borderless>
                    <tbody>
                      <ScrollBar
                        className="vertical-scroll ps-container ps-theme-default ps-active-y"
                        style={{
                          height: "155px",
                          border: "1px solid #efefef",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        {nextTesting.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <Media>
                                  <Image
                                    attrImage={{
                                      className: "img-fluid me-3 b-r-5",
                                      src: require("../../../assets/images/dashboard/Rectangle-27.jpg"),
                                      alt: "",
                                    }}
                                  />
                                  <Media body>
                                      <H5>{item.additionalInformation}</H5>
                                    </Link>
                                    <P>
                                      {moment(item.nextTestSchedule).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </P>
                                  </Media>
                                </Media>
                              </td>
                              <td>
                                <span className="badge badge-light-theme-light font-theme-light">
                                  {getDays(item.nextTestSchedule)} days left
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </ScrollBar>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};
export default DefaultContain;
