import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  FormGroup,
  Container,
  Row,
  Col,
  Input,
  Card,
  CardBody,
  CardHeader,
  Media,
} from "reactstrap";
import * as queries from "../../../graphql/queries";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import moment from "moment";
import DatePicker from "react-datepicker";
import { H5 } from "../../../AbstractElements";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { SiteContext } from "../../../App";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import * as mergedQueries from "../../../graphql/mergedQueries";
import SPCompany from "../../../Data/SPCompanyData/index";
import SiteData from "../../../Data/Sites/index";
import CategoryData from "../../../Data/Category/index";
import AssetTemplate from "../../../Data/AssetTemplate/index";
import SiteDBData from "../../../Data/DistributionBoard/index";
import { limit } from "../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const UserContain = () => {
  const LoginUserGroup = localStorage.getItem("groups");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [isLoading, setIsLoading] = useState(false);
  //const [limit, setLimit] = useState(100000);

  useEffect(() => {
    setIsLoading(true);
    //(async () => {
    // fetchCategory();
    // fetchAssetTemplate();
    // if (LoginUserGroup === "site_owner") {
    //   fetchSpcompany(LoginUserDetails.id);
    //   fetchSiteDetailsAO(LoginUserDetails.id);
    // } else if (LoginUserGroup === "electrician") {
    //   fetchSPCompanySU(LoginUserDetails.id);
    //   fetchSiteDetailsSP(LoginUserDetails.id);
    // } else if (LoginUserGroup === "site_user") {
    //   fetchSPCompanySU(LoginUserDetails.id);
    //   fetchSiteDetailsSU(LoginUserDetails.id);
    // } else {
    //   fetchSpcompany(LoginUserDetails.id);
    // }
    //})();
  }, []);

  async function fetchSpcompany(assetOwnerId) {
    try {
      setIsLoading(true);
      await client
        .graphql({
          query: mergedQueries.getAOLinkedSPCompanies,
          authMode: "API_KEY",
          variables: {
            assetownerID: assetOwnerId,
          },
        })
        .then(async (dataDetails) => {
          let siteData = dataDetails.data.siteDetailsByAssetownerID.items;
          let companyName = [];
          siteData.map((obj) => {
            if (obj.SPUserDetails.items.length > 0) {
              obj.SPUserDetails.items.map((user) => {
                if (user.UserDetails !== null) {
                  companyName.push(user.UserDetails);
                }
              });
            }
          });
          const uniqueData = companyName.filter((obj, index) => {
            return (
              index ===
              companyName.findIndex(
                (o) => obj.companyDetailsId === o.companyDetailsId
              )
            );
          });
          const promises = uniqueData.map((item) => {
            if (
              item.companyDetailsId !== null &&
              item.companyDetailsId !== ""
            ) {
              return client.graphql({
                query: queries.getSPCompanyDetails,
                variables: {
                  id: item.companyDetailsId,
                },
                authMode: "API_KEY",
              });
            }
          });
          Promise.all(promises)
            .then((spComanyResults) => {
              let companies = [];
              spComanyResults.map((spcompany) => {
                if (
                  spcompany !== undefined &&
                  spcompany.data.getSPCompanyDetails !== null
                ) {
                  companies.push(spcompany.data.getSPCompanyDetails);
                }
              });
              if (companies.length > 0) {
                SPCompany.setSPCompany(companies);
              } else {
                SPCompany.setSPCompany([]);
              }
            })
            .catch((error) => {
              console.log(error);
              SPCompany.setSPCompany([]);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          console.log("assetowner SP Company", error);
          SPCompany.setSPCompany([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("assetowner SP Company", error);
      SPCompany.setSPCompany([]);
      setIsLoading(false);
    }
  }

  async function fetchSPCompanySU(userId) {
    try {
      setIsLoading(true);
      await client
        .graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: userId,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: limit,
          },
        })
        .then((dataDetails) => {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          if (siteDetails.length > 0) {
            client
              .graphql({
                query: queries.listSiteDetails,
                variables: {
                  limit: limit,
                },
              })
              .then((result) => {
                console.log(result);
                const siteDetail = result.data.listSiteDetails.items.filter(
                  (item) => {
                    const _matchingAssetTemplate = siteDetails?.find(
                      (site) => site.sitedetailsID === item?.id
                    );
                    if (_matchingAssetTemplate) {
                      return item;
                    }
                  }
                );
                console.log(siteDetail);
                if (siteDetail.length > 0) {
                  const siteUserFilterArray = [];
                  siteDetail.map((item) => {
                    siteUserFilterArray.push(item.id);
                  });
                  client
                    .graphql({
                      query: queries.listLinkUsersAndSites,
                      authMode: "API_KEY",
                      variables: {
                        limit: limit,
                        filter: {
                          status: {
                            eq: "Active",
                          },
                        },
                      },
                    })
                    .then((dataDetails) => {
                      const spDetails =
                        dataDetails.data.listLinkUsersAndSites.items;
                      const asset =
                        dataDetails.data.listLinkUsersAndSites.items.filter(
                          (item) => {
                            const _matchSites = siteUserFilterArray?.find(
                              (id) => id === item?.sitedetailsID
                            );
                            if (_matchSites) {
                              return item;
                            }
                          }
                        );
                      console.log(spDetails);
                      console.log(asset);
                      if (asset.length > 0) {
                        const userFilterArray = [];
                        asset.map((item) => {
                          userFilterArray.push(item.userdetailsID);
                        });
                        console.log(userFilterArray);
                        client
                          .graphql({
                            query: queries.listUserDetails,
                            authMode: "API_KEY",
                            variables: {
                              limit: limit,
                            },
                          })
                          .then((response1) => {
                            console.log(response1);
                            const user = response1.data.listUserDetails.items;
                            const asset1 = user.filter((item) => {
                              const _matchSites = userFilterArray?.find(
                                (id) => id === item?.id
                              );
                              if (_matchSites) {
                                return item;
                              }
                            });
                            console.log(asset1);
                            client
                              .graphql({
                                query: queries.listUserDetails,
                                authMode: "API_KEY",
                                variables: {
                                  filter: {
                                    assetOwnerId: {
                                      eq: userId,
                                    },
                                  },
                                  limit: limit,
                                },
                              })
                              .then((response) => {
                                console.log(response);
                                console.log(response1);
                                const spuserrr =
                                  response1.data.listUserDetails.items;
                                if (asset1.length > 0) {
                                  const filterArray = [];
                                  asset1.map((item) => {
                                    filterArray.push(item.companyDetailsId);
                                  });
                                  console.log(filterArray);
                                  client
                                    .graphql({
                                      query: queries.listSPCompanyDetails,
                                      authMode: "API_KEY",
                                      variables: {
                                        limit: limit,
                                      },
                                    })
                                    .then((response3) => {
                                      console.log(response3);
                                      const comp =
                                        response3.data.listSPCompanyDetails
                                          .items;
                                      const asset2 = comp.filter((item) => {
                                        const _matchSites = filterArray?.find(
                                          (id) => id === item?.id
                                        );
                                        if (_matchSites) {
                                          return item;
                                        }
                                      });
                                      console.log(asset2);
                                      SPCompany.setSPCompany(asset2);
                                      setIsLoading(false);
                                    })
                                    .catch((error4) => {
                                      console.log(error4);
                                      setIsLoading(false);
                                    });
                                }
                              })
                              .catch((error4) => {
                                console.log(error4);
                                setIsLoading(false);
                              });
                          })
                          .catch((error1) => {
                            console.log(error1);
                            setIsLoading(false);
                          });
                      }
                    })
                    .catch((error2) => {
                      console.log(error2);
                      setIsLoading(false);
                    });
                } else {
                }
              })
              .catch((error2) => {
                console.log(error2);
                setIsLoading(false);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function fetchSiteDetailsSU(assetOwnerId) {
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: assetOwnerId,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
                nextToken: nextToken,
              },
            })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          const siteDetails = mergeAssets;
          let nextToken1 = "";
          let assets1 = [];
          await client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              let assetDatas = result.data.listSiteDetails.items;
              nextToken1 = result.data.listSiteDetails.nextToken;
              if (result.data.listSiteDetails.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await client
                  .graphql({
                    query: queries.listSiteDetails,
                    variables: {
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken1 = results.data.listSiteDetails.nextToken;
                    if (results.data.listSiteDetails.items.length > 0) {
                      assets1.push(results.data.listSiteDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                console.log(mergeAssets1);
                const siteDetail = mergeAssets1.filter((item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });
                console.log(siteDetail);
                SiteData.setSiteData(siteDetail);
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  async function fetchCategory() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let categories = [];
      await client
        .graphql({
          query: queries.listCategories,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        })
        .then(async (dataDetails) => {
          let assetDatas = dataDetails.data.listCategories.items;
          nextToken = dataDetails.data.listCategories.nextToken;
          if (dataDetails.data.listCategories.items.length > 0) {
            categories.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listCategories,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((dataDetailss) => {
                nextToken = dataDetailss.data.listCategories.nextToken;
                if (dataDetailss.data.listCategories.items.length > 0) {
                  categories.push(dataDetailss.data.listCategories.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(categories);
          if (categories.length > 0) {
            const mergeCategories = categories.flat(1);
            CategoryData.setCategoryData(mergeCategories);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          CategoryData.setCategoryData([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      CategoryData.setCategoryData([]);
      setIsLoading(false);
    }
  }

  async function fetchAssetTemplate() {
    try {
      setIsLoading(true);
      let nextToken = "";
      let assetTemplates = [];
      await client
        .graphql({
          query: queries.listAssetTemplates,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        })
        .then(async (dataDetails) => {
          let assetDatas = dataDetails.data.listAssetTemplates.items;
          nextToken = dataDetails.data.listAssetTemplates.nextToken;
          if (dataDetails.data.listAssetTemplates.items.length > 0) {
            assetTemplates.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listAssetTemplates,
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((dataDetailss) => {
                nextToken = dataDetailss.data.listAssetTemplates.nextToken;
                if (dataDetailss.data.listAssetTemplates.items.length > 0) {
                  assetTemplates.push(
                    dataDetailss.data.listAssetTemplates.items
                  );
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(assetTemplates);
          if (assetTemplates.length > 0) {
            const mergeAssetTemplates = assetTemplates.flat(1);
            AssetTemplate.setAssetTemplate(mergeAssetTemplates);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          AssetTemplate.setAssetTemplate([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      AssetTemplate.setAssetTemplate([]);
      setIsLoading(false);
    }
  }

  async function fetchSiteDetailsAO(assetOwnerId) {
    try {
      setIsLoading(true);
      let nextToken = "";
      let sites = [];
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: assetOwnerId,
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: assetOwnerId,
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  sites.push(results.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergeSites = sites.flat(1);
            SiteData.setSiteData(mergeSites);
            const promises1 = mergeSites.map(async (item) => {
              return client.graphql({
                query: queries.assetCountsBySitedetailsID,
                variables: {
                  sitedetailsID: item.id,
                  limit: limit,
                },
              });
            });
            Promise.all(promises1).then(async (results) => {
              console.log(results);
              let assetCount = [];
              if (results.length > 0) {
                results.forEach((result1) => {
                  if (result1.data.assetCountsBySitedetailsID !== null)
                    assetCount.push(
                      result1.data.assetCountsBySitedetailsID.items[0]
                    );
                });
                if (assetCount.length > 0) {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                } else {
                  localStorage.setItem(
                    "categoryAssetCount",
                    base64.encode(JSON.stringify(assetCount))
                  );
                }
              }
            });
            setIsLoading(false);
            fetchlistDistributionBox(mergeSites);
          }
        })
        .catch((error) => {
          console.log(error);
          SiteData.setSiteData([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      SiteData.setSiteData([]);
      setIsLoading(false);
    }
  }

  async function fetchSiteDetailsSP(assetOwnerId) {
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: assetOwnerId,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      })
      .then(async (result) => {
        let assetDatas = result.data.linkUsersAndSitesByUserdetailsID.items;
        nextToken = result.data.linkUsersAndSitesByUserdetailsID.nextToken;
        if (result.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: assetOwnerId,
                filter: {
                  status: {
                    eq: "Active",
                  },
                },
                limit: limit,
                nextToken: nextToken,
              },
            })
            .then((results) => {
              nextToken =
                results.data.linkUsersAndSitesByUserdetailsID.nextToken;
              if (
                results.data.linkUsersAndSitesByUserdetailsID.items.length > 0
              ) {
                assets.push(
                  results.data.linkUsersAndSitesByUserdetailsID.items
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          const siteDetails = mergeAssets;
          let nextToken1 = "";
          let assets1 = [];
          await client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
              },
              authMode: "API_KEY",
            })
            .then(async (result) => {
              let assetDatas = result.data.listSiteDetails.items;
              nextToken1 = result.data.listSiteDetails.nextToken;
              if (result.data.listSiteDetails.items.length > 0) {
                assets1.push(assetDatas);
              }
              while (nextToken1 !== null) {
                await client
                  .graphql({
                    query: queries.listSiteDetails,
                    variables: {
                      limit: limit,
                      nextToken: nextToken1,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken1 = results.data.listSiteDetails.nextToken;
                    if (results.data.listSiteDetails.items.length > 0) {
                      assets1.push(results.data.listSiteDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              console.log(assets1);
              if (assets1.length > 0) {
                const mergeAssets1 = assets1.flat(1);
                const siteDetail = mergeAssets1.filter((item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });
                SiteData.setSiteData(siteDetail);
                setIsLoading(false);
                fetchlistDistributionBox(siteDetail);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  async function fetchlistDistributionBox(siteData) {
    try {
      setIsLoading(true);
      const promises = siteData.map(async (item) => {
        return client.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
        });
      });
      Promise.all(promises)
        .then(async (results) => {
          console.log(results);
          let SiteDB = [];
          results.map((items) => {
            if (items.data.distributionBoxesBySitedetailsID.items.length > 0) {
              SiteDB.push(items.data.distributionBoxesBySitedetailsID.items[0]);
            }
          });
          if (SiteDB.length > 0) {
            SiteDBData.setSiteDBData(SiteDB);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  //----global site dropdown----
  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  console.log(selectedSiteGlobal);

  const [siteGlobalAOID, setSiteGlobalAOID] = React.useState([]);

  async function fetchSitesforglobal() {
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listSiteDetails.items;
        nextToken = result.data.listSiteDetails.nextToken;
        console.log(
          "first token count",
          result.data.listSiteDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listSiteDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listSiteDetails,
              variables: {
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listSiteDetails.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listSiteDetails.items.length);
              if (results.data.listSiteDetails.items.length > 0) {
                assets.push(results.data.listSiteDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const filtersite = mergeAssets.filter(
            (item) => item.id === selectedSiteGlobal
          );
          console.log(filtersite);
          console.log(filtersite[0].assetownerID);
          setSiteGlobalAOID(filtersite[0].assetownerID);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    {
      /*
    const dataDetails = await client.graphql({
      query: queries.listSiteDetails,
      authMode: 'API_KEY'
    }).then((response) => {
      console.log(response)
      const filtersite = response.data.listSiteDetails.items.filter(item => item.id === selectedSiteGlobal)
      console.log(filtersite)
      console.log(filtersite[0].assetownerID)
      setSiteGlobalAOID(filtersite[0].assetownerID)
      setIsLoading(false);
    })
      .catch(() => {
        setIsLoading(false);
      });
    */
    }
  }

  useEffect(() => {
    fetchSitesforglobal();
  }, [selectedSiteGlobal]);

  //-------single Calendar-----

  const [dateRange, setDateRange] = useState([null, null]);

  function SingleCalendar() {
    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();
    const startDate1 = date1 - 1 + "/" + month + "/" + year;
    const todayDate = date1 + "/" + month + "/" + year;

    const handleDateRangeChange = (dates) => {
      if (dates && dates.length === 2) {
        setDateRange(dates);
        fetchAssetOwnerDetailsData("Custom");
        fetchAssets("Custom");
        fetchSitesDetails("Custom");
        fetchServiceProviderDetails("Custom");
        fetchTestingDetails("Custom");
        fetchRepairDetails("Custom");
        fetchLogbookDetails("Custom");
        fetchSiteUserDetails("Custom");
      }
    };

    return (
      <Fragment>
        <Row className="d-flex justify-content-end">
          <Col xl="6">
            <div className="daterange-card">
              <div className="mb-3">
                <FormGroup>
                  <DatePicker
                    className="form-control digits"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateRangeChange}
                    isClearable={true}
                    placeholderText="Enter Date Range"
                    dateFormat="dd-MM-yyyy"
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }

  //-------------single calendar end-----------

  //-------------Daterange Start-----------------------------------------------------
  const [selectedOption, setSelectedOption] = useState("");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);

  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };

  const onDateRangeSelected = (range) => {
    //setSelectedOption('');
    handleDateRangeSelected(range);
  };

  function DateRangePicker({ onDateRangeSelected }) {
    //const [selectedOption, setSelectedOption] =  useState('');

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());

          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());

          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());

          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());

          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());

          break;

        default:
          break;
      }
    };

    //---------------------------------------------------------------------------
    const getTodayRange = () => {
      fetchAssetOwnerDetailsData("Today");
      fetchSitesDetails("Today");
      fetchAssets("Today");
      fetchServiceProviderDetails("Today");
      fetchTestingDetails("Today");
      fetchRepairDetails("Today");
      fetchLogbookDetails("Today");
      fetchSiteUserDetails("Today");
    };

    //---------------------------------------------------------------------------
    const getAllTimeRange = () => {
      const start = new Date(0);
      const end = new Date();

      fetchAssetOwnerDetailsData("All Time");
      fetchAssets("All Time");
      fetchSitesDetails("All Time");
      fetchServiceProviderDetails("All Time");
      fetchTestingDetails("All Time");
      fetchRepairDetails("All Time");
      fetchLogbookDetails("All Time");
      fetchSiteUserDetails("All Time");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [start, end];
    };

    //---------------------------------------------------------------------------
    const getWeekRange = () => {
      const currentDate = new Date();
      const currentDayOfWeek = currentDate.getDay();
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDayOfWeek
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDayOfWeek)
      );

      const today = new Date();
      console.log(today);
      console.log(moment(startOfWeek).format("YYYY-MM-DD"));

      console.log(endOfWeek);
      fetchAssetOwnerDetailsData("This Week");
      fetchAssets("This Week");
      fetchSitesDetails("This Week");
      fetchServiceProviderDetails("This Week");
      fetchTestingDetails("This Week");
      fetchRepairDetails("This Week");
      fetchLogbookDetails("This Week");
      fetchSiteUserDetails("This Week");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfWeek, endOfWeek];
    };
    //-----------------------------------------------------------------------
    const getMonthRange = () => {
      console.log("month range");
      const currentDate = new Date();
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      fetchAssetOwnerDetailsData("This Month");
      fetchAssets("This Month");
      fetchSitesDetails("This Month");
      fetchServiceProviderDetails("This Month");
      fetchTestingDetails("This Month");
      fetchRepairDetails("This Month");
      fetchLogbookDetails("This Month");
      fetchSiteUserDetails("This Month");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfMonth, endOfMonth];
    };
    //---------------------------------------------------------------------------
    const getThisYearRange = () => {
      const currentDate = new Date();
      const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
      const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);
      fetchAssetOwnerDetailsData("This Year");
      fetchAssets("This Year");
      fetchSitesDetails("This Year");
      fetchServiceProviderDetails("This Year");
      fetchTestingDetails("This Year");
      fetchRepairDetails("This Year");
      fetchLogbookDetails("This Year");
      fetchSiteUserDetails("This Year");
      console.log((onDateRangeSelected = { onDateRangeSelected }));
      return [startOfThisYear, endOfThisYear];
    };
    //---------------------------------------------------------------------------------

    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option
                  value="All Time"
                  selected={selectedOption === "All Time"}
                >
                  All Time
                </option>
                <option value="Today" selected={selectedOption === "Today"}>
                  Today
                </option>
                <option
                  value="This Week"
                  selected={selectedOption === "This Week"}
                >
                  This Week
                </option>
                <option
                  value="This Month"
                  selected={selectedOption === "This Month"}
                >
                  This Month
                </option>
                <option
                  value="This Year"
                  selected={selectedOption === "This Year"}
                >
                  This Year
                </option>
                <option value="Custom" selected={selectedOption === "Custom"}>
                  Custom
                </option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <SingleCalendar onDateRangeSelected={onDateRangeSelected} />
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  //--------------Daterange End----------------------------------------------------

  //-------------- Asset Owner Start -------------------------------------------
  const [errorMessage, setErrorMessage] = useState("");
  const [assetOwnerDetailsList, setAssetOwnerDetailsList] = useState([]);

  const [graphLabelsAssetOwner, setgraphLabelsAssetOwner] = useState([]);
  const [graphDataAssetOwner, setgraphDataAssetOwner] = useState([]);

  const chartAssetOwners = {
    labels: graphLabelsAssetOwner,
    datasets: [
      {
        label: "Asset Owners",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataAssetOwner,
      },
    ],
  };

  useEffect(() => {
    fetchAssetOwnerDetailsData("All Time");
  }, [selectedSiteGlobal, siteGlobalAOID]);

  async function fetchAssetOwnerDetailsData(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let assetOwnerDetails = [];
    let countsArray = [];
    let monthArray = [];

    setIsLoading(true);

    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listAssetOwners,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listAssetOwners.items.length > 0) {
      let assetDatas = result.data.listAssetOwners.items;
      nextToken = result.data.listAssetOwners.nextToken;
      console.log(
        "first token count",
        result.data.listAssetOwners.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listAssetOwners.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listAssetOwners,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listAssetOwners.items.length > 0) {
          nextToken = results.data.listAssetOwners.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listAssetOwners.items.length);
          if (results.data.listAssetOwners.items.length > 0) {
            assets.push(results.data.listAssetOwners.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);

        const filterAO = mergeAssets.filter(
          (item) => item.id === siteGlobalAOID
        );
        console.log(filterAO);
        const assetOwnersdata = selectedSiteGlobal ? filterAO : mergeAssets;
        console.log(assetOwnersdata);

        if (onDateRangeSelected === "All Time") {
          assetOwnerDetails = assetOwnersdata.filter((item) => !item._delete);
          //----Graph----------------------------------------
          let unique = assetOwnerDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssetOwner(monthArray);
          setgraphDataAssetOwner(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Week") {
          assetOwnerDetails = assetOwnersdata.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfWeek).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfWeek).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetOwnerDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssetOwner(monthArray);
          setgraphDataAssetOwner(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Month") {
          assetOwnerDetails = assetOwnersdata.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfMonth).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfMonth).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetOwnerDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssetOwner(monthArray);
          setgraphDataAssetOwner(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Today") {
          assetOwnerDetails = assetOwnersdata.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetOwnerDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssetOwner(monthArray);
          setgraphDataAssetOwner(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Year") {
          assetOwnerDetails = assetOwnersdata.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfThisYear).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfThisYear).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetOwnerDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssetOwner(monthArray);
          setgraphDataAssetOwner(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Custom") {
          assetOwnerDetails = assetOwnersdata.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startDate).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endDate).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetOwnerDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetOwnerDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssetOwner(monthArray);
          setgraphDataAssetOwner(countsArray);
          //------------------graph-------------------end---------------
        }

        setIsLoading(false);
        setAssetOwnerDetailsList(assetOwnerDetails);
      }
    }
  }

  //--------------Asset owner End----------------------------------------------------

  //--------------Assets ---------------

  const [assetsList, setAssetsList] = useState([]);
  const [graphLabelsAssets, setgraphLabelsAssets] = useState([]);
  const [graphDataAssets, setgraphDataAssets] = useState([]);

  const chartAssets = {
    labels: graphLabelsAssets,
    datasets: [
      {
        label: "Assets",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataAssets,
      },
    ],
  };

  useEffect(() => {
    fetchAssets("All Time");
  }, [selectedSiteGlobal]);

  async function fetchAssets(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let assetsCount = [];
    let countsArray = [];
    let monthArray = [];

    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listAssets,
      variables: {
        limit: limit,
        filter: {
          status: {
            ne: "delete",
          },
        },
      },
      authMode: "API_KEY",
    });
    if (result.data.listAssets.items.length > 0) {
      let assetDatas = result.data.listAssets.items;
      nextToken = result.data.listAssets.nextToken;
      console.log("first token count", result.data.listAssets.items.length);
      console.log("nextToken 1", nextToken);
      if (result.data.listAssets.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listAssets,
          variables: {
            limit: limit,
            filter: {
              status: {
                ne: "delete",
              },
            },
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listAssets.items.length > 0) {
          nextToken = results.data.listAssets.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listAssets.items.length);
          if (results.data.listAssets.items.length > 0) {
            assets.push(results.data.listAssets.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        const FilterAssets = mergeAssets.filter(
          (item) => item.siteId === selectedSiteGlobal
        );
        console.log(FilterAssets);
        const NewAsset = selectedSiteGlobal ? FilterAssets : mergeAssets;

        if (onDateRangeSelected === "All Time") {
          assetsCount = NewAsset.filter((item) => !item._delete);
          //----Graph----------------------------------------
          let unique = assetsCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssets(monthArray);
          setgraphDataAssets(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Week") {
          assetsCount = NewAsset.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfWeek).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfWeek).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetsCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssets(monthArray);
          setgraphDataAssets(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Month") {
          assetsCount = NewAsset.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfMonth).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfMonth).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetsCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssets(monthArray);
          setgraphDataAssets(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Today") {
          assetsCount = NewAsset.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetsCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssets(monthArray);
          setgraphDataAssets(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Year") {
          assetsCount = NewAsset.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfThisYear).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfThisYear).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetsCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssets(monthArray);
          setgraphDataAssets(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Custom") {
          assetsCount = NewAsset.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startDate).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endDate).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = assetsCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              assetsCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsAssets(monthArray);
          setgraphDataAssets(countsArray);
          //------------------graph-------------------end---------------
        }
        setIsLoading(false);
        setAssetsList(assetsCount);
      }
    }
  }

  //--------------Sites start---------------

  const [siteDetails, setSiteDetails] = useState([]);
  const [graphLabelsSites, setgraphLabelsSites] = useState([]);
  const [graphDataSites, setgraphDataSites] = useState([]);
  const chartSites = {
    labels: graphLabelsSites,
    datasets: [
      {
        label: "Sites",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataSites,
      },
    ],
  };

  useEffect(() => {
    fetchSitesDetails("All Time");
  }, [selectedSiteGlobal]);

  async function fetchSitesDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let sitesCount = [];
    let monthArray = [];
    let countsArray = [];
    setIsLoading(true);
    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listSiteDetails,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSiteDetails.items.length > 0) {
      let assetDatas = result.data.listSiteDetails.items;
      nextToken = result.data.listSiteDetails.nextToken;
      console.log(
        "first token count",
        result.data.listSiteDetails.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listSiteDetails.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listSiteDetails,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listSiteDetails.items.length > 0) {
          nextToken = results.data.listSiteDetails.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listSiteDetails.items.length);
          if (results.data.listSiteDetails.items.length > 0) {
            assets.push(results.data.listSiteDetails.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);

        const sitedata = mergeAssets;
        const sitesFilter = mergeAssets.filter(
          (item) => item.id === selectedSiteGlobal
        );
        console.log(sitesFilter);
        const sitesNew = selectedSiteGlobal ? sitesFilter : sitedata;
        console.log(sitesNew);

        if (onDateRangeSelected === "All Time") {
          sitesCount = sitesNew.filter((item) => !item._delete);
          //----Graph----------------------------------------
          let unique = sitesNew
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              sitesNew.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              sitesNew.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSites(monthArray);
          setgraphDataSites(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Week") {
          sitesCount = sitesNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfWeek).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfWeek).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = sitesCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSites(monthArray);
          setgraphDataSites(countsArray);
        } else if (onDateRangeSelected === "This Month") {
          sitesCount = sitesNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfMonth).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfMonth).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = sitesCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSites(monthArray);
          setgraphDataSites(countsArray);
          //--------------------------------------------------------
        } else if (onDateRangeSelected === "Today") {
          sitesCount = sitesNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = sitesCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSites(monthArray);
          setgraphDataSites(countsArray);
          //------------------------------end-------------------------------
        } else if (onDateRangeSelected === "This Year") {
          sitesCount = sitesNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfThisYear).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfThisYear).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = sitesCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSites(monthArray);
          setgraphDataSites(countsArray);
          //------------------------------end-------------------------------
        } else if (onDateRangeSelected === "Custom") {
          sitesCount = sitesNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startDate).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endDate).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = sitesCount
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              sitesCount.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSites(monthArray);
          setgraphDataSites(countsArray);
          //------------------------------end-------------------------------
        }
        setIsLoading(false);
        setSiteDetails(sitesCount);
      }
    }
  }

  //--- Sites End ----

  //--- Service provider Start ----
  const [listofServiceProviders, setlistofServiceProviders] = useState([]);
  const [graphLabelsSP, setgraphLabelsSP] = useState([]);
  const [graphDataSP, setgraphDataSP] = useState([]);
  const chartSP = {
    labels: graphLabelsSP,
    datasets: [
      {
        label: "Service Providers",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataSP,
      },
    ],
  };
  useEffect(() => {
    fetchServiceProviderDetails("All Time");
  }, [selectedSiteGlobal]);

  async function fetchServiceProviderDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let serviceProviders = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);
    if (selectedSiteGlobal) {
      console.log("global filtered data");
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.linkUsersAndSitesBySitedetailsID,
        authMode: "API_KEY",
        variables: {
          sitedetailsID: selectedSiteGlobal,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesBySitedetailsID.items;
        nextToken = result.data.linkUsersAndSitesBySitedetailsID.nextToken;
        console.log(
          "first token count",
          result.data.linkUsersAndSitesBySitedetailsID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: selectedSiteGlobal,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
            nextToken = results.data.linkUsersAndSitesBySitedetailsID.nextToken;
            console.log("nextToken", nextToken);
            console.log(
              results.data.linkUsersAndSitesBySitedetailsID.items.length
            );
            if (
              results.data.linkUsersAndSitesBySitedetailsID.items.length > 0
            ) {
              assets.push(results.data.linkUsersAndSitesBySitedetailsID.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const spDetails1 = mergeAssets;
          let userId = [];
          mergeAssets.map(async (item) => {
            userId.push({
              id: {
                eq: item.userdetailsID,
              },
            });
          });
          //--
          let nextToken1 = "";
          let assets1 = [];
          const result = await client.graphql({
            query: queries.listSPUserDetails,
            variables: {
              limit: limit,
              filter: {
                userGroup: {
                  eq: "electrician",
                },
              },
            },
            authMode: "API_KEY",
          });
          if (result.data.listSPUserDetails.items.length > 0) {
            let assetDatas = result.data.listSPUserDetails.items;
            nextToken1 = result.data.listSPUserDetails.nextToken;
            console.log(
              "first token count",
              result.data.listSPUserDetails.items.length
            );
            console.log("nextToken 1", nextToken1);
            if (result.data.listSPUserDetails.items.length > 0) {
              assets1.push(assetDatas);
            }
            while (nextToken1 !== null) {
              const results = await client.graphql({
                query: queries.listSPUserDetails,
                variables: {
                  limit: limit,
                  filter: {
                    //or: userId,
                    userGroup: {
                      eq: "electrician",
                    },
                  },
                  nextToken: nextToken1,
                },
                authMode: "API_KEY",
              });
              if (results.data.listSPUserDetails.items.length > 0) {
                nextToken1 = results.data.listSPUserDetails.nextToken;
                console.log("nextToken", nextToken1);
                console.log(results.data.listSPUserDetails.items.length);
                if (results.data.listSPUserDetails.items.length > 0) {
                  assets1.push(results.data.listSPUserDetails.items);
                }
              }
            }
            console.log(assets1);
            if (assets1.length > 0) {
              const mergeAssets1 = assets1.flat(1);
              console.log(mergeAssets1);

              const spDetails = mergeAssets1;
              const spDetails2 = spDetails.filter((item) => {
                const _matchSites = spDetails1?.find(
                  (user) => user.userdetailsID === item?.id
                );
                if (_matchSites) {
                  return item;
                }
              });

              if (onDateRangeSelected === "This Week") {
                serviceProviders = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startOfWeek).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endOfWeek).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = serviceProviders
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSP(monthArray);
                setgraphDataSP(countsArray);
                //------------------------------end-------------------------------
              } else if (onDateRangeSelected === "This Month") {
                serviceProviders = spDetails2.filterr(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startOfMonth).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endOfMonth).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = serviceProviders
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSP(monthArray);
                setgraphDataSP(countsArray);
                //------------------------------end-------------------------------
              } else if (onDateRangeSelected === "Today") {
                serviceProviders = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") ===
                    moment(new Date()).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = serviceProviders
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSP(monthArray);
                setgraphDataSP(countsArray);
                //------------------------------end-------------------------------
              } else if (onDateRangeSelected === "This Year") {
                serviceProviders = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startOfThisYear).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endOfThisYear).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = serviceProviders
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSP(monthArray);
                setgraphDataSP(countsArray);
                //------------------------------end-------------------------------
              } else if (onDateRangeSelected === "Custom") {
                serviceProviders = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startDate).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endDate).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = serviceProviders
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSP(monthArray);
                setgraphDataSP(countsArray);
                //------------------------------end-------------------------------
              } else if (onDateRangeSelected === "All Time") {
                serviceProviders = spDetails2;
                //----Graph----------------------------------------
                let unique = serviceProviders
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    serviceProviders.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSP(monthArray);
                setgraphDataSP(countsArray);
                //------------------------------end-------------------------------
              }

              setIsLoading(false);
              setlistofServiceProviders(serviceProviders);
            }
          }
        }
      }
    } else {
      console.log("not inside global filter");

      let countsArray = [];
      let monthArray = [];
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listSPUserDetails,
        variables: {
          filter: {
            userGroup: {
              eq: "electrician",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.listSPUserDetails.items.length > 0) {
        let assetDatas = result.data.listSPUserDetails.items;
        nextToken = result.data.listSPUserDetails.nextToken;
        console.log(
          "first token count",
          result.data.listSPUserDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listSPUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listSPUserDetails,
            variables: {
              filter: {
                userGroup: {
                  eq: "electrician",
                },
              },
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listSPUserDetails.items.length > 0) {
            nextToken = results.data.listSPUserDetails.nextToken;
            console.log("nextToken", nextToken);
            console.log(results.data.listSPUserDetails.items.length);
            if (results.data.listSPUserDetails.items.length > 0) {
              assets.push(results.data.listSPUserDetails.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);

          if (onDateRangeSelected === "This Week") {
            serviceProviders = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startOfWeek).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endOfWeek).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = serviceProviders
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSP(monthArray);
            setgraphDataSP(countsArray);
            //------------------------------end-------------------------------
          } else if (onDateRangeSelected === "This Month") {
            serviceProviders = mergeAssets.filterr(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startOfMonth).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endOfMonth).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = serviceProviders
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSP(monthArray);
            setgraphDataSP(countsArray);
            //------------------------------end-------------------------------
          } else if (onDateRangeSelected === "Today") {
            serviceProviders = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = serviceProviders
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSP(monthArray);
            setgraphDataSP(countsArray);
            //------------------------------end-------------------------------
          } else if (onDateRangeSelected === "This Year") {
            serviceProviders = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startOfThisYear).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endOfThisYear).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = serviceProviders
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSP(monthArray);
            setgraphDataSP(countsArray);
            //------------------------------end-------------------------------
          } else if (onDateRangeSelected === "Custom") {
            serviceProviders = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startDate).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endDate).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = serviceProviders
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSP(monthArray);
            setgraphDataSP(countsArray);
            //------------------------------end-------------------------------
          } else if (onDateRangeSelected === "All Time") {
            serviceProviders = mergeAssets;
            //----Graph----------------------------------------
            let unique = serviceProviders
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                serviceProviders.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSP(monthArray);
            setgraphDataSP(countsArray);
            //------------------------------end-------------------------------
          }
          setIsLoading(false);
          setlistofServiceProviders(serviceProviders);
        }
      }
    }
  }
  //--- Service provider End ----

  //--- Testing Start ----
  const [testingDetailsList, setTestingDetailsList] = useState([]);
  const [graphLabelsTests, setgraphLabelsTests] = useState([]);
  const [graphDataTests, setgraphDataTests] = useState([]);
  const chartTests = {
    labels: graphLabelsTests,
    datasets: [
      {
        label: "Tests",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataTests,
      },
    ],
  };
  useEffect(() => {
    fetchTestingDetails("All Time");
  }, []);

  async function fetchTestingDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let testingDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);

    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listTestings,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listTestings.items.length > 0) {
      let assetDatas = result.data.listTestings.items;
      nextToken = result.data.listTestings.nextToken;
      console.log("first token count", result.data.listTestings.items.length);
      console.log("nextToken 1", nextToken);
      if (result.data.listTestings.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listTestings,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listTestings.items.length > 0) {
          nextToken = results.data.listTestings.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listTestings.items.length);
          if (results.data.listTestings.items.length > 0) {
            assets.push(results.data.listTestings.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);

        if (onDateRangeSelected === "All Time") {
          testingDetails = mergeAssets.filter((item) => !item._delete);
          //----Graph----------------------------------------
          let unique = testingDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsTests(monthArray);
          setgraphDataTests(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Week") {
          testingDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfWeek).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfWeek).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = testingDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsTests(monthArray);
          setgraphDataTests(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Month") {
          testingDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfMonth).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfMonth).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = testingDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsTests(monthArray);
          setgraphDataTests(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Today") {
          testingDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = testingDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsTests(monthArray);
          setgraphDataTests(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Year") {
          testingDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfThisYear).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfThisYear).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = testingDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsTests(monthArray);
          setgraphDataTests(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Custom") {
          testingDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startDate).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endDate).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = testingDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              testingDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSP(monthArray);
          setgraphDataSP(countsArray);
          //------------------------------end-------------------------------
        }

        setIsLoading(false);
        setTestingDetailsList(testingDetails);
      }
    }
  }
  //--- Testing End ----

  //--- Repair Start ----
  const [repairDetailsList, setRepairDetailsList] = useState([]);
  const [graphLabelsRepairs, setgraphLabelsRepairs] = useState([]);
  const [graphDataRepairs, setgraphDataRepairs] = useState([]);
  const chartRepairs = {
    labels: graphLabelsRepairs,
    datasets: [
      {
        label: "Repairs",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataRepairs,
      },
    ],
  };
  useEffect(() => {
    fetchRepairDetails("All Time");
  }, []);

  async function fetchRepairDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let repairDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);

    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listRepairs,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listRepairs.items.length > 0) {
      let assetDatas = result.data.listRepairs.items;
      nextToken = result.data.listRepairs.nextToken;
      console.log("first token count", result.data.listRepairs.items.length);
      console.log("nextToken 1", nextToken);
      if (result.data.listRepairs.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listRepairs,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listRepairs.items.length > 0) {
          nextToken = results.data.listRepairs.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listRepairs.items.length);
          if (results.data.listRepairs.items.length > 0) {
            assets.push(results.data.listRepairs.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);

        if (onDateRangeSelected === "All Time") {
          repairDetails = mergeAssets.filter((item) => !item._delete);
          //----Graph----------------------------------------
          let unique = repairDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsRepairs(monthArray);
          setgraphDataRepairs(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Week") {
          repairDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfWeek).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfWeek).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = repairDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsRepairs(monthArray);
          setgraphDataRepairs(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Month") {
          repairDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfMonth).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfMonth).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = repairDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsRepairs(monthArray);
          setgraphDataRepairs(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Today") {
          repairDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = repairDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsRepairs(monthArray);
          setgraphDataRepairs(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Year") {
          repairDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfThisYear).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfThisYear).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = repairDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsRepairs(monthArray);
          setgraphDataRepairs(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Custom") {
          repairDetails = mergeAssets.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startDate).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endDate).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = repairDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              repairDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsSP(monthArray);
          setgraphDataSP(countsArray);
          //------------------------------end-------------------------------
        }

        setIsLoading(false);
        setRepairDetailsList(repairDetails);
      }
    }
  }
  //--- Repair End ----

  //--- Logbook Start ----
  const [logBookDetailsList, setLogBookDetailsList] = useState([]);
  const [graphLabelsLogBooks, setgraphLabelsLogBooks] = useState([]);
  const [graphDataLogBooks, setgraphDataLogBooks] = useState([]);
  const chartLogBooks = {
    labels: graphLabelsLogBooks,
    datasets: [
      {
        label: "LogBooks",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataLogBooks,
      },
    ],
  };
  useEffect(() => {
    fetchLogbookDetails("All Time");
  }, [selectedSiteGlobal]);

  async function fetchLogbookDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();

    let logbookDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);

    let nextToken = "";
    let assets = [];
    const result = await client.graphql({
      query: queries.listLogBooks,
      variables: {
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listLogBooks.items.length > 0) {
      let assetDatas = result.data.listLogBooks.items;
      nextToken = result.data.listLogBooks.nextToken;
      console.log("first token count", result.data.listLogBooks.items.length);
      console.log("nextToken 1", nextToken);
      if (result.data.listLogBooks.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listLogBooks,
          variables: {
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listLogBooks.items.length > 0) {
          nextToken = results.data.listLogBooks.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listLogBooks.items.length);
          if (results.data.listLogBooks.items.length > 0) {
            assets.push(results.data.listLogBooks.items);
          }
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);

        const logbookdata = mergeAssets;
        console.log(logbookdata);
        const logbookfilter = mergeAssets.filter(
          (item) => item.sitedetailsID === selectedSiteGlobal
        );
        console.log(logbookfilter);
        const logBookNew = selectedSiteGlobal ? logbookfilter : logbookdata;

        if (onDateRangeSelected === "All Time") {
          logbookDetails = logBookNew.filter((item) => !item._delete);
          //----Graph----------------------------------------
          let unique = logbookDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsLogBooks(monthArray);
          setgraphDataLogBooks(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Week") {
          logbookDetails = logBookNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfWeek).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfWeek).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = logbookDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsLogBooks(monthArray);
          setgraphDataLogBooks(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Month") {
          logbookDetails = logBookNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfMonth).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfMonth).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = logbookDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsLogBooks(monthArray);
          setgraphDataLogBooks(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Today") {
          logbookDetails = logBookNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = logbookDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsLogBooks(monthArray);
          setgraphDataLogBooks(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "This Year") {
          logbookDetails = logBookNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startOfThisYear).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endOfThisYear).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = logbookDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsLogBooks(monthArray);
          setgraphDataLogBooks(countsArray);
          //------------------graph-------------------end---------------
        } else if (onDateRangeSelected === "Custom") {
          logbookDetails = logBookNew.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") >=
              moment(startDate).format("YYYY-MM-DD") &&
              moment(item.createdAt).format("YYYY-MM-DD") <=
              moment(endDate).format("YYYY-MM-DD")
          );
          //----Graph----------------------------------------
          let unique = logbookDetails
            .map((item) => moment(item.createdAt).format("YYYY-MMM"))
            .filter((value, index, self) => self.indexOf(value) === index);
          unique.map((uitem) => {
            countsArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ).length
            );
          });
          unique.map((uitem) => {
            monthArray.push(
              logbookDetails.filter(
                (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
              ) && uitem
            );
          });
          setgraphLabelsLogBooks(monthArray);
          setgraphDataLogBooks(countsArray);
          //------------------graph-------------------end---------------
        }

        setIsLoading(false);
        setLogBookDetailsList(logbookDetails);
      }
    }
  }
  //--- Logbook End ----

  //--- SiteUser Start ----
  const [listofSiteUsers, setListofSiteUsers] = useState([]);
  const [graphLabelsSiteUsers, setgraphLabelsSiteUsers] = useState([]);
  const [graphDataSiteUsers, setgraphDataSiteUsers] = useState([]);
  const chartSiteUsers = {
    labels: graphLabelsSiteUsers,
    datasets: [
      {
        label: "SiteUsers",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "#2d85c3",
        data: graphDataSiteUsers,
      },
    ],
  };
  useEffect(() => {
    fetchSiteUserDetails("All Time");
  }, [selectedSiteGlobal]);

  async function fetchSiteUserDetails(onDateRangeSelected) {
    //---Week--
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    );
    const endOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    );
    // ---Month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    // ---year
    const startOfThisYear = new Date(currentDate.getFullYear(), 0, 1);
    const endOfThisYear = new Date(currentDate.getFullYear(), 11, 31);

    //---custom -----

    const [startDate, endDate] = dateRange;
    var date1 = new Date().getDate();
    var month = new Date().getMonth();
    var year = new Date().getFullYear();
    let siteUserDetails = [];
    let countsArray = [];
    let monthArray = [];
    setIsLoading(true);

    if (selectedSiteGlobal) {
      console.log("global filtered data");

      //--
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.linkUsersAndSitesBySitedetailsID,
        authMode: "API_KEY",
        variables: {
          sitedetailsID: selectedSiteGlobal,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesBySitedetailsID.items;
        nextToken = result.data.linkUsersAndSitesBySitedetailsID.nextToken;
        console.log(
          "first token count",
          result.data.linkUsersAndSitesBySitedetailsID.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: selectedSiteGlobal,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
            nextToken = results.data.linkUsersAndSitesBySitedetailsID.nextToken;
            console.log("nextToken", nextToken);
            console.log(
              results.data.linkUsersAndSitesBySitedetailsID.items.length
            );
            if (
              results.data.linkUsersAndSitesBySitedetailsID.items.length > 0
            ) {
              assets.push(results.data.linkUsersAndSitesBySitedetailsID.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          const spDetails1 = mergeAssets;
          let userId = [];
          mergeAssets.map(async (item) => {
            userId.push({
              id: {
                eq: item.userdetailsID,
              },
            });
          });
          //--
          let nextToken1 = "";
          let assets1 = [];
          const result = await client.graphql({
            query: queries.listSPUserDetails,
            variables: {
              limit: limit,
              filter: {
                userGroup: {
                  eq: "site_user",
                },
              },
            },
            authMode: "API_KEY",
          });
          if (result.data.listSPUserDetails.items.length > 0) {
            let assetDatas = result.data.listSPUserDetails.items;
            nextToken1 = result.data.listSPUserDetails.nextToken;
            console.log(
              "first token count",
              result.data.listSPUserDetails.items.length
            );
            console.log("nextToken 1", nextToken1);
            if (result.data.listSPUserDetails.items.length > 0) {
              assets1.push(assetDatas);
            }
            while (nextToken1 !== null) {
              const results = await client.graphql({
                query: queries.listSPUserDetails,
                variables: {
                  limit: limit,
                  filter: {
                    //or: userId,
                    userGroup: {
                      eq: "site_user",
                    },
                  },
                  nextToken: nextToken1,
                },
                authMode: "API_KEY",
              });
              if (results.data.listSPUserDetails.items.length > 0) {
                nextToken1 = results.data.listSPUserDetails.nextToken;
                console.log("nextToken", nextToken1);
                console.log(results.data.listSPUserDetails.items.length);
                if (results.data.listSPUserDetails.items.length > 0) {
                  assets1.push(results.data.listSPUserDetails.items);
                }
              }
            }
            console.log(assets1);
            if (assets1.length > 0) {
              const mergeAssets1 = assets1.flat(1);
              console.log(mergeAssets1);

              const spDetails = mergeAssets1;
              const spDetails2 = spDetails.filter((item) => {
                const _matchSites = spDetails1?.find(
                  (user) => user.userdetailsID === item?.id
                );
                if (_matchSites) {
                  return item;
                }
              });

              if (onDateRangeSelected === "This Week") {
                siteUserDetails = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startOfWeek).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endOfWeek).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = siteUserDetails
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSiteUsers(monthArray);
                setgraphDataSiteUsers(countsArray);
                //------------------graph-------------------end---------------
              } else if (onDateRangeSelected === "This Month") {
                siteUserDetails = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startOfMonth).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endOfMonth).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = siteUserDetails
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSiteUsers(monthArray);
                setgraphDataSiteUsers(countsArray);
                //------------------graph-------------------end---------------
              } else if (onDateRangeSelected === "Today") {
                siteUserDetails = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") ===
                    moment(new Date()).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = siteUserDetails
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSiteUsers(monthArray);
                setgraphDataSiteUsers(countsArray);
                //------------------graph-------------------end---------------
              } else if (onDateRangeSelected === "This Year") {
                siteUserDetails = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startOfThisYear).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endOfThisYear).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = siteUserDetails
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSiteUsers(monthArray);
                setgraphDataSiteUsers(countsArray);
                //------------------graph-------------------end---------------
              } else if (onDateRangeSelected === "Custom") {
                siteUserDetails = spDetails2.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") >=
                    moment(startDate).format("YYYY-MM-DD") &&
                    moment(item.createdAt).format("YYYY-MM-DD") <=
                    moment(endDate).format("YYYY-MM-DD")
                );
                //----Graph----------------------------------------
                let unique = siteUserDetails
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSiteUsers(monthArray);
                setgraphDataSiteUsers(countsArray);
                //------------------graph-------------------end---------------
              } else if (onDateRangeSelected === "All Time") {
                siteUserDetails = spDetails2;
                //----Graph----------------------------------------
                let unique = siteUserDetails
                  .map((item) => moment(item.createdAt).format("YYYY-MMM"))
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  );
                unique.map((uitem) => {
                  countsArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ).length
                  );
                });
                unique.map((uitem) => {
                  monthArray.push(
                    siteUserDetails.filter(
                      (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                    ) && uitem
                  );
                });
                setgraphLabelsSiteUsers(monthArray);
                setgraphDataSiteUsers(countsArray);
                //------------------graph-------------------end---------------
              }
              setIsLoading(false);
              setListofSiteUsers(siteUserDetails);
            }
          }
        }
      }
    } else {
      console.log("not inside global filter");

      let countsArray = [];
      let monthArray = [];
      setIsLoading(true);
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listSPUserDetails,
        variables: {
          filter: {
            userGroup: {
              eq: "site_user" || "site_users",
            },
          },
          limit: limit,
        },
        authMode: "API_KEY",
      });
      if (result.data.listSPUserDetails.items.length > 0) {
        let assetDatas = result.data.listSPUserDetails.items;
        nextToken = result.data.listSPUserDetails.nextToken;
        console.log(
          "first token count",
          result.data.listSPUserDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listSPUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listSPUserDetails,
            variables: {
              filter: {
                userGroup: {
                  eq: "site_user" || "site_users",
                },
              },
              limit: limit,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listSPUserDetails.items.length > 0) {
            nextToken = results.data.listSPUserDetails.nextToken;
            console.log("nextToken", nextToken);
            console.log(results.data.listSPUserDetails.items.length);
            if (results.data.listSPUserDetails.items.length > 0) {
              assets.push(results.data.listSPUserDetails.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          if (onDateRangeSelected === "This Week") {
            siteUserDetails = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startOfWeek).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endOfWeek).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = siteUserDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSiteUsers(monthArray);
            setgraphDataSiteUsers(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Month") {
            siteUserDetails = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startOfMonth).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endOfMonth).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = siteUserDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSiteUsers(monthArray);
            setgraphDataSiteUsers(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Today") {
            siteUserDetails = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = siteUserDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSiteUsers(monthArray);
            setgraphDataSiteUsers(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "This Year") {
            siteUserDetails = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startOfThisYear).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endOfThisYear).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = siteUserDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSiteUsers(monthArray);
            setgraphDataSiteUsers(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "Custom") {
            siteUserDetails = mergeAssets.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") >=
                moment(startDate).format("YYYY-MM-DD") &&
                moment(item.createdAt).format("YYYY-MM-DD") <=
                moment(endDate).format("YYYY-MM-DD")
            );
            //----Graph----------------------------------------
            let unique = siteUserDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSiteUsers(monthArray);
            setgraphDataSiteUsers(countsArray);
            //------------------graph-------------------end---------------
          } else if (onDateRangeSelected === "All Time") {
            siteUserDetails = mergeAssets;
            //----Graph----------------------------------------
            let unique = siteUserDetails
              .map((item) => moment(item.createdAt).format("YYYY-MMM"))
              .filter((value, index, self) => self.indexOf(value) === index);
            unique.map((uitem) => {
              countsArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ).length
              );
            });
            unique.map((uitem) => {
              monthArray.push(
                siteUserDetails.filter(
                  (d) => moment(d.createdAt).format("YYYY-MMM") === uitem
                ) && uitem
              );
            });
            setgraphLabelsSiteUsers(monthArray);
            setgraphDataSiteUsers(countsArray);
            //------------------graph-------------------end---------------
          }

          setIsLoading(false);
          setListofSiteUsers(siteUserDetails);
        }
      }
    }
  }

  //--- Logbook End --------

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <Row>
          <Col>
            <Card>
              <CardHeader className="pb-0"></CardHeader>
              <CardBody>
                <H5>Date Range Picker</H5>
                <span>
                  {" "}
                  <DateRangePicker
                    onDateRangeSelected={onDateRangeSelected}
                  ></DateRangePicker>{" "}
                </span>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Row>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        <div className="media-end">
                          <H5>{siteDetails.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/sites`}>Sites</Link>
                      </H5>
                    </Media>
                    <div className="media-end"></div>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartSites} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{assetOwnerDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/assetOwner`}>Asset Owners</Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartAssetOwners} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{listofSiteUsers.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/siteownerpanel/siteUser`}>Site Users</Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartSiteUsers} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{listofServiceProviders.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>
                        <Link to={`/siteownerpanel/serviceprovider`}>
                          Service Providers
                        </Link>
                      </H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartSP} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{testingDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>Tests</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartTests} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{repairDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>Repairs</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartRepairs} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        <div className="media-end">
                          <H5>{assetsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>Assets</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartAssets} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className="dash-lg-50 box-col-6">
              <Card className="crypto-chart">
                <CardHeader className="card-no-border">
                  <Media>
                    <Media body>
                      <div className="coin-logo-img bg-light-primary">
                        {/* <BitCoinSvg />*/}
                        <div className="media-end">
                          <H5>{logBookDetailsList.length}</H5>
                        </div>
                      </div>
                      <H5 attrH5={{ className: "font-primary" }}>Logbooks</H5>
                    </Media>
                  </Media>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="crypto-dashborad-chart">
                    <Line data={chartLogBooks} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Row>
        <br></br>
      </Container>
    </Fragment>
  );
  return <div>{isLoading ? <LoadingSpinner /> : renderList}</div>;
};
export default UserContain;
