import React, { Fragment, useState, useEffect } from "react";
import SiteDataList from "./SiteDetails";
import CompanyDetails from "./CompanyDetails";
import {
  Container,
  Form,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  CardHeader,
  Card,
  CardBody,
} from "reactstrap";
import { Btn, H6, H4 } from "../../../AbstractElements";
import { useForm, useFieldArray } from "react-hook-form";
import { registerAction } from "echarts";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";
import uuid from "react-native-uuid";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { use } from "i18next";
import SPCompany from "../../../Data/SPCompanyData/index";
import * as mergedQueries from "../../../graphql/mergedQueries";
import SiteData from "../../../Data/Sites/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const LinkUserwithSiteDetails = (props) => {
  const navigate = useNavigate();
  const state = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();

  const [usersDetails, setUsersDetails] = useState([]);
  const [userType, setUserType] = useState("");
  const [usersDetailsFilter, setUsersDetailsFilter] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [linkedSiteUsers, setLinkedSiteDetails] = useState([]);
  const [siteDetails, setSiteDetails] = useState([]);
  const [spcompanyname, setspcompanyname] = useState([]);
  const [compID, setCompId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoginUserGroup = localStorage.getItem("groups");

  async function fetchGetAOSite() {
    const result = await client.graphql({
      query: queries.listSiteDetails,
      variables: {
        filter: {
          assetownerID: {
            eq: LoginUserDetails.id,
          },
        },
        limit: 200000,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSiteDetails.items.length > 0) {
      console.log(result);
      setSiteDetails(result.data.listSiteDetails.items);
    } else {
      setSiteDetails([]);
    }
  }

  //---sp compnay
  async function fetchSpcompany(asetOwnerId) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: asetOwnerId,
            },
          },
        },
      });
      console.log("company " + asetOwnerId, getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
      }
    } catch (error) {
      console.log(error);
      //setIsLoading(false);
    }
  }

  async function fetchSPUserDetails() {
    const promises = siteDetails.map(async (site) => {
      return client.graphql({
        query: queries.linkUsersAndSitesBySitedetailsID,
        variables: {
          sitedetailsID: site.id,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: 200000,
        },
        authMode: "API_KEY",
      });
    });
    Promise.all(promises)
      .then((results) => {
        const linkedUsers = [];
        const SPUserDetails = [];
        if (results.length > 0) {
          results.map((user) => {
            if (user.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
              user.data.linkUsersAndSitesBySitedetailsID.items.map((item) => {
                linkedUsers.push(item);
              });
            }
          });
          if (linkedUsers.length > 0) {
            const dupeCheckLinkedUser = [];
            const promises1 = linkedUsers.map(async (user) => {
              if (props.data.id !== user.sitedetailsID) {
                if (dupeCheckLinkedUser.indexOf(user.userdetailsID) < 0) {
                  dupeCheckLinkedUser.push(user.userdetailsID);
                  return client.graphql({
                    query: queries.getSPUserDetails,
                    variables: {
                      id: user.userdetailsID,
                      filter: {
                        userGroup: {
                          eq: "site_user",
                        },
                        status: {
                          eq: "Active",
                        },
                      },
                      limit: 200000,
                    },
                    authMode: "API_KEY",
                  });
                }
              }
            });
            Promise.all(promises1).then((results1) => {
              results1.map((spUser) => {
                if (spUser && spUser.data.getSPUserDetails !== null) {
                  SPUserDetails.push(spUser.data.getSPUserDetails);
                }
              });
              console.log(SPUserDetails);
              setLinkedSiteDetails(
                SPUserDetails.filter((item) => {
                  var data = linkedUsers.find(
                    (user) =>
                      user.sitedetailsID === props.data.id &&
                      item.id === user.userdetailsID
                  );
                  if (!data) {
                    return item;
                  }
                })
              );
              setUsersDetails(
                SPUserDetails.filter((item) => {
                  var data = linkedUsers.find(
                    (user) =>
                      user.sitedetailsID === props.data.id &&
                      item.id === user.userdetailsID
                  );
                  if (!data) {
                    return item;
                  }
                })
              );
              setUsersDetailsFilter(
                SPUserDetails.filter((item) => {
                  var data = linkedUsers.find(
                    (user) =>
                      user.sitedetailsID === props.data.id &&
                      item.id === user.userdetailsID
                  );
                  if (!data) {
                    return item;
                  }
                })
              );
              console.log(
                SPUserDetails.filter((item) => {
                  var data = linkedUsers.find(
                    (user) =>
                      user.sitedetailsID === props.data.id &&
                      item.id === user.userdetailsID
                  );
                  if (!data) {
                    return item;
                  }
                })
              );
              // setLinkedSiteDetails(
              //   SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
              // );
              // setUsersDetails(
              //   SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
              // );
              // setUsersDetailsFilter(
              //   SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
              // );
            });
          } else {
            setLinkedSiteDetails(SPUserDetails);
            setUsersDetails(SPUserDetails);
            setUsersDetailsFilter(SPUserDetails);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // await client.graphql({
    //   query: queries.listSPUserDetails,
    //   variables: {
    //     filter: {
    //       userGroup: {
    //         eq: "site_user",
    //       },
    //       // invitedBy: {
    //       //   eq: LoginUserDetails.id,
    //       // },
    //       status: {
    //         eq: "Active",
    //       },
    //       sitedetailsID: {
    //         ne: props.data.id,
    //       },
    //     },
    //     limit: 200000,
    //   },
    //   authMode: "API_KEY",
    // })
    //   .then(async (result) => {
    //     console.log(result);
    //     const SPUserDetails = result.data.listSPUserDetails.items;
    //     await client.graphql({
    //       query: queries.linkUsersAndSitesBySitedetailsID,
    //       variables: {
    //         sitedetailsID: props.data.id,
    //         limit: 10000,
    //       },
    //       authMode: "API_KEY",
    //     })
    //       .then((siteUserResult) => {
    //         console.log(siteUserResult);
    //         const linkedUsers =
    //           siteUserResult.data.linkUsersAndSitesBySitedetailsID.items;
    //         if (linkedUsers.length > 0) {
    //           const nonlinkeduser = [];
    //           linkedUsers.map((user) => {
    //             nonlinkeduser.push(user.userdetailsID);
    //           });
    //           console.log(
    //             SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
    //           );
    //           setLinkedSiteDetails(
    //             SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
    //           );
    //           setUsersDetails(
    //             SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
    //           );
    //           setUsersDetailsFilter(
    //             SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
    //           );
    //         } else {
    //           setLinkedSiteDetails(SPUserDetails);
    //           setUsersDetails(SPUserDetails);
    //           setUsersDetailsFilter(SPUserDetails);
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //     {
    //       /*
    //     if (SPUserDetails.length > 0) {
    //       console.log(SPUserDetails);
    //       setLinkedSiteDetails(SPUserDetails);
    //       setUsersDetails(SPUserDetails);
    //       setUsersDetailsFilter(SPUserDetails);
    //     } else {
    //       setLinkedSiteDetails([]);
    //       setUsersDetails([]);
    //       setUsersDetailsFilter([]);
    //     }
    //   */
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  async function fetchSPUserDetailsAdmin() {
    const result = await client.graphql({
      query: queries.listSPUserDetails,
      variables: {
        filter: {
          userGroup: {
            eq: "site_user",
          },
          sitedetailsID: {
            ne: props.data.id,
          },
          status: {
            eq: "Active",
          },
        },
        limit: 200000,
      },
      authMode: "API_KEY",
    });
    if (result.data.listSPUserDetails.items.length > 0) {
      console.log(result);
      const SPUserDetails = result.data.listSPUserDetails.items;
      await client
        .graphql({
          query: queries.linkUsersAndSitesBySitedetailsID,
          variables: {
            sitedetailsID: props.data.id,
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 10000,
          },
          authMode: "API_KEY",
        })
        .then((siteUserResult) => {
          console.log(siteUserResult);
          const linkedUsers =
            siteUserResult.data.linkUsersAndSitesBySitedetailsID.items;
          if (linkedUsers.length > 0) {
            const nonlinkeduser = [];
            linkedUsers.map((user) => {
              nonlinkeduser.push(user.userdetailsID);
            });
            console.log(
              SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
            );
            setLinkedSiteDetails(
              SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
            );
            setUsersDetails(
              SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
            );
            setUsersDetailsFilter(
              SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
            );
          } else {
            setLinkedSiteDetails(SPUserDetails);
            setUsersDetails(SPUserDetails);
            setUsersDetailsFilter(SPUserDetails);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  //--- for sp comapny filtered sp
  async function fetchSpuserforSpComp(compId) {
    const response = await client.graphql({
      query: queries.listUserDetails,
      authMode: "API_KEY",
      variables: {
        filter: {
          companyDetailsId: {
            eq: compId,
          },
        },
        limit: 200000,
      },
    });
    if (response.data.listUserDetails.items.length > 0) {
      console.log(response);
      const spuser = response.data.listUserDetails.items;
      if (spuser.length > 0) {
        const response3 = await client.graphql({
          query: queries.listSPUserDetails,
          authMode: "API_KEY",
          variables: {
            filter: {
              status: {
                eq: "Active",
              },
            },
            limit: 200000,
          },
        });
        if (response3.data.listSPUserDetails.items.length > 0) {
          console.log(response3);
          const spDetails2 = response3.data.listSPUserDetails.items;
          const SPUserDetails = spDetails2.filter((item) => {
            const _matchSites = spuser?.find((user) => user.id === item?.id);
            if (_matchSites) {
              return item;
            }
          });
          console.log(SPUserDetails);
          const siteUserResult = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            variables: {
              sitedetailsID: props.data.id,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              limit: 10000,
            },
            authMode: "API_KEY",
          });
          if (
            siteUserResult.data.linkUsersAndSitesBySitedetailsID.items.length >
            0
          ) {
            console.log(siteUserResult);
            const linkedUsers =
              siteUserResult.data.linkUsersAndSitesBySitedetailsID.items;
            if (linkedUsers.length > 0) {
              const nonlinkeduser = [];
              linkedUsers.map((user) => {
                nonlinkeduser.push(user.userdetailsID);
              });
              console.log(
                SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
              );
              setLinkedSiteDetails(
                SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
              );
              setUsersDetails(
                SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
              );
              setUsersDetailsFilter(
                SPUserDetails.filter((item) => !nonlinkeduser.includes(item.id))
              );
            } else {
              setLinkedSiteDetails(SPUserDetails);
              setUsersDetails(SPUserDetails);
              setUsersDetailsFilter(SPUserDetails);
            }
          } else {
            setLinkedSiteDetails(SPUserDetails);
            setUsersDetails(SPUserDetails);
            setUsersDetailsFilter(SPUserDetails);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (userType === "electrician") {
      console.log("service provider selected");
      fetchSpuserforSpComp(compID);
    } else {
      console.log(" site user selected ");
      setCompId("");
      if (LoginUserGroup === "admin") {
        fetchSPUserDetailsAdmin();
      } else {
        fetchSPUserDetails();
      }
    }
  }, [compID, siteDetails.length > 0]);

  // sp company name ----
  async function fetchSpCompanyname() {
    try {
      const response2 = await client.graphql({
        query: queries.listSPCompanyDetails,
        variables: {
          limit: 10000,
        },
        authMode: "API_KEY",
      });
      if (response2.data.listSPCompanyDetails.items.length > 0) {
        setspcompanyname(response2.data.listSPCompanyDetails.items);
        console.log(response2.data.listSPCompanyDetails.items);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    //fetchGetAOSite();
    if (localStorage.getItem("site") === null && siteDetails.length === 0) {
      LoginUserGroup === "site_owner"
        ? fetchSiteDetailsAO()
        : fetchSiteDetailsSU();
    } else {
      let activeSites = JSON.parse(base64.decode(localStorage.getItem("site")));
      setSiteDetails(activeSites.filter((item) => item.status === "Active"));
    }
    fetchSpCompanyname();
  }, [props.data.id]);

  async function fetchSiteDetailsAO() {
    try {
      let nextToken = "";
      let sites = [];
      const result = await client.graphql({
        query: queries.siteDetailsByAssetownerID,
        variables: {
          assetownerID: LoginUserDetails.id,
          limit: 200000,
        },
        authMode: "API_KEY",
      });
      if (result.data.siteDetailsByAssetownerID.items.length > 0) {
        let assetDatas = result.data.siteDetailsByAssetownerID.items;
        nextToken = result.data.siteDetailsByAssetownerID.nextToken;
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          sites.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: LoginUserDetails.id,
              limit: 200000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.siteDetailsByAssetownerID.items.length > 0) {
            nextToken = results.data.siteDetailsByAssetownerID.nextToken;
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              sites.push(results.data.siteDetailsByAssetownerID.items);
            }
          }
        }
        console.log(sites);
        if (sites.length > 0) {
          const mergeSites = sites.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
    }
  }

  async function fetchSiteDetailsSU() {
    try {
      //setIsLoading(true);
      const response = await client.graphql({
        query: mergedQueries.getSiteUsersFromLink,
        authMode: "API_KEY",
        variables: {
          //nextToken: '',
          assetownerID: LoginUserDetails.assetOwnerId,
          userdetailsID: LoginUserDetails.id,
        },
      });
      console.log(response);
      let nextToken = "";
      let sitess = [];
      if (response.data.siteDetailsByAssetownerID.items.length > 0) {
        let sites = response.data.siteDetailsByAssetownerID.items;
        nextToken = response.data.siteDetailsByAssetownerID.nextToken;
        for (let site of sites) {
          console.log(site);
          console.log(site.LinkUsersAndSites.items.length);
          if (site.LinkUsersAndSites.items.length > 0) {
            sitess.push(site);
          }
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: mergedQueries.getSiteUsersFromLink,
            variables: {
              nextToken: nextToken,
              assetownerID: LoginUserDetails.assetOwnerId,
              userdetailsID: LoginUserDetails.id,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          sites = results.data.siteDetailsByAssetownerID.items;
          for (let site of sites) {
            console.log(site);
            console.log(site.LinkUsersAndSites.items.length);
            if (site.LinkUsersAndSites.items.length > 0) {
              sitess.push(site);
            }
          }
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);
          setSiteDetails(mergeSites.filter((item) => item.status === "Active"));
          SiteData.setSiteData(mergeSites);
          localStorage.setItem(
            "site",
            base64.encode(JSON.stringify(mergeSites))
          );
        }
      }
    } catch (error) {
      console.log(error);
      setSiteDetails([]);
    }
  }

  useEffect(() => {
    if (!userType) {
      setUsersDetailsFilter(usersDetails);
    } else {
      setUsersDetailsFilter(
        usersDetails.filter((item) => {
          return item.userGroup === userType;
        })
      );
    }
  }, [userType]);

  function onSubmit(formData) {
    console.log(formData);
    console.log(selectedUsers);
    try {
      selectedUsers.map(async (item) => {
        await client
          .graphql({
            query: mutations.createLinkUsersAndSite,
            variables: {
              input: {
                sitedetailsID: props.data.id,
                userdetailsID: item.id,
                status: "Active",
              },
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            client.graphql({
              query: mutations.updateSPUserDetails,
              variables: {
                input: {
                  id: item.id,
                  sitedetailsID: props.data.id,
                },
              },
              authMode: "API_KEY",
            });
            await client
              .graphql({
                query: queries.getUserDetails,
                variables: {
                  id: item.id,
                },
                authMode: "API_KEY",
              })
              .then(async (result) => {
                if (result.data.getUserDetails !== null) {
                  if (result.data.getUserDetails.assetOwnerId !== null) {
                    client.graphql({
                      query: mutations.updateUserDetails,
                      variables: {
                        input: {
                          id: item.id,
                          assetOwnerId: LoginUserDetails.id,
                        },
                      },
                      authMode: "API_KEY",
                    });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
            console.log(result);
            //---ao updation if not updated
            if (compID.length > 0) {
              const compsp = spcompanyname.filter((item) => item.id === compID);
              const linkao = await client.graphql({
                query: queries.linkAOAPCompaniesBySpaoId,
                authMode: "API_KEY",
                variables: {
                  spaoId: LoginUserDetails.id,
                  filter: {
                    spcomapnyId: {
                      eq: compID,
                    },
                  },
                },
              });
              console.log(linkao);
              if (linkao.data.linkAOAPCompaniesBySpaoId.items.length > 0) {
                console.log(" Ao Already updated in Database");
              } else {
                const resultao = await client.graphql({
                  query: mutations.createLinkAOAPCompany,
                  variables: {
                    input: {
                      companyName: compsp[0].companyName,
                      companyBN: compsp[0].companyBN,
                      companyAddress: compsp[0].companyAddress,
                      companyLicense: compsp[0].companyLicense,
                      logo: compsp[0].logo,
                      status: "Active",
                      email: compsp[0].email,
                      phone: compsp[0].phone,
                      spaoId:
                        LoginUserGroup === "site_user"
                          ? LoginUserDetails.assetOwnerId
                          : LoginUserDetails.id,
                      spcomapnyId: compID,
                    },
                  },
                  authMode: "API_KEY",
                });
                console.log(resultao);
              }
              //---sp updation if not updated
              //const compsp = spcompanyname.filter((item) => item.id === compID)
              const linksp = await client.graphql({
                query: queries.linkAOAPCompaniesBySpaoId,
                authMode: "API_KEY",
                variables: {
                  spaoId: item.id,
                  filter: {
                    spcomapnyId: {
                      eq: compID,
                    },
                  },
                },
              });
              console.log(linksp);
              if (linksp.data.linkAOAPCompaniesBySpaoId.items.length > 0) {
                console.log(" Sp Already updated in Database");
              } else {
                const resultsp = await client.graphql({
                  query: mutations.createLinkAOAPCompany,
                  variables: {
                    input: {
                      companyName: compsp[0].companyName,
                      companyBN: compsp[0].companyBN,
                      companyAddress: compsp[0].companyAddress,
                      companyLicense: compsp[0].companyLicense,
                      logo: compsp[0].logo,
                      status: "Active",
                      email: compsp[0].email,
                      phone: compsp[0].phone,
                      spaoId: item.id,
                      spcomapnyId: compID,
                    },
                  },
                  authMode: "API_KEY",
                });
                console.log(resultsp);
              }
            }
            fetchSpcompany(LoginUserDetails.id);
            toast.success(
              "Users added to " +
              props.data.siteName +
              " " +
              "( " +
              props.data.unitNumber +
              ")" +
              " site successfully!!!",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            props.LinkUserModaltoggle();
          })
          .catch((error) => {
            console.log(error);
            toast.error(
              "Error While adding users to site " +
              props.data.unitNumber +
              " : " +
              error,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          });
      });
    } catch (e) {
      console.log(e);
    }
  }

  const handleDropdownChangeComp = (event) => {
    setCompId(event.target.value);
  };

  return (
    <Fragment>
      <Container>
        <form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <H4>Add Site Users and Service Providers with Site :</H4>
          <br></br>
          <Row>
            <Col md="4 mb-3">
              <Label>User Type</Label>
              <select
                className="form-control"
                placeholder="Users"
                name="userType"
                {...register("userType", { required: true })}
                onChange={(event) => setUserType(event.target.value)}
              >
                <option value="">Choose User Type...</option>
                <option value="site_user">Site Users</option>
                <option value="electrician">Service Providers</option>
              </select>
              <span>{errors.userType && "Choose User Type."}</span>
            </Col>
            <Col md="4">
              {userType === "electrician" && (
                <>
                  <Label>Service Provider Company</Label>
                  <select
                    className="form-select"
                    name="company"
                    value={compID}
                    {...register("company", { required: true })}
                    onChange={handleDropdownChangeComp}
                  >
                    <option value="">Select Service Provider Company</option>
                    {spcompanyname.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.companyName}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="4 mb-3">
              <Label>Users</Label>
              {userType === "electrician" ? (
                <Typeahead
                  id="basic-typeahead"
                  labelKey={(usersDetailsFilter) =>
                    `${usersDetailsFilter.email}`
                  }
                  multiple={true}
                  options={usersDetailsFilter}
                  placeholder="Choose Users"
                  onChange={(d) => setSelectedUsers(d)}
                />
              ) : (
                <Typeahead
                  id="basic-typeahead"
                  labelKey={(usersDetailsFilter) =>
                    `${usersDetailsFilter.fname} ${usersDetailsFilter.lname !== null
                      ? usersDetailsFilter.lname
                      : ""
                    }`
                  }
                  multiple={true}
                  options={usersDetailsFilter}
                  placeholder="Choose Users"
                  onChange={(d) => setSelectedUsers(d)}
                />
              )}
            </Col>
            <Col md="4 mb-3">
              <br></br>
              <br></br>
              <Btn attrBtn={{ color: "primary" }}>Link User with Site</Btn>
            </Col>
          </Row>
        </form>
      </Container>
    </Fragment>
  );
};

export default LinkUserwithSiteDetails;
