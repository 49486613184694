import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Btn, H3, UL, LI } from "../../AbstractElements";
import {
  Container,
  Row,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Media,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../assets/scss/index.less";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";

import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import * as mergedQueries from "../../graphql/mergedQueries";
import { toast } from "react-toastify";
import awsExports from "../../aws-exports";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import ScrollBar from "react-perfect-scrollbar";
import AWS from "aws-sdk";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  REGION,
  ACCESSKEYID,
  SECRETACCESSKEY,
  USERPOOLID,
  COMPANY_FOLDER,
  S3_BUCKET,
  limit,
} from "../../Config/Config";
import { SiteContext } from "../../App";
import { SiteDetailsContext } from "../../App";
import { Typeahead } from "react-bootstrap-typeahead";
import { nanoid } from "nanoid";
import Autocomplete from "react-google-autocomplete";
import DeniReactTreeView from "deni-react-treeview";
import { aocontext } from "../../App";
import SPCompany from "../../Data/SPCompanyData/index";
import SiteData from "../../Data/Sites/index";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const ServiceProviderDetails = () => {
  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    e,
    ...form
  } = useForm();
  const [listofServiceProviders, setlistofServiceProviders] = useState([]);
  const [spcompanyname, setspcompanyname] = useState([]);
  const [spCompany, setSpCompany] = useState(SPCompany.getSPCompany());
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [collection, setCollection] = React.useState([]);
  const [sites, setsites] = React.useState([]);
  const [sitesSelected, setsitesSelected] = React.useState([]);
  const [permissions, setPermissions] = useState([]);
  const [spglobal, setspglobal] = useState([]);
  const [siteError, setSiteError] = useState(false);
  const [siteListVersion, setSiteListVersion] = useState(false);
  const { selectedAssetOwnerGlobal, handleAssetOwner } = useContext(aocontext);

  const permissionsList = [
    //"Create Assets",
    //"Import Assets",
    //"Edit Assets",
    //"View Assets",
    "Create Tasks",
    "Assign Tasks",
    "View Tasks",
    "Export Tasks",
    //"Create Sites",
    //"Edit Sites",
    //"View Sites",
    //"Import Sites",
    "Create Certificates",
    "View Certificates",
    "Download Reports",
    "View Reports",
  ];

  AWS.config.update({
    accessKeyId: ACCESSKEYID,
    secretAccessKey: SECRETACCESSKEY,
  });

  const COGNITO_CLIENT = new AWS.CognitoIdentityServiceProvider({
    apiVersion: "2022-10-03",
    region: "ap-southeast-2",
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setItemforPermission(item);
    fnPermissionPopup(item);
  };

  const [showAddCompany, setShowAddCompany] = useState(false);
  const [companyAdress, setCompanyAdress] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [addressError, setAddressError] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState("");

  const [showInvite, setShowInvite] = useState(false);
  const handleShowInvite = () => setShowInvite(true);
  const handleCloseInvite = () => {
    reset();
    setShowAddCompany(false);
    setShowInvite(false);
  };

  const [itemforPermission, setItemforPermission] = useState([]);
  const [userSite, setUserSite] = useState([]);
  const [username, setusername] = useState([]);
  const [usercomp, setusercomp] = useState([]);
  const [spDB, setSpDB] = useState([]);
  const [siteuserexist, setsiteuserexist] = useState(SiteData.getSiteData());

  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const LoggedInUserId = LoginUserDetails.id;
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
      LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];

  const [filteredData, setFilteredData] = React.useState([]);

  const fnPermissionPopup = (item) => {
    if (item.permission) {
      setPermissions(JSON.parse(item.permission));
    } else {
      setPermissions([]);
    }
  };
  const LoginUserGroup = localStorage.getItem("groups");
  var status = LoginUserGroup === "admin" ? "Active" : "Inactive";

  const handleEdit = useCallback((row) => navigate(`/addedit/${row.id}`), []);
  const handleView = useCallback(
    (row) => navigate(`/addedit/${row.id}/view`),
    []
  );

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  const { siteDetailsGlobal, handleSiteDetailsChange } =
    useContext(SiteDetailsContext);
  //-----------------Permissions-----

  const editPermissions = async () => {
    try {
      console.log("inside editPermission function");
      console.log(permissions);
      console.log(itemforPermission);
      let otherPerm = ["View Logbooks", "View Service Providers"];
      let permSet = new Set(permissions);
      otherPerm.forEach((permission) => permSet.add(permission));
      let perm = Array.from(permSet);
      console.log(perm);

      client
        .graphql({
          query: mutations.updateUserDetails,
          variables: {
            input: {
              id: itemforPermission.id,
              permissions: JSON.stringify(perm),
            },
          },
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });

      await client
        .graphql({
          query: mutations.updateSPUserDetails,
          variables: {
            input: {
              id: itemforPermission.id,
              permission: JSON.stringify(perm),
            },
          },
        })
        .then((result) => {
          toast.success(" Updated successfully.", {
            position: toast.POSITION.TOP_CENTER,
          });
          window.location = `/addedit/${itemforPermission.id}/view`;
        })
        .catch((error) => {
          console.error(error);
          toast.error(" Error while Updating ", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  async function fetchUser() {
    //--
    try {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listUserDetails,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      });
      if (result.data.listUserDetails.items.length > 0) {
        let assetDatas = result.data.listUserDetails.items;
        nextToken = result.data.listUserDetails.nextToken;
        // console.log(
        //   "first token count",
        //   result.data.listUserDetails.items.length
        // );
        // console.log("nextToken 1", nextToken);
        if (result.data.listUserDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listUserDetails,
            variables: {
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listUserDetails.items.length > 0) {
            nextToken = results.data.listUserDetails.nextToken;
            // console.log("nextToken", nextToken);
            // console.log(results.data.listUserDetails.items.length);
            if (results.data.listUserDetails.items.length > 0) {
              assets.push(results.data.listUserDetails.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setusername(mergeAssets);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchspusercompany() {
    try {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listSPCompanyDetails,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      });
      if (result.data.listSPCompanyDetails.items.length > 0) {
        let assetDatas = result.data.listSPCompanyDetails.items;
        nextToken = result.data.listSPCompanyDetails.nextToken;
        console.log(
          "first token count",
          result.data.listSPCompanyDetails.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listSPCompanyDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listSPCompanyDetails,
            variables: {
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listSPCompanyDetails.items.length > 0) {
            nextToken = results.data.listSPCompanyDetails.nextToken;
            console.log("nextToken", nextToken);
            console.log(results.data.listSPCompanyDetails.items.length);
            if (results.data.listSPCompanyDetails.items.length > 0) {
              assets.push(results.data.listSPCompanyDetails.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setusercomp(mergeAssets);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getUsername(spid) {
    const name = username.find((item) => item.id === spid);
    return name ? name.username : "";
  }

  function getCompnayLicence(spid) {
    const name = username.find((item) => item.id === spid);
    //console.log(name)
    if (name) {
      const assetOwnerId = name.companyDetailsId;
      const assetOwnerNew = usercomp.find((item) => item.id === assetOwnerId);
      if (assetOwnerNew) {
        return assetOwnerNew ? assetOwnerNew.companyLicense : "";
      }
    }
    return "";
  }

  function getUsercompnay(spid) {
    //const name = username.find((item) => item.id === spid);
    //console.log(name)
    if (spid) {
      //const assetOwnerId = name.companyDetailsId;
      const assetOwnerNew = usercomp.find((item) => item.id === spid);
      if (assetOwnerNew) {
        return assetOwnerNew ? assetOwnerNew.companyName : "";
      }
    }
    return "";
  }

  async function fetchSp() {
    try {
      const result = await client.graphql({
        query: queries.listSPUserDetails,
        authMode: "API_KEY",
      });
      const sites = result.data.listSPUserDetails.items;
      if (sites.length > 0) {
        let nextToken = result.data.listSPUserDetails.nextToken;
        let spUsers = [];
        if (sites.length > 0) {
          spUsers.push(sites);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listSPUserDetails,
            variables: {
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listSPUserDetails.items.length > 0) {
            nextToken = results.data.listSPUserDetails.nextToken;
            if (results.data.listSPUserDetails.items.length > 0) {
              spUsers.push(results.data.listSPUserDetails.items);
            }
          }
        }
        if (spUsers.length > 0) {
          console.log(sites);
          setSpDB(sites);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchSitesForUserExist() {
    try {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: 2000000,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
        authMode: "API_KEY",
      });
      if (result.data.listSiteDetails.items.length > 0) {
        let assetDatas = result.data.listSiteDetails.items;
        nextToken = result.data.listSiteDetails.nextToken;
        if (result.data.listSiteDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listSiteDetails,
            variables: {
              limit: 2000000,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listSiteDetails.items.length > 0) {
            nextToken = results.data.listSiteDetails.nextToken;
            if (results.data.listSiteDetails.items.length > 0) {
              assets.push(results.data.listSiteDetails.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setsiteuserexist(mergeAssets);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  //---------------------------Add/Remove sites-----------------------------------------------

  const onSubmit = useCallback(async (formData, e) => {
    setLoading(true);
    // setIsLoading(true);
    console.log(formData);
    let dupLicence = "";
    if (formData.Licencenumb !== undefined) {
      dupLicence = spcompanyname.filter((company) => {
        if (company.companyLicense != null) {
          return (
            company.companyLicense.toLowerCase() ===
            formData.Licencenumb.toLowerCase()
          );
        }
        return false;
      });
    }
    if (dupLicence.length === 0) {
      console.log(permissions);
      let otherPerm = ["View Logbooks", "View Service Providers"];
      let perm = [];
      if (permissions.length > 0) {
        perm = permissions.concat(otherPerm);
      }
      console.log(perm);
      if (sitesSelected.length > 0) {
        setSiteError(false);
        try {
          const result = await client.graphql({
            query: queries.userDetailsByEmail,
            variables: {
              email: formData.email,
            },
            authMode: "API_KEY",
          });
          // .then(async (result) => {
          console.log(result.data.userDetailsByEmail.items);
          const emailcompfilter =
            result.data.userDetailsByEmail.items.length > 0 &&
            result.data.userDetailsByEmail.items.filter(
              (item) => item.companyDetailsId === formData.company
            );
          console.log(emailcompfilter);
          if (emailcompfilter.length > 0) {
            let spUserId = result.data.userDetailsByEmail.items;

            const filteredUsers = await client.graphql({
              query: queries.getSPUserDetails,
              variables: {
                id: emailcompfilter[0].id,
              },
              authMode: "API_KEY",
            });
            // const filteredUsers = spDB.filter((item) => {
            //   return item.id === spUserId[0].id;
            // });
            console.log(filteredUsers);
            if (filteredUsers !== null) {
              if (
                filteredUsers.data.getSPUserDetails.userGroup !== "site_user"
              ) {
                let successCount = 0;
                let siteIds = [];
                let siteIdsmatch = [];
                let dataCount = 0;
                let errorCount = 0;
                console.log("some sites exist");
                if (showAddCompany) {
                  console.log("New company");
                  let userSub = "";
                  let userEmail = "";
                  let userName = "";
                  try {
                    let un = formData.email.split("@");
                    userName = un[0].toLowerCase() + "elect";
                    console.log("different Email");
                    let nname = "";
                    nname = userName + nanoid(5);
                    userName = nname.toLowerCase();
                    const cognitoParams = {
                      UserPoolId: USERPOOLID,
                      Username: userName.replace(/\s/g, ""),
                      UserAttributes: [
                        {
                          Name: "email",
                          Value: formData.email,
                        },
                      ],
                      DesiredDeliveryMediums: ["EMAIL"],
                      TemporaryPassword: userName + "Z@123456789",
                      ForceAliasCreation: false,
                    };
                    console.log(cognitoParams);
                    let response = await COGNITO_CLIENT.adminCreateUser(
                      cognitoParams
                    ).promise();
                    // await COGNITO_CLIENT.adminSetUserMFAPreference({
                    //   UserPoolId: USERPOOLID,
                    //   Username: userName.replace(/\s/g, ""),
                    //   SoftwareTokenMfaSettings: {
                    //     Enabled: true,
                    //     PreferredMfa: true,
                    //   },
                    // }).promise();
                    console.log(JSON.stringify(response));
                    const usersDetails = response.User.Attributes;
                    usersDetails.forEach((item) => {
                      if (item.Name === "sub") {
                        userSub = item.Value;
                      }
                      if (item.Name === "email") {
                        userEmail = item.Value;
                      }
                    });
                    // Verify the user's email
                    const emailVerificationParams = {
                      UserPoolId: USERPOOLID,
                      Username: userName.replace(/\s/g, ""),
                      UserAttributes: [
                        {
                          Name: "email_verified",
                          Value: "true",
                        },
                      ],
                    };

                    let emailVerificationResponse =
                      await COGNITO_CLIENT.adminUpdateUserAttributes(
                        emailVerificationParams
                      ).promise();
                    console.log(emailVerificationResponse);

                    var params = {
                      GroupName: "electrician" /* required */,
                      UserPoolId: USERPOOLID /* required */,
                      Username: userName.replace(/\s/g, "") /* required */,
                    };
                    let groupResponse =
                      await COGNITO_CLIENT.adminAddUserToGroup(
                        params
                      ).promise();
                    console.log(groupResponse);
                    // create new company
                    try {
                      await client
                        .graphql({
                          query: mutations.createSPCompanyDetails,
                          variables: {
                            input: {
                              companyName: formData.companyname,
                              companyBN: formData.businessNumber,
                              companyAddress: companyAdress,
                              companyLicense: formData.Licencenumb,
                              logo: selectedFile,
                              status: "Active",
                              phone: formData.companyPhone,
                              email: formData.companyEmail,
                            },
                          },
                          authMode: "API_KEY",
                        })
                        .then(async (result) => {
                          console.log(result);
                          console.log(result.data.createSPCompanyDetails.id);

                          const addedsite = await client.graphql({
                            query: queries.getSPCompanyDetails,
                            variables: {
                              id: result.data.createSPCompanyDetails.id,
                            },
                            authMode: "API_KEY",
                          });
                          if (addedsite.data.getSPCompanyDetails !== null) {
                            if (localStorage.getItem("spCompany") !== null) {
                              let activeDB = JSON.parse(
                                base64.decode(localStorage.getItem("spCompany"))
                              );
                              console.log(activeDB);
                              activeDB.push(addedsite.data.getSPCompanyDetails);
                              console.log(activeDB);
                              localStorage.setItem(
                                "spCompany",
                                base64.encode(JSON.stringify(activeDB))
                              );
                            }
                          }
                          const resultsp = await client.graphql({
                            query: mutations.createLinkAOAPCompany,
                            variables: {
                              input: {
                                companyName: formData.companyname,
                                companyBN: formData.businessNumber,
                                companyAddress: companyAdress,
                                companyLicense: formData.Licencenumb,
                                logo: selectedFile,
                                status: "Active",
                                email: formData.companyEmail,
                                phone: formData.companyPhone,
                                spaoId: userSub,
                                spcomapnyId:
                                  result.data.createSPCompanyDetails.id,
                              },
                            },
                            authMode: "API_KEY",
                          });
                          console.log(resultsp);
                          //--ao
                          const resultao = await client.graphql({
                            query: mutations.createLinkAOAPCompany,
                            variables: {
                              input: {
                                companyName: formData.companyname,
                                companyBN: formData.businessNumber,
                                companyAddress: companyAdress,
                                companyLicense: formData.Licencenumb,
                                logo: selectedFile,
                                status: "Active",
                                email: formData.companyEmail,
                                phone: formData.companyPhone,
                                spaoId:
                                  LoginUserGroup === "site_user"
                                    ? LoginUserDetails.assetOwnerId
                                    : LoginUserDetails.id,
                                spcomapnyId:
                                  result.data.createSPCompanyDetails.id,
                              },
                            },
                            authMode: "API_KEY",
                          });
                          console.log(resultao);
                          fetchSpCompanyname();
                          toast.success("Successfully added new Company", {
                            position: toast.POSITION.TOP_CENTER,
                          });
                          // create new user in USERDETAILS DB
                          await client.graphql({
                            query: mutations.createUserDetails,
                            variables: {
                              input: {
                                id: userSub,
                                username: userName.replace(/\s/g, ""),
                                email: userEmail,
                                assetOwnerId: LoggedInUserId,
                                companyDetailsId: result.data
                                  .createSPCompanyDetails.id
                                  ? result.data.createSPCompanyDetails.id
                                  : formData.company,
                                status: "Active",
                                permissions: showAddCompany
                                  ? JSON.stringify(perm)
                                  : [],
                              },
                            },
                            authMode: "API_KEY",
                          });
                          // create new user in SP USERDETAILS DB
                          await client
                            .graphql({
                              query: mutations.createSPUserDetails,
                              variables: {
                                input: {
                                  id: userSub,
                                  fname: formData.firstName,
                                  lname: formData.lastName,
                                  email: formData.email,
                                  userGroup: "electrician",
                                  invitedBy: LoggedInUserId,
                                  status: status,
                                  SPGroup: showAddCompany ? "admin" : "",
                                  permission: showAddCompany
                                    ? JSON.stringify(perm)
                                    : [],
                                  spcompanydetailsID: result.data
                                    .createSPCompanyDetails.id
                                    ? result.data.createSPCompanyDetails.id
                                    : formData.company,
                                },
                              },
                              authMode: "API_KEY",
                            })
                            .then(async (res) => {
                              console.log(res);
                              const addedsite = await client.graphql({
                                query: queries.getSPUserDetails,
                                variables: {
                                  id: userSub,
                                },
                                authMode: "API_KEY",
                              });
                              if (addedsite.data.getSPUserDetails !== null) {
                                if (localStorage.getItem("spuser") !== null) {
                                  let activeSites = JSON.parse(
                                    base64.decode(
                                      localStorage.getItem("spuser")
                                    )
                                  );
                                  console.log(activeSites);
                                  activeSites.push(
                                    addedsite.data.getSPUserDetails
                                  );
                                  console.log(activeSites);
                                  // localStorage.setItem(
                                  //   "spuser",
                                  //   base64.encode(JSON.stringify(activeSites))
                                  // );
                                }
                              }
                              e.target.reset();
                              setShowAddCompany(!showAddCompany);
                              setShowInvite(false);
                              fetchspusercompany();
                              reset();
                              fetchUser();
                              sitesSelected?.map(async (item) => {
                                await client.graphql({
                                  query: mutations.updateSPUserDetails,
                                  variables: {
                                    input: {
                                      sitedetailsID: item.id,
                                      id: userSub,
                                    },
                                  },
                                  authMode: "API_KEY",
                                });
                                await client
                                  .graphql({
                                    query: mutations.createLinkUsersAndSite,
                                    variables: {
                                      input: {
                                        sitedetailsID: item.id,
                                        userdetailsID: userSub,
                                        status: "Active",
                                      },
                                    },
                                    authMode: "API_KEY",
                                  })
                                  .then((result) => {
                                    console.log(result);
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                    toast.error(" Error while Creating ", {
                                      position: toast.POSITION.TOP_CENTER,
                                    });
                                  });
                              });
                              setSiteListVersion(true);
                              listSPDetails("All Time");
                              setLoading(false);
                              setIsLoading(false);
                              reset();
                              setsitesSelected([]);
                              toast.success("Created successfully.", {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            })
                            .catch((errors) => {
                              toast.error(errors, {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            });
                        })
                        .catch((error) => {
                          toast.error(
                            "Error while adding new Company, try again",
                            {
                              position: toast.POSITION.TOP_CENTER,
                            }
                          );
                          console.error(error);
                        });
                    } catch (error) {
                      console.error(error);
                    }
                  } catch (err) {
                    console.error(err);
                    toast.error(err.message, {
                      position: toast.POSITION.TOP_CENTER,
                    });
                  }
                  //end of add new company create new sp as admin
                } else {
                  await client
                    .graphql({
                      query: queries.linkUsersAndSitesByUserdetailsID,
                      variables: {
                        userdetailsID: filteredUsers.data.getSPUserDetails.id,
                        // filter: {
                        //   status: {
                        //     eq: "Active"
                        //   }
                        // },
                      },
                      authMode: "API_KEY",
                    })
                    .then(async (result) => {
                      console.log(result);
                      const filterUser =
                        result.data.linkUsersAndSitesByUserdetailsID.items;
                      console.log(filterUser);
                      const sitedetailsIDs = filterUser.map(
                        (item) => item.sitedetailsID
                      );
                      console.log(sitedetailsIDs);
                      const FilterSites = siteuserexist.filter((item) =>
                        sitedetailsIDs.includes(item.id)
                      );
                      console.log(FilterSites);
                      console.log(sitesSelected);
                      sitesSelected?.map(async (item) => {
                        const matchingSite = FilterSites.find(
                          (site) => site.id === item.id
                        );

                        if (matchingSite) {
                          successCount = successCount + 1;
                          errorCount = errorCount + 1;
                          siteIdsmatch.push(item.id);
                        } else {
                          await client
                            .graphql({
                              query: mutations.createLinkUsersAndSite,
                              variables: {
                                input: {
                                  sitedetailsID: item.id,
                                  userdetailsID:
                                    filteredUsers.data.getSPUserDetails.id,
                                  status: "Active",
                                },
                              },
                              authMode: "API_KEY",
                            })
                            .then((result) => {
                              console.log(result);
                              successCount = successCount + 1;
                              console.log(successCount);
                              dataCount = dataCount + 1;
                              siteIds.push(item.id);
                              console.log(siteIds);
                              if (sitesSelected.length === successCount) {
                                const matchingSite = siteuserexist.filter(
                                  (item) => siteIds.includes(item.id)
                                );
                                const siteNames = matchingSite.map(
                                  (site) =>
                                    `${site.siteName} (${site.unitNumber})`
                                );
                                const concatenatedSiteNames =
                                  siteNames.join(", ");

                                toast.success(
                                  `Created Successfully for sites: ${concatenatedSiteNames}`,
                                  {
                                    position: toast.POSITION.TOP_CENTER,
                                  }
                                );
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                              toast.error("Error while Creating ", {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            });

                          setLoading(false);
                          setIsLoading(false);
                          e.target.reset();
                          setShowInvite(false);
                          fetchspusercompany();
                          setSiteListVersion(true);
                          reset();
                          listSPDetails("All Time");
                          reset();
                          fetchUser();
                          setsitesSelected([]);
                          //await fetchLinkUserSite();
                          await fetchSp();
                        }
                      });
                      if (errorCount > 0) {
                        const matchingSite = siteuserexist.filter((item) =>
                          siteIdsmatch.includes(item.id)
                        );
                        const siteNames = matchingSite.map(
                          (site) => `${site.siteName} (${site.unitNumber})`
                        );
                        const concatenatedSiteNames = siteNames.join(", ");
                        toast.success(
                          `User has already accepted the invitation for site:  ${concatenatedSiteNames}`,
                          {
                            position: toast.POSITION.TOP_CENTER,
                          }
                        );
                        setLoading(false);
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }
              } else {
                //User already exist as Site User
                let userSub = "";
                let userEmail = "";
                let userName = "";
                try {
                  let un = formData.email.split("@");
                  userName = un[0].toLowerCase() + "elect";
                  await client
                    .graphql({
                      query: queries.listUserDetails,
                      variables: {
                        limit: 10000,
                        filter: {
                          username: {
                            eq: userName.replace(/\s/g, ""),
                          },
                          email: {
                            eq: formData.email,
                          },
                        },
                      },
                      authMode: "API_KEY",
                    })
                    .then(async (userD) => {
                      console.log(userD.data.listUserDetails.items);
                      if (userD.data.listUserDetails.items.length > 0) {
                        console.log("Same Email");
                        toast.success(
                          `User has already accepted the invitation for all selected site/s`,
                          {
                            position: toast.POSITION.TOP_CENTER,
                          }
                        );
                        setLoading(false);
                      } else {
                        console.log("different Email");
                        let nname = "";
                        nname = userName + nanoid(5);
                        userName = nname.toLowerCase();
                        const cognitoParams = {
                          UserPoolId: USERPOOLID,
                          Username: userName.replace(/\s/g, ""),
                          UserAttributes: [
                            {
                              Name: "email",
                              Value: formData.email,
                            },
                          ],
                          DesiredDeliveryMediums: ["EMAIL"],
                          TemporaryPassword: userName + "Z@123456789",
                          ForceAliasCreation: false,
                        };
                        console.log(cognitoParams);
                        let response = await COGNITO_CLIENT.adminCreateUser(
                          cognitoParams
                        ).promise();
                        // await COGNITO_CLIENT.adminSetUserMFAPreference({
                        //   UserPoolId: USERPOOLID,
                        //   Username: userName.replace(/\s/g, ""),
                        //   SoftwareTokenMfaSettings: {
                        //     Enabled: true,
                        //     PreferredMfa: true,
                        //   },
                        // }).promise();
                        console.log(JSON.stringify(response));
                        const usersDetails = response.User.Attributes;
                        usersDetails.forEach((item) => {
                          if (item.Name === "sub") {
                            userSub = item.Value;
                          }
                          if (item.Name === "email") {
                            userEmail = item.Value;
                          }
                        });
                        // Verify the user's email
                        const emailVerificationParams = {
                          UserPoolId: USERPOOLID,
                          Username: userName.replace(/\s/g, ""),
                          UserAttributes: [
                            {
                              Name: "email_verified",
                              Value: "true",
                            },
                          ],
                        };

                        let emailVerificationResponse =
                          await COGNITO_CLIENT.adminUpdateUserAttributes(
                            emailVerificationParams
                          ).promise();
                        console.log(emailVerificationResponse);
                        if (showAddCompany) {
                          console.log("New company");
                          console.log(formData);
                          try {
                            await client
                              .graphql({
                                query: mutations.createSPCompanyDetails,
                                variables: {
                                  input: {
                                    companyName: formData.companyname,
                                    companyBN: formData.businessNumber,
                                    companyAddress: companyAdress,
                                    companyLicense: formData.Licencenumb,
                                    logo: selectedFile,
                                    status: "Active",
                                    phone: formData.companyPhone,
                                    email: formData.companyEmail,
                                  },
                                },
                                authMode: "API_KEY",
                              })
                              .then(async (result) => {
                                console.log(result);
                                console.log(
                                  result.data.createSPCompanyDetails.id
                                );
                                const addedsite = await client.graphql({
                                  query: queries.getSPCompanyDetails,
                                  variables: {
                                    id: result.data.createSPCompanyDetails.id,
                                  },
                                  authMode: "API_KEY",
                                });
                                if (
                                  addedsite.data.getSPCompanyDetails !== null
                                ) {
                                  if (
                                    localStorage.getItem("spCompany") !== null
                                  ) {
                                    let activeDB = JSON.parse(
                                      base64.decode(
                                        localStorage.getItem("spCompany")
                                      )
                                    );
                                    console.log(activeDB);
                                    activeDB.push(
                                      addedsite.data.getSPCompanyDetails
                                    );
                                    console.log(activeDB);
                                    localStorage.setItem(
                                      "spCompany",
                                      base64.encode(JSON.stringify(activeDB))
                                    );
                                  }
                                }
                                const resultsp = await client.graphql({
                                  query: mutations.createLinkAOAPCompany,
                                  variables: {
                                    input: {
                                      companyName: formData.companyname,
                                      companyBN: formData.businessNumber,
                                      companyAddress: companyAdress,
                                      companyLicense: formData.Licencenumb,
                                      logo: selectedFile,
                                      status: "Active",
                                      email: formData.companyEmail,
                                      phone: formData.companyPhone,
                                      spaoId: userSub,
                                      spcomapnyId:
                                        result.data.createSPCompanyDetails.id,
                                    },
                                  },
                                  authMode: "API_KEY",
                                });
                                console.log(resultsp);
                                //--ao
                                const resultao = await client.graphql({
                                  query: mutations.createLinkAOAPCompany,
                                  variables: {
                                    input: {
                                      companyName: formData.companyname,
                                      companyBN: formData.businessNumber,
                                      companyAddress: companyAdress,
                                      companyLicense: formData.Licencenumb,
                                      logo: selectedFile,
                                      status: "Active",
                                      email: formData.companyEmail,
                                      phone: formData.companyPhone,
                                      spaoId:
                                        LoginUserGroup === "site_user"
                                          ? LoginUserDetails.assetOwnerId
                                          : LoginUserDetails.id,
                                      spcomapnyId:
                                        result.data.createSPCompanyDetails.id,
                                    },
                                  },
                                  authMode: "API_KEY",
                                });
                                console.log(resultao);
                                fetchSpCompanyname();
                                toast.success(
                                  "Successfully added new Company",
                                  {
                                    position: toast.POSITION.TOP_CENTER,
                                  }
                                );
                                client.graphql({
                                  query: mutations.createUserDetails,
                                  variables: {
                                    input: {
                                      id: userSub,
                                      username: userName.replace(/\s/g, ""),
                                      email: userEmail,
                                      assetOwnerId: LoggedInUserId,
                                      companyDetailsId:
                                        result.data.createSPCompanyDetails.id,
                                      status: "Active",
                                      permissions: JSON.stringify(perm),
                                    },
                                  },
                                  authMode: "API_KEY",
                                });
                                setNewCompanyId(
                                  result.data.createSPCompanyDetails.id
                                );
                              })
                              .catch((error) => {
                                toast.error(
                                  "Error while adding new Company, try again",
                                  {
                                    position: toast.POSITION.TOP_CENTER,
                                  }
                                );
                                console.error(error);
                              });
                          } catch (error) {
                            console.error(error);
                          }
                        } else {
                          client.graphql({
                            query: mutations.createUserDetails,
                            variables: {
                              input: {
                                id: userSub,
                                username: userName.replace(/\s/g, ""),
                                email: userEmail,
                                assetOwnerId: LoggedInUserId,
                                companyDetailsId: formData.company,
                                status: "Active",
                                //permissions: JSON.stringify(perm),
                              },
                            },
                            authMode: "API_KEY",
                          });
                        }
                        var params = {
                          GroupName: "electrician" /* required */,
                          UserPoolId: USERPOOLID /* required */,
                          Username: userName.replace(/\s/g, "") /* required */,
                        };
                        let groupResponse =
                          await COGNITO_CLIENT.adminAddUserToGroup(
                            params
                          ).promise();
                        console.log(groupResponse);
                        //} catch (err) {
                        //    console.log(err);
                        // }
                        //create cognito user
                        //---sp id updation in linkaospcompany
                        const compsp = usercomp.filter(
                          (item) => item.id === formData.company
                        );
                        console.log(compsp);
                        const resultsp = await client.graphql({
                          query: mutations.createLinkAOAPCompany,
                          variables: {
                            input: {
                              companyName: compsp[0].companyName,
                              companyBN: compsp[0].companyBN,
                              companyAddress: compsp[0].companyAddress,
                              companyLicense: compsp[0].companyLicense,
                              logo: compsp[0].logo,
                              status: "Active",
                              email: compsp[0].email,
                              phone: compsp[0].phone,
                              spaoId: userSub,
                              spcomapnyId: formData.company,
                            },
                          },
                          authMode: "API_KEY",
                        });
                        console.log(resultsp);
                        //---ao/su updation if not updated
                        const linkao = await client.graphql({
                          query: queries.linkAOAPCompaniesBySpaoId,
                          authMode: "API_KEY",
                          variables: {
                            spaoId: LoginUserDetails.id,
                            filter: {
                              spcomapnyId: {
                                eq: formData.company,
                              },
                            },
                          },
                        });
                        console.log(linkao);
                        if (
                          linkao.data.linkAOAPCompaniesBySpaoId.items.length > 0
                        ) {
                          console.log("Already updated in Database");
                        } else {
                          const resultao = await client.graphql({
                            query: mutations.createLinkAOAPCompany,
                            variables: {
                              input: {
                                companyName: compsp[0].companyName,
                                companyBN: compsp[0].companyBN,
                                companyAddress: compsp[0].companyAddress,
                                companyLicense: compsp[0].companyLicense,
                                logo: compsp[0].logo,
                                status: "Active",
                                email: compsp[0].email,
                                phone: compsp[0].phone,
                                spaoId:
                                  LoginUserGroup === "site_user"
                                    ? LoginUserDetails.assetOwnerId
                                    : LoginUserDetails.id,
                                spcomapnyId: formData.company,
                              },
                            },
                            authMode: "API_KEY",
                          });
                          console.log(resultao);
                        }

                        client
                          .graphql({
                            query: mutations.createSPUserDetails,
                            variables: {
                              input: {
                                id: userSub,
                                fname: formData.firstName,
                                lname: formData.lastName,
                                email: formData.email,
                                userGroup: "electrician",
                                invitedBy: LoggedInUserId,
                                status: status,
                                SPGroup: showAddCompany ? "admin" : "",
                                permission: showAddCompany
                                  ? JSON.stringify(perm)
                                  : [],
                                spcompanydetailsID: formData.company,
                              },
                            },
                            authMode: "API_KEY",
                          })
                          .then(async (res) => {
                            console.log(res);
                            const addedsite = await client.graphql({
                              query: queries.getSPUserDetails,
                              variables: {
                                id: userSub,
                              },
                              authMode: "API_KEY",
                            });
                            if (addedsite.data.getSPUserDetails !== null) {
                              if (localStorage.getItem("spuser") !== null) {
                                let activeSites = JSON.parse(
                                  base64.decode(localStorage.getItem("spuser"))
                                );
                                console.log(activeSites);
                                activeSites.push(
                                  addedsite.data.getSPUserDetails
                                );
                                console.log(activeSites);
                                // localStorage.setItem(
                                //   "spuser",
                                //   base64.encode(JSON.stringify(activeSites))
                                // );
                              }
                            }
                            e.target.reset();
                            setShowAddCompany(!showAddCompany);
                            setShowInvite(false);
                            fetchspusercompany();
                            reset();
                            fetchUser();
                            sitesSelected?.map((item) => {
                              client.graphql({
                                query: mutations.updateSPUserDetails,
                                variables: {
                                  input: {
                                    sitedetailsID: item.id,
                                    id: userSub,
                                  },
                                },
                                authMode: "API_KEY",
                              });
                              client
                                .graphql({
                                  query: mutations.createLinkUsersAndSite,
                                  variables: {
                                    input: {
                                      sitedetailsID: item.id,
                                      userdetailsID: userSub,
                                      status: "Active",
                                    },
                                  },
                                  authMode: "API_KEY",
                                })
                                .then((result) => {
                                  console.log(result);
                                })
                                .catch((error) => {
                                  console.error(error);
                                  toast.error(" Error while Creating ", {
                                    position: toast.POSITION.TOP_CENTER,
                                  });
                                });
                            });
                            setSiteListVersion(true);
                            listSPDetails("All Time");
                            reset();
                            setsitesSelected([]);
                            setLoading(false);
                            setIsLoading(false);
                            toast.success(" Created successfully.", {
                              position: toast.POSITION.TOP_CENTER,
                            });
                          })
                          .catch((errors) => {
                            toast.error(errors, {
                              position: toast.POSITION.TOP_CENTER,
                            });
                          });
                      }
                    });
                } catch (err) {
                  console.error(err);
                  if (err.message === "User account already exists") {
                  }
                  toast.error(err.message, {
                    position: toast.POSITION.TOP_CENTER,
                  });
                }

                //------
              }
            }
          } else {
            // user exists for different company
            let userSub = "";
            let userEmail = "";
            let userName = "";
            try {
              let un = formData.email.split("@");
              userName = un[0].toLowerCase() + "elect";
              await client
                .graphql({
                  query: queries.listUserDetails,
                  variables: {
                    limit: 10000,
                    filter: {
                      username: {
                        eq: userName.replace(/\s/g, ""),
                      },
                      email: {
                        eq: formData.email,
                      },
                    },
                  },
                  authMode: "API_KEY",
                })
                .then(async (userD) => {
                  console.log(userD.data.listUserDetails.items);
                  if (userD.data.listUserDetails.items.length > 0) {
                    console.log("Same Email");
                    toast.success(
                      `User has already accepted the invitation for all selected site/s`,
                      {
                        position: toast.POSITION.TOP_CENTER,
                      }
                    );
                  } else {
                    console.log("different Email");
                    let nname = "";
                    nname = userName + nanoid(5);
                    userName = nname.toLowerCase();
                    const cognitoParams = {
                      UserPoolId: USERPOOLID,
                      Username: userName.replace(/\s/g, ""),
                      UserAttributes: [
                        {
                          Name: "email",
                          Value: formData.email,
                        },
                      ],
                      DesiredDeliveryMediums: ["EMAIL"],
                      TemporaryPassword: userName + "Z@123456789",
                      ForceAliasCreation: false,
                    };
                    console.log(cognitoParams);
                    let response = await COGNITO_CLIENT.adminCreateUser(
                      cognitoParams
                    ).promise();
                    // await COGNITO_CLIENT.adminSetUserMFAPreference({
                    //   UserPoolId: USERPOOLID,
                    //   Username: userName.replace(/\s/g, ""),
                    //   SoftwareTokenMfaSettings: {
                    //     Enabled: true,
                    //     PreferredMfa: true,
                    //   },
                    // }).promise();
                    console.log(JSON.stringify(response));
                    const usersDetails = response.User.Attributes;
                    usersDetails.forEach((item) => {
                      if (item.Name === "sub") {
                        userSub = item.Value;
                      }
                      if (item.Name === "email") {
                        userEmail = item.Value;
                      }
                    });
                    // Verify the user's email
                    const emailVerificationParams = {
                      UserPoolId: USERPOOLID,
                      Username: userName.replace(/\s/g, ""),
                      UserAttributes: [
                        {
                          Name: "email_verified",
                          Value: "true",
                        },
                      ],
                    };

                    let emailVerificationResponse =
                      await COGNITO_CLIENT.adminUpdateUserAttributes(
                        emailVerificationParams
                      ).promise();
                    console.log(emailVerificationResponse);
                    if (showAddCompany) {
                      console.log("New company");
                      console.log(formData);
                      try {
                        await client
                          .graphql({
                            query: mutations.createSPCompanyDetails,
                            variables: {
                              input: {
                                companyName: formData.companyname,
                                companyBN: formData.businessNumber,
                                companyAddress: companyAdress,
                                companyLicense: formData.Licencenumb,
                                logo: selectedFile,
                                status: "Active",
                                phone: formData.companyPhone,
                                email: formData.companyEmail,
                              },
                            },
                            authMode: "API_KEY",
                          })
                          .then(async (result) => {
                            console.log(result);
                            console.log(result.data.createSPCompanyDetails.id);
                            const addedsite = await client.graphql({
                              query: queries.getSPCompanyDetails,
                              variables: {
                                id: result.data.createSPCompanyDetails.id,
                              },
                              authMode: "API_KEY",
                            });
                            if (addedsite.data.getSPCompanyDetails !== null) {
                              let activeDB = JSON.parse(
                                base64.decode(localStorage.getItem("spCompany"))
                              );
                              console.log(activeDB);
                              activeDB.push(addedsite.data.getSPCompanyDetails);
                              console.log(activeDB);
                              localStorage.setItem(
                                "spCompany",
                                base64.encode(JSON.stringify(activeDB))
                              );
                            }
                            client.graphql({
                              query: mutations.createLinkAOAPCompany,
                              variables: {
                                input: {
                                  companyName: formData.companyname,
                                  companyBN: formData.businessNumber,
                                  companyAddress: companyAdress,
                                  companyLicense: formData.Licencenumb,
                                  logo: selectedFile,
                                  status: "Active",
                                  email: formData.companyEmail,
                                  phone: formData.companyPhone,
                                  spaoId: userSub,
                                  spcomapnyId:
                                    result.data.createSPCompanyDetails.id,
                                },
                              },
                              authMode: "API_KEY",
                            });
                            const resultao = await client.graphql({
                              query: mutations.createLinkAOAPCompany,
                              variables: {
                                input: {
                                  companyName: formData.companyname,
                                  companyBN: formData.businessNumber,
                                  companyAddress: companyAdress,
                                  companyLicense: formData.Licencenumb,
                                  logo: selectedFile,
                                  status: "Active",
                                  email: formData.companyEmail,
                                  phone: formData.companyPhone,
                                  spaoId:
                                    LoginUserGroup === "site_user"
                                      ? LoginUserDetails.assetOwnerId
                                      : LoginUserDetails.id,
                                  spcomapnyId:
                                    result.data.createSPCompanyDetails.id,
                                },
                              },
                              authMode: "API_KEY",
                            });
                            console.log(resultao);
                            fetchSpCompanyname();
                            toast.success("Successfully added new Company", {
                              position: toast.POSITION.TOP_CENTER,
                            });
                            client.graphql({
                              query: mutations.createUserDetails,
                              variables: {
                                input: {
                                  id: userSub,
                                  username: userName.replace(/\s/g, ""),
                                  email: userEmail,
                                  assetOwnerId: LoggedInUserId,
                                  companyDetailsId:
                                    result.data.createSPCompanyDetails.id,
                                  status: "Active",
                                  permissions: JSON.stringify(perm),
                                },
                              },
                              authMode: "API_KEY",
                            });
                            setNewCompanyId(
                              result.data.createSPCompanyDetails.id
                            );
                            client
                              .graphql({
                                query: mutations.createSPUserDetails,
                                variables: {
                                  input: {
                                    id: userSub,
                                    fname: formData.firstName,
                                    lname: formData.lastName,
                                    email: formData.email,
                                    userGroup: "electrician",
                                    invitedBy: LoggedInUserId,
                                    status: status,
                                    SPGroup: showAddCompany ? "admin" : "",
                                    permission: showAddCompany
                                      ? JSON.stringify(perm)
                                      : [],
                                    spcompanydetailsID:
                                      result.data.createSPCompanyDetails.id,
                                  },
                                },
                                authMode: "API_KEY",
                              })
                              .then(async (res) => {
                                console.log(res);
                                const addedsite = await client.graphql({
                                  query: queries.getSPUserDetails,
                                  variables: {
                                    id: userSub,
                                  },
                                  authMode: "API_KEY",
                                });
                                if (addedsite.data.getSPUserDetails !== null) {
                                  if (localStorage.getItem("spuser") !== null) {
                                    let activeSites = JSON.parse(
                                      base64.decode(
                                        localStorage.getItem("spuser")
                                      )
                                    );
                                    console.log(activeSites);
                                    activeSites.push(
                                      addedsite.data.getSPUserDetails
                                    );
                                    console.log(activeSites);
                                    // localStorage.setItem(
                                    //   "spuser",
                                    //   base64.encode(JSON.stringify(activeSites))
                                    // );
                                  }
                                }
                                e.target.reset();
                                setShowAddCompany(!showAddCompany);
                                setShowInvite(false);
                                fetchspusercompany();
                                reset();
                                fetchUser();
                                //setSiteListVersion((prevVersion) => prevVersion + 1);
                                //listSPDetails("All Time");
                                sitesSelected?.map((item) => {
                                  client.graphql({
                                    query: mutations.updateSPUserDetails,
                                    variables: {
                                      input: {
                                        sitedetailsID: item.id,
                                        id: userSub,
                                      },
                                    },
                                    authMode: "API_KEY",
                                  });
                                  client
                                    .graphql({
                                      query: mutations.createLinkUsersAndSite,
                                      variables: {
                                        input: {
                                          sitedetailsID: item.id,
                                          userdetailsID: userSub,
                                          status: "Active",
                                        },
                                      },
                                      authMode: "API_KEY",
                                    })
                                    .then((result) => {
                                      console.log(result);
                                    })
                                    .catch((error) => {
                                      console.error(error);
                                      toast.error(" Error while Creating ", {
                                        position: toast.POSITION.TOP_CENTER,
                                      });
                                    });
                                });
                                setLoading(false);
                                setIsLoading(false);
                                setSiteListVersion(true);
                                listSPDetails("All Time");
                                reset();
                                setsitesSelected([]);
                                toast.success(" Created successfully.", {
                                  position: toast.POSITION.TOP_CENTER,
                                });
                              })
                              .catch((errors) => {
                                toast.error(errors, {
                                  position: toast.POSITION.TOP_CENTER,
                                });
                              });
                          })
                          .catch((error) => {
                            toast.error(
                              "Error while adding new Company, try again",
                              {
                                position: toast.POSITION.TOP_CENTER,
                              }
                            );
                            console.error(error);
                          });
                      } catch (error) {
                        console.error(error);
                      }
                    } else {
                      const resultt = await client.graphql({
                        query: mutations.createUserDetails,
                        variables: {
                          input: {
                            id: userSub,
                            username: userName.replace(/\s/g, ""),
                            email: userEmail,
                            assetOwnerId: LoggedInUserId,
                            companyDetailsId: formData.company,
                            status: "Active",
                            //permissions: JSON.stringify(perm),
                          },
                        },
                        authMode: "API_KEY",
                      });
                      console.log(resultt);
                      //---sp id updation in linkaospcompany
                      const compsp = usercomp.filter(
                        (item) => item.id === formData.company
                      );
                      console.log(compsp);
                      const resultsp = await client.graphql({
                        query: mutations.createLinkAOAPCompany,
                        variables: {
                          input: {
                            companyName: compsp[0].companyName,
                            companyBN: compsp[0].companyBN,
                            companyAddress: compsp[0].companyAddress,
                            companyLicense: compsp[0].companyLicense,
                            logo: compsp[0].logo,
                            status: "Active",
                            email: compsp[0].email,
                            phone: compsp[0].phone,
                            spaoId: userSub,
                            spcomapnyId: compsp[0].id,
                          },
                        },
                        authMode: "API_KEY",
                      });
                      console.log(resultsp);
                      //---ao/su updation if not updated
                      const linkao = await client.graphql({
                        query: queries.linkAOAPCompaniesBySpaoId,
                        authMode: "API_KEY",
                        variables: {
                          spaoId: LoginUserDetails.id,
                          filter: {
                            spcomapnyId: {
                              eq: formData.company,
                            },
                          },
                        },
                      });
                      console.log(linkao);
                      if (
                        linkao.data.linkAOAPCompaniesBySpaoId.items.length > 0
                      ) {
                        console.log("Already updated in Database");
                      } else {
                        const resultao = await client.graphql({
                          query: mutations.createLinkAOAPCompany,
                          variables: {
                            input: {
                              companyName: compsp[0].companyName,
                              companyBN: compsp[0].companyBN,
                              companyAddress: compsp[0].companyAddress,
                              companyLicense: compsp[0].companyLicense,
                              logo: compsp[0].logo,
                              status: "Active",
                              email: compsp[0].email,
                              phone: compsp[0].phone,
                              spaoId:
                                LoginUserGroup === "site_user"
                                  ? LoginUserDetails.assetOwnerId
                                  : LoginUserDetails.id,
                              spcomapnyId: formData.company,
                            },
                          },
                          authMode: "API_KEY",
                        });
                        console.log(resultao);
                      }
                      client
                        .graphql({
                          query: mutations.createSPUserDetails,
                          variables: {
                            input: {
                              id: userSub,
                              fname: formData.firstName,
                              lname: formData.lastName,
                              email: formData.email,
                              userGroup: "electrician",
                              invitedBy: LoggedInUserId,
                              status: status,
                              SPGroup: showAddCompany ? "admin" : "",
                              permission: showAddCompany
                                ? JSON.stringify(perm)
                                : [],
                              spcompanydetailsID: formData.company,
                            },
                          },
                          authMode: "API_KEY",
                        })
                        .then(async (res) => {
                          console.log(res);
                          const addedsite = await client.graphql({
                            query: queries.getSPUserDetails,
                            variables: {
                              id: userSub,
                            },
                            authMode: "API_KEY",
                          });
                          if (addedsite.data.getSPUserDetails !== null) {
                            if (localStorage.getItem("spuser") !== null) {
                              let activeSites = JSON.parse(
                                base64.decode(localStorage.getItem("spuser"))
                              );
                              console.log(activeSites);
                              activeSites.push(addedsite.data.getSPUserDetails);
                              console.log(activeSites);
                              // localStorage.setItem(
                              //   "spuser",
                              //   base64.encode(JSON.stringify(activeSites))
                              // );
                            }
                          }
                          e.target.reset();
                          setShowAddCompany(!showAddCompany);
                          setShowInvite(false);
                          fetchspusercompany();
                          reset();
                          fetchUser();
                          //setSiteListVersion((prevVersion) => prevVersion + 1);
                          //listSPDetails("All Time");
                          sitesSelected?.map((item) => {
                            client.graphql({
                              query: mutations.updateSPUserDetails,
                              variables: {
                                input: {
                                  sitedetailsID: item.id,
                                  id: userSub,
                                },
                              },
                              authMode: "API_KEY",
                            });
                            client
                              .graphql({
                                query: mutations.createLinkUsersAndSite,
                                variables: {
                                  input: {
                                    sitedetailsID: item.id,
                                    userdetailsID: userSub,
                                    status: "Active",
                                  },
                                },
                                authMode: "API_KEY",
                              })
                              .then((result) => {
                                console.log(result);
                              })
                              .catch((error) => {
                                console.error(error);
                                toast.error(" Error while Creating ", {
                                  position: toast.POSITION.TOP_CENTER,
                                });
                              });
                          });
                          setSiteListVersion(true);
                          listSPDetails("All Time");
                          reset();
                          setsitesSelected([]);
                          setLoading(false);
                          setIsLoading(false);
                          toast.success(" Created successfully.", {
                            position: toast.POSITION.TOP_CENTER,
                          });
                        })
                        .catch((errors) => {
                          toast.error(errors, {
                            position: toast.POSITION.TOP_CENTER,
                          });
                        });
                    }
                    var params = {
                      GroupName: "electrician" /* required */,
                      UserPoolId: USERPOOLID /* required */,
                      Username: userName.replace(/\s/g, "") /* required */,
                    };
                    let groupResponse =
                      await COGNITO_CLIENT.adminAddUserToGroup(
                        params
                      ).promise();
                    console.log(groupResponse);
                  }
                });
            } catch (err) {
              console.error(err);
              if (err.message === "User account already exists") {
              }
              toast.error(err.message, {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
          // })
          // .catch((error) => {
          //   console.log(error);
          // });
        } catch (error) {
          console.log(error);
        }
      } else {
        setSiteError(true);
      }
    } else {
      setLoading(false);
      toast.error(
        "Duplicate Electrical Licence Number.Please Check Electrical Licence Number Entered.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  });

  // sp company name ----
  async function fetchSpCompanyname() {
    try {
      //--
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listSPCompanyDetails,
        variables: {
          limit: 2000000,
        },
        authMode: "API_KEY",
      });
      if (result.data.listSPCompanyDetails.items.length > 0) {
        let assetDatas = result.data.listSPCompanyDetails.items;
        nextToken = result.data.listSPCompanyDetails.nextToken;
        // console.log(
        //   "first token count",
        //   result.data.listSPCompanyDetails.items.length
        // );
        // console.log("nextToken 1", nextToken);
        if (result.data.listSPCompanyDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listSPCompanyDetails,
            variables: {
              limit: 2000000,
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listSPCompanyDetails.items.length > 0) {
            nextToken = results.data.listSPCompanyDetails.nextToken;
            // console.log("nextToken", nextToken);
            // console.log(results.data.listSPCompanyDetails.items.length);
            if (results.data.listSPCompanyDetails.items.length > 0) {
              assets.push(results.data.listSPCompanyDetails.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setspcompanyname(mergeAssets);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchSpCompanyname();
    //fetchLinkUserSite();
    fetchSp();
    fetchSitesForUserExist();
    fetchUser();
    fetchspusercompany();
    // if (spCompany.length === 0) {
    //   if (LoginUserGroup === "spao") {
    //     fetchSPCompanySU(LoginUserDetails.companyDetailsId);
    //   } else {
    //     fetchSpcompany(LoginUserDetails.id);
    //   }
    // }
    if (localStorage.getItem("spCompany") === null && spCompany.length === 0) {
      LoginUserGroup === "site_owner"
        ? fetchSpcompany(LoginUserDetails.id)
        : LoginUserGroup === "site_user" || LoginUserGroup === "electrician"
          ? fetchSPCompanySU(LoginUserDetails.id)
          : fetchSPCompanySPAO(LoginUserDetails);
    } else {
      console.log(JSON.parse(base64.decode(localStorage.getItem("spCompany"))));
      setSpCompany(
        JSON.parse(base64.decode(localStorage.getItem("spCompany")))
      );
    }
  }, []);

  //---sp company for su/sp
  async function fetchSPCompanySU(userId) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: userId,
            },
          },
        },
      });
      console.log("company " + userId, getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        setSpCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  //--sp company for ao
  async function fetchSPCompanySPAO(user) {
    try {
      const getSPComp = await client.graphql({
        query: queries.getSPCompanyDetails,
        authMode: "API_KEY",
        variables: {
          id: user.companyDetailsId,
        },
      });
      console.log("company " + user.companyDetailsId, getSPComp);
      if (getSPComp.data.getSPCompanyDetails !== null) {
        let spcomp = [];
        spcomp.push(getSPComp.data.getSPCompanyDetails);
        SPCompany.setSPCompany(spcomp);
        setSpCompany(spcomp);
        localStorage.setItem(
          "spCompany",
          base64.encode(JSON.stringify(spcomp))
        );
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  async function fetchSpcompany(asetOwnerId) {
    try {
      const getLinkAOAPCompanyDetails = await client.graphql({
        query: queries.listLinkAOAPCompanies,
        authMode: "API_KEY",
        variables: {
          limit: 10000,
          filter: {
            spaoId: {
              eq: asetOwnerId,
            },
          },
        },
      });
      console.log("company " + asetOwnerId, getLinkAOAPCompanyDetails);
      if (
        getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items.length > 0
      ) {
        SPCompany.setSPCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        setSpCompany(
          getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
        );
        localStorage.setItem(
          "spCompany",
          base64.encode(
            JSON.stringify(
              getLinkAOAPCompanyDetails.data.listLinkAOAPCompanies.items
            )
          )
        );
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  //---Approve Status-----------------------------

  const ApproveStatus = (status) => {
    return status.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  // const handleChange = async (item) => {
  //   var statusSp;

  //   if (item.status.toLowerCase() === "active") {
  //     statusSp = "Inactive";
  //     toast.success("Deactivated successfully.", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   } else {
  //     statusSp = "Active";
  //     toast.success("Activated successfully.", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   }
  //   console.log(item);

  //   await client
  //     .graphql({
  //       query: mutations.updateSPUserDetails,
  //       variables: {
  //         input: { id: item.id, status: statusSp },
  //       },
  //     })
  //     .then((result) => {
  //       listSPDetails("All Time");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error("Error while Updating ", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     });
  // };

  //----------------------------List--------------------------------------

  const handleChange = async (item) => {
    try {
      var statusSp;

      if (item.status.toLowerCase() === "active") {
        statusSp = "Inactive";
        toast.success("Deactivated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        statusSp = "Active";
        toast.success("Activated successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      console.log(item);

      // const result = await client
      //   .graphql({
      //     query: mutations.updateSPUserDetails,
      //     variables: {
      //       input: { id: item.id, status: statusSp },
      //     },
      //   });
      //   .then((result) => {
      //     fetchSiteUserData("All Time");
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     toast.error("Error while Updating ", {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //   });
      const result = await client.graphql({
        query: mutations.updateSPUserDetails,
        variables: {
          input: { id: item.id, status: statusSp },
        },
      });
      console.log(result);
      if (result) {
        listSPDetails("All Time");
      } else {
        toast.error("Error while Updating ", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //setIsLoading(true);
    fetchSites();
    listSPDetails("All Time");
  }, [selectedSiteGlobal, selectedAssetOwnerGlobal, spglobal]);

  useEffect(() => {
    if (siteListVersion === true) {
      listSPDetails("All Time");
      fetchUser();
      setSiteListVersion(false);
    }
  }, [siteListVersion]);

  //--------------------------------------
  async function fetchSites() {
    // if (LoginUserGroup === "admin") {
    //--
    try {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.listSiteDetails,
        variables: {
          limit: limit,
          filter: {
            status: {
              eq: "Active",
            },
          },
        },
        authMode: "API_KEY",
      });
      if (result.data.listSiteDetails.items.length > 0) {
        let assetDatas = result.data.listSiteDetails.items;
        nextToken = result.data.listSiteDetails.nextToken;
        if (result.data.listSiteDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.listSiteDetails,
            variables: {
              limit: limit,
              filter: {
                status: {
                  eq: "Active",
                },
              },
              nextToken: nextToken,
            },
            authMode: "API_KEY",
          });
          if (results.data.listSiteDetails.items.length > 0) {
            nextToken = results.data.listSiteDetails.nextToken;
            if (results.data.listSiteDetails.items.length > 0) {
              assets.push(results.data.listSiteDetails.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          const sites = mergeAssets;
          console.log(sites);
          if (LoginUserGroup === "admin") {
            setsites(sites);
          } else if (
            LoginUserGroup === "site_user" ||
            LoginUserGroup === "electrician"
          ) {
            const dataDetails = await client.graphql({
              query: queries.linkUsersAndSitesByUserdetailsID,
              authMode: "API_KEY",
              variables: {
                userdetailsID: LoginUserDetails.id,
                filter: {
                  status: {
                    eq: "Active"
                  }
                },
                limit: limit,
              },
            });
            if (
              dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
            ) {
              const siteDetails =
                dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
              if (siteDetails.length > 0) {
                const siteDetailsu = sites.filter((item) => {
                  const _matchingSites = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingSites) {
                    return item;
                  }
                });
                console.log(siteDetailsu);
                setsites(siteDetailsu);
                //setIsLoading(false);
              } else {
                setsites([]);
                //setIsLoading(false);
              }
            }
          } else {
            setsites(
              sites.filter((item) => {
                return item.assetownerID === LoginUserDetails.id;
              })
            );
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  //---site global filter for admin
  async function fetchSitesforglobal() {
    //setIsLoading(true);
    //-
    try {
      let nextToken = "";
      let assets = [];
      const result = await client.graphql({
        query: queries.linkUsersAndSitesBySitedetailsID,
        authMode: "API_KEY",
        variables: {
          sitedetailsID: selectedSiteGlobal,
          filter: {
            status: {
              eq: "Active"
            }
          },
          limit: limit,
        },
      });
      if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
        let assetDatas = result.data.linkUsersAndSitesBySitedetailsID.items;
        nextToken = result.data.linkUsersAndSitesBySitedetailsID.nextToken;
        if (result.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: selectedSiteGlobal,
              filter: {
                status: {
                  eq: "Active"
                }
              },
              limit: limit,
              nextToken: nextToken,
            },
          });
          if (results.data.linkUsersAndSitesBySitedetailsID.items.length > 0) {
            nextToken = results.data.linkUsersAndSitesBySitedetailsID.nextToken;
            if (
              results.data.linkUsersAndSitesBySitedetailsID.items.length > 0
            ) {
              assets.push(results.data.linkUsersAndSitesBySitedetailsID.items);
            }
          }
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setspglobal(mergeAssets);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (selectedSiteGlobal) {
      fetchSitesforglobal();
    }
  }, [selectedSiteGlobal]);

  //-----List ---LoggedIn Asset Owner Details---------------------

  const listSPDetails = async (DateRange) => {
    console.log("list sites", sites);
    let serviceProviders = [];
    //setIsLoading(true);
    if (LoginUserGroup === "admin") {
      //-----------Admin users---------------
      setIsLoading(true);
      const response = await client.graphql({
        query: mergedQueries.listSPSUUserDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
          userGroup: "electrician",
        },
      });

      console.log(response);
      if (
        response.data &&
        response.data.listSPUserDetails &&
        response.data.listSPUserDetails.items &&
        response.data.listSPUserDetails.items.length > 0
      ) {
        const spUser = response.data.listSPUserDetails.items;
        const sitesfilter = spUser.filter((item) => {
          const _matchSites = spglobal?.find(
            (user) => user.userdetailsID === item?.id
          );
          if (_matchSites) {
            return item;
          }
        });
        console.log(sitesfilter);
        var filterSitesData = spUser.filter(
          (item) => item.id === selectedSiteGlobal
        );
        const sortedItems = selectedSiteGlobal
          ? sitesfilter.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
          : spUser.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        console.log(sortedItems);
        const spDetails = sortedItems;
        if (DateRange === "This Week") {
          serviceProviders = spDetails.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return (
              createdAtDate.isSameOrAfter(
                moment(startOfWeek, "DD/MM/YYYY"),
                "day"
              ) &&
              createdAtDate.isSameOrBefore(
                moment(endOfWeek, "DD/MM/YYYY"),
                "day"
              )
            );
          });
        } else if (DateRange === "This Month") {
          serviceProviders = spDetails.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startOfMonth, "DD/MM/YYYY"),
              moment(endOfMonth, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "Today") {
          serviceProviders = spDetails.filter(
            (item) =>
              moment(item.createdAt).format("YYYY-MM-DD") ===
              moment(new Date()).format("DD/MM/YYYY")
          );
        } else if (DateRange === "This Year") {
          serviceProviders = spDetails.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startOfThisYear, "DD/MM/YYYY"),
              moment(endOfThisYear, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "Custom") {
          console.log(startDate);
          console.log(endDate);
          serviceProviders = spDetails.filter((item) => {
            const createdAtDate = moment(item.createdAt);
            return createdAtDate.isBetween(
              moment(startDate, "DD/MM/YYYY"),
              moment(endDate, "DD/MM/YYYY"),
              null,
              "[]"
            );
          });
        } else if (DateRange === "All Time") {
          serviceProviders = spDetails;
        }
        // for (let item of serviceProviders) {
        //   const usersDetails = await client.graphql({
        //     query: queries.getUserDetails,
        //     authMode: "API_KEY",
        //     variables: {
        //       id: item.id,
        //     },
        //   });
        //   if (usersDetails.data.getUserDetails !== null) {
        //     const spUserName = usersDetails.data.getUserDetails.username;
        //     item.username = spUserName;
        //   }
        // }
        setlistofServiceProviders(serviceProviders);
        setCollection(cloneDeep(serviceProviders.slice(0, countPerPage)));

        setIsLoading(false);
      } else {
        setlistofServiceProviders([]);
        setIsLoading(false);
        setCollection([]);
      }
    } else if (LoginUserGroup === "site_user") {
      if (localStorage.getItem("spuser") !== null) {
        let activeAssets = JSON.parse(
          base64.decode(localStorage.getItem("spuser"))
        );
        console.log(activeAssets);
        const electrician = activeAssets.filter(
          (item) => item.userGroup === "electrician"
        );
        console.log("site user login related sp");
        const spDetails = electrician;
        if (spDetails.length > 0) {
          if (DateRange === "This Week") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return (
                createdAtDate.isSameOrAfter(
                  moment(startOfWeek, "DD/MM/YYYY"),
                  "day"
                ) &&
                createdAtDate.isSameOrBefore(
                  moment(endOfWeek, "DD/MM/YYYY"),
                  "day"
                )
              );
            });
          } else if (DateRange === "This Month") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfMonth, "DD/MM/YYYY"),
                moment(endOfMonth, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Today") {
            serviceProviders = spDetails.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") ===
                moment(new Date()).format("DD/MM/YYYY")
            );
          } else if (DateRange === "This Year") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfThisYear, "DD/MM/YYYY"),
                moment(endOfThisYear, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Custom") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startDate, "DD/MM/YYYY"),
                moment(endDate, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "All Time") {
            serviceProviders = spDetails;
          }
          for (let item of serviceProviders) {
            const usersDetails = await client.graphql({
              query: queries.getUserDetails,
              authMode: "API_KEY",
              variables: {
                id: item.id,
              },
            });
            if (usersDetails.data.getUserDetails !== null) {
              const spUserName = usersDetails.data.getUserDetails.username;
              item.username = spUserName;
            }
          }
          setlistofServiceProviders(serviceProviders);
          setCollection(cloneDeep(serviceProviders.slice(0, countPerPage)));

          setIsLoading(false);
        } else {
          setlistofServiceProviders([]);
          setIsLoading(false);
          setCollection([]);
        }
      } else {
        const dataDetails = await client.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            filter: {
              status: {
                eq: "Active"
              }
            },
            limit: 100000,
          },
        });
        if (
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
        ) {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          console.log(siteDetails);
          const siteglobal = siteDetails.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const sitesss = selectedSiteGlobal ? siteglobal : siteDetails;
          console.log(sitesss);
          let userIds = [];
          let nextToken1 = "";
          if (sitesss.length > 0) {
            for (let site of sitesss) {
              const getSiteIds = await client.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.sitedetailsID,
                  filter: {
                    status: {
                      eq: "Active"
                    }
                  },
                },
              });
              console.log(getSiteIds);
              if (
                getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length >
                0
              ) {
                nextToken1 =
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
                );
                while (nextToken1 !== null) {
                  const getSiteIds1 = await client.graphql({
                    query: queries.linkUsersAndSitesBySitedetailsID,
                    authMode: "API_KEY",
                    variables: {
                      sitedetailsID: site.sitedetailsID,
                      filter: {
                        status: {
                          eq: "Active"
                        }
                      },
                      nextToken: nextToken1,
                    },
                  });
                  console.log(getSiteIds1);
                  if (
                    getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                      .length > 0
                  ) {
                    nextToken1 =
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID
                        .nextToken;
                    userIds.push(
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                    );
                  }
                }
                console.log(userIds);
                if (userIds.length > 0) {
                  const mergeUserIds = userIds.flat(1);
                  const response1 = await client.graphql({
                    query: queries.listSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      filter: {
                        userGroup: {
                          eq: "electrician",
                        },
                      },
                      limit: 200000,
                    },
                  });
                  if (response1.data.listSPUserDetails.items.length > 0) {
                    console.log(LoggedInUserId);
                    const spDetails2 =
                      response1.data.listSPUserDetails.items.filter((item) => {
                        const _matchSites = mergeUserIds?.find(
                          (user) => user.userdetailsID === item?.id
                        );
                        if (_matchSites) {
                          return item;
                        }
                      });
                    console.log(spDetails2);
                    const sortedItems = spDetails2.sort((a, b) => {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    console.log(sortedItems);
                    const spDetails = sortedItems;
                    console.log(spDetails);
                    if (spDetails.length > 0) {
                      if (DateRange === "This Week") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return (
                            createdAtDate.isSameOrAfter(
                              moment(startOfWeek, "DD/MM/YYYY"),
                              "day"
                            ) &&
                            createdAtDate.isSameOrBefore(
                              moment(endOfWeek, "DD/MM/YYYY"),
                              "day"
                            )
                          );
                        });
                      } else if (DateRange === "This Month") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfMonth, "DD/MM/YYYY"),
                            moment(endOfMonth, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Today") {
                        serviceProviders = spDetails.filter(
                          (item) =>
                            moment(item.createdAt).format("YYYY-MM-DD") ===
                            moment(new Date()).format("DD/MM/YYYY")
                        );
                      } else if (DateRange === "This Year") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfThisYear, "DD/MM/YYYY"),
                            moment(endOfThisYear, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Custom") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startDate, "DD/MM/YYYY"),
                            moment(endDate, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "All Time") {
                        serviceProviders = spDetails;
                      }
                      for (let item of serviceProviders) {
                        const usersDetails = await client.graphql({
                          query: queries.getUserDetails,
                          authMode: "API_KEY",
                          variables: {
                            id: item.id,
                          },
                        });
                        if (usersDetails.data.getUserDetails !== null) {
                          const spUserName =
                            usersDetails.data.getUserDetails.username;
                          item.username = spUserName;
                        }
                      }
                      setlistofServiceProviders(serviceProviders);
                      setCollection(
                        cloneDeep(serviceProviders.slice(0, countPerPage))
                      );

                      setIsLoading(false);
                    } else {
                      setlistofServiceProviders([]);
                      setIsLoading(false);
                      setCollection([]);
                    }
                  }
                } else {
                  setlistofServiceProviders([]);
                  setIsLoading(false);
                  setCollection([]);
                }
              }
            }
          } else {
            setlistofServiceProviders([]);
            setIsLoading(false);
            setCollection([]);
          }
        }
      }
    } else if (LoginUserGroup === "electrician") {
      console.log("service provider login related sp");
      if (localStorage.getItem("spuser") !== null) {
        let activeAssets = JSON.parse(
          base64.decode(localStorage.getItem("spuser"))
        );
        console.log(activeAssets);
        const electrician = activeAssets.filter(
          (item) => item.userGroup === "electrician"
        );
        const spDetails = electrician;
        if (spDetails.length > 0) {
          if (DateRange === "This Week") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return (
                createdAtDate.isSameOrAfter(
                  moment(startOfWeek, "DD/MM/YYYY"),
                  "day"
                ) &&
                createdAtDate.isSameOrBefore(
                  moment(endOfWeek, "DD/MM/YYYY"),
                  "day"
                )
              );
            });
          } else if (DateRange === "This Month") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfMonth, "DD/MM/YYYY"),
                moment(endOfMonth, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Today") {
            serviceProviders = spDetails.filter(
              (item) =>
                moment(item.createdAt).format("YYYY-MM-DD") ===
                moment(new Date()).format("DD/MM/YYYY")
            );
          } else if (DateRange === "This Year") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfThisYear, "DD/MM/YYYY"),
                moment(endOfThisYear, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Custom") {
            serviceProviders = spDetails.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startDate, "DD/MM/YYYY"),
                moment(endDate, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "All Time") {
            serviceProviders = spDetails;
          }
          for (let item of serviceProviders) {
            const usersDetails = await client.graphql({
              query: queries.getUserDetails,
              authMode: "API_KEY",
              variables: {
                id: item.id,
              },
            });
            if (usersDetails.data.getUserDetails !== null) {
              const spUserName = usersDetails.data.getUserDetails.username;
              item.username = spUserName;
            }
          }
          setlistofServiceProviders(serviceProviders);
          setCollection(cloneDeep(serviceProviders.slice(0, countPerPage)));

          setIsLoading(false);
        } else {
          setlistofServiceProviders([]);
          setIsLoading(false);
          setCollection([]);
        }
      } else {
        const dataDetails = await client.graphql({
          query: queries.linkUsersAndSitesByUserdetailsID,
          authMode: "API_KEY",
          variables: {
            userdetailsID: LoginUserDetails.id,
            filter: {
              status: {
                eq: "Active"
              }
            },
            limit: 100000,
          },
        });
        if (
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0
        ) {
          const siteDetails =
            dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
          console.log(siteDetails);
          const siteglobal = siteDetails.filter(
            (item) => item.sitedetailsID === selectedSiteGlobal
          );
          const sitesss = selectedSiteGlobal ? siteglobal : siteDetails;
          console.log(sitesss);
          let userIds = [];
          let nextToken1 = "";
          if (sitesss.length > 0) {
            for (let site of sitesss) {
              const getSiteIds = await client.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.sitedetailsID,
                  filter: {
                    status: {
                      eq: "Active"
                    }
                  },
                },
              });
              console.log(getSiteIds);
              if (
                getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length >
                0
              ) {
                nextToken1 =
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
                );
                while (nextToken1 !== null) {
                  const getSiteIds1 = await client.graphql({
                    query: queries.linkUsersAndSitesBySitedetailsID,
                    authMode: "API_KEY",
                    variables: {
                      sitedetailsID: site.sitedetailsID,
                      filter: {
                        status: {
                          eq: "Active"
                        }
                      },
                      nextToken: nextToken1,
                    },
                  });
                  console.log(getSiteIds1);
                  if (
                    getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                      .length > 0
                  ) {
                    nextToken1 =
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID
                        .nextToken;
                    userIds.push(
                      getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                    );
                  }
                }
                console.log(userIds);
                if (userIds.length > 0) {
                  const mergeUserIds = userIds.flat(1);
                  const response1 = await client.graphql({
                    query: queries.listSPUserDetails,
                    authMode: "API_KEY",
                    variables: {
                      filter: {
                        userGroup: {
                          eq: "electrician",
                        },
                      },
                      limit: 200000,
                    },
                  });
                  if (response1.data.listSPUserDetails.items.length > 0) {
                    console.log(LoggedInUserId);
                    const spDetails2 =
                      response1.data.listSPUserDetails.items.filter((item) => {
                        const _matchSites = mergeUserIds?.find(
                          (user) => user.userdetailsID === item?.id
                        );
                        if (_matchSites) {
                          return item;
                        }
                      });
                    console.log(spDetails2);
                    const sortedItems = spDetails2.sort((a, b) => {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    console.log(sortedItems);
                    const spDetails = sortedItems;
                    console.log(spDetails);
                    if (spDetails.length > 0) {
                      if (DateRange === "This Week") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return (
                            createdAtDate.isSameOrAfter(
                              moment(startOfWeek, "DD/MM/YYYY"),
                              "day"
                            ) &&
                            createdAtDate.isSameOrBefore(
                              moment(endOfWeek, "DD/MM/YYYY"),
                              "day"
                            )
                          );
                        });
                      } else if (DateRange === "This Month") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfMonth, "DD/MM/YYYY"),
                            moment(endOfMonth, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Today") {
                        serviceProviders = spDetails.filter(
                          (item) =>
                            moment(item.createdAt).format("YYYY-MM-DD") ===
                            moment(new Date()).format("DD/MM/YYYY")
                        );
                      } else if (DateRange === "This Year") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startOfThisYear, "DD/MM/YYYY"),
                            moment(endOfThisYear, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "Custom") {
                        serviceProviders = spDetails.filter((item) => {
                          const createdAtDate = moment(item.createdAt);
                          return createdAtDate.isBetween(
                            moment(startDate, "DD/MM/YYYY"),
                            moment(endDate, "DD/MM/YYYY"),
                            null,
                            "[]"
                          );
                        });
                      } else if (DateRange === "All Time") {
                        serviceProviders = spDetails;
                      }
                      for (let item of serviceProviders) {
                        const usersDetails = await client.graphql({
                          query: queries.getUserDetails,
                          authMode: "API_KEY",
                          variables: {
                            id: item.id,
                          },
                        });
                        if (usersDetails.data.getUserDetails !== null) {
                          const spUserName =
                            usersDetails.data.getUserDetails.username;
                          item.username = spUserName;
                        }
                      }
                      setlistofServiceProviders(serviceProviders);
                      setCollection(
                        cloneDeep(serviceProviders.slice(0, countPerPage))
                      );

                      setIsLoading(false);
                    } else {
                      setlistofServiceProviders([]);
                      setIsLoading(false);
                      setCollection([]);
                    }
                  }
                } else {
                  setlistofServiceProviders([]);
                  setIsLoading(false);
                  setCollection([]);
                }
              }
            }
          } else {
            setlistofServiceProviders([]);
            setIsLoading(false);
            setCollection([]);
          }
        }
      }
      // const dataDetails = await client.graphql({
      //   query: queries.linkUsersAndSitesByUserdetailsID,
      //   authMode: "API_KEY",
      //   variables: {
      //     userdetailsID: LoginUserDetails.id,
      //     limit: 100000,
      //   },
      // });
      // if (dataDetails.data.linkUsersAndSitesByUserdetailsID.items.length > 0) {
      //   const siteDetails =
      //     dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
      //   console.log(siteDetails);
      //   const result = await client.graphql({
      //     query: queries.listSiteDetails,
      //     variables: {
      //       limit: 100000,
      //     },
      //     authMode: "API_KEY",
      //   });
      //   if (result.data.listSiteDetails.items.length > 0) {
      //     console.log(result);
      //     const sites = result.data.listSiteDetails.items;
      //     const sitesfilter = sites.filter((item) => {
      //       const _matchSites = siteDetails?.find(
      //         (user) => user.sitedetailsID === item?.id
      //       );
      //       if (_matchSites) {
      //         return item;
      //       }
      //     });
      //     const siteglobal = sitesfilter.filter(
      //       (item) => item.id === selectedSiteGlobal
      //     );
      //     const sitesss = selectedSiteGlobal ? siteglobal : sitesfilter;
      //     console.log(sitesfilter);
      //     const data = sitesss.filter(
      //       (item) => item.assetownerID === selectedAssetOwnerGlobal
      //     );
      //     const filterdata = selectedAssetOwnerGlobal ? data : sitesss;
      //     console.log(filterdata);
      //     //---
      //     let userIds = [];
      //     let nextToken1 = "";
      //     if (filterdata.length > 0) {
      //       for (let site of filterdata) {
      //         const getSiteIds = await client.graphql({
      //           query: queries.linkUsersAndSitesBySitedetailsID,
      //           authMode: "API_KEY",
      //           variables: {
      //             sitedetailsID: site.id,
      //           },
      //         });
      //         console.log(getSiteIds);
      //         if (
      //           getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
      //             .length > 0
      //         ) {
      //           nextToken1 =
      //             getSiteIds.data.linkUsersAndSitesBySitedetailsID
      //               .nextToken;
      //           userIds.push(
      //             getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
      //           );
      //           while (nextToken1 !== null) {
      //             const getSiteIds1 = await client.graphql({
      //               query: queries.linkUsersAndSitesBySitedetailsID,
      //               authMode: "API_KEY",
      //               variables: {
      //                 sitedetailsID: site.id,
      //                 nextToken: nextToken1,
      //               },
      //             });
      //             console.log(getSiteIds1);
      //             if (
      //               getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
      //                 .length > 0
      //             ) {
      //               nextToken1 =
      //                 getSiteIds1.data.linkUsersAndSitesBySitedetailsID
      //                   .nextToken;
      //               userIds.push(
      //                 getSiteIds1.data.linkUsersAndSitesBySitedetailsID
      //                   .items
      //               );
      //             }
      //           }
      //           console.log(userIds);
      //           if (userIds.length > 0) {
      //             const mergeUserIds = userIds.flat(1);
      //             const response1 = await client.graphql({
      //               query: queries.listSPUserDetails,
      //               authMode: "API_KEY",
      //               variables: {
      //                 filter: {
      //                   userGroup: {
      //                     eq: "electrician",
      //                   },
      //                 },
      //                 limit: 200000,
      //               },
      //             });
      //             if (response1.data.listSPUserDetails.items.length > 0) {
      //               console.log(LoggedInUserId);
      //               const spDetails2 =
      //                 response1.data.listSPUserDetails.items.filter(
      //                   (item) => {
      //                     const _matchSites = mergeUserIds?.find(
      //                       (user) => user.userdetailsID === item?.id
      //                     );
      //                     if (_matchSites) {
      //                       return item;
      //                     }
      //                   }
      //                 );
      //               console.log(spDetails2);
      //               const sortedItems = spDetails2.sort((a, b) => {
      //                 return (
      //                   new Date(b.createdAt) - new Date(a.createdAt)
      //                 );
      //               });
      //               console.log(sortedItems);
      //               const spDetails = sortedItems;
      //               console.log(spDetails);
      //               if (spDetails.length > 0) {
      //                 if (DateRange === "This Week") {
      //                   serviceProviders = spDetails.filter((item) => {
      //                     const createdAtDate = moment(item.createdAt);
      //                     return (
      //                       createdAtDate.isSameOrAfter(
      //                         moment(startOfWeek, "DD/MM/YYYY"),
      //                         "day"
      //                       ) &&
      //                       createdAtDate.isSameOrBefore(
      //                         moment(endOfWeek, "DD/MM/YYYY"),
      //                         "day"
      //                       )
      //                     );
      //                   });
      //                 } else if (DateRange === "This Month") {
      //                   serviceProviders = spDetails.filter((item) => {
      //                     const createdAtDate = moment(item.createdAt);
      //                     return createdAtDate.isBetween(
      //                       moment(startOfMonth, "DD/MM/YYYY"),
      //                       moment(endOfMonth, "DD/MM/YYYY"),
      //                       null,
      //                       "[]"
      //                     );
      //                   });
      //                 } else if (DateRange === "Today") {
      //                   serviceProviders = spDetails.filter(
      //                     (item) =>
      //                       moment(item.createdAt).format(
      //                         "YYYY-MM-DD"
      //                       ) === moment(new Date()).format("DD/MM/YYYY")
      //                   );
      //                 } else if (DateRange === "This Year") {
      //                   serviceProviders = spDetails.filter((item) => {
      //                     const createdAtDate = moment(item.createdAt);
      //                     return createdAtDate.isBetween(
      //                       moment(startOfThisYear, "DD/MM/YYYY"),
      //                       moment(endOfThisYear, "DD/MM/YYYY"),
      //                       null,
      //                       "[]"
      //                     );
      //                   });
      //                 } else if (DateRange === "Custom") {
      //                   serviceProviders = spDetails.filter((item) => {
      //                     const createdAtDate = moment(item.createdAt);
      //                     return createdAtDate.isBetween(
      //                       moment(startDate, "DD/MM/YYYY"),
      //                       moment(endDate, "DD/MM/YYYY"),
      //                       null,
      //                       "[]"
      //                     );
      //                   });
      //                 } else if (DateRange === "All Time") {
      //                   serviceProviders = spDetails;
      //                 }
      //                 for (let item of serviceProviders) {
      //                   const usersDetails = await client.graphql({
      //                     query: queries.getUserDetails,
      //                     authMode: "API_KEY",
      //                     variables: {
      //                       id: item.id,
      //                     },
      //                   });
      //                   if (usersDetails.data.getUserDetails !== null) {
      //                     const spUserName =
      //                       usersDetails.data.getUserDetails.username;
      //                     item.username = spUserName;
      //                   }
      //                 }
      //                 setlistofServiceProviders(serviceProviders);
      //                 setCollection(
      //                   cloneDeep(serviceProviders.slice(0, countPerPage))
      //                 );

      //                 setIsLoading(false);
      //               } else {
      //                 setlistofServiceProviders([]);
      //                 setIsLoading(false);
      //                 setCollection([]);
      //               }
      //             }
      //           } else {
      //             setlistofServiceProviders([]);
      //             setIsLoading(false);
      //             setCollection([]);
      //           }
      //         }
      //       }
      //     } else {
      //       setlistofServiceProviders([]);
      //       setIsLoading(false);
      //       setCollection([]);
      //     }

      //     // if (filterdata.length > 0) {
      //     //   await client.graphql({
      //     //     query: queries.listLinkUsersAndSites,
      //     //     authMode: "API_KEY",
      //     //     variables: {
      //     //       limit: 200000,
      //     //     },
      //     //   })
      //     //     .then(async (dataDetails) => {
      //     //       console.log(dataDetails);
      //     //       const spDetails1 =
      //     //         dataDetails.data.listLinkUsersAndSites.items;
      //     //       if (spDetails1.length > 0) {
      //     //         const spDetailss = spDetails1.filter((item) => {
      //     //           const _matchSites = filterdata?.find(
      //     //             (user) => user.id === item?.sitedetailsID
      //     //           );
      //     //           if (_matchSites) {
      //     //             return item;
      //     //           }
      //     //         });
      //     //         console.log(spDetailss);
      //     //         await client.graphql({
      //     //           query: queries.listSPUserDetails,
      //     //           authMode: "API_KEY",
      //     //           variables: {
      //     //             filter: {
      //     //               userGroup: {
      //     //                 eq: "electrician",
      //     //               },
      //     //             },
      //     //             limit: 200000,
      //     //           },
      //     //         })
      //     //           .then(async (response1) => {
      //     //             console.log(LoggedInUserId);
      //     //             const spDetails2 =
      //     //               response1.data.listSPUserDetails.items.filter(
      //     //                 (item) => {
      //     //                   const _matchSites = spDetailss?.find(
      //     //                     (user) => user.userdetailsID === item?.id
      //     //                   );
      //     //                   if (_matchSites) {
      //     //                     return item;
      //     //                   }
      //     //                 }
      //     //               );
      //     //             console.log(spDetails2);
      //     //             const sortedItems = spDetails2.sort((a, b) => {
      //     //               return (
      //     //                 new Date(b.createdAt) - new Date(a.createdAt)
      //     //               );
      //     //             });
      //     //             console.log(sortedItems);
      //     //             const spDetails = selectedSiteGlobal
      //     //               ? spDetails1
      //     //               : sortedItems;
      //     //             console.log(spDetails);
      //     //             if (spDetails.length > 0) {
      //     //               if (DateRange === "This Week") {
      //     //                 serviceProviders = spDetails.filter((item) => {
      //     //                   const createdAtDate = moment(item.createdAt);
      //     //                   return (
      //     //                     createdAtDate.isSameOrAfter(
      //     //                       moment(startOfWeek, "DD/MM/YYYY"),
      //     //                       "day"
      //     //                     ) &&
      //     //                     createdAtDate.isSameOrBefore(
      //     //                       moment(endOfWeek, "DD/MM/YYYY"),
      //     //                       "day"
      //     //                     )
      //     //                   );
      //     //                 });
      //     //               } else if (DateRange === "This Month") {
      //     //                 serviceProviders = spDetails.filter((item) => {
      //     //                   const createdAtDate = moment(item.createdAt);
      //     //                   return createdAtDate.isBetween(
      //     //                     moment(startOfMonth, "DD/MM/YYYY"),
      //     //                     moment(endOfMonth, "DD/MM/YYYY"),
      //     //                     null,
      //     //                     "[]"
      //     //                   );
      //     //                 });
      //     //               } else if (DateRange === "Today") {
      //     //                 serviceProviders = spDetails.filter(
      //     //                   (item) =>
      //     //                     moment(item.createdAt).format(
      //     //                       "YYYY-MM-DD"
      //     //                     ) === moment(new Date()).format("DD/MM/YYYY")
      //     //                 );
      //     //               } else if (DateRange === "This Year") {
      //     //                 serviceProviders = spDetails.filter((item) => {
      //     //                   const createdAtDate = moment(item.createdAt);
      //     //                   return createdAtDate.isBetween(
      //     //                     moment(startOfThisYear, "DD/MM/YYYY"),
      //     //                     moment(endOfThisYear, "DD/MM/YYYY"),
      //     //                     null,
      //     //                     "[]"
      //     //                   );
      //     //                 });
      //     //               } else if (DateRange === "Custom") {
      //     //                 serviceProviders = spDetails.filter((item) => {
      //     //                   const createdAtDate = moment(item.createdAt);
      //     //                   return createdAtDate.isBetween(
      //     //                     moment(startDate, "DD/MM/YYYY"),
      //     //                     moment(endDate, "DD/MM/YYYY"),
      //     //                     null,
      //     //                     "[]"
      //     //                   );
      //     //                 });
      //     //               } else if (DateRange === "All Time") {
      //     //                 serviceProviders = spDetails;
      //     //               }
      //     //               for (let item of serviceProviders) {
      //     //                 const usersDetails = await client.graphql({
      //     //                   query: queries.getUserDetails,
      //     //                   authMode: "API_KEY",
      //     //                   variables: {
      //     //                     id: item.id,
      //     //                   },
      //     //                 });

      //     //                 const spUserName =
      //     //                   usersDetails.data.getUserDetails.username;
      //     //                 item.username = spUserName;
      //     //               }
      //     //               setlistofServiceProviders(serviceProviders);
      //     //               setCollection(
      //     //                 cloneDeep(serviceProviders.slice(0, countPerPage))
      //     //               );

      //     //               setIsLoading(false);
      //     //             } else {
      //     //               setlistofServiceProviders([]);
      //     //               setIsLoading(false);
      //     //               setCollection([]);
      //     //             }
      //     //           })
      //     //           .catch((error1) => {
      //     //             console.log(error1);
      //     //             setIsLoading(false);
      //     //           });
      //     //       }
      //     //     })
      //     //     .catch((error2) => {
      //     //       console.log(error2);
      //     //       setIsLoading(false);
      //     //     });
      //     // } else {
      //     //   setlistofServiceProviders([]);
      //     //   setIsLoading(false);
      //     //   setCollection([]);
      //     // }
      //   }
      // }
      // else {
      //   setlistofServiceProviders([]);
      //   setIsLoading(false);
      //   setCollection([]);
      // }
    } else {
      console.log("ao related sp");
      //--
      // if (localStorage.getItem("spuser") !== null) {
      //   let activeAssets = JSON.parse(
      //     base64.decode(localStorage.getItem("spuser"))
      //   );
      //   console.log(activeAssets);
      //   const electrician = activeAssets.filter(
      //     (item) => item.userGroup === "electrician"
      //   );
      //   const spDetails = electrician;
      //   if (spDetails.length > 0) {
      //     if (DateRange === "This Week") {
      //       serviceProviders = spDetails.filter((item) => {
      //         const createdAtDate = moment(item.createdAt);
      //         return (
      //           createdAtDate.isSameOrAfter(
      //             moment(startOfWeek, "DD/MM/YYYY"),
      //             "day"
      //           ) &&
      //           createdAtDate.isSameOrBefore(
      //             moment(endOfWeek, "DD/MM/YYYY"),
      //             "day"
      //           )
      //         );
      //       });
      //     } else if (DateRange === "This Month") {
      //       serviceProviders = spDetails.filter((item) => {
      //         const createdAtDate = moment(item.createdAt);
      //         return createdAtDate.isBetween(
      //           moment(startOfMonth, "DD/MM/YYYY"),
      //           moment(endOfMonth, "DD/MM/YYYY"),
      //           null,
      //           "[]"
      //         );
      //       });
      //     } else if (DateRange === "Today") {
      //       serviceProviders = spDetails.filter(
      //         (item) =>
      //           moment(item.createdAt).format("YYYY-MM-DD") ===
      //           moment(new Date()).format("DD/MM/YYYY")
      //       );
      //     } else if (DateRange === "This Year") {
      //       serviceProviders = spDetails.filter((item) => {
      //         const createdAtDate = moment(item.createdAt);
      //         return createdAtDate.isBetween(
      //           moment(startOfThisYear, "DD/MM/YYYY"),
      //           moment(endOfThisYear, "DD/MM/YYYY"),
      //           null,
      //           "[]"
      //         );
      //       });
      //     } else if (DateRange === "Custom") {
      //       serviceProviders = spDetails.filter((item) => {
      //         const createdAtDate = moment(item.createdAt);
      //         return createdAtDate.isBetween(
      //           moment(startDate, "DD/MM/YYYY"),
      //           moment(endDate, "DD/MM/YYYY"),
      //           null,
      //           "[]"
      //         );
      //       });
      //     } else if (DateRange === "All Time") {
      //       serviceProviders = spDetails;
      //     }
      //     for (let item of serviceProviders) {
      //       const usersDetails = await client.graphql({
      //         query: queries.getUserDetails,
      //         authMode: "API_KEY",
      //         variables: {
      //           id: item.id,
      //         },
      //       });
      //       if (usersDetails.data.getUserDetails !== null) {
      //         const spUserName = usersDetails.data.getUserDetails.username;
      //         item.username = spUserName;
      //       }
      //     }
      //     setlistofServiceProviders(serviceProviders);
      //     setCollection(cloneDeep(serviceProviders.slice(0, countPerPage)));

      //     setIsLoading(false);
      //   } else {
      //     setlistofServiceProviders([]);
      //     setIsLoading(false);
      //     setCollection([]);
      //   }
      // } else {
      let userIds = [];
      let nextToken1 = "";
      let sites = JSON.parse(base64.decode(localStorage.getItem("site")));
      const filterSites = sites.filter(
        (item) => item.id === selectedSiteGlobal
      );
      const filtersitesss = selectedSiteGlobal ? filterSites : sites;
      console.log(filtersitesss);
      const activesites = sites.filter((item) => item.status === "Active");
      if (activesites.length > 0) {
        for (let site of activesites) {
          const getSiteIds = await client.graphql({
            query: queries.linkUsersAndSitesBySitedetailsID,
            authMode: "API_KEY",
            variables: {
              sitedetailsID: site.id,
              // filter: {
              //   status: {
              //     eq: "Active"
              //   }
              // },
            },
          });
          console.log(getSiteIds);
          if (
            getSiteIds.data.linkUsersAndSitesBySitedetailsID.items.length > 0
          ) {
            nextToken1 =
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.nextToken;
            userIds.push(
              getSiteIds.data.linkUsersAndSitesBySitedetailsID.items
            );
            while (nextToken1 !== null) {
              const getSiteIds1 = await client.graphql({
                query: queries.linkUsersAndSitesBySitedetailsID,
                authMode: "API_KEY",
                variables: {
                  sitedetailsID: site.id,
                  // filter: {
                  //   status: {
                  //     eq: "Active"
                  //   }
                  // },
                  nextToken: nextToken1,
                },
              });
              console.log(getSiteIds1);
              if (
                getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                  .length > 0
              ) {
                nextToken1 =
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.nextToken;
                userIds.push(
                  getSiteIds1.data.linkUsersAndSitesBySitedetailsID.items
                );
              }
            }
          }
        }
        console.log(userIds);
        if (userIds.length > 0) {
          const mergeUserIds = userIds.flat(1);
          const response1 = await client.graphql({
            query: queries.listSPUserDetails,
            authMode: "API_KEY",
            variables: {
              filter: {
                userGroup: {
                  eq: "electrician",
                },
              },
              limit: 200000,
            },
          });
          if (response1.data.listSPUserDetails.items.length > 0) {
            console.log(LoggedInUserId);
            const spDetails2 = response1.data.listSPUserDetails.items.filter(
              (item) => {
                const _matchSites = mergeUserIds?.find(
                  (user) => user.userdetailsID === item?.id
                );
                if (_matchSites) {
                  return item;
                }
              }
            );
            console.log(spDetails2);
            const sortedItems = spDetails2.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            console.log(sortedItems);
            const spDetails = sortedItems;
            console.log(spDetails);
            if (spDetails.length > 0) {
              if (DateRange === "This Week") {
                serviceProviders = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return (
                    createdAtDate.isSameOrAfter(
                      moment(startOfWeek, "DD/MM/YYYY"),
                      "day"
                    ) &&
                    createdAtDate.isSameOrBefore(
                      moment(endOfWeek, "DD/MM/YYYY"),
                      "day"
                    )
                  );
                });
              } else if (DateRange === "This Month") {
                serviceProviders = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startOfMonth, "DD/MM/YYYY"),
                    moment(endOfMonth, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "Today") {
                serviceProviders = spDetails.filter(
                  (item) =>
                    moment(item.createdAt).format("YYYY-MM-DD") ===
                    moment(new Date()).format("DD/MM/YYYY")
                );
              } else if (DateRange === "This Year") {
                serviceProviders = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startOfThisYear, "DD/MM/YYYY"),
                    moment(endOfThisYear, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "Custom") {
                serviceProviders = spDetails.filter((item) => {
                  const createdAtDate = moment(item.createdAt);
                  return createdAtDate.isBetween(
                    moment(startDate, "DD/MM/YYYY"),
                    moment(endDate, "DD/MM/YYYY"),
                    null,
                    "[]"
                  );
                });
              } else if (DateRange === "All Time") {
                serviceProviders = spDetails;
              }
              for (let item of serviceProviders) {
                const usersDetails = await client.graphql({
                  query: queries.getUserDetails,
                  authMode: "API_KEY",
                  variables: {
                    id: item.id,
                  },
                });
                if (usersDetails.data.getUserDetails !== null) {
                  const spUserName =
                    usersDetails.data.getUserDetails.username;
                  item.username = spUserName;
                }
              }
              setlistofServiceProviders(serviceProviders);
              setCollection(
                cloneDeep(serviceProviders.slice(0, countPerPage))
              );

              setIsLoading(false);
            } else {
              setlistofServiceProviders([]);
              setIsLoading(false);
              setCollection([]);
            }
          }
        } else {
          setlistofServiceProviders([]);
          setIsLoading(false);
          setCollection([]);
        }
        // }
        //}
      } else {
        setlistofServiceProviders([]);
        setIsLoading(false);
        setCollection([]);
      }
      // }
    }
  };

  //----------------------------Table--------------------------------------

  const globalSearch = (searchValue) => {
    let filteredData = listofServiceProviders.filter((row) => {
      const username = getUsername(row.id);
      const company = getUsercompnay(row.id);
      const licence = getCompnayLicence(row.id);
      return (
        row.fname?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.lname?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
        row.mobile?.toLowerCase().includes(searchValue.toLowerCase()) ||
        moment(row.createdAt)
          .format("DD/MM/YYYY")
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        username?.toLowerCase().includes(searchValue.toLowerCase()) ||
        licence?.toLowerCase().includes(searchValue.toLowerCase()) ||
        company?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(listofServiceProviders.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(listofServiceProviders.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  //-----------------------------------------Display------------------------------------------
  const handleUserGroupChange = (event) => {
    const selectedUserGroup = event.target.value;
    if (selectedUserGroup === "") {
      setCollection(cloneDeep(listofServiceProviders.slice(0, countPerPage)));
    } else {
      const filteredSPs = listofServiceProviders.filter(
        (sp) => sp.userGroup === selectedUserGroup
      );
      setCollection(cloneDeep(filteredSPs.slice(0, countPerPage)));
    }
  };

  // date range start-----
  const [selectedOption, setSelectedOption] = useState("");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);

  //---Week------------------------------------------

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const startOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    )
  ).format("DD/MM/YYYY");

  const endOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    )
  ).format("DD/MM/YYYY");

  // ---Month---------------------------

  const startOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  ).format("DD/MM/YYYY");

  const endOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  ).format("DD/MM/YYYY");

  // ---year------------------------------

  const startOfThisYear = moment(
    new Date(currentDate.getFullYear(), 0, 1)
  ).format("DD/MM/YYYY");

  const endOfThisYear = moment(
    new Date(currentDate.getFullYear(), 11, 31)
  ).format("DD/MM/YYYY");

  //---custom -------------------------------------------------

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //----------------------------------------------------------------------

  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };

  const onDateRangeSelected = (range) => {
    //setSelectedOption("");
    handleDateRangeSelected(range);
  };

  function DateRangePicker({ onDateRangeSelected }) {
    // const [selectedOption, setSelectedOption] = useState();

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());
          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());
          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());
          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());
          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());
          break;
        case "Custom":
          onDateRangeSelected(getCustomRange());
          break;
        default:
          break;
      }
    };

    const getTodayRange = () => {
      listSPDetails("Today");
    };

    const getCustomRange = () => {
      listSPDetails("Custom");
    };

    const getAllTimeRange = () => {
      listSPDetails("All Time");
    };

    const getWeekRange = () => {
      listSPDetails("This Week");
    };

    const getMonthRange = () => {
      console.log("inside the month range function........");
      listSPDetails("This Month");
    };

    const getThisYearRange = () => {
      listSPDetails("This Year");
    };

    //----------------------------------------------------------------------------------------

    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="All Time">All Time</option>
                <option value="Today">Today</option>
                <option value="This Week">This Week</option>
                <option value="This Month">This Month</option>
                <option value="This Year">This Year</option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <Fragment>
                  <Row className="d-flex justify-content-end">
                    <Col xl="6">
                      <div className="daterange-card">
                        <div className="mb-3">
                          <FormGroup>
                            <DatePicker
                              className="form-control digits"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(d) => {
                                setDateRange(d);
                                console.log(d);
                                console.log(startDate);
                                console.log(endDate);
                                listSPDetails("Custom");
                              }}
                              isClearable={true}
                              placeholderText="Enter Date Range"
                              dateFormat="dd-MM-yyyy"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  const selectSites = (d) => {
    console.log(d);
    setsitesSelected(d);
    if (d.length > 0) {
      setSiteError(false);
    } else {
      setSiteError(true);
    }
  };

  //-----logo
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `public/${COMPANY_FOLDER}/${file.name}`,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        const filedetails = [];
        filedetails.push({ fileName: file.name, type: file.type });
        console.log(filedetails[0].fileName);
        setSelectedFile(filedetails[0].fileName);
      })
      .send((err) => {
        if (err) console.error(err);
      });
  };

  // Add/Remove Permissions
  const handleCheck = (event) => {
    var updatedList = [...permissions];
    if (event.target.checked) {
      updatedList = [...permissions, event.target.value];
    } else {
      updatedList.splice(permissions.indexOf(event.target.value), 1);
    }
    setPermissions(updatedList);
  };
  const checkedItems = permissions.length
    ? permissions.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";
  // Return classes based on whether item is checked
  var isChecked = (item) =>
    permissions.includes(item) ? "checked-item" : "not-checked-item";

  const handleSelectAllSites = (event) => {
    try {
      if (event.target.checked) {
        setsitesSelected(sites);
      } else {
        setsitesSelected([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Row>
          <DateRangePicker
            onDateRangeSelected={onDateRangeSelected}
          ></DateRangePicker>
        </Row>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="4">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search Service Providers.."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col sm="4" className="text-end">
                    <Form.Select
                      name="userGroup"
                      onChange={handleUserGroupChange}
                    >
                      <option value="">Choose....</option>
                      <option value="electrician">Electrician</option>
                      <option value="cleaner">Cleaner</option>
                    </Form.Select>
                  </Col>
                  <Col sm="4" className="text-end">
                    {LoginUserGroup != "admin" && (
                      <>
                        <Button onClick={handleShowInvite}>
                          {" "}
                          + Invite Service Provider{" "}
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    {isLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <Table className="table-striped  table-de">
                        <thead className="custom-table-head">
                          <tr>
                            {/* <th scope="col">First Name</th>
                          <th scope="col">Last Name</th> */}
                            <th scope="col">Company</th>
                            <th scope="col">Electrcal Licence Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">UserName</th>
                            <th scope="col">Added On</th>
                            <th scope="col">Approval Status</th>
                            <th scope="col">Approve</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className="trhover">
                          {collection.length > 0 ? (
                            collection.map((item) => (
                              <tr>
                                {/* <td>{item.fname}</td>
                              <td>{item.lname}</td> */}
                                <td>
                                  {getUsercompnay(item.spcompanydetailsID)}
                                </td>
                                <td>{item.license}</td>
                                <td>{item.email}</td>
                                <td>
                                  {LoginUserGroup === "admin"
                                    ? item.UserDetails?.username
                                    : item.username}
                                </td>
                                <td>
                                  {/*
                                {new Date(item.createdAt).toLocaleDateString(
                                  "fr-CA"
                                )}
                                */}
                                  {moment(item.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>{item.status}</td>
                                <td>
                                  <Media body className="icon-state switch-sm">
                                    <Label className="switch">
                                      <input
                                        type="checkbox"
                                        onChange={(e) => handleChange(item, e)}
                                      />
                                      <span
                                        className={ApproveStatus(item.status)}
                                      ></span>
                                    </Label>
                                  </Media>
                                </td>
                                <td>
                                  <Btn
                                    attrBtn={{
                                      className: "btn-outline-* btn-xs m-r-5",
                                      color: "secondary",
                                      title: "Permissions",
                                      onClick: () => handleShow(item),
                                    }}
                                  >
                                    <i className="fa fa-check-square"></i>
                                  </Btn>
                                  <Btn
                                    attrBtn={{
                                      className: "btn-outline-* btn-xs m-r-5",
                                      color: "info",
                                      title: "Edit",
                                      onClick: () => handleEdit(item),
                                    }}
                                  >
                                    <i className="fa fa-edit "></i>
                                  </Btn>
                                  <Btn
                                    attrBtn={{
                                      className: "btn-outline-* btn-xs m-r-5",
                                      color: "primary",
                                      title: "View",
                                      onClick: () => handleView(item),
                                    }}
                                  >
                                    <i className="fa fa-eye"></i>{" "}
                                  </Btn>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No Service Provider Available.
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </Col>
              </div>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={
                    value ? filteredData.length : listofServiceProviders.length
                  }
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
            <Modal show={show} size="lg" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Edit Permissions for{" "}
                  {itemforPermission.fname && itemforPermission.lname
                    ? itemforPermission.fname + " " + itemforPermission.lname
                    : itemforPermission.email}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md="6 mb-3">
                    <Label>Choose Permissions</Label>
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "300px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {permissionsList.map((item, index) => (
                        <Label className="d-block" key={index}>
                          <input
                            value={item}
                            type="checkbox"
                            onChange={handleCheck}
                            checked={permissions.includes(item)}
                          />
                          <span className={isChecked(item)}>{item}</span>
                        </Label>
                      ))}
                      {/* {permissionsList.map(function (ub) {
                        return (
                          <UL>
                            <LI>
                              <a>{ub.text}</a>
                              <UL>
                                {ub.children.map(function (item) {
                                  <LI>
                                    <Label className="d-block" key={item.id}>
                                      <input
                                        value={item.text}
                                        type="checkbox"
                                        onChange={handleCheck}
                                        checked={permissions.includes(
                                          item.text
                                        )}
                                      />
                                      <span className={isChecked(item.text)}>
                                        {item.text}
                                      </span>
                                    </Label>
                                    ;
                                  </LI>;
                                })}
                              </UL>
                            </LI>
                          </UL>
                        );
                      })} */}
                    </ScrollBar>
                  </Col>
                  <Col md="6 mb-3">
                    <Label>Selected Permissions</Label>
                    <ScrollBar
                      className="vertical-scroll ps-container ps-theme-default ps-active-y"
                      style={{
                        height: "300px",
                        border: "1px solid #efefef",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {permissions.map((item, index) => (
                        <li>{item}</li>
                      ))}
                    </ScrollBar>
                    {/* <DeniReactTreeView
                      items={data}
                      showCheckbox={true}
                      onNodeSelect={handleNodeSelect}
                      selectedNodes={selectedNodes}
                    />
                    <DeniReactTreeView
                      style={{ marginRight: "10px", marginBottom: "10px" }}
                      showCheckbox={true}
                      theme={"classic"}
                      items={permissionsList}
                      onCheckItem={onCheck}
                    /> */}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => editPermissions()}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showInvite} size="lg" onHide={handleCloseInvite}>
              <Form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Invite Service Providers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col md="4 mb-3">
                      <Label>First Name</Label>
                      <input
                        className="form-control"
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        {...register("firstName", { required: true })}
                      />
                      <span>{errors.email && "First Name is required"}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    <Col md="4 mb-3">
                      <Label>Last Name</Label>
                      <input
                        className="form-control"
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        {...register("lastName", { required: true })}
                      />
                      <span>{errors.email && "Last Name is required"}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    <Col md="6 mb-3">
                      <Label>E-mail</Label>
                      <input
                        className="form-control"
                        name="email"
                        type="email"
                        placeholder="E-mail"
                        {...register("email", { required: true })}
                      />
                      <span>{errors.email && "email is required"}</span>
                      <div className="valid-feedback">{"Looks good!"}</div>
                    </Col>
                    {/* </Row>
                  <Row> */}
                    <Col md="6 mb-3">
                      <Label>Select Service Provider Company</Label>
                      {LoginUserGroup === "spao" && (
                        <>
                          <select
                            className="form-select"
                            name="company"
                            {...register("company", {
                              required: !showAddCompany,
                            })}
                            defaultValue={newCompanyId}
                          >
                            <option value="">Select Company</option>
                            {spCompany &&
                              spCompany.map((item) => (
                                <option value={item.spcomapnyId} key={item.spcomapnyId}>
                                  {item.companyName}
                                </option>
                              ))}
                          </select>
                          <span>
                            {errors.company &&
                              "Service Provider Company is required"}
                          </span>
                        </>
                      )}
                      {LoginUserGroup !== "spao" && (
                        <>
                          <select
                            className="form-select"
                            name="company"
                            {...register("company", {
                              required: !showAddCompany,
                            })}
                            defaultValue={newCompanyId}
                          >
                            <option value="">Select Company</option>
                            {spcompanyname.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.companyName}
                              </option>
                            ))}
                          </select>
                          <span>
                            {errors.company &&
                              "Service Provider Company is required"}
                          </span>
                        </>
                      )}
                      {/* </Col>
                    </Row>
                  <Row>
                    <Col md="6 mb-3 pt-3"></Col>
                    <Col md="6 mb-3 pt-3"> */}
                      {LoginUserGroup !== "spao" && (
                        <>
                          <Label>OR </Label>
                          <br />
                          <a
                            onClick={() => setShowAddCompany(!showAddCompany)}
                            className="btn btn-primary  m-l-10"
                          >
                            {" "}
                            {!showAddCompany ? "Create New Company" : "Cancel"}
                          </a>
                        </>
                      )}

                      {/* <Btn
                        attrBtn={{
                          color: "primary",
                          onclick: handleShowNewCompany,
                        }}
                      >
                      </Btn> */}
                    </Col>
                  </Row>
                  <Row>
                    {showAddCompany && (
                      <Col md="12">
                        <H3>Add New Company</H3>
                        <Row>
                          <Col md="6 mb-3">
                            <Label>Company Name</Label>
                            <input
                              className="form-control"
                              name="companyname"
                              type="text"
                              placeholder="Company Name"
                              {...register("companyname", { required: true })}
                            />
                            <span>
                              {errors.companyname && "Company Name is required"}
                            </span>
                          </Col>
                          <Col md="6 mb-3">
                            <Label>Business Number</Label>
                            <input
                              className="form-control"
                              name="businessNumber"
                              type="text"
                              placeholder="Business Number"
                              {...register("businessNumber", {
                                required: false,
                              })}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label>Company Address</Label>
                            <input
                              className="form-control"
                              name="companyaddress"
                              type="hidden"
                              placeholder="Company Address"
                            />
                            <Autocomplete
                              apiKey="AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4"
                              className="form-control"
                              onPlaceSelected={(place) => {
                                console.log(place);
                                setCompanyAdress(place.formatted_address);
                                if (place) {
                                  setAddressError(false);
                                } else {
                                  setAddressError(true);
                                }
                              }}
                              options={{
                                types: ["address"],
                                componentRestrictions: { country: "AU" },
                              }}
                              name="companyaddress"
                              placeholder="Company Address"
                            />
                            {addressError ? (
                              <span>{"Selecting Address is required"}</span>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md="6 mb-3">
                            <Label>Electrical Licence#</Label>
                            <input
                              className="form-control"
                              name="Licencenumb"
                              type="text"
                              placeholder="Company/Electrical Licence Number"
                              {...register("Licencenumb", { required: true })}
                            />
                            <span>
                              {errors.Licencenumb &&
                                "Electrical Licence Number is required"}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label>Company Phone Number</Label>
                            <input
                              className="form-control"
                              name="companyPhone"
                              type="Number"
                              placeholder="Company Phone Number"
                              {...register("companyPhone", { required: true })}
                            />
                            <span>
                              {errors.companyPhone &&
                                "Company Phone Number is required"}
                            </span>
                          </Col>
                          <Col md="6 mb-3">
                            <Label>E-Mail</Label>
                            <input
                              className="form-control"
                              type="email"
                              name="companyEmail"
                              placeholder="Email"
                              {...register("companyEmail", { required: true })}
                            />
                            <span>
                              {errors.companyEmail && "Email is required"}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label>Company Logo</Label>
                            <input
                              className="form-control"
                              name="companylogo"
                              type="file"
                              placeholder="Logo"
                              onChange={handleFileInput}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6 mb-3">
                            <Label>Choose Permissions</Label>
                            <ScrollBar
                              className="vertical-scroll ps-container ps-theme-default ps-active-y"
                              style={{
                                height: "300px",
                                border: "1px solid #efefef",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              {permissionsList.map((item, index) => (
                                <Label className="d-block" key={index}>
                                  <input
                                    value={item}
                                    type="checkbox"
                                    onChange={handleCheck}
                                    checked={permissions.includes(item)}
                                  />
                                  <span className={isChecked(item)}>
                                    {item}
                                  </span>
                                </Label>
                              ))}
                            </ScrollBar>
                          </Col>
                          <Col md="6 mb-3">
                            <Label>Selected Permissions</Label>
                            <ScrollBar
                              className="vertical-scroll ps-container ps-theme-default ps-active-y"
                              style={{
                                height: "300px",
                                border: "1px solid #efefef",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              {permissions.map((item, index) => (
                                <li>{item}</li>
                              ))}
                            </ScrollBar>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <input
                        type="checkbox"
                        onChange={(event) => handleSelectAllSites(event)}
                      />{" "}
                      Select All Sites
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>Choose Sites</Label>
                      <Typeahead
                        id="rendering-example"
                        multiple={true}
                        options={sites}
                        labelKey={(sites) =>
                          `${sites.siteName} ,(${sites.unitNumber ? sites.unitNumber : ""
                          })`
                        }
                        placeholder="Choose Sites"
                        onChange={(d) => selectSites(d)}
                      />
                      {siteError ? (
                        <span>{"Selecting Site is required"}</span>
                      ) : (
                        ""
                      )}
                      {sitesSelected.length > 0 && (
                        <>
                          <Label>Selected Sites</Label>
                          <ScrollBar
                            className="vertical-scroll ps-container ps-theme-default ps-active-y"
                            style={{
                              height: "200px",
                              border: "1px solid #efefef",
                              padding: "1px",
                              borderRadius: "1px",
                            }}
                          >
                            {sitesSelected.map((item, index) => (
                              <Label className="d-block" key={index}>
                                <span>
                                  {item.siteName},{" (" + item.unitNumber + ")"}
                                </span>
                              </Label>
                            ))}
                          </ScrollBar>
                        </>
                      )}
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      disabled: loading ? loading : loading,
                    }}
                  >
                    {loading ? "Inviting..." : "Add"}
                  </Btn>
                  <Button variant="danger" onClick={handleCloseInvite}>
                    Cancel
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
  return (
    <div>
      {renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default ServiceProviderDetails;
