import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  FormGroup,
  Input,
  Table,
  CardHeader,
  CardFooter,
  Col,
  Card,
  InputGroup,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ButtonDropdown,
  DropdownToggle,
} from "reactstrap";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "../../../assets/scss/index.less";
import { PlusCircle } from "react-feather";
import { Btn, H5, LI, H4, UL } from "../../../AbstractElements";
import * as queries from "../../../graphql/queries";
import * as mergedQueries from "../../../graphql/mergedQueries";
import * as mutations from "../../../graphql/mutations";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import LinkUserWithSite from "../AddSite/linkUserwithSite";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { useForm, useFieldArray } from "react-hook-form";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import SiteDBData from "../../../Data/DistributionBoard/index";
import SiteData from "../../../Data/Sites/index";

// import GoogleMapReact from 'google-map-react';
import { GOOGLEAPIKEY } from "../../../Config/Config";
import HeaderContain from "../../../Layout/Header/HeaderContain";
import { SiteContext } from "../../../App";
import { limit } from "../../../Config/Config";
import { generateClient } from "aws-amplify/api";
const client = generateClient({
  headers: {
    "x-api-key": "da2-ox6gomta4baghjuzk6hpogw25y",
  },
});
var base64 = require("base-64");

const placesLibrary = ["places"];
const SiteListDetails = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () =>
    navigate(`${process.env.PUBLIC_URL}/site/addSite`);
  const handleLinkUserSite = (row) =>
    navigate(`${process.env.PUBLIC_URL}/site/linksite`, row);
  const [siteDetails, setSiteDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [value, setValue] = useState("");
  const countPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [locations, setLocation] = useState([]);
  const [distributionSite, setDistributionSite] = useState([]);
  const [distributionSite1, setDistributionSite1] = useState([]);
  const [distributionSiteFiltrer, setDistributionSiteFiltrer] = useState([]);
  const LoginUserGroup = localStorage.getItem("groups");
  const LoginUserDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userPermission =
    LoginUserDetails.hasOwnProperty("permissions") &&
      LoginUserDetails.permissions !== null
      ? JSON.parse(LoginUserDetails.permissions)
      : [];
  const [LoggedInUserId, setLoggedInUserId] = useState("");
  const [catId, setCatId] = useState();
  const [maxNum, setmaxNum] = useState();
  const [filteredDataSite, setFilteredDataSite] = React.useState([]);
  //const [limit, setLimit] = useState(100000);

  const [filteredData, setFilteredData] = React.useState([]);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [statusSp, setStatusSp] = useState("");
  const [currentSite, setCurrentSite] = useState(null);


  var status = LoginUserGroup === "admin" ? "Active" : "Inactive";
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTumeD-l8p-2slpOP7PEJ7KKOlaFfOOj4",
    libraries: placesLibrary,
  });

  const location = {
    address: "",
  };

  const { selectedSiteGlobal, handleSiteChange } = useContext(SiteContext);
  console.log(selectedSiteGlobal);

  const deleteAllToggle = () => {
    setDeleteAllModal(!deleteAllModal);
  };

  //---Approve Status-----------------------------

  const ApproveStatus = (status) => {
    return status?.toLowerCase() === "active"
      ? "switch-state bg-success"
      : "switch-state bg-danger";
  };

  // const handleChange = async (item) => {
  //   try {
  //     var statusSp;
  //     if (item.status.toLowerCase() === "active") {
  //       statusSp = "Inactive";
  //       toast.success("Deactivated successfully.", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     } else {
  //       statusSp = "Active";
  //       toast.success("Activated successfully.", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     }
  //     console.log(item);

  //     const result = await client.graphql({
  //       query: mutations.updateSiteDetails,
  //       variables: {
  //         input: { id: item.id, status: statusSp },
  //       },
  //     });
  //     if (result) {
  //       console.log(result);
  //       const addedsite = await client.graphql({
  //         query: queries.getSiteDetails,
  //         variables: {
  //           id: item.id,
  //           limit: 10000,
  //         },
  //         authMode: "API_KEY",
  //       });
  //       console.log(addedsite);
  //       if (addedsite.data.getSiteDetails !== null) {
  //         let activeAssets = [];
  //         if (localStorage.getItem("site") !== null) {
  //           activeAssets = JSON.parse(
  //             base64.decode(localStorage.getItem("site"))
  //           );
  //           console.log(activeAssets);
  //           const existingIndex = activeAssets.findIndex(
  //             (item) => item.id === addedsite.data.getSiteDetails.id
  //           );
  //           console.log(existingIndex);
  //           if (existingIndex > -1) {
  //             activeAssets[existingIndex] = addedsite.data.getSiteDetails;
  //           } else {
  //             activeAssets.push(addedsite.data.getSiteDetails);
  //           }
  //           console.log(activeAssets);
  //           localStorage.setItem(
  //             "site",
  //             base64.encode(JSON.stringify(activeAssets))
  //           );
  //         }
  //       }
  //       // if (LoginUserGroup === "admin") {
  //       //   fetchSiteDetails();
  //       // } else if (LoginUserGroup === "site_user") {
  //       //   fetchSiteDetailsSU();
  //       // } else {
  //       //   fetchSiteDetailsAO(LoginUserDetails.id);
  //       // }
  //       fetchSiteDetailsData("All Time");
  //     } else {
  //       toast.error("Error while Updating ", {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     }
  //   } catch (e) {
  //     toast.error("Error while Updating ", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //   }
  // };

  const handleChange = (item, e) => {
    console.log(item);
    setCurrentSite(item);
    setSiteName(item.siteName);
    setStatusSp(item.status.toLowerCase() === "active" ? "Inactive" : "Active");
    setDeleteAllModal(true);
  };


  const handleConfirmStatusChange = async () => {
    console.log(currentSite);
    if (currentSite) {
      try {
        const result = await client.graphql({
          query: mutations.updateSiteDetails,
          variables: {
            input: { id: currentSite.id, status: statusSp },
          },
        });

        if (result) {
          toast.success(`${statusSp} successfully.`, {
            position: toast.POSITION.TOP_CENTER,
          });
          if (statusSp === "Inactive") {
            let nextToken = "";
            let assets = [];
            const result = await client.graphql({
              query: queries.tasksBySitedetailsID,
              variables: {
                sitedetailsID: currentSite.id,
                // filter: {
                //   taskStatus: {
                //     ne: "Completed",
                //   },
                // },
                limit: limit,
              },
              authMode: "API_KEY",
            });
            if (result.data.tasksBySitedetailsID.items.length > 0) {
              let assetDatas = result.data.tasksBySitedetailsID.items;
              nextToken = result.data.tasksBySitedetailsID.nextToken;
              console.log(
                "first token count",
                result.data.tasksBySitedetailsID.items.length
              );
              console.log("nextToken 1", nextToken);
              if (result.data.tasksBySitedetailsID.items.length > 0) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                const results = await client.graphql({
                  query: queries.tasksBySitedetailsID,
                  variables: {
                    sitedetailsID: currentSite.id,
                    // filter: {
                    //   taskStatus: {
                    //     ne: "Completed",
                    //   },
                    // },
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
                if (results.data.tasksBySitedetailsID.items.length > 0) {
                  nextToken = results.data.tasksBySitedetailsID.nextToken;
                  console.log("nextToken", nextToken);
                  console.log(results.data.tasksBySitedetailsID.items.length);
                  if (results.data.tasksBySitedetailsID.items.length > 0) {
                    assets.push(results.data.tasksBySitedetailsID.items);
                  }
                }
              }
              console.log(assets);
              if (assets.length > 0) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                //---
                const sortItemsnew = mergeAssets.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });


                sortItemsnew.map(async (item, i) => {
                  console.log("Item:", item);
                  await sleep(Math.random() * i * 17); // Wait
                  await client
                    .graphql({
                      query: mutations.updateTask,
                      variables: {
                        input: {
                          id: item.id,
                          status: "Inactive",
                        },
                      },
                      authMode: "API_KEY",
                    })
                    .then((result) => {
                      console.log("Result:", result);
                    })
                    .catch((error) => {
                      console.error("GraphQL Error:", error);
                    });
                });

                //--
              } else {
                console.log("---");
              }
            } else {
              console.log("no tasks..");
              setIsLoading(false);
            }
          }
          else {
            let nextToken = "";
            let assets = [];
            const result = await client.graphql({
              query: queries.tasksBySitedetailsID,
              variables: {
                sitedetailsID: currentSite.id,
                // filter: {
                //   taskStatus: {
                //     ne: "Completed",
                //   },
                // },
                limit: limit,
              },
              authMode: "API_KEY",
            });
            if (result.data.tasksBySitedetailsID.items.length > 0) {
              let assetDatas = result.data.tasksBySitedetailsID.items;
              nextToken = result.data.tasksBySitedetailsID.nextToken;
              console.log(
                "first token count",
                result.data.tasksBySitedetailsID.items.length
              );
              console.log("nextToken 1", nextToken);
              if (result.data.tasksBySitedetailsID.items.length > 0) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                const results = await client.graphql({
                  query: queries.tasksBySitedetailsID,
                  variables: {
                    sitedetailsID: currentSite.id,
                    // filter: {
                    //   taskStatus: {
                    //     ne: "Completed",
                    //   },
                    // },
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                });
                if (results.data.tasksBySitedetailsID.items.length > 0) {
                  nextToken = results.data.tasksBySitedetailsID.nextToken;
                  console.log("nextToken", nextToken);
                  console.log(results.data.tasksBySitedetailsID.items.length);
                  if (results.data.tasksBySitedetailsID.items.length > 0) {
                    assets.push(results.data.tasksBySitedetailsID.items);
                  }
                }
              }
              console.log(assets);
              if (assets.length > 0) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                //---
                const sortItemsnew = mergeAssets.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });


                sortItemsnew.map(async (item, i) => {
                  console.log("Item:", item);
                  await sleep(Math.random() * i * 17); // Wait
                  await client
                    .graphql({
                      query: mutations.updateTask,
                      variables: {
                        input: {
                          id: item.id,
                          status: "Active",
                        },
                      },
                      authMode: "API_KEY",
                    })
                    .then((result) => {
                      console.log("Result:", result);
                    })
                    .catch((error) => {
                      console.error("GraphQL Error:", error);
                    });
                });

                //--
              } else {
                console.log("---");
              }
            } else {
              console.log("no tasks..");
              setIsLoading(false);
            }
          }

          // Update the site status locally after the update
          // const updatedSite = await client.graphql({
          //   query: queries.getSiteDetails,
          //   variables: {
          //     id: currentSite.id,
          //     limit: 10000,
          //   },
          //   authMode: "API_KEY",
          // });

          // if (updatedSite.data.getSiteDetails) {
          //   // Handle updating local storage or state as needed
          //   const updatedSiteDetails = updatedSite.data.getSiteDetails;
          //   let activeAssets = JSON.parse(localStorage.getItem("site")) || [];

          //   const existingIndex = activeAssets.findIndex((site) => site.id === updatedSiteDetails.id);
          //   if (existingIndex > -1) {
          //     activeAssets[existingIndex] = updatedSiteDetails;
          //   } else {
          //     activeAssets.push(updatedSiteDetails);
          //   }

          //   localStorage.setItem("site", base64.encode(JSON.stringify(activeAssets)));
          // }

          // Close modal after updating the status
          setDeleteAllModal(false);
          fetchSiteDetailsData("All Time"); // Refresh site details
        } else {
          toast.error("Error while updating", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (e) {
        toast.error("Error while updating", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };


  async function fetchSiteDetailsSU() {
    await client
      .graphql({
        query: queries.linkUsersAndSitesByUserdetailsID,
        authMode: "API_KEY",
        variables: {
          userdetailsID: LoginUserDetails.id,
          filter: {
            status: {
              eq: "Active",
            },
          },
          limit: limit,
        },
      })
      .then(async (dataDetails) => {
        const siteDetails =
          dataDetails.data.linkUsersAndSitesByUserdetailsID.items;
        if (siteDetails.length > 0) {
          //--
          let nextToken = "";
          let assets = [];
          await client
            .graphql({
              query: queries.listSiteDetails,
              authMode: "API_KEY",
              variables: {
                limit: limit,
              },
            })
            .then(async (result) => {
              let assetDatas = result.data.listSiteDetails.items;
              nextToken = result.data.listSiteDetails.nextToken;
              if (result.data.listSiteDetails.items.length > 0) {
                assets.push(assetDatas);
              }
              while (nextToken !== null) {
                await client
                  .graphql({
                    query: queries.listSiteDetails,
                    authMode: "API_KEY",
                    variables: {
                      limit: limit,
                      nextToken: nextToken,
                    },
                    authMode: "API_KEY",
                  })
                  .then((results) => {
                    nextToken = results.data.listSiteDetails.nextToken;
                    if (results.data.listSiteDetails.items.length > 0) {
                      assets.push(results.data.listSiteDetails.items);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setCollection([]);
                  });
              }
              console.log(assets);
              if (assets.length > 0) {
                const mergeAssets = assets.flat(1);
                console.log(mergeAssets);
                //--
                const siteDetail = mergeAssets;
                const filteredSites = siteDetail.filter(
                  (item) => item.id === selectedSiteGlobal
                );
                console.log(filteredSites);
                const siteDetailsu = siteDetail.filter((item) => {
                  const _matchingAssetTemplate = siteDetails?.find(
                    (site) => site.sitedetailsID === item?.id
                  );
                  if (_matchingAssetTemplate) {
                    return item;
                  }
                });
                const sitefiltered = selectedSiteGlobal
                  ? filteredSites
                  : siteDetailsu;
                console.log(sitefiltered);
                const collectArray = [];
                sitefiltered.map((site) => {
                  client
                    .graphql({
                      query: queries.getCompanyDetails,
                      variables: {
                        id: site.companydetailsID,
                        limit: limit,
                      },
                      authMode: "API_KEY",
                    })
                    .then((dataDetails) => {
                      console.log(dataDetails);
                      const comp =
                        dataDetails.data.getCompanyDetails.companyName;
                      collectArray.push({
                        id: site.id,
                        unitNumber: site.unitNumber,
                        companydetailsID: site.companydetailsID,
                        suburb: site.suburb,
                        state: site.state,
                        createdAt: site.createdAt,
                        companyName: comp,
                        status: site.status,
                        siteName: site.siteName,
                      });
                      setSiteDetails(collectArray);
                      setCollection(
                        cloneDeep(collectArray.slice(0, countPerPage))
                      );
                    })
                    .catch((error1) => {
                      console.log(error1);
                      setIsLoading(false);
                    });
                });
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setCollection([]);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  //----------------------------------------------------------------------------------
  const handelAction = (action, id) => {
    toast.success(action, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  //---------------------------------------------------------------------------------------------
  async function fetchCompanyDetails() {
    await client
      .graphql({
        query: queries.listCompanyDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      })
      .then((dataDetails) => {
        const companyNames = dataDetails.data.listCompanyDetails.items;
        // let filteredArray = [];
        // filteredArray = companyNames.filter(function(item, pos){
        //   return companyNames.indexOf(item) == pos;
        // });
        client
          .graphql({
            query: queries.listSPCompanyDetails,
            authMode: "API_KEY",
            variables: {
              limit: limit,
            },
          })
          .then((dataDetailsSP) => {
            const companyNamesSP =
              dataDetailsSP.data.listSPCompanyDetails.items;
            console.log(companyNamesSP);
            // let filteredArraySP = [];
            // filteredArraySP = companyNamesSP.filter(function(item, pos){
            //   return companyNamesSP.indexOf(item) == pos;
            // });
            let CompanyNamesList = [];
            CompanyNamesList.push(...companyNamesSP);
            CompanyNamesList.push(...companyNames);
            setCompanyDetails(CompanyNamesList); //.filter(function(item, pos){
            //   return CompanyNamesList.indexOf(item) == pos;
            // }));
          })
          .catch((errors) => {
            console.log(errors);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    //fetchTasksinactive();
    //fulltasks();
  }, [])

  async function fetchTasksinactive() {
    let nextToken = "";
    let assets = [];
    let count = 0;
    let nextToken1 = "";
    let sitess = [];
    const result = await client.graphql({
      query: queries.siteDetailsByAssetownerID,
      variables: {
        assetownerID: LoginUserDetails.id,
        limit: 200000,
      },
      authMode: "API_KEY",
    });
    if (result.data.siteDetailsByAssetownerID.items.length > 0) {
      let assetDatas = result.data.siteDetailsByAssetownerID.items;
      nextToken1 = result.data.siteDetailsByAssetownerID.nextToken;
      sitess.push(assetDatas);
      while (nextToken1 !== null) {
        const results = await client.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: 200000,
            nextToken: nextToken1,
          },
          authMode: "API_KEY",
        });
        nextToken1 = results.data.siteDetailsByAssetownerID.nextToken;
        if (results.data.siteDetailsByAssetownerID.items.length > 0) {
          sitess.push(results.data.siteDetailsByAssetownerID.items);
        }
      }
      console.log(sitess);
      if (sitess.length > 0) {
        const mergeSites = sitess.flat(1);
        //---
        const inactivesites = mergeSites.filter((item) => item.status === "Inactive");
        console.log(inactivesites);
        for (let site of inactivesites) {
          count = count + 1;
          const result = await client.graphql({
            query: queries.tasksBySitedetailsID,
            variables: {
              sitedetailsID: site.id,
              // filter: {
              //   taskStatus: {
              //     ne: "Completed",
              //   },
              // },
              limit: limit,
            },
            authMode: "API_KEY",
          });
          if (result.data.tasksBySitedetailsID.items.length > 0) {
            let assetDatas = result.data.tasksBySitedetailsID.items;
            nextToken = result.data.tasksBySitedetailsID.nextToken;
            console.log(
              "first token count",
              result.data.tasksBySitedetailsID.items.length
            );
            console.log("nextToken 1", nextToken);
            if (result.data.tasksBySitedetailsID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              const results = await client.graphql({
                query: queries.tasksBySitedetailsID,
                variables: {
                  sitedetailsID: site.id,
                  // filter: {
                  //   taskStatus: {
                  //     ne: "Completed",
                  //   },
                  // },
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              });
              if (results.data.tasksBySitedetailsID.items.length > 0) {
                nextToken = results.data.tasksBySitedetailsID.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.tasksBySitedetailsID.items.length);
                if (results.data.tasksBySitedetailsID.items.length > 0) {
                  assets.push(results.data.tasksBySitedetailsID.items);
                }
              }
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              //---
              const sortItemsnew = mergeAssets.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              if (count === inactivesites.length) {
                console.log("------all data fetched");
                console.log(sortItemsnew);
              }

              sortItemsnew.map(async (item, i) => {
                console.log("Item:", item);
                await sleep(Math.random() * i * 17); // Wait
                await client
                  .graphql({
                    query: mutations.updateTask,
                    variables: {
                      input: {
                        id: item.id,
                        taskStatus: "Inactive",
                      },
                    },
                    authMode: "API_KEY",
                  })
                  .then((result) => {
                    console.log("Result:", result);
                  })
                  .catch((error) => {
                    console.error("GraphQL Error:", error);
                  });
              });

              // let csvContent = "";

              // // Define your headers
              // const headers = [
              //   "Id",
              //   "Added On",
              //   "Task Type",
              //   "Site",
              //   "Category",
              //   "Task Status",
              //   "Task Assignee",
              //   "Task Assigned By",
              //   "Task Assigned Date",
              //   "Due Date",
              // ];
              // csvContent += headers.join(",") + "\n";

              // // Replace `#` with something else (e.g., "") to avoid CSV formatting issues
              // const replaceHash = (value) => {
              //   if (value && typeof value === "string") {
              //     return value.replace(/#/g, '""');
              //   } else {
              //     return value;
              //   }
              // };

              // // Export data based on activeTab
              // let exportData = [];


              // // Process each item and add it to the CSV
              // mergeAssets.forEach((item) => {
              //   const row = [
              //     '"' + item.id + '"',
              //     '"' + moment(item.createdAt).format("DD/MM/YYYY") + '"',
              //     '"' + replaceHash(item.taskType) + '"',
              //     '"' + replaceHash((JSON.parse(item.taskCompletionLink)[0].site)) + '"',
              //     '"' + replaceHash((JSON.parse(item.taskCompletionLink)[0].category)) + '"',
              //     '"' + replaceHash(item.taskStatus) + '"',
              //     '"' + replaceHash((item.taskAssignee)) + '"',
              //     '"' + replaceHash((item.assignedBy)) + '"',
              //     '"' + moment(item.assignedDate).format("DD/MM/YYYY") + '"',
              //     item.dueDate !== null
              //       ? '"' + moment(item.dueDate).format("DD/MM/YYYY") + '"'
              //       : '"-"',
              //   ];
              //   csvContent += row.join(",") + "\n";
              // });

              // // Encode the CSV data to make it downloadable
              // const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);

              // // Create a link element to trigger download
              // const link = document.createElement("a");
              // link.setAttribute("href", encodedUri);
              // link.setAttribute("download", "tasks.csv");
              // document.body.appendChild(link);
              // link.click();
              // document.body.removeChild(link);

              // // Revert the hashes to the original "#" after exporting
              // const revertHash = (value) => {
              //   if (value) {
              //     return value.replace(/{{HASH}}/g, "#");
              //   } else {
              //     return value;
              //   }
              // };

              // // Optionally revert hashes in the original data (useful for editing)
              // exportData.forEach((item) => {
              //   item.taskType = revertHash(item.taskType);
              //   item.taskStatus = revertHash(item.taskStatus);
              //   item.taskAssignee = revertHash(item.taskAssignee);
              //   item.assignedBy = revertHash(item.assignedBy);
              // });

              //--
            } else {

            }
          } else {
            console.log("no tasks..");
            setIsLoading(false);
          }
        }
      }
    } else {
      setIsLoading(false);
    }
  }

  async function fulltasks() {
    let nextToken = "";
    let assets = [];
    let count = 0;
    let nextToken1 = "";
    let sitess = [];
    // const result = await client.graphql({
    //   query: queries.siteDetailsByAssetownerID,
    //   variables: {
    //     assetownerID: LoginUserDetails.id,
    //     limit: 200000,
    //   },
    //   authMode: "API_KEY",
    // });
    // if (result.data.siteDetailsByAssetownerID.items.length > 0) {
    //   let assetDatas = result.data.siteDetailsByAssetownerID.items;
    //   nextToken1 = result.data.siteDetailsByAssetownerID.nextToken;
    //   sitess.push(assetDatas);
    //   while (nextToken1 !== null) {
    //     const results = await client.graphql({
    //       query: queries.siteDetailsByAssetownerID,
    //       variables: {
    //         assetownerID: LoginUserDetails.id,
    //         limit: 200000,
    //         nextToken: nextToken1,
    //       },
    //       authMode: "API_KEY",
    //     });
    //     nextToken1 = results.data.siteDetailsByAssetownerID.nextToken;
    //     if (results.data.siteDetailsByAssetownerID.items.length > 0) {
    //       sitess.push(results.data.siteDetailsByAssetownerID.items);
    //     }
    //   }
    //   console.log(sitess);
    //   if (sitess.length > 0) {
    //     const mergeSites = sitess.flat(1);
    //     //---
    //     const inactivesites = mergeSites.filter((item) => item.status === "Inactive");
    //     console.log(inactivesites);
    //     for (let site of inactivesites) {
    count = count + 1;
    const result = await client.graphql({
      query: queries.listTasks,
      variables: {
        //sitedetailsID: site.id,
        filter: {
          taskStatus: {
            eq: "Inactive",
          },
        },
        limit: limit,
      },
      authMode: "API_KEY",
    });
    if (result.data.listTasks.items.length > 0) {
      let assetDatas = result.data.listTasks.items;
      nextToken = result.data.listTasks.nextToken;
      console.log(
        "first token count",
        result.data.listTasks.items.length
      );
      console.log("nextToken 1", nextToken);
      if (result.data.listTasks.items.length > 0) {
        assets.push(assetDatas);
      }
      while (nextToken !== null) {
        const results = await client.graphql({
          query: queries.listTasks,
          variables: {
            //sitedetailsID: site.id,
            filter: {
              taskStatus: {
                eq: "Inactive",
              },
            },
            limit: limit,
            nextToken: nextToken,
          },
          authMode: "API_KEY",
        });
        if (results.data.listTasks.items.length > 0) {
          nextToken = results.data.listTasks.nextToken;
          console.log("nextToken", nextToken);
          console.log(results.data.listTasks.items.length);
          if (results.data.listTasks.items.length > 0) {
            assets.push(results.data.listTasks.items);
          }
        }
        else {
          nextToken = null;
        }
      }
      console.log(assets);
      if (assets.length > 0) {
        const mergeAssets = assets.flat(1);
        console.log(mergeAssets);
        //---
        const sortItemsnew = mergeAssets.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        // if (count === inactivesites.length) {
        //   console.log("------all data fetched");
        //   console.log(sortItemsnew);
        // }

        sortItemsnew.map(async (item, i) => {
          console.log("Item:", item);
          await sleep(Math.random() * i * 17); // Wait
          await client
            .graphql({
              query: mutations.updateTask,
              variables: {
                input: {
                  id: item.id,
                  status: "Inactive",
                },
              },
              authMode: "API_KEY",
            })
            .then((result) => {
              console.log("Result:", result);
            })
            .catch((error) => {
              console.error("GraphQL Error:", error);
            });
        });

        //--
      } else {

      }
    } else {
      console.log("no tasks..");
      setIsLoading(false);
    }
    //}
    //}
    // } else {
    //   setIsLoading(false);
    // }
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  //-------------------------------------------------------------------------------------------------
  async function fetchSiteDetails() {
    setIsLoading(true);
    //--
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listSiteDetails,
        authMode: "API_KEY",
        variables: {
          limit: limit,
        },
      })
      .then(async (result) => {
        let assetDatas = result.data.listSiteDetails.items;
        nextToken = result.data.listSiteDetails.nextToken;
        if (result.data.listSiteDetails.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listSiteDetails,
              authMode: "API_KEY",
              variables: {
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listSiteDetails.nextToken;
              if (results.data.listSiteDetails.items.length > 0) {
                assets.push(results.data.listSiteDetails.items);
              }
            })
            .catch((error) => {
              console.log(error);
              setCollection([]);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          const sortedItems = mergeAssets.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log(sortedItems);
          const siteDetails = sortedItems;
          const filteredSites = siteDetails.filter(
            (item) => item.id === selectedSiteGlobal
          );
          console.log(filteredSites);
          const sitefiltered = selectedSiteGlobal ? filteredSites : siteDetails;
          console.log(sitefiltered);
          setCollection(cloneDeep(sitefiltered.slice(0, countPerPage)));
          console.log(siteDetails);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setCollection([]);
        setIsLoading(false);
      });
  }

  //----------------------------------------------------------

  async function fetchSiteDetailsAOo(aoID) {
    const collectArray = [];
    setIsLoading(true);
    await client
      .graphql({
        query: queries.getAssetOwner,
        authMode: "API_KEY",
        variables: {
          id: LoginUserDetails.id,
          limit: limit,
        },
      })
      .then(async (dataDetails) => {
        const AOcompanyNames = dataDetails.data.getAssetOwner;
        let nextToken = "";
        let assets = [];
        await client
          .graphql({
            query: queries.siteDetailsByAssetownerID,
            variables: {
              assetownerID: aoID,
              limit: limit,
            },
            authMode: "API_KEY",
          })
          .then(async (result) => {
            let assetDatas = result.data.siteDetailsByAssetownerID.items;
            nextToken = result.data.siteDetailsByAssetownerID.nextToken;
            if (result.data.siteDetailsByAssetownerID.items.length > 0) {
              assets.push(assetDatas);
            }
            while (nextToken !== null) {
              await client
                .graphql({
                  query: queries.siteDetailsByAssetownerID,
                  variables: {
                    assetownerID: aoID,
                    limit: limit,
                    nextToken: nextToken,
                  },
                  authMode: "API_KEY",
                })
                .then((results) => {
                  nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                  if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                    assets.push(results.data.siteDetailsByAssetownerID.items);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setCollection([]);
                });
            }
            console.log(assets);
            if (assets.length > 0) {
              const mergeAssets = assets.flat(1);
              console.log(mergeAssets);
              //--
              const sortedItems = mergeAssets.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              });
              const siteDetail = sortedItems;
              const filteredSites = siteDetail.filter(
                (item) => item.id === selectedSiteGlobal
              );
              const sitefiltered = selectedSiteGlobal
                ? filteredSites
                : siteDetail;
              setIsLoading(false);
              if (sitefiltered.length > 0) {
                sitefiltered.map((site) => {
                  collectArray.push({
                    id: site.id,
                    unitNumber: site.unitNumber,
                    suburb: site.suburb,
                    companydetailsID: site.companydetailsID,
                    state: site.state,
                    createdAt: site.createdAt,
                    companyName: AOcompanyNames.companyName,
                    status: site.status,
                    siteName: site.siteName,
                  });
                  setSiteDetails(collectArray);
                  setCollection(cloneDeep(collectArray.slice(0, countPerPage)));
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setCollection([]);
            setIsLoading(false);
          });
      })
      .catch((errors) => {
        console.log(errors);
        setErrorMessage("Unable to fetch the list");
        setIsLoading(false);
      });
  }

  //-------------------------------------------------------------------------------------------------------

  useEffect(() => {
    fetchCompanyDetails();
    setLoggedInUserId(LoginUserDetails.id);
    console.log(LoginUserDetails.id);
    console.log(LoginUserDetails);
    const LoginUserSiteDetails =
      LoginUserDetails.LinkUsersAndSites.items &&
        LoginUserDetails.LinkUsersAndSites.items.length > 0
        ? LoginUserDetails.LinkUsersAndSites.items
        : [];
    SerialId();
    async function SerialId() {
      await client
        .graphql({
          query: queries.listDistributionBoxes,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        })
        .then((response) => {
          const DistributionBoxesData =
            response.data.listDistributionBoxes.items;

          let idArry = [];
          let maxnumber = 0;

          DistributionBoxesData.map((item) => {
            if (
              item.distributionBoxNumber !== "" &&
              item.distributionBoxNumber !== null
            ) {
              if (
                isNaN(Number(item.distributionBoxNumber.replace(/^\D+/g, "")))
              ) {
                idArry.push(1);
              } else {
                idArry.push(
                  Number(item.distributionBoxNumber.replace(/^\D+/g, ""))
                );
              }
            }
          });

          maxnumber = Math.max(...idArry);
          if (idArry.length > 0) {
            if (maxnumber !== 0 && maxnumber !== null) {
              const res = digits_count(Number(maxnumber + 1));
              if (res === 1) {
                setCatId("000");
                setmaxNum(maxnumber + 1);
              } else if (res === 2) {
                setCatId("00");
                setmaxNum(maxnumber + 1);
              } else if (res === 3) {
                setCatId("0");
                setmaxNum(maxnumber + 1);
              } else if (res === 4) {
                setCatId("");
                setmaxNum(maxnumber + 1);
              }
            } else {
              setCatId("000");
              setmaxNum(1);
            }
          } else {
            setCatId("000");
            setmaxNum(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedSiteGlobal]);

  const digits_count = (n) => {
    var count = 0;
    if (n >= 1) ++count;
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  };
  //------------------------------------------------------------

  const getCompanyName = (id) => {
    const company = companyDetails.filter((item) => {
      return item.id === id;
    });
    if (company.length > 0) {
      return company[0].companyName;
    } else {
      return "";
    }
  };

  const getDistributionBoxCount = (id) => {
    try {
      const dbCount = distributionSite1.filter((item) => {
        return item.sitedetailsID === id;
      });
      console.log(dbCount.length);
      return dbCount.length > 0 ? dbCount.length : "-";
    } catch (err) {
      console.log(err);
      return "-";
    }
  };

  const getCompanyNames = async (id) => {
    await client
      .graphql({
        query: queries.getCompanyDetails,
        variables: {
          id: id,
        },
        authMode: "API_KEY",
      })
      .then((result) => {
        console.log(result);
        return result.data.getCompanyDetails.companyName;
      })
      .catch((error) => {
        console.log(error);
        return "";
      });
  };

  const handelDelete = async (id, version, name) => {
    await client
      .graphql({
        query: mutations.updateSiteDetails,
        variables: {
          input: { id: id, delete: true, _version: version },
        },
      })
      .then((result) => {
        console.log(result);
        toast.success(name + " Site deleted successfully.", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchSiteDetailsData("All Time");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error while deleting " + name + " Site", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const globalSearch = (searchValue) => {
    console.log(siteDetails);
    let filteredData = siteDetails.filter((item) => {
      const compName = getCompanyName(item.companydetailsID);
      console.log(compName);
      return (
        item.companyName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.suburb?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.state?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.status?.toLowerCase().includes(searchValue.toLowerCase()) ||
        moment(item.createdAt)
          .format("DD/MM/YYYY")
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        item.unitNumber?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.siteName?.toLowerCase().includes(searchValue.toLowerCase()) ||
        compName?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    return cloneDeep(filteredData);
  };

  React.useEffect(() => {
    if (!value) {
      setCollection(cloneDeep(siteDetails.slice(0, countPerPage)));
      setFilteredData([]);
    } else {
      const filteredData = globalSearch(value);
      setFilteredData(filteredData);
      setCollection(cloneDeep(filteredData.slice(0, countPerPage)));
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;

    if (!value) {
      setCollection(cloneDeep(siteDetails.slice(from, to)));
    } else {
      const filteredData = globalSearch(value);
      const pageData = filteredData.slice(from, to);
      setCollection(cloneDeep(pageData));
    }
  };

  const [ViewLarge, setViewLarge] = useState(false);
  const [viewData, setViewData] = useState([]);
  const handleClose = () => setViewLarge(!ViewLarge);
  const [siteUsers, setSiteUsers] = useState([]);
  const ViewLargeModal = async (siteData) => {
    if (
      LoginUserGroup === "site_owner" ||
      LoginUserGroup === "spao" ||
      LoginUserGroup === "site_user"
    ) {
      navigate(`${process.env.PUBLIC_URL}/catassetlist`, {
        state: {
          site: siteData,
        },
      });
      // navigate(`${process.env.PUBLIC_URL}/sites/siteview`, { state: siteData });
      location.address =
        siteData.suburb +
        " " +
        siteData.street +
        " " +
        siteData.postCode +
        " " +
        siteData.state +
        " " +
        siteData.country;
    } else if (LoginUserGroup === "admin") {
      navigate(`${process.env.PUBLIC_URL}/sites/siteview`, { state: siteData });
      location.address =
        siteData.suburb +
        " " +
        siteData.street +
        " " +
        siteData.postCode +
        " " +
        siteData.state +
        " " +
        siteData.country;
    }
  };

  const [Viewdbid1, setViewdbid1] = useState(false);
  const ViewdbidModaltoggle1 = () => setViewdbid1(!Viewdbid1);
  const callDb = (sites) => {
    setid(sites);
    const dbCount = distributionSite1.filter((item) => {
      return item.sitedetailsID === sites.id;
    });
    setDistributionSiteFiltrer(dbCount);
    setViewdbid1(!Viewdbid1);
  };

  const ViewLargeModaltoggle = () => setViewLarge(!ViewLarge);
  const [LinkUser, setLinkUser] = useState(false);
  const LinkUserModal = (row) => {
    setViewData(row);
    LinkUserModaltoggle();
  };
  const LinkUserModaltoggle = () => setLinkUser(!LinkUser);

  const [Viewdbid, setViewdbid] = useState(false);
  const [selectedSiteId, setid] = useState([]);
  const ViewDistributionBoxModal = (sites) => {
    setid(sites);
    setViewdbid(!Viewdbid);
  };
  const ViewdbidModaltoggle = () => setViewdbid(!Viewdbid);
  const [distributionBoxAdress, setdistributionBoxAdress] = useState([]);
  const { register, control, handleSubmit, reset, watch, e, ...form } = useForm(
    {
      defaultValues: {
        distributionBoxDetails: [
          {
            distributionBox: "",
            location: "",
          },
        ],
      },
    }
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: "distributionBoxDetails",
  });

  const onSubmit = async (data, e) => {
    try {
      let count = 0;
      let duplicate = 0;
      console.log("distributionBoxAdress", data);
      const inputArray = data.distributionBoxDetails;
      const dbdata = await client.graphql({
        query: queries.distributionBoxesBySitedetailsID,
        variables: {
          sitedetailsID: selectedSiteId.id,
          limit: limit,
        },
      });
      await Promise.all(
        inputArray.map(async function (d, idx) {
          const dbdata = await client.graphql({
            query: queries.distributionBoxesBySitedetailsID,
            variables: {
              sitedetailsID: selectedSiteId.id,
              filter: {
                distributionBoxName: {
                  eq: d.distributionBox,
                },
              },
              limit: limit,
            },
          });
          console.log(dbdata);
          if (dbdata.data.distributionBoxesBySitedetailsID.items.length > 0) {
            console.log("duplicate distribution board for a selected site");
            duplicate = duplicate + 1;
            // toast.error("Distribution Board Saved Succesfully!..", {
            //   position: toast.POSITION.TOP_CENTER,
            // });
          } else {
            const result = await client.graphql({
              query: mutations.createDistributionBox,
              variables: {
                input: {
                  distributionBoxName: d.distributionBox,
                  location: d.location,
                  sitedetailsID: selectedSiteId.id,
                  status: "Active",
                  distributionBoxNumber: "DB" + catId + maxNum,
                },
              },
              authMode: "API_KEY",
            });
            console.log(result);
            count = count + 1;
            const addedsite = await client.graphql({
              query: queries.getDistributionBox,
              variables: {
                id: result.data.createDistributionBox.id,
              },
              authMode: "API_KEY",
            });
            if (addedsite.data.getDistributionBox !== null) {
              if (localStorage.getItem("siteDB") !== null) {
                let activeDB = JSON.parse(
                  base64.decode(localStorage.getItem("siteDB"))
                );
                console.log(activeDB);
                activeDB.push(addedsite.data.getDistributionBox);
                console.log(activeDB);
                localStorage.setItem(
                  "siteDB",
                  base64.encode(JSON.stringify(activeDB))
                );
              }
            }
          }
        })
      );
      //fetchSiteDetailsAO(LoginUserDetails.id);
      if (count === inputArray.length) {
        toast.success("Distribution Board Saved Succesfully!..", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      if (duplicate > 0 && count === 0) {
        toast.error(
          `Duplicate Distribution Board for the selected Site! (${duplicate} duplicates found)`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      if (count > 0 && duplicate > 0) {
        toast.error(
          `Duplicate Distribution Board for the selected Site! (${duplicate} )`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        toast.success(
          `Distribution Board Saved Succesfully! for the selected Site! (${count} )`,
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }

      e.target.reset();
      setViewdbid(!Viewdbid);
      delete state.state;
      const formsdata = {
        distributionBox: "",
        location: "",
      };
      reset({ ...formsdata });

      state.state && state.state.page === "ao"
        ? navigate(`${process.env.PUBLIC_URL}/assetOwner`)
        : navigate(`${process.env.PUBLIC_URL}/sites`);

      await fetchDBcount();
      console.log("db list");
    } catch (error) {
      console.log(error);
    }
  };

  // ---getting all sites and added db
  async function fetchSiteDetailsAO(assetOwnerId) {
    try {
      //setIsLoading(true);
      let nextToken = "";
      let sites = [];
      await client
        .graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: assetOwnerId,
            limit: limit,
          },
          authMode: "API_KEY",
        })
        .then(async (result) => {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            sites.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.siteDetailsByAssetownerID,
                variables: {
                  assetownerID: assetOwnerId,
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.siteDetailsByAssetownerID.nextToken;
                if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                  sites.push(results.data.siteDetailsByAssetownerID.items);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log(sites);
          if (sites.length > 0) {
            const mergeSites = sites.flat(1);
            SiteData.setSiteData(mergeSites);
            //setSiteDetails(mergeSites);
            localStorage.setItem(
              "site",
              base64.encode(JSON.stringify(mergeSites))
            );
            fetchlistDistributionBox(mergeSites);
          }
        })
        .catch((error) => {
          console.log(error);
          SiteData.setSiteData([]);
          //setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
      //setIsLoading(false);
    }
  }

  async function fetchlistDistributionBox(siteData) {
    try {
      setIsLoading(true);
      const promises = siteData.map((item) => {
        return client.graphql({
          query: queries.distributionBoxesBySitedetailsID,
          variables: {
            sitedetailsID: item.id,
            limit: limit,
          },
        });
      });
      Promise.all(promises)
        .then(async (results) => {
          console.log("results -> ", results);
          let SiteDB = [];
          results.map((items) => {
            if (items.data.distributionBoxesBySitedetailsID.items.length > 0) {
              SiteDB.push(items.data.distributionBoxesBySitedetailsID.items);
            }
          });
          if (SiteDB.length > 0) {
            SiteDBData.setSiteDBData(SiteDB.flat(1));
            console.log("siteDB", SiteDB.flat(1));
            localStorage.setItem(
              "siteDB",
              base64.encode(JSON.stringify(SiteDB.flat(1)))
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  function refreshPage() {
    window.location.reload(true);
  }

  const onLoad = (autocomplete) => {
    console.log("autocomplete: ", autocomplete);
  };

  const onPlaceChanged = () => {
    console.log("this.autocomplete.getPlace()");
  };
  // date range filter
  const [selectedOption, setSelectedOption] = useState("");
  const [dateRange1, setDateRange1] = useState([new Date(), new Date()]);

  //---Week------------------------------------------

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay();
  const startOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDayOfWeek
    )
  ).format("DD/MM/YYYY");
  const endOfWeek = moment(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (6 - currentDayOfWeek)
    )
  ).format("DD/MM/YYYY");

  // ---Month---------------------------

  const startOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  ).format("DD/MM/YYYY");
  const endOfMonth = moment(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  ).format("DD/MM/YYYY");

  // ---year------------------------------

  const startOfThisYear = moment(
    new Date(currentDate.getFullYear(), 0, 1)
  ).format("DD/MM/YYYY");
  const endOfThisYear = moment(
    new Date(currentDate.getFullYear(), 11, 31)
  ).format("DD/MM/YYYY");

  //---custom -------------------------------------------------

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  //--------------------------------------------------------------

  //----------------------------------------------------------------------

  const handleDateRangeSelected = (range) => {
    setDateRange1(range);
    console.log(range);
  };

  const onDateRangeSelected = (range) => {
    //setSelectedOption("");
    handleDateRangeSelected(range);
  };

  function DateRangePicker({ onDateRangeSelected }) {
    // const [selectedOption, setSelectedOption] = useState();

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption);

      switch (event.target.value) {
        case "Today":
          onDateRangeSelected(getTodayRange());
          break;
        case "This Week":
          onDateRangeSelected(getWeekRange());
          break;
        case "This Month":
          onDateRangeSelected(getMonthRange());
          break;
        case "This Year":
          onDateRangeSelected(getThisYearRange());
          break;
        case "All Time":
          onDateRangeSelected(getAllTimeRange());
          break;
        case "Custom":
          // onDateRangeSelected(getCustomRange());
          break;
        default:
          break;
      }
    };

    const getTodayRange = () => {
      fetchSiteDetailsData("Today");
    };

    const getCustomRange = () => {
      fetchSiteDetailsData("Custom");
    };

    const getAllTimeRange = () => {
      fetchSiteDetailsData("All Time");
    };

    const getWeekRange = () => {
      fetchSiteDetailsData("This Week");
    };

    const getMonthRange = () => {
      console.log("inside the month range function........");
      fetchSiteDetailsData("This Month");
    };

    const getThisYearRange = () => {
      fetchSiteDetailsData("This Year");
    };

    //----------------------------------------------------------------------------------------

    return (
      <div className="daterange-card">
        <Row>
          <Col xs="6">
            <FormGroup>
              <Input
                type="select"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="All Time">All Time</option>
                <option value="Today">Today</option>
                <option value="This Week">This Week</option>
                <option value="This Month">This Month</option>
                <option value="This Year">This Year</option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              {selectedOption === "Custom" && (
                <Fragment>
                  <Row className="d-flex justify-content-end">
                    <Col xl="6">
                      <div className="daterange-card">
                        <div className="mb-3">
                          <FormGroup>
                            <DatePicker
                              className="form-control digits"
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              onChange={(d) => {
                                setDateRange(d);
                                console.log(d);
                                console.log(startDate);
                                console.log(endDate);
                                fetchSiteDetailsData("Custom");
                              }}
                              isClearable={true}
                              placeholderText="Enter Date Range"
                              dateFormat="dd-MM-yyyy"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
  useEffect(() => {
    fetchSiteDetailsData("All Time");

    fetchDBcount();
    if (state.state && state.state.id) {
      ViewDistributionBoxModal(state.state);
    }
  }, [selectedSiteGlobal]);

  async function fetchDBcount() {
    let nextToken = "";
    let assets = [];
    await client
      .graphql({
        query: queries.listDistributionBoxes,
        variables: {
          limit: limit,
        },
        authMode: "API_KEY",
      })
      .then(async (result) => {
        let assetDatas = result.data.listDistributionBoxes.items;
        nextToken = result.data.listDistributionBoxes.nextToken;
        console.log(
          "first token count",
          result.data.listDistributionBoxes.items.length
        );
        console.log("nextToken 1", nextToken);
        if (result.data.listDistributionBoxes.items.length > 0) {
          assets.push(assetDatas);
        }
        while (nextToken !== null) {
          await client
            .graphql({
              query: queries.listDistributionBoxes,
              variables: {
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            })
            .then((results) => {
              nextToken = results.data.listDistributionBoxes.nextToken;
              console.log("nextToken", nextToken);
              console.log(results.data.listDistributionBoxes.items.length);
              if (results.data.listDistributionBoxes.items.length > 0) {
                assets.push(results.data.listDistributionBoxes.items);
              }
            })
            .catch((error) => {
              console.log(error);
              setDistributionSite1([]);
            });
        }
        console.log(assets);
        if (assets.length > 0) {
          const mergeAssets = assets.flat(1);
          console.log(mergeAssets);
          //--
          setDistributionSite1(mergeAssets);
        }
      })
      .catch((error) => {
        console.log(error);
        setDistributionSite1([]);
      });
  }

  async function fetchSiteDetailsData(DateRange) {
    let siteDetailsData = [];
    if (LoginUserGroup === "admin") {
      setIsLoading(true);
      //---
      let nextToken = "";
      let assets = [];
      await client
        .graphql({
          query: queries.listSiteDetails,
          authMode: "API_KEY",
          variables: {
            limit: limit,
          },
        })
        .then(async (result) => {
          let assetDatas = result.data.listSiteDetails.items;
          nextToken = result.data.listSiteDetails.nextToken;
          console.log(
            "first token count",
            result.data.listSiteDetails.items.length
          );
          console.log("nextToken 1", nextToken);
          if (result.data.listSiteDetails.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            await client
              .graphql({
                query: queries.listSiteDetails,
                authMode: "API_KEY",
                variables: {
                  limit: limit,
                  nextToken: nextToken,
                },
                authMode: "API_KEY",
              })
              .then((results) => {
                nextToken = results.data.listSiteDetails.nextToken;
                console.log("nextToken", nextToken);
                console.log(results.data.listSiteDetails.items.length);
                if (results.data.listSiteDetails.items.length > 0) {
                  assets.push(results.data.listSiteDetails.items);
                }
              })
              .catch((error) => {
                console.log(error);
                setCollection([]);
              });
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            console.log(result);
            const siteDetail = mergeAssets;
            const sortedItems = mergeAssets.sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            console.log(sortedItems);
            const filteredSites = sortedItems.filter(
              (item) => item.id === selectedSiteGlobal
            );
            console.log(filteredSites);
            const sitefiltered = selectedSiteGlobal
              ? filteredSites
              : sortedItems;
            console.log(sitefiltered);

            console.log(siteDetail);
            if (DateRange === "This Week") {
              siteDetailsData = sitefiltered.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return (
                  createdAtDate.isSameOrAfter(
                    moment(startOfWeek, "DD/MM/YYYY"),
                    "day"
                  ) &&
                  createdAtDate.isSameOrBefore(
                    moment(endOfWeek, "DD/MM/YYYY"),
                    "day"
                  )
                );
              });
            } else if (DateRange === "This Month") {
              siteDetailsData = sitefiltered.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return createdAtDate.isBetween(
                  moment(startOfMonth, "DD/MM/YYYY"),
                  moment(endOfMonth, "DD/MM/YYYY"),
                  null,
                  "[]"
                );
              });
            } else if (DateRange === "Today") {
              siteDetailsData = sitefiltered.filter(
                (item) =>
                  moment(item.createdAt).format("DD/MM/YYYY") ===
                  moment(new Date()).format("DD/MM/YYYY")
              );
            } else if (DateRange === "This Year") {

              siteDetailsData = sitefiltered.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return createdAtDate.isBetween(
                  moment(startOfThisYear, "DD/MM/YYYY"),
                  moment(endOfThisYear, "DD/MM/YYYY"),
                  null,
                  "[]"
                );
              });
            } else if (DateRange === "Custom") {
              siteDetailsData = sitefiltered.filter((item) => {
                const createdAtDate = moment(item.createdAt);
                return createdAtDate.isBetween(
                  moment(startDate, "DD/MM/YYYY"),
                  moment(endDate, "DD/MM/YYYY"),
                  null,
                  "[]"
                );
              });
            } else if (DateRange === "All Time") {
              siteDetailsData = sitefiltered.filter((item) => !item._delete);
            }
            //setIsLoading(false);
            setSiteDetails(siteDetailsData);
            setCollection(cloneDeep(siteDetailsData.slice(0, countPerPage)));
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setCollection([]);
          setIsLoading(false);
        });
    } else if (LoginUserGroup === "site_user") {
      const collectArray2 = [];
      setIsLoading(true);
      //--
      const response = await client.graphql({
        query: mergedQueries.getSiteUsersFromLink,
        authMode: "API_KEY",
        variables: {
          //nextToken: '',
          assetownerID: LoginUserDetails.assetOwnerId,
          userdetailsID: LoginUserDetails.id,
        },
      });
      console.log(response);
      let nextToken = "";
      let sitess = [];
      if (response.data.siteDetailsByAssetownerID.items.length > 0) {
        let sites = response.data.siteDetailsByAssetownerID.items;
        nextToken = response.data.siteDetailsByAssetownerID.nextToken;
        for (let site of sites) {
          console.log(site);
          console.log(site.LinkUsersAndSites.items.length);
          if (site.LinkUsersAndSites.items.length > 0) {
            sitess.push(site);
          }
        }
        while (nextToken !== null) {
          const results = await client.graphql({
            query: mergedQueries.getSiteUsersFromLink,
            variables: {
              nextToken: nextToken,
              assetownerID: LoginUserDetails.assetOwnerId,
              userdetailsID: LoginUserDetails.id,
            },
            authMode: "API_KEY",
          });
          nextToken = results.data.siteDetailsByAssetownerID.nextToken;
          sites = results.data.siteDetailsByAssetownerID.items;
          for (let site of sites) {
            console.log(site);
            console.log(site.LinkUsersAndSites.items.length);
            if (site.LinkUsersAndSites.items.length > 0) {
              sitess.push(site);
            }
          }
        }
        console.log(sitess);
        if (sitess.length > 0) {
          const mergeSites = sitess.flat(1);
          const sortedItems = mergeSites.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          console.log(sortedItems);
          const filteredSites = sortedItems.filter(
            (item) => item.id === selectedSiteGlobal
          );
          console.log(filteredSites);
          const sitefiltered = selectedSiteGlobal ? filteredSites : sortedItems;
          console.log(sitefiltered);
          if (DateRange === "This Week") {
            siteDetailsData = sitefiltered.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return (
                createdAtDate.isSameOrAfter(
                  moment(startOfWeek, "DD/MM/YYYY"),
                  "day"
                ) &&
                createdAtDate.isSameOrBefore(
                  moment(endOfWeek, "DD/MM/YYYY"),
                  "day"
                )
              );
            });
          } else if (DateRange === "This Month") {
            siteDetailsData = sitefiltered.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfMonth, "DD/MM/YYYY"),
                moment(endOfMonth, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Today") {
            siteDetailsData = sitefiltered.filter(
              (item) =>
                moment(item.createdAt).format("DD/MM/YYYY") ===
                moment(new Date()).format("DD/MM/YYYY")
            );
          } else if (DateRange === "This Year") {
            siteDetailsData = sitefiltered.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startOfThisYear, "DD/MM/YYYY"),
                moment(endOfThisYear, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "Custom") {
            siteDetailsData = sitefiltered.filter((item) => {
              const createdAtDate = moment(item.createdAt);
              return createdAtDate.isBetween(
                moment(startDate, "DD/MM/YYYY"),
                moment(endDate, "DD/MM/YYYY"),
                null,
                "[]"
              );
            });
          } else if (DateRange === "All Time") {
            siteDetailsData = sitefiltered.filter((item) => !item._delete);
          }
          setSiteDetails(siteDetailsData);
          setCollection(cloneDeep(siteDetailsData.slice(0, countPerPage)));
          setIsLoading(false);
        } else {
          setSiteDetails([]);
          setCollection([]);
          setIsLoading(false);
        }
      }
    } else {
      const collectArray2 = [];
      setIsLoading(true);
      //--

      const dataDetails = await client.graphql({
        query: queries.getAssetOwner,
        authMode: "API_KEY",
        variables: {
          id: LoginUserDetails.id,
          limit: limit,
        },
      });
      if (dataDetails.data.getAssetOwner !== null) {
        const AOcompanyNames = dataDetails.data.getAssetOwner;
        let nextToken = "";
        let assets = [];
        const result = await client.graphql({
          query: queries.siteDetailsByAssetownerID,
          variables: {
            assetownerID: LoginUserDetails.id,
            limit: limit,
          },
          authMode: "API_KEY",
        });
        if (result.data.siteDetailsByAssetownerID.items.length > 0) {
          let assetDatas = result.data.siteDetailsByAssetownerID.items;
          nextToken = result.data.siteDetailsByAssetownerID.nextToken;
          if (result.data.siteDetailsByAssetownerID.items.length > 0) {
            assets.push(assetDatas);
          }
          while (nextToken !== null) {
            const results = await client.graphql({
              query: queries.siteDetailsByAssetownerID,
              variables: {
                assetownerID: LoginUserDetails.id,
                limit: limit,
                nextToken: nextToken,
              },
              authMode: "API_KEY",
            });
            if (results.data.siteDetailsByAssetownerID.items.length > 0) {
              nextToken = results.data.siteDetailsByAssetownerID.nextToken;
              if (results.data.siteDetailsByAssetownerID.items.length > 0) {
                assets.push(results.data.siteDetailsByAssetownerID.items);
              }
            }
          }
          console.log(assets);
          if (assets.length > 0) {
            const mergeAssets = assets.flat(1);
            console.log(mergeAssets);
            //--
            const siteDetail = mergeAssets;
            if (siteDetail.length > 0) {
              siteDetail.map((site) => {
                collectArray2.push({
                  id: site.id,
                  unitNumber: site.unitNumber,
                  siteName: site.siteName,
                  companydetailsID: site.companydetailsID,
                  suburb: site.suburb,
                  state: site.state,
                  createdAt: site.createdAt,
                  companyName: AOcompanyNames.companyName,
                  status: site.status,
                });
                const sortedItems = collectArray2.sort((a, b) => {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                });
                console.log(sortedItems);
                const filteredSites = sortedItems.filter(
                  (item) => item.id === selectedSiteGlobal
                );
                console.log(filteredSites);
                const sitefiltered = selectedSiteGlobal
                  ? filteredSites
                  : sortedItems;
                console.log(sitefiltered);

                if (DateRange === "This Week") {
                  siteDetailsData = sitefiltered.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return (
                      createdAtDate.isSameOrAfter(
                        moment(startOfWeek, "DD/MM/YYYY"),
                        "day"
                      ) &&
                      createdAtDate.isSameOrBefore(
                        moment(endOfWeek, "DD/MM/YYYY"),
                        "day"
                      )
                    );
                  });
                } else if (DateRange === "This Month") {
                  siteDetailsData = sitefiltered.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfMonth, "DD/MM/YYYY"),
                      moment(endOfMonth, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Today") {
                  siteDetailsData = sitefiltered.filter(
                    (item) =>
                      moment(item.createdAt).format("DD/MM/YYYY") ===
                      moment(new Date()).format("DD/MM/YYYY")
                  );
                } else if (DateRange === "This Year") {
                  siteDetailsData = sitefiltered.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startOfThisYear, "DD/MM/YYYY"),
                      moment(endOfThisYear, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "Custom") {
                  siteDetailsData = sitefiltered.filter((item) => {
                    const createdAtDate = moment(item.createdAt);
                    return createdAtDate.isBetween(
                      moment(startDate, "DD/MM/YYYY"),
                      moment(endDate, "DD/MM/YYYY"),
                      null,
                      "[]"
                    );
                  });
                } else if (DateRange === "All Time") {
                  siteDetailsData = sitefiltered.filter(
                    (item) => !item._delete
                  );
                }
                setIsLoading(false);
                setSiteDetails(
                  siteDetailsData.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  )
                );
                setCollection(
                  cloneDeep(siteDetailsData.slice(0, countPerPage))
                );
              });
            }
          }
        }
      }
    }
  }

  const toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  const handleMainButtonClick = () => {
    // Handle the click on the main button here
    alert("Main Button Clicked");
  };

  const handleDropdownItemClick = (action) => {
    // Handle the click on a dropdown item here
    alert(`Dropdown Item Clicked: ${action}`);
  };

  // date range ends
  const renderList = (
    <Fragment>
      <Container fluid={true} className="ecommerce-dash">
        <br></br>
        <Row>
          <DateRangePicker
            onDateRangeSelected={onDateRangeSelected}
          ></DateRangePicker>
        </Row>
        <Row>
          <Col md="12" className="project-list">
            <Card className="custom-card">
              <CardHeader>
                <Row>
                  <Col md="6">
                    <InputGroup>
                      <span className="input-group-text">
                        <i className="icon-search"></i>
                      </span>
                      <Input
                        type="search"
                        placeholder="Search Sites.."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="3"></Col>
                  {LoginUserGroup !== "site_user" && (
                    <Col md="3">
                      <Dropdown className="separated-btn">
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          Add Sites
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {userPermission.includes("Create Sites") && (
                            <Dropdown.Item
                              href={`${process.env.PUBLIC_URL}/site/addSite`}
                            >
                              Add Site
                            </Dropdown.Item>
                          )}
                          {LoginUserGroup === "admin" && (
                            <Dropdown.Item
                              href={`${process.env.PUBLIC_URL}/site/addSite`}
                            >
                              Add Site
                            </Dropdown.Item>
                          )}
                          {userPermission.includes("Import Sites") && (
                            <Dropdown.Item
                              href={`${process.env.PUBLIC_URL}/bulkimport`}
                            >
                              Bulk Import
                            </Dropdown.Item>
                          )}
                          {/* {LoginUserGroup === "admin" && (
                            <Dropdown.Item
                              href={`${process.env.PUBLIC_URL}/bulkimport`}
                            >
                              Bulk Import
                            </Dropdown.Item>
                          )} */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Site Name</th>
                          <th>Company Name</th>
                          <th>Address</th>
                          <th>Added On</th>
                          <th>Approval Status</th>
                          <th>Approve</th>
                          <th>Distribution Board Count</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {collection.map((site) => (
                          <tr key={site.id}>
                            <td>{site.siteName}</td>
                            <td>{getCompanyName(site.companydetailsID)}</td>
                            <td>{site.unitNumber}</td>
                            <td>
                              {moment(site.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>{site.status}</td>
                            <td>
                              <Media body className="icon-state switch-sm">
                                <Label className="switch">
                                  <input
                                    type="checkbox"
                                    onChange={(e) => handleChange(site, e)}
                                  />
                                  <span
                                    className={ApproveStatus(site.status)}
                                  ></span>
                                </Label>
                              </Media>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="customDBcss"
                                onClick={() => {
                                  callDb(site);
                                }}
                              >
                                {getDistributionBoxCount(site.id)}
                              </button>
                            </td>
                            <td>
                              <Btn
                                attrBtn={{
                                  className: "btn-outline-* btn-xs m-r-5",
                                  color: "secondary",
                                  title: "Link Users",
                                  onClick: () => {
                                    LinkUserModal(site);
                                  },
                                }}
                              >
                                <i className="fa fa-group"></i>
                              </Btn>
                              {/* <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'danger', title: 'Delete',  onClick: () => handelDelete(`${site.id}`, `${site._version}`, `${site.unitNumber}`)}} ><i className="fa fa-trash-o"></i></Btn> */}
                              {userPermission.includes("Edit Sites") && (
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "info",
                                    title: "Edit",
                                    onClick: () =>
                                      navigate(
                                        `${process.env.PUBLIC_URL}/site/addSite`,
                                        { state: `${site.id}` }
                                      ),
                                  }}
                                >
                                  <i className="fa fa-edit "></i>
                                </Btn>
                              )}
                              {userPermission.includes("View Sites") && (
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "success",
                                    title: "View",
                                    onClick: () => {
                                      ViewLargeModal(site);
                                    },
                                  }}
                                >
                                  <i className="fa fa-eye"></i>{" "}
                                </Btn>
                              )}
                              {/* <Btn attrBtn={{ className: "btn-outline-* btn-xs m-r-5" , color: 'success', title: 'View', onClick: () => navigate(`${process.env.PUBLIC_URL}/site/addSite`, {state: {id:`${site.id}`,view:`view`}})}} ><i className="fa fa-eye"></i> </Btn> */}
                              {LoginUserGroup !== "site_user" && (
                                <Btn
                                  attrBtn={{
                                    className: "btn-outline-* btn-xs m-r-5",
                                    color: "primary",
                                    title: "Link Distribution Boards",
                                    onClick: () => {
                                      ViewDistributionBoxModal(site);
                                    },
                                  }}
                                >
                                  <i className="fa fa-th"></i>
                                </Btn>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </div>
              <CardFooter className="row">
                <Pagination
                  className="p-t-10"
                  pageSize={countPerPage}
                  onChange={updatePage}
                  current={currentPage}
                  total={value ? filteredData.length : siteDetails.length}
                  showTotal={(total, range) =>
                    `${range[0]} - ${range[1]} of ${total} items`
                  }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={ViewLarge}
          toggle={ViewLargeModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={ViewLargeModaltoggle}>
            View Site List
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <Row>
                <Col md="12">
                  <iframe
                    src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.address}&zoom=14&size=400x400&key=${GOOGLEAPIKEY}&output=embed`}
                  ></iframe>
                </Col>
              </Row>
              <Row>
                <Col md="6 mb-3">
                  <Label>Unit Number: </Label>
                  <Label>{viewData.unitNumber}</Label>
                </Col>
                <Col md="6 mb-3">
                  <Label>Company Name: </Label>
                  <Label>{viewData.companyName}</Label>
                </Col>
              </Row>
              <Row>
                <Col md="6 mb-3">
                  <Label>Address: </Label>
                  <Label>
                    {viewData.street} {viewData.suburb} {viewData.state}{" "}
                    {viewData.country} {viewData.postCode}
                  </Label>
                </Col>
                <Col md="6 mb-3">
                  <Label>Distribution Boxes : </Label>
                  <UL>
                    {distributionSite.map((item, i) => (
                      <LI key={i}>
                        {item.distributionBoxName}
                        <br />
                      </LI>
                    ))}
                  </UL>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <H5>Site Users & Service Providers</H5>
                  <div className="table-responsive">
                    <Table className="table-striped  table-de">
                      <thead className="custom-table-head">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Added On</th>
                          <th>User Type</th>
                        </tr>
                      </thead>
                      <tbody className="trhover">
                        {siteUsers.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {item.fname} {item.lname}
                            </td>
                            <td>{item.email}</td>
                            <td>
                              {moment(item.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              {item.userGroup === "site_user"
                                ? "Site Users"
                                : "Service Providers"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                {/* <Col md="6">
                      
                      <UL>
                        {
                          siteUsers.map((item, i) => 
                            <LI key={i}>{item.fname} {item.lname}</LI>  
                          )
                        }
                      </UL>
                    </Col>
                    <Col md="6">
                      <Label>:</Label>
                      <UL>
                        {
                          spUsers.map((item, index) => 
                            <LI key={index}>{item.fname} {item.lname}</LI>  
                          )
                        }
                      </UL>
                    </Col> */}
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={LinkUser}
          toggle={LinkUserModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={LinkUserModaltoggle}>
            Link Users To {viewData.siteName}
          </ModalHeader>
          <ModalBody>
            <LinkUserWithSite
              data={viewData}
              LinkUserModaltoggle={LinkUserModaltoggle}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={Viewdbid}
          toggle={ViewdbidModaltoggle}
          size="lg"
          centered
        >
          <ModalHeader toggle={ViewdbidModaltoggle}>
            Add Distribution Board to {selectedSiteId.siteName}
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <br></br>
              <Form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col md="9">
                    <ul>
                      {fields.map((item, index) => {
                        return (
                          <li key={item.id}>
                            <Row>
                              <Col md="5 mb-3">
                                <Label>Distribution Board</Label>
                                <input
                                  className="form-control"
                                  placeholder="Distribution Board"
                                  {...register(
                                    `distributionBoxDetails.${index}.distributionBox`,
                                    { required: true }
                                  )}
                                />
                              </Col>
                              <Col md="5 mb-3">
                                <Label>Distribution Board Location</Label>
                                <Autocomplete
                                  onLoad={onLoad}
                                  onPlaceChanged={onPlaceChanged}
                                  options={{
                                    types: ["address"],
                                    componentRestrictions: { country: "AU" },
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Distribution Board Location"
                                    {...register(
                                      `distributionBoxDetails.${index}.location`,
                                      { required: true }
                                    )}
                                  />
                                </Autocomplete>
                                {/* <GooglePlacesAutocomplete
                                  onLoad={onLoad}
                                  onPlaceChanged={onPlaceChanged}
                                  selectProps={{
                                    fetchDetails: true,
                                    onChange: (data, details, place) => {
                                      setLocation(data.label);
                                      console.log("details", details);
                                    },
                                  }}
                                  options={{
                                    types: ["address"],
                                    componentRestrictions: { country: "AU" },
                                  }}
                                />
                                <input
                                  value={locations}
                                  type="hidden"                                  
                                  className="form-control"
                                  placeholder="Distribution Board Location"
                                  {...register(
                                    `distributionBoxDetails.${index}.location`
                                  )}
                                /> */}
                              </Col>
                              <Col md="2 mb-3">
                                <Label className="m-t-50"></Label>
                                {index > 0 && (
                                  <i
                                    className="icofont icofont-trash"
                                    onClick={() => remove(index)}
                                  ></i>
                                )}
                              </Col>
                            </Row>
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                  <Col md="3">
                    <button
                      type="button"
                      className="btn btn-primary m-r-10"
                      onClick={() => {
                        append({ distributionBox: "", location: "" });
                      }}
                    >
                      {" "}
                      <i className="icofont icofont-plus"></i> Add Distribution
                      Board{" "}
                    </button>
                  </Col>
                </Row>
                <Btn attrBtn={{ color: "primary" }}>Save</Btn>
              </Form>
            </Container>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={Viewdbid1}
          toggle={ViewdbidModaltoggle1}
          size="lg"
          centered
        >
          <ModalHeader toggle={ViewdbidModaltoggle1}>
            Distribution Boards linked to {selectedSiteId.siteName}
          </ModalHeader>
          <ModalBody className="modal-body">
            <Container fluid={true}>
              <br></br>
              <Row>
                <Col md="9">
                  <ul>
                    {distributionSiteFiltrer.map((item, index) => {
                      return (
                        <li key={index}>
                          <Label>{item.distributionBoxName}</Label>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        <Modal isOpen={deleteAllModal} toggle={deleteAllToggle}>
          <ModalBody>
            <Form className="form-bookmark">
              <H4>
                {statusSp === "Inactive"
                  ? `Are you sure you want to deactivate the site? Deactivating the site may result in the loss of associated assets and tasks for "${siteName}"?`
                  : `Activate the site "${siteName}"?`
                }
              </H4>
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "ms-1",
                  onClick: deleteAllToggle, // Close the modal on cancel
                }}
              >
                Cancel
              </Btn>
              &nbsp;&nbsp;
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => handleConfirmStatusChange(),
                }}
              >
                Ok
              </Btn>
            </Form>
          </ModalBody>
        </Modal>

      </Container>
    </Fragment>
  );
  return (
    <div>
      {isLoading ? <LoadingSpinner /> : renderList}
      {errorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default SiteListDetails;
